import { useMerchantTheme } from '@domain/merchants/queries/useMerchantTheme';
import { ECustomThemeViews } from '@olo-web/types/enums';
import { IMerchantTheme } from '@olo-web/types/merchantTheme.interface';
import { storage } from '@olo-web/utils/google/firebase';
import { FirebaseError } from 'firebase-admin';
import { useQuery, UseQueryResult } from 'react-query';

export interface IPreviousImageResponse extends firebase.default.storage.Reference {
  url: string;
}

export const getAllMerchantImages = async (
  theme: IMerchantTheme,
  type: ECustomThemeViews
): Promise<IPreviousImageResponse[]> => {
  try {
    if (!storage) return [];
    const ref = storage().ref().child(`/${theme.id}/${type}`);
    const images = await ref.listAll();
    if (!images?.items?.length) return [];
    const promises = images.items.map((item) => item.getDownloadURL());
    const urls = await Promise.all(promises);
    return images.items.map((item, idx) => ({ ...item, url: urls[idx], fullPath: item.fullPath }));
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useAllMerchantImages(
  type: ECustomThemeViews
): UseQueryResult<IPreviousImageResponse[], FirebaseError> {
  const { data: theme } = useMerchantTheme();
  const enabled = !!(type && theme?.id);

  return useQuery(['previous-images', theme?.id, type], () => getAllMerchantImages(theme, type), {
    staleTime: 1000000,
    enabled,
  });
}
