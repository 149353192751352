import { theme } from '@chakra-ui/react';
const d = theme.components.Form;

const Form = {
  ...d,
  baseStyle: {
    helperText: {
      color: 'gray.500',
      fontSize: 'sm',
      lineHeight: 'normal',
      mt: 2,
    },
    requiredIndicator: {
      color: 'currentColor',
      marginStart: 1,
    },
  },
};

export default Form;
