import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSavedOrderState } from '@olo-web/client-state';

export const useCurrentMerchantSavedOrderId = (): string | null => {
  const savedOrders = useSavedOrderState();
  const router = useRouter();
  const { merchantId } = router.query;

  return useMemo(() => {
    return savedOrders[merchantId as string] || null;
  }, [savedOrders, merchantId]);
};
