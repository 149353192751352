/* eslint-disable react-hooks/rules-of-hooks */
import { useRouter } from 'next/router';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useLevelupCustomer } from '@olo-web/domain/customer/queries';
import {
  useIsLevelup,
  useIsOrderConfirmationPage,
  useIsOrderReceiptPage,
  useIsLevelUpCheckoutPage,
} from '@olo-web/utils/common/hooks';
import { useLevelupDispatch, useLevelupState } from '@olo-web/client-state';

export const useLevelup = () => {
  const isLevelup = useIsLevelup();
  // Necessary, otherwise will execute all this levelUp code and cause bugs in OLO. Mainly the onSuccessOrder function.
  if (!isLevelup) return {};

  const router = useRouter();
  const { userToken } = router.query;
  const {
    data: levelupUser,
    isLoading: isLoadingUser,
    error: errorUserInfo,
  } = useLevelupCustomer(userToken as string);

  const levelupDispatch = useLevelupDispatch();
  const { error: errorCheckInfo } = useLevelupState();
  const isOrderConfirmationPage = useIsOrderConfirmationPage();
  const isOrderReceiptPage = useIsOrderReceiptPage();
  const isLevelUpCheckoutPage = useIsLevelUpCheckoutPage();
  const isHistorical = isOrderConfirmationPage || isOrderReceiptPage;
  const onSuccessOrder = (order: IOrder) => {
    if (isHistorical || isLevelUpCheckoutPage) return;
    router.push({
      pathname: `/-/-/${order?.merchantID}/pay/${order?.checkId}`,
    });
  };

  const {
    data: order,
    isLoading: isLoadingOrder,
    isSuccess: orderSucceeded,
  } = useOrder(onSuccessOrder);

  const resetError = () => {
    levelupDispatch({
      type: 'LEVELUP_ERROR',
      payload: null,
    });
  };

  return {
    levelupOrder: order,
    levelupUser,
    levelupDispatch,
    errorUserInfo,
    errorCheckInfo,
    resetError,
    loading: isLoadingOrder || isLoadingUser,
    userToken,
    orderStored: orderSucceeded,
    onSuccessOrder,
  };
};
