import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMemo } from 'react';

export const useGetFullTableIdAndNumber = (): string => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const tableNumber: string = order?.tableNumber;
  const merchantTablePrefix: string = merchant?.dineintableidentifier;

  // This useMemo will return one string with the merchant table prefix and the table number
  return useMemo(() => {
    const tableNumFields: Array<string> = tableNumber?.split(' ');
    // check if table number string has 2 or more separate words, if so just take the last one which will be the actual number/letter
    return `${merchantTablePrefix} ${
      tableNumFields?.length > 1 ? tableNumFields[tableNumFields.length - 1] : tableNumber
    }`;
  }, [tableNumber, merchantTablePrefix]);
};
