import axios from 'axios';
import { useMutation, MutationOptions } from 'react-query';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '../queries/useOrder';
import { useDeliveryAddressState } from '@olo-web/client-state';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';

export const addDeliveryAddressToOrder = async (
  merchantId: string,
  orderId: string,
  address: IDeliveryAddress
): Promise<IPreviousOrder[]> => {
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/delivery-address`;
    const { data } = await axios.post(url, address);
    return data;
  } catch (error) {
    throw {
      message: error?.response?.data?.message,
      code: error?.response?.data?.code,
    };
  }
};

export const useAddDeliveryAddressToOrder = (options?: MutationOptions) => {
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();
  const { addressForm, address } = useDeliveryAddressState();
  const updateOrderInCache = useUpdateOrderInCache();

  return useMutation(
    () => addDeliveryAddressToOrder(merchant?.merchantId, order?.id, addressForm || address),
    {
      onSuccess: (data) => {
        updateOrderInCache(data);
      },
      ...options,
    }
  );
};
