import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useCustomerDispatch } from '@olo-web/client-state';
import { serializeToCamelCase } from '@olo-web/utils/common/functions';
import { identify } from 'react-fullstory';
import { identifyUser } from './../../../utils/metrics/datadog/index';
import { useSendEvent, useToast } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { login } from './useLogin';

export type SignUpProps = {
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  phone: string;
};

export const signup = async ({
  email,
  firstName,
  lastName,
  password,
  phone,
}: SignUpProps): Promise<any> => {
  try {
    const url = `/api/customers/register`;
    const body = {
      email,
      name: `${firstName} ${lastName}`,
      password,
      phone,
    };
    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useSignup(options?: UseMutationOptions<any, AxiosError, SignUpProps>) {
  const customerDispatch = useCustomerDispatch();
  const queryClient = useQueryClient();
  const { sendEvent } = useSendEvent();
  const { notify } = useToast();

  return useMutation(signup, {
    ...options,
    onSuccess: async (data, variables, context) => {
      const { email, firstName, lastName, password } = variables;
      const customerData = await login({ email, password });
      const customer = serializeToCamelCase({
        ...(customerData?.customer_response || {}),
      });
      queryClient.setQueryData(['customer', customer?.customerId], customer);
      customerDispatch({
        type: 'UPDATE',
        payload: {
          customerId: customer?.customerId,
          sessionId: customer?.sessionId,
          sessionSecret: customer?.sessionSecret,
          loginTimestamp: customer?.timestamp,
        },
      });
      identify(customer?.customer_id);
      identifyUser({ id: customer?.customerId, name: firstName + ' ' + lastName, email });
      notify({
        status: 'success',
        description: 'You’ve successfully created a new account!',
        isClosable: true,
      });
      sendEvent(EAnalyticsEventNames.CREATE_ACCOUNT, {
        googleAnalytics: {
          tagOverrides: {
            customerId: customer?.customerId,
          },
        },
      });
      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
  });
}
