import { useCheckoutState, useCustomerState } from '@olo-web/client-state';
import { useGiftCardBalance } from '@olo-web/domain';
import { useCheckout } from '@olo-web/domain/orders/mutations/useCheckout';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useCallback } from 'react';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';
import { useCustomerContactInfo } from './useCustomerContactInfo';
import { useCalculateMixedPayment } from './useCalculateMixedPayment';
import { useIsScanAndPayPage, useIsDineIn } from '@olo-web/utils/common/hooks';

export const useCompleteOrderWithCardOnTab = () => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const { mutateAsync: checkout } = useCheckout();
  const { orderNote, gclid, paymentForGuestIds, guestBalance } = useCheckoutState();
  const customer = useCustomerContactInfo();
  const { data: giftCard } = useGiftCardBalance();
  const { customerId, sessionId, sessionSecret } = useCustomerState();
  const { tipAmount, giftCardTipAmount, amount, giftCardAmount } = useCalculateMixedPayment();
  const isScanAndPayPage = useIsScanAndPayPage();
  const isSplitByAmount = useIsSplitByAmount();
  const isDineIn = useIsDineIn();

  const giftCardPaymentObject = giftCard
    ? {
        amount: giftCardAmount,
        tipAmount: giftCardTipAmount,
        giftCardNum: giftCard?.cardNumber,
      }
    : {};

  const cardOnTabPaymentObject = {
    tipAmount,
    vtsAuthCaptureInfo: {
      paymentChannel: 'qr-order-pay',
    },
  };

  const orderPaymentInfo = giftCard
    ? [{ ...giftCardPaymentObject }, { ...cardOnTabPaymentObject }]
    : [{ ...cardOnTabPaymentObject }];

  const checkoutWithCardOnTab = useCallback(() => {
    return checkout({
      merchantId: merchant?.merchantId,
      orderId: order?.id,
      checkId: order?.checkId,
      customerSessionDetails: {
        customerId,
        sessionId,
        sessionSecret,
      },
      orderNotes: orderNote,
      customer,
      paymentInfo: orderPaymentInfo,
      conversionId: gclid,
      conversionType: gclid ? 'google' : null,
    });
  }, [
    checkout,
    merchant?.merchantId,
    order?.id,
    order?.checkId,
    customerId,
    sessionId,
    sessionSecret,
    orderNote,
    customer,
    paymentForGuestIds,
    tipAmount,
    guestBalance,
    isScanAndPayPage,
    isDineIn,
    gclid,
    isSplitByAmount,
    giftCardPaymentObject,
    amount,
  ]);

  return checkoutWithCardOnTab;
};
