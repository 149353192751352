import { useRouter } from 'next/router';
import { setCookie } from 'nookies';
import { useMerchantGroup } from '@domain/merchants/queries/useMerchantGroup';
import { createMerchantPath } from '@domain/merchants/hooks/useMerchantPath';
import { useMenuDispatch, useAlertState, useCheckoutDispatch } from '@olo-web/client-state';
import { ECookies, EGALocations } from '@olo-web/types/enums';

import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useSendEvent } from '@olo-web/utils/common/hooks';

export function useChangeLocation() {
  const { data: group } = useMerchantGroup();
  const menuDispatch = useMenuDispatch();
  const checkoutDispatch = useCheckoutDispatch();
  const router = useRouter();
  const alertState = useAlertState();
  const { sendEvent } = useSendEvent();

  return async function (nextId?: string) {
    const nextMerchantId = alertState.alertContext?.nextMerchantId || nextId;
    if (!nextMerchantId) {
      return;
    }
    const currentMerchant = group?.merchants.find((m) => m.merchantId === nextMerchantId);

    menuDispatch({ type: 'UPDATE_SELECTED_GROUP', payload: '' });

    checkoutDispatch({
      type: 'CLEAR_SELECTED_CARD',
    });

    sendEvent(EAnalyticsEventNames.DEFAULT, {
      googleAnalytics: {
        eventInfo: {
          location: EGALocations.ONLINE_ORDER,
          action: 'click',
          object: EAnalyticsEventNames.LOCATION_SELECTION_CONFIRMATION,
        },
        eventMetadata: {
          merchant_id: currentMerchant.merchantId,
          location_name: currentMerchant.locationString,
        },
      },
    });

    const path = createMerchantPath({ merchant: currentMerchant, groupId: group?.id });

    setCookie(null, ECookies.preferredLocation, currentMerchant?.merchantId, {
      path: `/${path.split('/')[1]}`,
    });

    await router.push(path, path, { shallow: false });
    router.reload();
  };
}
