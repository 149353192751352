export const isModifierGroupValid = (mg: IMenuItemModifierGroupDisplay): boolean => {
  if (mg.min < 1) return true;

  const numModsSelected = mg.modifiers.filter((m) => {
    const isImplicitDefaultModSelected =
      m.implicitDefault === true &&
      m.options.findIndex(
        (option) => option.id === 'implicitly-selected' && option.isSelected === true
      ) !== -1;

    if (isImplicitDefaultModSelected) return false;

    return m.isSelected;
  }).length;

  if (numModsSelected < mg.min) return false;

  return true;
};
