import { theme } from '@chakra-ui/react';
const defaultStyle = theme.components.Select;

const Select = {
  ...defaultStyle,
  baseStyle: (): any => ({
    ...defaultStyle.baseStyle,
    field: {
      paddingLeft: '2.5rem',
      borderWidth: '30px',
      '& > select': {
        paddingLeft: 10,
      },
    },
  }),
  defaultProps: {
    colorScheme: 'primary',
    size: 'md',
    variant: 'outline',
  },
};

export default Select;
