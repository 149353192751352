import { theme, CSSWithMultiValues, RecursivePseudo } from '@chakra-ui/react';
import { IComponentThemeProps } from '../types';

const defaultStyle = theme.components.Button;
const baseStyle = defaultStyle.baseStyle as CSSWithMultiValues &
  RecursivePseudo<CSSWithMultiValues>;

const Button = {
  ...defaultStyle,
  baseStyle: {
    ...baseStyle,
    fontWeight: 600,
    letterSpacing: '0.01em',
    color: 'white',
    _disabled: {
      ...baseStyle?._disabled,
      opacity: 0.3,
    },
  },
  variants: {
    ...defaultStyle.variants,
    primaryModal: ({ colorScheme }: IComponentThemeProps): any => ({
      ...defaultStyle.variants.solid,
      bg: colorScheme?.['350'] ? `${colorScheme}.350` : `${colorScheme}.300`,
      color: 'white',
      maxWidth: '400px',
      width: '100%',
      height: '44px',
      borderRadius: '2px',
      _hover: {
        bg: `${colorScheme}.400`,
        _disabled: {
          bg: `${colorScheme}.300`,
        },
      },
    }),
    solid: ({ colorScheme }: IComponentThemeProps): any => ({
      ...defaultStyle.variants.solid,
      bg: colorScheme?.['350'] ? `${colorScheme}.350` : `${colorScheme}.300`,
      color: 'white',
      _hover: {
        bg: `${colorScheme}.400`,
        _disabled: {
          bg: `${colorScheme}.300`,
        },
      },
      _active: {
        bg: `${colorScheme}.500`,
        _disabled: {
          bg: `${colorScheme}.400`,
        },
      },
    }),
    outline: ({ colorScheme }: IComponentThemeProps): any => ({
      ...defaultStyle.variants.outline,
      bg: `${colorScheme}.50`,
      borderColor: colorScheme?.['350'] ? `${colorScheme}.350` : `${colorScheme}.300`,
      color: `blackAlpha.900`,
      _hover: {
        bg: `${colorScheme}.100`,
        _disabled: {
          bg: `${colorScheme}.50`,
        },
      },
      _active: {
        color: `${colorScheme}.500`,
        bg: `${colorScheme}.200`,
        _disabled: {
          bg: `${colorScheme}.50`,
        },
      },
    }),
    tertiary: ({ colorScheme }: IComponentThemeProps): any => ({
      ...defaultStyle.variants.link,
      bg: 'transparent',
      label: {
        color: colorScheme?.['350'] ? `${colorScheme}.350` : `${colorScheme}.300`,
      },
      color: colorScheme?.['350'] ? `${colorScheme}.350` : `${colorScheme}.300`,
      _hover: {
        color: `${colorScheme}.500`,
        _disabled: {
          color: `${colorScheme}.200`,
        },
      },
      _active: {
        color: `${colorScheme}.600`,
        _disabled: {
          color: colorScheme?.['350'] ? `${colorScheme}.350` : `${colorScheme}.300`,
        },
      },
    }),
    link: ({ colorScheme }: IComponentThemeProps): any => ({
      ...defaultStyle.variants.link,
      bg: 'transparent',
      label: {
        color: colorScheme?.['350'] ? `${colorScheme}.350` : `${colorScheme}.300`,
      },
      color: colorScheme?.['350'] ? `${colorScheme}.350` : `${colorScheme}.300`,
      _hover: {
        color: `${colorScheme}.500`,
        _disabled: {
          color: `${colorScheme}.200`,
        },
      },
      _active: {
        color: `${colorScheme}.600`,
        _disabled: {
          color: colorScheme?.['350'] ? `${colorScheme}.350` : `${colorScheme}.300`,
        },
      },
    }),
  },
  sizes: {
    ...defaultStyle.sizes,
    md: {
      ...defaultStyle.sizes.md,
      h: '44px',
      minW: '44px',
      fontSize: 'sm',
    },
  },
  defaultProps: {
    colorScheme: 'base',
    variants: 'outlined',
    size: 'md',
  },
};

export default Button;
