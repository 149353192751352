import { useEffect, useState, useCallback } from 'react';
import { auth } from '../firebase';
import { ELegalLinks } from '@olo-web/types/enums';

export const useThemeAuthUi = (elementId: string) => {
  const [ui, setUi] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const setUpUi = useCallback(async () => {
    const firebaseui = await import('firebaseui');
    const fbui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth());
    setUi(fbui);
    const config = {
      // signInSuccessUrl: '/',
      callbacks: {
        uiShown: () => setIsLoading(false),
        signInSuccessWithAuthResult: () => false,
      },
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: auth.EmailAuthProvider.PROVIDER_ID,
          signInMethod: auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
          url: `${window.location.host}/complete-complete-signin?path=${window.location.pathname}`,
        },
        auth.GoogleAuthProvider.PROVIDER_ID,
        // auth.FacebookAuthProvider.PROVIDER_ID,
        // auth.TwitterAuthProvider.PROVIDER_ID,
        // auth.GithubAuthProvider.PROVIDER_ID,
      ],
      tosUrl: ELegalLinks.TERMS_OF_USE,
      privacyPolicyUrl: ELegalLinks.PRIVACY_POLICY,
    };

    const startAuthUI = function (elementId: string) {
      fbui.start(elementId, config);
    };
    startAuthUI(elementId);
  }, [elementId]);

  useEffect(() => {
    setUpUi();
  }, [setUpUi]);

  return {
    isPending: !!ui?.isPendingRedirect(),
    isLoading,
  };
};
