import { useMemo } from 'react';
import { useOrderType } from '@olo-web/utils/common/hooks';
import { useMerchant } from '@domain/merchants/queries/useMerchant';

export const useCustomerMessage = () => {
  const orderType = useOrderType();
  const { data: merchant } = useMerchant();

  return useMemo(() => {
    return merchant?.orderTypes?.[orderType]?.customerMessage
      ? merchant?.orderTypes?.[orderType]?.customerMessage.trim()
      : null;
  }, [orderType, merchant]);
};
