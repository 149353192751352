import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useIsDineIn, useSendEvent } from '@olo-web/utils/common/hooks';
import { useSavedOrderDispatch } from '@olo-web/client-state';
import axios, { AxiosError } from 'axios';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useMutation, UseMutationOptions } from 'react-query';
import { useOrderIdFeatureFlagEnabled } from '@olo-web/utils/common/hooks/useOrderIdFeatureFlagEnabled';

type CreateEmptyOrderProps = {
  merchantId: string;
  orderInfo: ICreateOrderInfo;
};

export const createEmptyOrder = async ({
  merchantId,
  orderInfo,
}: CreateEmptyOrderProps): Promise<IOrder> => {
  try {
    const url = `/api/merchants/${merchantId}/orders`;
    const { data } = await axios.post(url, orderInfo);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useCreateEmptyOrder = (
  options?: UseMutationOptions<IOrder, AxiosError, CreateEmptyOrderProps>
) => {
  const updateOrderInCache = useUpdateOrderInCache();
  const savedOrderDispatch = useSavedOrderDispatch();
  const isDineIn = useIsDineIn();
  const { sendEvent } = useSendEvent();
  const orderIdFFEnabled = useOrderIdFeatureFlagEnabled();

  return useMutation(createEmptyOrder, {
    ...options,
    onSuccess: (data, variables, context) => {
      const { merchantId } = variables;
      const queryKey = isDineIn
        ? orderIdFFEnabled
          ? ['order', merchantId, data.id]
          : ['order', merchantId, data.tableNumber]
        : undefined;

      updateOrderInCache(data, queryKey);

      if (data?.id && merchantId && !isDineIn) {
        savedOrderDispatch({
          type: 'SAVE',
          payload: {
            merchantId,
            orderId: data?.id,
          },
        });
        sendEvent(EAnalyticsEventNames.ORDER_STARTED);
      }
      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
  });
};
