import { theme } from '@chakra-ui/react';
const d = theme.components.Alert;

const Alert = {
  ...d,
  baseStyle: {
    ...d.baseStyle,
    description: {
      ...d.baseStyle.description,
      fontSize: 'sm',
      lineHeight: 'sm',
    },
    title: {
      ...d.baseStyle.title,
      fontSize: 'sm',
      lineHeight: 'sm',
    },
  },
  defaultProps: {
    variant: 'left-accent',
    colorScheme: 'primary',
  },
};

export default Alert;
