import { useMerchant, useMerchantGroup } from '@domain/merchants/queries';
import { useMutation, MutationOptions } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useSavedDineInContextState, useModalDispatch } from '@olo-web/client-state';
import { useToast, useSendEvent } from '@olo-web/utils/common/hooks';
import { createFriendlyErrorMessage } from '@olo-web/utils/common/functions/createFriendlyErrorMessage';
import { EAnalyticsEventNames, EGALocations } from '@olo-web/types/enums';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useCheckoutDispatch, useCheckoutState } from '@olo-web/client-state/checkoutContext';

// The paymentauthorization sends items ontop of adding the pre-auth
export const startATabAuth = async (
  orderId: string,
  merchantId: string,
  guestId: string,
  token: string,
  zipCode: string
): Promise<IOrder> => {
  try {
    const { data } = await axios.post(
      `/api/merchants/${merchantId}/orders/${orderId}/paymentauthorization`,
      {
        guestId,
        authInfo: { paymentToken: token, postalCode: zipCode },
      }
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useStartATabAuth = (options?: MutationOptions<any, AxiosError, string>) => {
  const { data: group } = useMerchantGroup();
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const savedDineInState = useSavedDineInContextState();
  const { notify } = useToast();
  const merchId = merchant?.merchantId || group?.eGiftCardMerchantId;
  const { sendEvent } = useSendEvent();
  const modalDispatch = useModalDispatch();
  const checkoutDispatch = useCheckoutDispatch();
  const updateOrderInCache = useUpdateOrderInCache();
  const checkoutState = useCheckoutState();
  const preauthAmount = Math.max(
    Number(order?.balanceDueAmount),
    Number(merchant?.defaulttabamount || 0)
  );
  return useMutation(
    (token) =>
      startATabAuth(order.id, merchId, savedDineInState?.guest?.id, token, checkoutState?.zipCode),
    {
      ...options,
      onMutate: () => {
        checkoutDispatch({
          type: 'SET_START_A_TAB_LOADING',
          payload: true,
        });
      },
      onSuccess: async (order) => {
        updateOrderInCache(order);
        checkoutDispatch({
          type: 'SET_START_A_TAB_LOADING',
          payload: false,
        });
        sendEvent(EAnalyticsEventNames.DEFAULT, {
          googleAnalytics: {
            eventInfo: {
              location: EGALocations.DINE_IN,
              action: 'click',
              object: EAnalyticsEventNames.START_A_TAB_SUCCESS,
            },
            eventMetadata: {
              authAmount: preauthAmount,
              paymentType: checkoutState?.selectedPaymentMethod,
            },
          },
        });
        modalDispatch({ type: 'CLOSE_MODAL' });
      },
      onError: (error) => {
        //This resets the CC fields when the end point errors so they can re-enter their CC info
        checkoutDispatch({
          type: 'REMOVE_PAYMENT',
        });
        checkoutDispatch({
          type: 'SET_START_A_TAB_LOADING',
          payload: false,
        });
        notify({
          status: 'error',
          title: "There's an issue with your card",
          description: createFriendlyErrorMessage(error),
          variant: 'left-accent',
          position: 'top',
          isClosable: true,
        });
        sendEvent(EAnalyticsEventNames.DEFAULT, {
          googleAnalytics: {
            eventInfo: {
              location: EGALocations.DINE_IN,
              action: 'click',
              object: EAnalyticsEventNames.START_A_TAB_FAIL,
            },
            eventMetadata: {
              errorInformation: error?.message,
              paymentType: checkoutState?.selectedPaymentMethod,
              authAmount: preauthAmount,
            },
          },
        });
      },
    }
  );
};
