import { createStore } from '@olo-web/utils/common/store';
import { AlertProps } from '@chakra-ui/react';

interface IAlertState {
  alertKey?: string;
  alertContext?: any;
  alertProps?: Omit<AlertProps, 'children' | 'onClose' | 'isOpen'>;
  isCloseOnOutsideClick?: boolean;
}

type TClosealertAction = Omit<IStandardAction<'CLOSE_ALERT'>, 'payload'>;
type TOpenalertAction = IStandardAction<'OPEN_ALERT', IAlertState>;

export type TalertAction = TClosealertAction | TOpenalertAction;

const initialState: IAlertState = {
  alertKey: '',
  alertContext: {},
  alertProps: {},
  isCloseOnOutsideClick: true,
};

const reducer = (state: IAlertState, action: TalertAction): IAlertState => {
  switch (action.type) {
    case 'OPEN_ALERT':
      return { ...state, ...action.payload };
    case 'CLOSE_ALERT':
      return initialState;
    default:
      return state;
  }
};

export const [AlertContextProvider, useAlertDispatch, useAlertState] = createStore<
  IAlertState,
  TalertAction
>(reducer, initialState, {
  name: 'alert',
});
