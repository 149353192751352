import { useMemo } from 'react';
import { EOrderTime } from '@olo-web/types/enums';
import { useScheduleTypeState } from '@olo-web/client-state';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useFeatureFlags } from '@domain/featureFlags';
import { isFeatureEnabledForMerchant } from '@olo-web/utils/common/functions/isFeatureEnabledForMerchant';
import { useMerchant } from '@domain/merchants/queries/useMerchant';

export const useIsASAP = () => {
  const { data: order } = useOrder();
  const { data: flags } = useFeatureFlags();
  const { data: merchant } = useMerchant();

  const enforceisASAPFeatureFlagEnabled = isFeatureEnabledForMerchant({
    featureEnabled: flags?.handleAsapLogicOnServer?.on,
    allowList: flags?.handleAsapLogicOnServer?.allow,
    denyList: flags?.handleAsapLogicOnServer?.deny,
    merchantId: merchant?.merchantId,
  });
  const scheduleType = useScheduleTypeState();

  const isUsingScheduleTypeAsap = useMemo(() => {
    return scheduleType === EOrderTime.ASAP;
  }, [scheduleType]);

  return enforceisASAPFeatureFlagEnabled ? order?.isAsap : isUsingScheduleTypeAsap;
};
