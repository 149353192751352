import { useCheckoutState, useCustomerState } from '@olo-web/client-state';
import { useGiftCardBalance } from '@olo-web/domain';
import { useCheckout } from '@olo-web/domain/orders/mutations/useCheckout';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { usePaymentMethodValues } from '@olo-web/domain/payments/hooks';
import { useCallback } from 'react';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';
import { useCustomerContactInfo } from './useCustomerContactInfo';
import { useDeliverServiceData } from './useDoorDashDeliveryData';

export const useCompleteOrderWithGiftCard = () => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const { mutateAsync: checkout } = useCheckout();
  const customer = useCustomerContactInfo();
  const { orderNote, gclid, guestBalance, paymentForGuestIds } = useCheckoutState();
  const { tipAmount: tip } = usePaymentMethodValues();
  const tipAmount = tip?.toFixed(2) ?? '0.00';
  const { data: giftCard } = useGiftCardBalance();
  const { customerId, sessionId, sessionSecret } = useCustomerState();
  const deliveryServiceData = useDeliverServiceData();
  const isSplitByAmount = useIsSplitByAmount();

  return useCallback(() => {
    return checkout({
      merchantId: merchant?.merchantId,
      orderId: order?.id,
      checkId: order?.checkId,
      customerSessionDetails: {
        customerId,
        sessionId,
        sessionSecret,
      },
      paymentForGuestIds: isSplitByAmount ? paymentForGuestIds : undefined,
      orderNotes: orderNote,
      customer,
      paymentInfo: {
        tipAmount,
        amount: isSplitByAmount ? guestBalance : undefined,
        giftCardNum: giftCard?.cardNumber,
      },
      conversionId: gclid,
      conversionType: gclid ? 'google' : null,
      deliveryAddress: {
        address1: deliveryServiceData?.deliveryInfo?.dropoffAddress?.street,
        address2: deliveryServiceData?.deliveryInfo?.dropoffAddress?.unit,
        city: deliveryServiceData?.deliveryInfo?.dropoffAddress?.city,
        state: deliveryServiceData?.deliveryInfo?.dropoffAddress?.state,
        zip: deliveryServiceData?.deliveryInfo?.dropoffAddress?.zipCode,
      },
    });
  }, [
    checkout,
    merchant?.merchantId,
    order?.id,
    order?.checkId,
    customerId,
    sessionId,
    sessionSecret,
    orderNote,
    customer,
    tipAmount,
    guestBalance,
    giftCard?.cardNumber,
    gclid,
    paymentForGuestIds,
    deliveryServiceData?.deliveryInfo?.dropoffAddress?.street,
    deliveryServiceData?.deliveryInfo?.dropoffAddress?.unit,
    deliveryServiceData?.deliveryInfo?.dropoffAddress?.city,
    deliveryServiceData?.deliveryInfo?.dropoffAddress?.state,
    deliveryServiceData?.deliveryInfo?.dropoffAddress?.zipCode,
    isSplitByAmount,
  ]);
};
