import { isObject } from '@olo-web/utils/common/functions';
import { IValidateOrderResponse } from '@olo-web/domain/orders/mutations/useValidateOrder';

export const orderHasValidationIssues = (data: IValidateOrderResponse): boolean => {
  return !!(
    isObject(data?.order) ||
    data?.unavailability?.unavailableItems?.length ||
    data?.unavailability?.unavailableModifiers?.length
  );
};
