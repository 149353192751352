type OrderItemFormToApiProps = {
  item: ISubMenuItemDisplay;
  parentId?: string;
};

const transformModifierOptions = (options: ISubModifierOptionDisplay[]): ISubModifierOption[] => {
  return options
    .filter((o) => o.isSelected && o.id !== 'implicitly-selected')
    .map((o) => ({
      name: o?.name,
      price: o.price,
      id: o.id,
      groupItemId: o.groupItemId,
      displayAmount: o.displayAmount,
    }));
};

const transformModifier = (
  modifier: IMenuItemModifierDisplay,
  isChoiceList: boolean
): IMenuItemModifier => {
  const { isSelected, options, implicitDefault, ...rest } = modifier;

  if (!isSelected) return null;

  const transformedOptions = transformModifierOptions(options);

  if (implicitDefault && !transformedOptions?.length) return null;

  const option: ISubModifierOption =
    transformedOptions?.length > 0 && !isChoiceList
      ? transformedOptions[0]
      : {
          id: modifier?.choiceOptionId,
          name: '',
          price: modifier.choiceListPrice,
          groupItemId: modifier.groupItemId,
          displayAmount: modifier.price,
        };

  let name = modifier?.name;
  if (option?.name?.length) {
    name = `${option?.name} ${name}`;
  }

  return {
    ...rest,
    id: option.id,
    name,
    options: transformedOptions,
  };
};

const transformModifiers = (
  modifiers: IMenuItemModifierDisplay[],
  isChoiceList: boolean
): IMenuItemModifier[] => {
  return modifiers.map((m) => transformModifier(m, isChoiceList)).filter(Boolean);
};

const transformModifierGroup = (
  modifierGroup: IMenuItemModifierGroupDisplay
): IMenuAddItemModifier => {
  const { modifiers, id, name, isChoiceList, groupId, min, max } = modifierGroup;

  const transformedModifiers = transformModifiers(modifiers, isChoiceList);

  if (!transformedModifiers?.length) return null;

  // for some reason, the data structure changes from a get to a post for the same data
  // ...what was once `modifiers.modifiers.options` is now `modifiers.options.options`
  return {
    id,
    name,
    isChoiceList,
    groupId,
    min,
    max,
    options: transformedModifiers,
  };
};

const transformModifierGroups = (
  modifierGroups: IMenuItemModifierGroupDisplay[]
): IMenuAddItemModifier[] => {
  return modifierGroups.map((m) => transformModifierGroup(m)).filter(Boolean);
};

const transformSubItemGroup = (subItemGroup: ISubItemGroupDisplay): ISubMenuAddItem => {
  const {
    items,
    id,
    groupId,
    pricingKind,
    priceLevelId,
    isRollup,
    printInRed,
    min,
    max,
    position,
  } = subItemGroup;

  /* eslint-disable */
  const transformedSubItems = transformSubItems(items, id);
  /* eslint-enable */

  if (!transformedSubItems?.length) return null;

  return {
    id,
    groupId,
    pricingKind,
    priceLevelId,
    isRollup,
    printInRed,
    min,
    max,
    position,
    items: transformedSubItems,
  };
};

const transformSubItemGroups = (subItemGroups: ISubItemGroupDisplay[]): ISubMenuAddItem[] => {
  return subItemGroups.map((s) => transformSubItemGroup(s)).filter(Boolean);
};

export const transformOrderItemFormDataToApi = ({
  item,
  parentId = '',
}: OrderItemFormToApiProps): IOrderAddItem => {
  const { id, qty, menuItemId, menuItemLocationId, notes, modifiers, subItems, isSubItem } = item;

  const transformedData = {
    id: isSubItem ? id || `olo${Math.random().toString(36).substr(2, 16)}` : '', // this can stay blank, we don't have an order id yet
    quantity: qty >= 1 ? (isSubItem ? 1 : qty) : 0, // our BE doesn't respect multi-qty on sub items
    menuItemId: menuItemId ? String(menuItemId) : id,
    menuItemLocationId,
    notes: notes || '',
    modifiers: transformModifierGroups(modifiers),
    subItems: transformSubItemGroups(subItems),
    subItemId: parentId,
  };

  return transformedData;
};

const transformSubItems = (subItems: ISubMenuItemDisplay[], parentId = ''): IOrderAddItem[] => {
  // the quantity on a sub item is meaningless, if you send it, it will be accepted
  // ...and returned, however it will not be included in the price, nor will it show
  // ... up on the ticket in the kitchen, so we have to separate the quantity out
  return subItems
    .filter((s) => s.isSelected)
    .reduce((a, c) => {
      if (c.qty < 2) {
        const t = transformOrderItemFormDataToApi({
          item: c,
          parentId,
        });
        return [...a, t];
      }

      const qtyArr = Array(c.qty).fill(0);
      const tS = qtyArr.map(() =>
        transformOrderItemFormDataToApi({
          item: { ...c, quantity: 1 },
          parentId,
        })
      );
      return [...a, ...tS];
    }, []);
};
