import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMemo } from 'react';

export const useIsOrderTypeInvalid = () => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();

  return useMemo(() => {
    if (!order?.id) return false;

    for (const key in merchant?.orderTypeIds) {
      if (order?.orderTypeId === merchant?.orderTypeIds[key]) return false;
    }
    return true;
  }, [merchant?.orderTypeIds, order?.id, order?.orderTypeId]);
};
