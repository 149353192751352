import { useMerchantOrderTypeDetails } from '@domain/merchants/queries/useMerchantOrderTypeDetails';

export const useIsOnPremisePaymentOnly = (): boolean => {
  const { data: orderTypeDetails } = useMerchantOrderTypeDetails();

  return (
    (orderTypeDetails?.payLaterCCEnabled || orderTypeDetails?.payLaterCashEnabled) &&
    !orderTypeDetails?.onlineCCPaymentEnabled
  );
};
