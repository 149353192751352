import React, { useCallback, useEffect } from 'react';
import { useCompleteOrderWithSelectedCard } from './useCompleteOrderWithSelectedCard';
import { useCompleteOrderWithCardOnTab } from './useCompleteOrderWithCardOnTab';
import { useCompleteOrderWithCash } from './useCompleteOrderWithCash';
import { useCompleteOrderWithGiftCard } from './useCompleteOrderWithGiftCard';
import { useCompleteOrderWithPaymentToken } from './useCompleteOrderWithPaymentToken';
import { useCompleteOrderWithMixedPayments } from './useCompleteOrderWithMixedPayments';
import { useCompleteOrderWithMixedSavedPayments } from './useCompleteOrderWithMixedSavedPayments';
import { CompleteOrderError } from '@olo-web/domain/orders/mutations/useCheckout';
import { useValidateOnCheckout } from './useValidateOnCheckout';
import {
  useCheckoutDispatch,
  useCheckoutState,
  useAlertDispatch,
  TCheckoutAction,
  TCustomerAction,
  TalertAction,
  useScreenSizeState,
  useCustomerDispatch,
} from '@olo-web/client-state';
import { useGiftCardBalance } from '@olo-web/domain';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useGoToConfirmation } from '@olo-web/utils/common/hooks/useGoToConfirmation';
import { useMerchant, useMerchantOrderTypeDetails } from '@domain/merchants/queries';
import {
  useCurrentOrderTips,
  useToast,
  useIsScanAndPayPage,
  useIsDineIn,
  useHandleAsapLogicOnServer,
} from '@olo-web/utils/common/hooks';
import { usePaymentMethodValues } from '@olo-web/domain/payments/hooks';
import { EAlertTypes } from '@olo-web/types/enums';
import { useUpdateLocalPaymentType } from './useUpdateLocalPaymentType';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';
import { useRouter } from 'next/router';
import { useCompleteOrderOnPremise } from './useCompleteOrderOnPremise';

const handleSubmitOrderError = (
  err: CompleteOrderError,
  checkoutDispatch: React.Dispatch<TCheckoutAction>,
  alertDispatch: React.Dispatch<TalertAction>,
  toast,
  isSplitByAmount = false,
  customerDispatch: React.Dispatch<TCustomerAction>,
  refetchOrder: (options?: any) => Promise<any>
) => {
  checkoutDispatch({ type: 'COMPLETE_SUBMISSION' });

  if (err?.code === 'INACTIVE_DISCOUNT_ERROR') {
    customerDispatch({
      type: 'REMOVE_PROMO',
      payload: err?.additionalData?.discountIds[0],
    });
    refetchOrder();
  }

  if (err?.code === 'PACING_CAPACITY_ERROR') {
    alertDispatch({
      type: 'OPEN_ALERT',
      payload: {
        alertKey: err?.alternateDateTimes?.length
          ? EAlertTypes.PACING_CAPACITY_ALERT
          : EAlertTypes.NO_AVAILABLE_TIMES_ALERT,
        alertContext: err?.alternateDateTimes,
      },
    });
    return;
  }
  checkoutDispatch({ type: 'REMOVE_PAYMENT' });

  if (err?.message.includes('AVS REJECTED')) {
    toast({
      status: 'error',
      title: "There's an issue with your card",
      description: 'Please update the ZIP code to match your card information',
      isClosable: true,
      duration: null,
      variant: 'left-accent',
    });
    console.error('[Checkout Error]', err);
  } else if (
    !(isSplitByAmount && err?.message === 'Payment amount total cannot exceed the check balance')
  ) {
    toast({
      status: 'error',
      title: 'Error placing your order',
      description: err?.message ?? 'Something went wrong',
      isClosable: true,
      duration: null,
      variant: 'left-accent',
    });
  }
  console.error('[Checkout Error]', err);
};

const useSubmitOrderWithPaymentToken = () => {
  const { data: giftCard } = useGiftCardBalance();
  const completeOrderWithMixedPayments = useCompleteOrderWithMixedPayments();
  const completeOrderWithPaymentToken = useCompleteOrderWithPaymentToken();
  return (paymentToken, mobileCustomer?: ICustomer) => {
    if (giftCard) {
      return completeOrderWithMixedPayments(paymentToken, mobileCustomer);
    }
    return completeOrderWithPaymentToken(paymentToken, mobileCustomer);
  };
};

const useSubmitOrderWithSavedCard = () => {
  const { data: giftCard } = useGiftCardBalance();
  const completeOrderWithMixedSavedPayments = useCompleteOrderWithMixedSavedPayments();
  const completeOrderWithSelectedCard = useCompleteOrderWithSelectedCard();
  return useCallback(() => {
    if (giftCard) {
      return completeOrderWithMixedSavedPayments();
    }
    return completeOrderWithSelectedCard();
  }, [completeOrderWithMixedSavedPayments, completeOrderWithSelectedCard, giftCard]);
};

const useSubmitOrderWithCardOnTab = () => {
  const completeOrderWithCardOnTab = useCompleteOrderWithCardOnTab();
  return useCallback(() => {
    return completeOrderWithCardOnTab();
  }, [completeOrderWithCardOnTab]);
};
export const usePlaceOrderWithPaymentToken = () => {
  const submitOrderWithPaymentToken = useSubmitOrderWithPaymentToken();
  const goToConfirmation = useGoToConfirmation({ invalidatePreviousOrders: true });
  const { notify } = useToast();
  const checkoutDispatch = useCheckoutDispatch();
  const customerDispatch = useCustomerDispatch();
  const alertDispatch = useAlertDispatch();
  const updateLocalPaymentType = useUpdateLocalPaymentType();
  const isSplitByAmount = useIsSplitByAmount();
  const isScanAndPayPage = useIsScanAndPayPage();
  const { refetch: refetchOrder } = useOrder();
  const router = useRouter();
  const handleAsapLogicOnServer = useHandleAsapLogicOnServer();

  return async (paymentToken, mobileCustomer?: ICustomer) => {
    checkoutDispatch({ type: 'START_SUBMISSION' });

    try {
      updateLocalPaymentType();
      await submitOrderWithPaymentToken(paymentToken, mobileCustomer);

      const queryParams: { checkId?: string } = {};
      if (isScanAndPayPage) {
        queryParams.checkId = router.query.checkId as string;
      }
      goToConfirmation(queryParams);
    } catch (err) {
      if (!handleAsapLogicOnServer) {
        handleSubmitOrderError(
          err,
          checkoutDispatch,
          alertDispatch,
          notify,
          isSplitByAmount,
          customerDispatch,
          refetchOrder
        );
      }
    }
  };
};

export const useSubmitOrder = () => {
  const submitOrderWithSavedCard = useSubmitOrderWithSavedCard();
  const submitOrderWithCardOnTab = useSubmitOrderWithCardOnTab();
  const completeOrderWithCash = useCompleteOrderWithCash();
  const conpleteOrderOnPremise = useCompleteOrderOnPremise();
  const completeOrderWithGiftCard = useCompleteOrderWithGiftCard();
  const submitOrderWithPaymentToken = useSubmitOrderWithPaymentToken();
  const {
    isCash,
    isCreditCard,
    isCreditCardOnTab,
    giftCardCoversEntireOrderAmount,
    isZeroChargeOrder,
    isMobilePayment,
    isPayOnPremise,
  } = usePaymentMethodValues();
  const { selectedCard, addedPayment } = useCheckoutState();
  const { notify } = useToast();

  // We do not need to invoke collectJS for these types of checkouts
  return useCallback(() => {
    if (isPayOnPremise) {
      return conpleteOrderOnPremise();
    }

    if (isCash || isZeroChargeOrder) {
      return completeOrderWithCash();
    }

    if (giftCardCoversEntireOrderAmount) {
      return completeOrderWithGiftCard();
    }

    if (isCreditCardOnTab) {
      return submitOrderWithCardOnTab();
    }

    if (isCreditCard && selectedCard?.id) {
      return submitOrderWithSavedCard();
    }

    if ((isCreditCard || isMobilePayment) && addedPayment?.token) {
      return submitOrderWithPaymentToken(addedPayment?.token);
    }

    // We should never reach this because one of the above should be true, but just incase
    notify({
      status: 'error',
      title: "There's an issue with your payment method",
      description: 'Please refresh the page and give us a call if the issue persists',
      position: 'top-right',
      variant: 'left-accent',
    });
    return Promise.reject();
  }, [
    addedPayment?.token,
    completeOrderWithCash,
    completeOrderWithGiftCard,
    giftCardCoversEntireOrderAmount,
    isCash,
    isCreditCard,
    isMobilePayment,
    isZeroChargeOrder,
    isPayOnPremise,
    notify,
    selectedCard?.id,
    submitOrderWithPaymentToken,
    submitOrderWithSavedCard,
    conpleteOrderOnPremise,
  ]);
};

interface IConfig {
  onSuccess?: () => any;
  onError?: () => void;
}

export const usePlaceOrder = ({ onSuccess, onError }: IConfig = {}) => {
  const router = useRouter();
  const { refetch: fetchMerchant } = useMerchant();
  const { refetch: refetchOrder } = useOrder();
  const submitOrder = useSubmitOrder();
  const checkoutDispatch = useCheckoutDispatch();
  const customerDispatch = useCustomerDispatch();
  const { notify, toast } = useToast();
  const goToConfirmation = useGoToConfirmation({ invalidatePreviousOrders: true });
  const alertDispatch = useAlertDispatch();
  const isScanAndPayPage = useIsScanAndPayPage();
  const isSplitByAmount = useIsSplitByAmount();
  const { data: merchantOrderDetails } = useMerchantOrderTypeDetails();
  const { selectedTips, customTipsMode, customTipsPercentage, customTipsAmount } =
    useCurrentOrderTips();
  const { isMd } = useScreenSizeState();
  const validateOnCheckout = useValidateOnCheckout();
  const tipToastId = 'no-tip-selected';
  const { isCash, isPayOnPremise } = usePaymentMethodValues();
  const handleAsapLogicOnServer = useHandleAsapLogicOnServer();

  useEffect(() => {
    if (selectedTips) toast.close(tipToastId);
  }, [selectedTips, toast]);
  const updateLocalPaymentType = useUpdateLocalPaymentType();
  const isDinein = useIsDineIn();

  const placeOrder = useCallback(async () => {
    // If it's a mobile payment we have no choice but to handle everything on the otherside of the
    // collectJS callback, so the logic that follows this is duplicated there anyways
    checkoutDispatch({ type: 'START_SUBMISSION' });

    try {
      await validateOnCheckout();
    } catch (err) {
      return;
    }

    const invalidTip =
      (!selectedTips && !isPayOnPremise) ||
      (selectedTips === 'custom' &&
        ((customTipsMode === 'percentages' && !customTipsPercentage) ||
          (customTipsMode === 'amount' && !customTipsAmount) ||
          (selectedTips === 'custom' && !customTipsMode)));

    if (merchantOrderDetails?.tippable && invalidTip && !isCash) {
      if (!toast.isActive(tipToastId)) {
        notify({
          id: tipToastId,
          title: 'Please select your tip amount',
          status: 'error',
          variant: 'left-accent',
          position: isMd ? 'top-right' : 'top',
          isClosable: true,
        });
      }
      checkoutDispatch({ type: 'COMPLETE_SUBMISSION' });

      const el = document.getElementById('tips-selection-button-group');
      if (el) {
        el.scrollIntoView();
        el.focus();
      }
      onError?.();
      return;
    }

    try {
      updateLocalPaymentType();
      await submitOrder();
      const queryParams: { checkId?: string } = {};
      if (isScanAndPayPage) {
        queryParams.checkId = router.query.checkId as string;
      }
      goToConfirmation(queryParams);
      onSuccess?.();
    } catch (err) {
      if (!handleAsapLogicOnServer) {
        handleSubmitOrderError(
          err,
          checkoutDispatch,
          alertDispatch,
          notify,
          isSplitByAmount,
          customerDispatch,
          refetchOrder
        );
      }
      onError?.();
      if (isDinein) {
        refetchOrder();
      }
    }
  }, [
    checkoutDispatch,
    selectedTips,
    isPayOnPremise,
    customTipsMode,
    customTipsPercentage,
    customTipsAmount,
    merchantOrderDetails?.tippable,
    isCash,
    validateOnCheckout,
    toast,
    onError,
    notify,
    isMd,
    updateLocalPaymentType,
    submitOrder,
    isScanAndPayPage,
    goToConfirmation,
    onSuccess,
    router.query.checkId,
    handleAsapLogicOnServer,
    isDinein,
    alertDispatch,
    isSplitByAmount,
    customerDispatch,
    refetchOrder,
  ]);

  return useCallback(async () => {
    if (isDinein || isScanAndPayPage) {
      await placeOrder();
    } else {
      fetchMerchant().then((data) => {
        if (data.data.isOrderingAvailable) {
          placeOrder();
        }
      });
    }
  }, [isDinein, isScanAndPayPage, placeOrder, fetchMerchant]);
};
