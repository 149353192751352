import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useQuery, UseQueryResult } from 'react-query';

export const getLeadTimes = async (merchantId: string): Promise<IMerchantLeadTimes> => {
  try {
    const url = `/api/merchants/${merchantId}/lead-times`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useLeadTimes(merchantId?: string): UseQueryResult<IMerchantLeadTimes, AxiosError> {
  const router = useRouter();
  const { merchantId: merchantIdFormUrl } = router.query;
  const merchId = merchantId || merchantIdFormUrl;
  return useQuery(['lead-times', merchId], () => getLeadTimes(merchId as string), {
    staleTime: 1000000,
    enabled: !!merchId,
  });
}
