interface IParams {
  featureEnabled?: boolean;
  allowList?: string[];
  denyList?: string[];
  merchantId?: string;
}

export const isFeatureEnabledForMerchant = ({
  featureEnabled,
  allowList,
  denyList,
  merchantId,
}: IParams) => {
  if (featureEnabled) {
    return !denyList?.includes(merchantId!);
  } else {
    return !!allowList?.includes(merchantId!);
  }
};
