import { useRouter } from 'next/router';

export const useIsWelcomePage = () => {
  const router = useRouter();

  return router?.asPath
    ?.split('?')?.[0]
    ?.split('/')
    .some((q) => q === 'welcome');
};
