import { useCallback } from 'react';
import { isFeatureEnabledForMerchant } from '@olo-web/utils/common/functions';
import { useIsCheckoutPage, useIsStartATabModal } from '@olo-web/utils/common/hooks';
import { useGoToMenu } from '@olo-web/utils/common/hooks/useGoToMenu';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useFeatureFlags } from '@olo-web/domain';
import { useMerchant } from '@domain/merchants/queries';
import { EAlertTypes, EModalTypes } from '@olo-web/types/enums';
import { useCheckoutDispatch, useAlertDispatch, useModalDispatch } from '@olo-web/client-state';
import { orderHasValidationIssues } from '@olo-web/domain/orders/functions/orderHasValidationIssues';
import { useIsScanAndPayPage, useIsDineIn } from '@olo-web/utils/common/hooks';
import { useValidateOrder } from '@olo-web/domain/orders/mutations/useValidateOrder';

export const useValidateOnCheckout = () => {
  const isScanAndPayPage = useIsScanAndPayPage();
  const { data: featureFlags } = useFeatureFlags();
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const checkoutDispatch = useCheckoutDispatch();
  const alertDispatch = useAlertDispatch();
  const modalDispatch = useModalDispatch();
  const isDinein = useIsDineIn();
  const isCheckoutPage = useIsCheckoutPage();
  const goToMenu = useGoToMenu({ resetOrderStatus: false });
  const { mutateAsync: validateOrder } = useValidateOrder();
  const isHandleAsapLogicOnServer = isFeatureEnabledForMerchant({
    merchantId: merchant?.merchantId,
    featureEnabled: featureFlags?.handleAsapLogicOnServer?.on,
    allowList: featureFlags?.handleAsapLogicOnServer?.allow,
    denyList: featureFlags?.handleAsapLogicOnServer?.deny,
  });
  const isStartATabModal = useIsStartATabModal();

  return useCallback(async () => {
    if (isScanAndPayPage) return;
    const isDineInPayNow = isDinein && !merchant?.dineinPayLaterEnabled;

    if (
      (!isDinein && !isHandleAsapLogicOnServer) ||
      (isCheckoutPage && isDineInPayNow) ||
      isStartATabModal
    ) {
      try {
        const validationData = await validateOrder({
          orderId: order?.id,
          merchantId: merchant?.merchantId || order?.merchantID,
        });

        if (orderHasValidationIssues(validationData)) {
          checkoutDispatch({ type: 'COMPLETE_SUBMISSION' });
          alertDispatch({
            type: 'OPEN_ALERT',
            payload: {
              alertKey: EAlertTypes.PRODUCT_UNAVAILABILITY,
              isCloseOnOutsideClick: false,
            },
          });
          throw new Error('Products unavailable');
        }
      } catch (error) {
        console.error(error);

        if (error.code === 404) {
          checkoutDispatch({ type: 'COMPLETE_SUBMISSION' });
          modalDispatch({
            type: 'OPEN_MODAL',
            payload: {
              modalKey: EModalTypes.UNKNOWN_ERROR,
              modalContext: {
                onClose: () => {
                  goToMenu({ shouldClearOrder: true });
                },
              },
            },
          });
        }
        throw error;
      }
    }
  }, [
    alertDispatch,
    checkoutDispatch,
    goToMenu,
    isDinein,
    isHandleAsapLogicOnServer,
    isScanAndPayPage,
    merchant?.dineinPayLaterEnabled,
    merchant?.merchantId,
    modalDispatch,
    order?.id,
    order?.merchantID,
    validateOrder,
    isStartATabModal,
    isCheckoutPage,
  ]);
};
