import { useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useCustomerContactInfo } from './useCustomerContactInfo';
import { useCheckoutState, useDeliveryAddressState, useAlertState } from '@olo-web/client-state';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { usePaymentMethodValues } from '@olo-web/domain/payments/hooks';
import { EAlertTypes } from '@olo-web/types/enums';
import { useIsASAP } from '@olo-web/utils/common/hooks';

export const usePickupTime = () => {
  const alertState = useAlertState();
  const isASAP = useIsASAP();

  return useCallback(
    (orderDateTime: string) => {
      return alertState?.alertKey === EAlertTypes.PACING_CAPACITY_ALERT && isASAP
        ? dayjs(alertState?.alertContext[0]).millisecond(0).toISOString()
        : orderDateTime;
    },
    [alertState?.alertContext, alertState?.alertKey, isASAP]
  );
};

export const useDeliverServiceData = () => {
  const { data: merchant } = useMerchant();
  const customer = useCustomerContactInfo();
  const { address } = useDeliveryAddressState();
  const { data: order } = useOrder();
  const { tipAmount } = usePaymentMethodValues();
  const { orderNote, isContactlessDelivery } = useCheckoutState();
  const getPickupTime = usePickupTime();

  return useMemo(() => {
    const deliveryInfo: ICreateDeliveryServiceInfo = {
      platform: 'olo',
      platformReference: order?.id,
      pickupTime: getPickupTime(order?.orderDateTime),
      customer: {
        email: customer?.email,
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        phoneNumber: customer?.phoneNo,
        shouldSendNotifications: true,
      },
      dropoffAddress: {
        city: address?.city,
        state: address?.state,
        street: address?.address1,
        unit: address?.address2 || '',
        zipCode: address?.zip,
      },
      pickupAddress: {
        city: merchant?.city,
        state: merchant?.state,
        street: merchant?.street1,
        unit: merchant?.street2 || '',
        zipCode: merchant?.zip,
      },
      orderValue: Number(order?.displayTotalAmount) * 100, // value in cents
      tip: (tipAmount || 0) * 100, // value in cents
      numItems: order?.items?.reduce((prev, cur) => prev + +cur.quantity, 0),
      pickupPhoneNumber: `+1${merchant?.phone}`,
      dropoffInstructions: orderNote,
      isContactlessDelivery: isContactlessDelivery,
    };

    return {
      merchantId: merchant?.merchantId,
      deliveryInfo,
    };
  }, [
    address?.address1,
    address?.address2,
    address?.city,
    address?.state,
    address?.zip,
    customer?.email,
    customer?.firstName,
    customer?.lastName,
    customer?.phoneNo,
    isContactlessDelivery,
    merchant?.city,
    merchant?.merchantId,
    merchant?.phone,
    merchant?.state,
    merchant?.street1,
    merchant?.street2,
    merchant?.zip,
    order?.displayTotalAmount,
    order?.id,
    order?.items,
    order?.orderDateTime,
    orderNote,
    tipAmount,
    getPickupTime,
  ]);
};
