export enum EOrderErrorsCode {
  ONLINE_ORDERING_PAUSED = 'ONLINE_ORDERING_PAUSED',
  UNAVAILABLE_ITEMS = 'UNAVAILABLE_ITEMS',
  ORDER_UPDATED = 'ORDER_UPDATED',
  INVALID_ORDER_TIME = 'INVALID_ORDER_TIME',
  CONVERTED_TO_ASAP = 'CONVERTED_TO_ASAP',
  PACING_CAPACITY_ERROR = 'PACING_CAPACITY_ERROR',
  SIGNIFICANT_ASAP_CHANGE = 'SIGNIFICANT_ASAP_CHANGE',
  INVALID_TABLE_NUMBER = 'INVALID_TABLE_NUMBER',
  TABLE_NUMBER_REQUIRED = 'TABLE_NUMBER_REQUIRED',
  INACTIVE_DISCOUNT = 'INACTIVE_DISCOUNT',
  ORDER_TOTAL_CHANGED = 'ORDER_TOTAL_CHANGED',
}
