import { useEffect } from 'react';
import Router from 'next/router';
import { useGlobalUIDispatch } from '@olo-web/client-state';

export const useRouterLoading = (): void => {
  const globalUIDispatch = useGlobalUIDispatch();

  useEffect(() => {
    const handleStart = () => {
      globalUIDispatch({ type: 'ROUTER_IS_LOADING' });
    };

    const handleComplete = () => {
      globalUIDispatch({ type: 'CANCEL_ROUTER_LOADING' });
    };

    Router.events.on('routeChangeStart', handleStart);
    Router.events.on('routeChangeComplete', handleComplete);
    Router.events.on('routeChangeError', handleComplete);

    return () => {
      Router.events.off('routeChangeStart', handleStart);
      Router.events.off('routeChangeComplete', handleComplete);
      Router.events.off('routeChangeError', handleComplete);
    };
  }, [globalUIDispatch]);
};
