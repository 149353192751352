import { useMerchant } from '@olo-web/domain';
import { useFeatureFlags } from '@olo-web/domain/featureFlags/queries/useFeatureFlags';
import { isFeatureEnabledForMerchant } from '@olo-web/utils/common/functions/isFeatureEnabledForMerchant';

export const useGoogleAISuggestionsABStatus = (): {
  showInMenu: boolean;
  showModalBeforeCheckout: boolean;
  showInMenuAndBeforeCheckout: boolean;
  isGoogleAIABTestingEnabled: boolean;
} => {
  const { data: flags } = useFeatureFlags();
  const { data: merchant } = useMerchant();
  const GoogleAISuggestionsList = flags?.GoogleAISuggestionsAllowDenyListing;
  const isGoogleAIABTestingEnabled = isFeatureEnabledForMerchant({
    featureEnabled: GoogleAISuggestionsList?.on,
    allowList: GoogleAISuggestionsList?.allow,
    denyList: GoogleAISuggestionsList?.deny,
    merchantId: merchant?.merchantId,
  });

  const showInMenu = flags?.GoogleAISuggestions === 'menu' && isGoogleAIABTestingEnabled;
  const showModalBeforeCheckout =
    flags?.GoogleAISuggestions === 'checkout' && isGoogleAIABTestingEnabled;
  const showInMenuAndBeforeCheckout =
    flags?.GoogleAISuggestions === 'all' && isGoogleAIABTestingEnabled;

  return {
    showInMenu,
    showModalBeforeCheckout,
    showInMenuAndBeforeCheckout,
    isGoogleAIABTestingEnabled,
  };
};
