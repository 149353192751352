import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useQueries, UseQueryResult } from 'react-query';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { getItem, configureItemData } from '@olo-web/domain/menu';

export function useItems(
  menuItemLocationIds: string[],
  merchantId?: string
): UseQueryResult<IConfiguredMenuItem, AxiosError>[] {
  const { data } = useOrder();
  const router = useRouter();
  const { merchantId: merchantIdFormUrl } = router.query;
  const merchId = merchantId || merchantIdFormUrl;
  const enabled = Boolean(
    merchId && data?.orderTypeId && data?.orderDateTime && menuItemLocationIds?.length
  );

  const queries = menuItemLocationIds.map((itemId) => ({
    queryKey: ['item', itemId],
    queryFn: () => getItem(merchId as string, itemId, data?.orderTypeId, data?.orderDateTime),
    staleTime: 1000000,
    retry: 2,
    enabled,
    select: configureItemData,
  }));

  return useQueries(queries);
}
