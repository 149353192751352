import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Visa = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 34 22" fill="none" {...props}>
      <path
        d="M32.1111 0H1.88889C0.845684 0 0 0.820811 0 1.83333V20.1667C0 21.1792 0.845684 22 1.88889 22H32.1111C33.1543 22 34 21.1792 34 20.1667V1.83333C34 0.820811 33.1543 0 32.1111 0Z"
        fill="#191971"
      />
      <path
        d="M18.51 15.4263C17.6927 15.427 16.8823 15.2772 16.1193 14.9845L16.5144 13.1805C17.1872 13.5354 17.9363 13.721 18.697 13.7213H18.7547C19.4083 13.7121 20.0683 13.4555 20.0683 12.9018C20.0775 12.5489 19.7685 12.2684 18.9298 11.866C18.1048 11.4709 17.0112 10.8072 17.0112 9.60639C17.0103 7.98847 18.52 6.85547 20.6825 6.85547C21.3843 6.85632 22.0805 6.98043 22.7395 7.22214L22.3545 9.02247C21.8018 8.76719 21.2005 8.6346 20.5917 8.6338C20.457 8.6338 20.3167 8.64022 20.1875 8.65305C19.5531 8.73555 19.2708 9.06097 19.2708 9.34697C19.2708 9.69622 19.7291 9.92905 20.302 10.2251C21.2187 10.6945 22.3535 11.2784 22.3471 12.6516L22.393 12.5791C22.3838 14.3071 20.8805 15.4208 18.564 15.4208L18.51 15.4263ZM24.5948 15.3548H22.2811L25.5811 7.64014C25.6568 7.45927 25.7841 7.30472 25.9472 7.19581C26.1102 7.0869 26.3017 7.02846 26.4978 7.0278H28.3311L30.1122 15.353H28.0745L27.8086 14.1109H25.044L24.5948 15.353V15.3548ZM26.7801 9.2773L25.6205 12.4004H27.4539L26.7801 9.2773ZM14.549 15.3273H12.349L14.1824 6.99939H16.3824L14.549 15.3264V15.3273ZM9.72829 15.2998H7.33212L5.57762 8.64664C5.56187 8.48926 5.50518 8.33875 5.41318 8.21009C5.32119 8.08144 5.19711 7.97911 5.05329 7.9133C4.36283 7.59204 3.63528 7.35742 2.88721 7.2148L2.94221 6.97189H6.67946C6.92234 6.96877 7.15837 7.05232 7.34519 7.20756C7.53202 7.3628 7.65738 7.57954 7.69879 7.81889L8.61546 12.6351L10.9035 6.97189H13.2868L9.72829 15.2989V15.2998Z"
        fill="white"
      />
    </Icon>
  );
};
