import { friendlyErrorMessages } from './../constants/friendlyErrorMessages';

export const createFriendlyErrorMessage = (error) => {
  const message = error?.response?.data?.error || error?.response?.data?.message || error?.message;
  if (message) {
    const idx = Object.keys(friendlyErrorMessages).findIndex((k) => message.includes(k));
    if (idx >= 0) return friendlyErrorMessages[Object.keys(friendlyErrorMessages)[idx]];
    return message;
  }
  return 'Please contact support';
};
