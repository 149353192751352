import { theme } from '@chakra-ui/react';
import { IComponentThemeProps } from '../types';
const d = theme.components.Checkbox;

const Checkbox = {
  ...d,
  baseStyle: ({ flexDir, colorScheme }: IComponentThemeProps) => ({
    ...d.baseStyle,
    control: {
      borderColor: 'blackAlpha.350',
      _checked: {
        bg: `${colorScheme}.350`,
        borderColor: `${colorScheme}.350`,
        _hover: {
          bg: `${colorScheme}.400`,
        },
      },
    },

    label: {
      w: flexDir === 'row-reverse' ? '100%' : 'auto',
      ml: flexDir === 'row-reverse' ? 0 : 2,
      mr: flexDir === 'row-reverse' ? 4 : 0,
      userSelect: 'none',
      _disabled: {
        opacity: 1,
      },
    },
  }),
  sizes: {
    ...d.sizes,
    md: {
      ...d.sizes.md,
      container: { h: '44px' },
    },
  },
  defaultProps: {
    colorScheme: 'primary',
    size: 'md',
  },
};

export default Checkbox;
