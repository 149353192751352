import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

interface IGooglePayBorder extends IconProps {
  variant: 'black' | 'white';
}

export const GooglePayBorder = (props: IGooglePayBorder) => {
  const { variant = 'black', ...rest } = props;

  const isBlack = variant === 'black';

  return (
    <Icon viewBox="0 0 44 23" fill="none" {...rest}>
      <path
        d="M31.7364 0H11.4987C5.17442 0 0 5.175 0 11.5C0 17.825 5.17442 23 11.4987 23H31.7364C38.0607 23 43.2351 17.825 43.2351 11.5C43.2351 5.175 38.0607 0 31.7364 0Z"
        fill={isBlack ? 'white' : 'transparent'}
      />
      <path
        d="M31.7364 0.9315C33.1565 0.9315 34.5363 1.21325 35.8357 1.76525C37.0948 2.3 38.2217 3.06475 39.1991 4.0365C40.1707 5.00825 40.9354 6.141 41.4701 7.40025C42.022 8.69975 42.3037 10.0798 42.3037 11.5C42.3037 12.9202 42.022 14.3002 41.4701 15.5997C40.9354 16.859 40.1707 17.986 39.1991 18.9635C38.2274 19.9352 37.0948 20.7 35.8357 21.2347C34.5363 21.7867 33.1565 22.0685 31.7364 22.0685H11.4987C10.0786 22.0685 8.69877 21.7867 7.39941 21.2347C6.14031 20.7 5.01343 19.9352 4.03604 18.9635C3.0644 17.9917 2.29974 16.859 1.76505 15.5997C1.21311 14.3002 0.931394 12.9202 0.931394 11.5C0.931394 10.0798 1.21311 8.69975 1.76505 7.40025C2.29974 6.141 3.0644 5.014 4.03604 4.0365C5.00768 3.06475 6.14031 2.3 7.39941 1.76525C8.69877 1.21325 10.0786 0.9315 11.4987 0.9315H31.7364ZM31.7364 0H11.4987C5.17442 0 0 5.175 0 11.5C0 17.825 5.17442 23 11.4987 23H31.7364C38.0607 23 43.2351 17.825 43.2351 11.5C43.2351 5.175 38.0607 0 31.7364 0Z"
        fill={isBlack ? '#3C4043' : 'white'}
      />
      <path
        d="M20.6156 12.3168V15.7956H19.5117V7.20508H22.4381C23.1798 7.20508 23.8122 7.45233 24.3297 7.94683C24.8586 8.44133 25.1231 9.04508 25.1231 9.75808C25.1231 10.4883 24.8586 11.0921 24.3297 11.5808C23.818 12.0696 23.1855 12.3111 22.4381 12.3111H20.6156V12.3168ZM20.6156 8.26308V11.2588H22.4611C22.8981 11.2588 23.266 11.1093 23.5535 10.8161C23.8467 10.5228 23.9962 10.1663 23.9962 9.76383C23.9962 9.36708 23.8467 9.01633 23.5535 8.72308C23.266 8.41833 22.9038 8.26883 22.4611 8.26883H20.6156V8.26308Z"
        fill={isBlack ? '#3C4043' : 'white'}
      />
      <path
        d="M28.0095 9.72363C28.8259 9.72363 29.4699 9.94213 29.9413 10.3791C30.4128 10.8161 30.6485 11.4141 30.6485 12.1731V15.7956H29.5964V14.9791H29.5504C29.0962 15.6519 28.4867 15.9854 27.7278 15.9854C27.0781 15.9854 26.5377 15.7956 26.1007 15.4104C25.6638 15.0251 25.4453 14.5479 25.4453 13.9729C25.4453 13.3634 25.6753 12.8804 26.1352 12.5239C26.5952 12.1616 27.2104 11.9834 27.975 11.9834C28.6305 11.9834 29.1709 12.1041 29.5906 12.3456V12.0926C29.5906 11.7074 29.4411 11.3854 29.1364 11.1151C28.8317 10.8449 28.4752 10.7126 28.067 10.7126C27.4518 10.7126 26.9631 10.9714 26.6067 11.4946L25.635 10.8851C26.1697 10.1089 26.9631 9.72363 28.0095 9.72363ZM26.5837 13.9901C26.5837 14.2776 26.7044 14.5191 26.9516 14.7089C27.1931 14.8986 27.4806 14.9964 27.8083 14.9964C28.274 14.9964 28.688 14.8239 29.0502 14.4789C29.4124 14.1339 29.5964 13.7314 29.5964 13.2656C29.2514 12.9954 28.7742 12.8574 28.159 12.8574C27.7106 12.8574 27.3369 12.9666 27.0379 13.1794C26.7332 13.4036 26.5837 13.6739 26.5837 13.9901Z"
        fill={isBlack ? '#3C4043' : 'white'}
      />
      <path
        d="M36.6493 9.91309L32.9697 18.3771H31.8313L33.1997 15.4158L30.7734 9.91309H31.9751L33.7229 14.1336H33.7459L35.4477 9.91309H36.6493Z"
        fill={isBlack ? '#3C4043' : 'white'}
      />
      <path
        d="M16.2269 11.6147C16.2269 11.2547 16.1947 10.9103 16.1349 10.5791H11.5078V12.4766L14.1726 12.4772C14.0645 13.1085 13.7167 13.6467 13.1837 14.0055V15.2366H14.77C15.6962 14.3793 16.2269 13.112 16.2269 11.6147Z"
        fill="#4285F4"
      />
      <path
        d="M13.1825 14.0064C12.741 14.3043 12.1724 14.4785 11.5072 14.4785C10.2222 14.4785 9.13211 13.6126 8.74173 12.4453H7.10547V13.7149C7.91613 15.3238 9.58229 16.4278 11.5072 16.4278C12.8376 16.4278 13.9552 15.9902 14.7688 15.2369L13.1825 14.0064Z"
        fill="#34A853"
      />
      <path
        d="M8.58798 11.504C8.58798 11.1763 8.6426 10.8594 8.74206 10.5616V9.29199H7.1058C6.77061 9.95727 6.58203 10.7082 6.58203 11.504C6.58203 12.2998 6.77118 13.0508 7.1058 13.716L8.74206 12.4464C8.6426 12.1486 8.58798 11.8318 8.58798 11.504Z"
        fill="#FABB05"
      />
      <path
        d="M11.5072 8.52737C12.2333 8.52737 12.8836 8.7775 13.397 9.26625L14.8027 7.86152C13.9489 7.0663 12.8358 6.57812 11.5072 6.57812C9.58286 6.57812 7.91613 7.68212 7.10547 9.29097L8.74173 10.5606C9.13211 9.39332 10.2222 8.52737 11.5072 8.52737Z"
        fill="#E94235"
      />
    </Icon>
  );
};
