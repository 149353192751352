import { useCurrentMerchantDayJs } from '@domain/merchants/hooks/useCurrentMerchantDayJs';
import { useCallback } from 'react';
import { useModalDispatch, useScreenSizeState } from '@olo-web/client-state';
import { EAnalyticsEventNames, EGALocations, EGAActions } from '@olo-web/types/enums';
import { EModalTypes } from '@olo-web/types/enums';
import { useIsMenuPage, useSendEvent } from '@olo-web/utils/common/hooks';
import { useToast, isDeliveryOrder, getEstimatedTimeText } from '@olo-web/utils/common/hooks';
import { useMerchant } from '@domain/merchants/queries/useMerchant';

export const useNotifySignificantASAPChange = () => {
  const { dayjs } = useCurrentMerchantDayJs();
  const { notify } = useToast();
  const { data: merchant } = useMerchant();
  const { isMd } = useScreenSizeState();
  const modalDispatch = useModalDispatch();
  const isMenuPage = useIsMenuPage();
  const { sendEvent } = useSendEvent({ canRepeat: true });

  return useCallback(
    (updatedOrder: IOrder) => {
      const now = dayjs();
      const orderTimeDate = dayjs(updatedOrder?.orderDateTime);
      const isOrderTimeToday = orderTimeDate.isSame(now, 'day');
      if (isOrderTimeToday) {
        notify({
          id: 'new-estimated-time',
          title: `We've updated your ${
            isDeliveryOrder(updatedOrder, merchant) ? 'delivery' : 'pickup'
          } time`,
          description: `Your new estimated time is ${getEstimatedTimeText(
            updatedOrder?.orderDateTime,
            merchant
          )}`,
          isClosable: true,
          duration: null,
          status: 'info',
          variant: 'left-accent',
          position: isMd ? 'top-right' : 'top',
        });
      } else {
        modalDispatch({
          type: 'OPEN_MODAL',
          payload: { modalKey: EModalTypes.CONFIRM_FULFILLMENT_TIME },
        });
      }
      sendEvent(EAnalyticsEventNames.DEFAULT, {
        googleAnalytics: {
          eventInfo: {
            location: EGALocations.ONLINE_ORDER,
            action: EGAActions.ERROR,
            object: EAnalyticsEventNames.SIGNIFICANT_ASAP_CHANGE,
          },
          eventMetadata: {
            locationOfError: isMenuPage ? 'continue-to-checkout' : 'purchase',
          },
        },
      });
    },
    [dayjs, isMd, isMenuPage, merchant, modalDispatch, notify, sendEvent]
  );
};
