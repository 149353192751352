import { useCallback } from 'react';
import { addFormDataToItem, transformOrderItemFormDataToApi } from '@olo-web/domain';
import { useModalDispatch, useModalState } from '@olo-web/client-state';
import { useAddOrderItem } from '../mutations/useAddOrderItem';

export const useAddItemFromDineinModal = (): any => {
  const modalDispatch = useModalDispatch();
  const modalState = useModalState();
  const { mutate: addOrderItem } = useAddOrderItem();

  return useCallback(
    (orderId, merchantId, guest) => {
      if (!orderId) {
        modalDispatch({
          type: 'OPEN_PREVIOUS_MODAL',
        });
      } else {
        const { item, vals } = modalState?.modalContext;
        const updatedItem = addFormDataToItem(item, vals);
        const addItem = transformOrderItemFormDataToApi({
          item: {
            ...updatedItem,
            qty: vals?.quantity,
            notes: vals?.notes,
          },
        });

        const body = {
          item: addItem,
          merchantId,
          orderId,
          guestId: guest?.id,
        };

        addOrderItem(body);
      }
    },
    [addOrderItem, modalDispatch, modalState?.modalContext]
  );
};
