import { FirebaseError } from 'firebase-admin';
import { storage } from '../../../utils/google/firebase/firebase';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

export const deleteMerchantImage = async (path: string): Promise<any> => {
  try {
    return await storage().ref().child(path).delete();
  } catch (error) {
    throw new Error(error?.message || error);
  }
};

export const useDeleteMerchantImage = (
  options?: UseMutationOptions<any, FirebaseError, string>
) => {
  const queryClient = useQueryClient();
  return useMutation(deleteMerchantImage, {
    ...options,
    onSuccess: (_, variables, context) => {
      queryClient.refetchQueries(['previous-images']);
      if (options?.onSuccess) options.onSuccess(_, variables, context);
    },
  });
};
