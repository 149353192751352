import { theme } from '@chakra-ui/react';
const defaultStyle = theme.components.Tabs;

const Tabs = {
  ...defaultStyle,
  sizes: {
    ...defaultStyle.sizes,
    md: {
      ...defaultStyle.sizes.md,
      tab: {
        ...defaultStyle.sizes.md.tab,
        h: '44px',
      },
    },
  },
  defaultProps: {
    ...defaultStyle.defaultProps,
    colorScheme: 'primary',
  },
};

export default Tabs;
