import axios, { AxiosError } from 'axios';
import { useQueryClient, useMutation, UseMutationOptions } from 'react-query';
import { useCustomerDispatch, useModalState } from '@olo-web/client-state';
import { serializeToCamelCase } from '@olo-web/utils/common/functions';
import { identify } from 'react-fullstory';
import { identifyUser } from '@olo-web/utils/metrics/datadog/index';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames } from '@olo-web/types/enums';

// this combines two mutations from the legacy app
// one used an authToken, one used email && password
// this accepts either

export type LoginProps = {
  email?: string;
  password?: string;
  authToken?: string;
};

export const login = async ({ email, password, authToken }: LoginProps): Promise<any> => {
  try {
    if (!((email && password) || authToken)) throw new Error('Missing necessary credentials');

    const body = authToken ? { auth_token: authToken } : { email, password };

    const { data } = await axios.post(`/api/customers/login`, body);

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useLogin(options: UseMutationOptions<ICustomer, AxiosError, LoginProps>) {
  const customerDispatch = useCustomerDispatch();
  const queryClient = useQueryClient();
  const { sendEvent } = useSendEvent();
  const { analyticsAuthEntryPoint } = useModalState();

  return useMutation(login, {
    ...options,
    onSuccess: (data, variables, context) => {
      const customer = serializeToCamelCase({
        ...(data?.customer_response || {}),
      });
      queryClient.setQueryData(['customer', customer?.customerId], customer);
      customerDispatch({
        type: 'UPDATE',
        payload: {
          customerId: customer?.customerId,
          sessionId: customer?.sessionId,
          sessionSecret: customer?.sessionSecret,
          loginTimestamp: customer?.timestamp,
        },
      });
      const name = customer?.fullName || customer?.firstName + ' ' + customer?.lastName;
      identify(customer?.customerId, {
        displayName: name,
        email: customer?.email,
      });
      identifyUser({ id: customer?.customerId, name, email: customer?.email });
      sendEvent(EAnalyticsEventNames.SIGN_IN, {
        googleAnalytics: {
          entryPoint: analyticsAuthEntryPoint,
          tagOverrides: {
            customerId: customer?.customerId,
          },
        },
      });
      if (options?.onSuccess) options?.onSuccess(customer, variables, context);
    },
  });
}
