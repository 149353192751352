export const isOrderingAvailable = (merchant: IMerchant) => {
  if (merchant?.orderTypes?.doordashdrive) {
    return !!(
      (merchant?.orderTypes?.pickup && merchant?.orderTypes?.pickup?.asapOrderDateTime) ||
      (merchant?.orderTypes?.doordashdrive &&
        merchant?.orderTypes?.doordashdrive?.asapOrderDateTime)
    );
  }

  return !!(
    (merchant?.orderTypes?.pickup && merchant?.orderTypes?.pickup?.asapOrderDateTime) ||
    (merchant?.orderTypes?.delivery && merchant?.orderTypes?.delivery?.asapOrderDateTime)
  );
};
