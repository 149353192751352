import { useMemo } from 'react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { EOrderTypes } from '@olo-web/types/enums';

interface IOrderTypeItems extends IRibbonItem {
  available?: boolean;
}

export const useOrderTypeItems = (): IOrderTypeItems[] => {
  const { data: merchant } = useMerchant();

  return useMemo(
    () => [
      {
        label: 'Pickup',
        value: EOrderTypes.PICKUP,
        available: merchant?.orderTypes?.pickup
          ? merchant?.orderTypes?.pickup?.asapOrderDateTime !== null
          : false,
      },
      {
        label: 'Delivery',
        value: merchant?.hasDeliveryService ? EOrderTypes.DOORDASH_DRIVE : EOrderTypes.DELIVERY, // this does not determine DDD or in-house delivery
        available:
          (merchant?.orderTypes?.delivery &&
            merchant?.orderTypes?.delivery?.asapOrderDateTime !== null) ||
          (merchant?.orderTypes?.doordashdrive &&
            merchant?.orderTypes?.doordashdrive?.asapOrderDateTime !== null),
      },
    ],
    [
      merchant?.hasDeliveryService,
      merchant?.orderTypes?.delivery,
      merchant?.orderTypes?.pickup,
      merchant?.orderTypes?.doordashdrive,
    ]
  );
};
