import React, { FC, useEffect, useState } from 'react';
import Head from 'next/head';
import { Box, Link } from '@chakra-ui/react';
import { ModalListener } from '@organisms/Modals/ModalListener.ui';
import { AlertListener } from '@organisms/Alerts/AlertListener.ui';
import { RouterLoading } from '@atoms/RouterLoading';
import devTools from '../../../utils/dev/devTools';
import { Dependencies, useCheckDisruption } from '@spotoninc/consumer-disruption-banner';
import { useRouterLoading } from '@olo-web/utils/common/hooks/useRouterLoading';
import { useScreenSizeListener } from '@olo-web/utils/common/hooks/useScreenSizeListener';
import { useJoinSourceHeader } from '@olo-web/utils/common/hooks/useJoinSourceHeader';
import dynamic from 'next/dynamic';
import { useFontsToLoad } from '@olo-web/theme/hooks/useFontsToLoad';
import {
  useSubscribeToNats,
  END_POINTS,
  useNatsConnectionListener,
} from '@olo-web/utils/common/nats/useNats';
import { useIsUpdateThemeQueryParamTrue } from '@olo-web/theme/hooks/useIsUpdateThemeQueryParamTrue';
import { useFeatureFlags } from '@olo-web/domain/featureFlags/queries/useFeatureFlags';
import { useRemoveInvalidRewards } from '@olo-web/domain/orders/hooks/useRemoveInvalidRewards';
import {
  useCustomerDispatch,
  useGlobalUIDispatch,
  useNatsState,
  useSavedDineInContextState,
  useDeliveryAddressState,
  useModalDispatch,
} from '@olo-web/client-state';
import { isFeatureEnabledForMerchant } from '@olo-web/utils/common/functions';
import { GatewayJS } from '@olo-web/components/templates/Checkout/common/components/CollectJS/GatewayJS.script';
import {
  useListenForGclid,
  useListenForContinuedOrderEvent,
} from '@olo-web/utils/google/analytics';
import { useMerchantImages } from '@domain/merchants/hooks/useMerchantImages';
import { useMerchant, useMerchantGroup } from '@domain/merchants/queries';
import { AppVersion } from '@olo-web/components/organisms/Alerts/AppVersion.ui';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import {
  useHandleAsapLogicOnServer,
  useIsDineIn,
  useIsThirdPartyDelivery,
  useToast,
} from '@olo-web/utils/common/hooks';
import { ECookies, EOrderStatus, EModalTypes } from '@olo-web/types/enums';
import { useOrderIdFeatureFlagEnabled } from '@olo-web/utils/common/hooks/useOrderIdFeatureFlagEnabled';
import { CollectJS } from '@olo-web/components/templates/Checkout/common/components/CollectJS';
import { useListenForUtmParams } from '@olo-web/utils/google/analytics/hooks/useListenForUtmParams';
import { parseCookies } from 'nookies';
import { useAddDeliveryAddressToOrder } from '@olo-web/domain/orders/mutations/useAddDeliveryAddressToOrder';
import { useGoogleAISuggestionsABStatus } from '@olo-web/utils/common/hooks/useGoogleAISuggestionsStatus';

const CustomThemeDrawer = dynamic(
  () => import('../../organisms/CustomThemeDrawer/CustomThemeDrawer.ui')
);

type T = {
  children: any;
};

export const AppLayout: FC<T> = ({ children }) => {
  useRouterLoading();
  useRemoveInvalidRewards();
  useListenForGclid();
  useListenForUtmParams();
  useListenForContinuedOrderEvent();
  useScreenSizeListener();
  useJoinSourceHeader();
  useNatsConnectionListener();

  const { data: flags } = useFeatureFlags();
  const [hasAddedDeliveryAddress, setHasAddedDeliveryAddress] = useState(false);
  const isUpdateTheme = useIsUpdateThemeQueryParamTrue();
  const fontsToLoad = useFontsToLoad();
  const { logoUrl, backgroundUrl, faviconUrl } = useMerchantImages();
  const { data: group } = useMerchantGroup();
  const { data: merchant } = useMerchant();
  const nats = useSubscribeToNats();
  const customerDispatch = useCustomerDispatch();
  const { topic: topicFromState } = useNatsState();
  const savedDineInState = useSavedDineInContextState();
  const globalUIDispatch = useGlobalUIDispatch();
  const { notify, toast } = useToast();
  const { data: order } = useOrder();
  const orderIdFFEnabled = useOrderIdFeatureFlagEnabled();
  const handleAsapLogicOnServer = useHandleAsapLogicOnServer();
  const { address } = useDeliveryAddressState();
  const isDineIn = useIsDineIn();
  const isThirdPartyDelivery = useIsThirdPartyDelivery();
  const { mutate: addDeliveryAddressToOrder } = useAddDeliveryAddressToOrder();
  const statusPageShouldShowBanner = useCheckDisruption([
    Dependencies.OnlineOrdering,
    Dependencies.PaymentProcessing,
  ]);
  const expiredDealToastId = 'expired-deal';
  const modalDispatch = useModalDispatch();
  const initialCookies = parseCookies();

  const { showInMenu, showInMenuAndBeforeCheckout, isGoogleAIABTestingEnabled } =
    useGoogleAISuggestionsABStatus();
  useEffect(() => {
    if (
      !initialCookies[ECookies.cookieDisclaimerHasShown] &&
      isGoogleAIABTestingEnabled &&
      (showInMenu || showInMenuAndBeforeCheckout)
    ) {
      modalDispatch({
        type: 'OPEN_MODAL',
        payload: {
          modalKey: EModalTypes.COOKIE_BANNER,
        },
      });
    }
  }, [
    initialCookies[ECookies.cookieDisclaimerHasShown],
    showInMenu,
    showInMenuAndBeforeCheckout,
    isGoogleAIABTestingEnabled,
  ]);

  devTools.start();

  useEffect(() => {
    customerDispatch({
      type: 'SET_DISRUPTION',
      payload: { statusPageShouldShowBanner },
    });
  }, [statusPageShouldShowBanner, customerDispatch]);

  // This useEffect adds the delivery address to the order when the guest has a saved address and starts a new order.
  // It's needed because he won't add the address through the modal which is where the delivery address is added to the order.
  useEffect(() => {
    if (
      !isDineIn &&
      order &&
      !order.deliveryAddress &&
      address &&
      handleAsapLogicOnServer &&
      isThirdPartyDelivery &&
      !hasAddedDeliveryAddress
    ) {
      setHasAddedDeliveryAddress(true);
      addDeliveryAddressToOrder();
    }
  }, [
    addDeliveryAddressToOrder,
    address,
    handleAsapLogicOnServer,
    hasAddedDeliveryAddress,
    isDineIn,
    isThirdPartyDelivery,
    order,
  ]);

  useEffect(() => {
    const cookies = parseCookies();
    const userKey = cookies?.[ECookies.ldUserKey];
    globalUIDispatch({ type: 'SET_LD_USER_KEY', payload: userKey });
  }, [globalUIDispatch]);

  useEffect(() => {
    if (!handleAsapLogicOnServer && order?.requestDetails?.InactiveDiscountError) {
      if (!toast.isActive(expiredDealToastId)) {
        notify({
          id: expiredDealToastId,
          description: order?.requestDetails?.InactiveDiscountError,
          status: 'info',
          variant: 'left-accent',
          position: 'top',
          isClosable: true,
        });
      }
    }
  }, [order?.requestDetails, notify, toast, handleAsapLogicOnServer]);
  useEffect(() => {
    const subscribeToNats = async () => {
      try {
        if (topicFromState === null && merchant?.merchantId) {
          if (order?.isReserveOrder && order?.groupOrderInfo?.status === EOrderStatus.PENDING) {
            const topic = END_POINTS.orderNotification(merchant.merchantId, order?.id);
            await nats?.subscribe(topic);
          } else if (
            savedDineInState?.orderId &&
            order?.groupOrderInfo?.status !== EOrderStatus.COMPLETED &&
            orderIdFFEnabled
          ) {
            const topic = END_POINTS.orderNotification(
              merchant.merchantId,
              savedDineInState?.orderId
            );
            await nats?.subscribe(topic);
          } else if (
            savedDineInState?.table?.id &&
            order?.groupOrderInfo?.status !== EOrderStatus.COMPLETED
          ) {
            const topic = END_POINTS.tableNotification(
              merchant.merchantId,
              savedDineInState?.table?.id
            );
            await nats?.subscribe(topic);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    subscribeToNats();
  }, [
    savedDineInState,
    merchant,
    topicFromState,
    nats,
    order?.groupOrderInfo?.status,
    orderIdFFEnabled,
    order?.id,
    order?.isReserveOrder,
  ]);

  const showAppVersion = isFeatureEnabledForMerchant({
    featureEnabled: flags?.appVersionAllowDeny?.on,
    allowList: flags?.appVersionAllowDeny?.allow,
    denyList: flags?.appVersionAllowDeny?.deny,
    merchantId: merchant?.merchantId,
  });

  return (
    <Box
      pos="absolute"
      top="env(safe-area-inset-top)"
      bottom={0}
      left={0}
      right={0}
      overflowY="scroll"
      overflowX="hidden"
    >
      <Head>
        {fontsToLoad.map((font) => (
          <link
            key={`loading-font=${font}`}
            href={`https://fonts.googleapis.com/css2?family=${font}:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap`}
            rel="stylesheet"
          />
        ))}
        <link rel="shortcut icon" href={faviconUrl || '%PUBLIC_URL%/favicon.ico'} />
        <title>{`Order ${merchant?.name || group?.name || 'Online'}`}</title>
        <meta key="og:title" property="og:title" content={`Order from ${group?.name}`} />
        {(backgroundUrl || logoUrl) && (
          <meta key="og:image" property="og:image" content={backgroundUrl ?? logoUrl} />
        )}
      </Head>

      <Link
        href="#main-content"
        aria-label="Skip to main Content"
        bgColor="primary.400"
        color="white"
        fontWeight={'semibold'}
        p={3}
        fontSize={14}
        pos="absolute"
        id="stmc"
        zIndex={-10}
        tabIndex={0}
        opacity="0"
        _focus={{ opacity: '1', zIndex: '999' }}
      >
        Skip to main content
      </Link>
      <GatewayJS />
      {!!(!flags?.hideMerchantTheming && isUpdateTheme) && <CustomThemeDrawer />}
      <RouterLoading />
      {children}
      <ModalListener />
      <AlertListener />
      <CollectJS />
      <ReactQueryDevtools initialIsOpen={false} />
      {showAppVersion && <AppVersion />}
    </Box>
  );
};
