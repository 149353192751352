import React from 'react';
import { Badge } from '@chakra-ui/react';

const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || '';

export const AppVersion = () => (
  <Badge textTransform="none" pos="absolute" top={2} p={2} left={2}>
    {appVersion.indexOf('-') !== -1
      ? `v${appVersion.substr(0, appVersion.indexOf('-'))}`
      : `v${appVersion}`}
  </Badge>
);
