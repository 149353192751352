import { useMemo } from 'react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '../queries/useOrder';
import { useOrderType } from '@olo-web/utils/common/hooks';

export const useIsMinOrderAmountUnmet = () => {
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();
  const orderType = useOrderType();

  return useMemo(() => {
    if (!orderType) return false;
    const minAmount = Number(merchant?.orderTypes?.[orderType]?.minOrderAmount || 0);
    const orderAmount = Number(order?.preDiscountSubtotal || 0);

    if (!(orderAmount && minAmount)) return false;

    return orderAmount < minAmount;
  }, [order?.preDiscountSubtotal, merchant?.orderTypes, orderType]);
};
