import { useCustomerState, useCheckoutDispatch, useCheckoutState } from '@olo-web/client-state';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { serializeToCamelCase } from '@olo-web/utils/common/functions';
import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { configHeaders } from '../functions';
import dayjs from 'dayjs';

export const getPaymentDetails = async (
  customerId: string,
  merchantId: string,
  sessionId: string,
  sessionSecret: string,
  loginTimestamp: number | string
): Promise<IPaymentDetails> => {
  try {
    const url = `/api/customers/${customerId}/get-payment-details/${merchantId}`;
    const headers = configHeaders({
      customerId,
      sessionId,
      loginTimestamp,
      sessionSecret,
    });

    const { data } = await axios.get(url, { headers });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

interface IUsePaymentDetails extends IPaymentDetails {
  savedValidCards: IPaymentCardDetails[];
}

const configurePaymentDetails = (data) => {
  let cards = [];

  if (data?.payment_info?.length) {
    cards = data.payment_info
      .sort((cardOne, cardTwo) => (cardTwo.isDefault ? 1 : 0) - (cardOne.isDefault ? 1 : 0))
      .filter((card) => dayjs(card.cc_expiration).isAfter(dayjs(), 'day'))
      .splice(0, 4);
  }

  return serializeToCamelCase({
    ...data,
    savedValidCards: cards,
  });
};

export const usePaymentDetails = (
  merchantId?: string
): UseQueryResult<IUsePaymentDetails, AxiosError> => {
  const customerState = useCustomerState();
  const checkoutDispatch = useCheckoutDispatch();
  const { selectedCard } = useCheckoutState();
  const { data: merchant } = useMerchant();

  const [cId, sessId, sessSec, loginTs, merchId] = [
    customerState?.customerId,
    customerState?.sessionId,
    customerState?.sessionSecret,
    customerState?.loginTimestamp,
    merchantId || merchant?.merchantId,
  ];

  const enabled = Boolean(cId && merchId && sessId && sessSec && loginTs);

  return useQuery(
    ['payment-details', merchId, cId],
    () => getPaymentDetails(cId, merchId, sessId, sessSec, loginTs),
    {
      staleTime: Infinity,
      enabled,
      select: configurePaymentDetails,
      retry: 1,
      onSuccess: (data) => {
        if (!selectedCard && data?.savedValidCards?.length) {
          checkoutDispatch({
            type: 'SET_SELECTED_CARD',
            payload: data.savedValidCards[0],
          });
        }
      },
    }
  );
};
