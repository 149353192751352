import React, { FC } from 'react';
import { Box, Progress } from '@chakra-ui/react';
import { useGlobalUIState } from '@olo-web/client-state';

export const RouterLoading: FC = () => {
  const { routerLoading } = useGlobalUIState();

  if (!routerLoading) return null;

  return (
    <Box pos="fixed" top="0" right="0" left="0" zIndex={99999}>
      <Progress isIndeterminate bg="transparent" colorScheme="primary" size="sm" />
    </Box>
  );
};
