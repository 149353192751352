import { DiscountType } from '@olo-web/domain/orders/types';

type TReadableName = 'reward' | 'deal' | 'promo code' | 'offer';

export const getDiscountReadableName = (discountType: DiscountType): TReadableName => {
  switch (discountType) {
    case DiscountType.REWARD:
      return 'reward';
    case DiscountType.DEAL:
      return 'deal';
    case DiscountType.PROMO_CODE:
      return 'promo code';
    default:
      return 'offer';
  }
};
