import { useOrderUnavailabilityDispatch } from '@olo-web/client-state';
import axios from 'axios';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMutation, UseMutationOptions } from 'react-query';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { isFeatureEnabledForMerchant, serializeToCamelCase } from '@olo-web/utils';
import { orderHasValidationIssues } from '@olo-web/domain/orders/functions/orderHasValidationIssues';
import { useErrorOrderScenarios } from '@domain/orders/hooks';
import { useFeatureFlags, useMerchant, useMenu } from '@domain';
import { useCurrentMerchantDayJs } from '@domain/merchants/hooks';
import { useNotifySignificantASAPChange } from '@domain/orders/hooks';

export interface IValidateOrderResponse {
  order: IOrder | null;
  unavailability: IOrderUnavailability;
}

type TProps = {
  merchantId: string;
  orderId: string;
};

interface IError {
  message: string;
  code: string | number;
  alternateDateTimes?: Array<string>;
  additionalData?: {
    order?: IOrder;
  };
}

export const getValidateOrder = async ({
  merchantId,
  orderId,
}: TProps): Promise<IValidateOrderResponse> => {
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/preflightavailability`;
    const { data } = await axios.get(url);
    return serializeToCamelCase(data);
  } catch (error) {
    throw {
      message: error?.response?.data?.message || error?.response?.data?.error || error,
      code: error?.response?.data?.code,
      alternateDateTimes: error?.response?.data?.alternateDateTimes,
    };
  }
};

export const postValidateOrder = async ({
  merchantId,
  orderId,
}: TProps): Promise<IValidateOrderResponse> => {
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/validate`;
    const { data } = await axios.post(url);
    return serializeToCamelCase(data);
  } catch (error) {
    throw {
      message: error?.response?.data?.message || error?.response?.data?.error || error,
      code: error?.response?.data?.code,
      alternateDateTimes: error?.response?.data?.alternateDateTimes,
      additionalData: error?.response?.data?.additionalData,
    };
  }
};

export const useValidateOrder = (
  options?: UseMutationOptions<IValidateOrderResponse, IError, TProps>
) => {
  const updateOrderInCache = useUpdateOrderInCache();
  const unavailabilityDispatch = useOrderUnavailabilityDispatch();
  const { data: featureFlags } = useFeatureFlags();
  const { data: merchant } = useMerchant();
  const { data: oldOrder } = useOrder();
  const { dayjs } = useCurrentMerchantDayJs();
  const errorOrderScenarios = useErrorOrderScenarios();
  const { refetch: refetchMenu } = useMenu();
  const notifySignificantAsapChange = useNotifySignificantASAPChange();

  const isHandleAsapLogicOnServer = isFeatureEnabledForMerchant({
    merchantId: merchant?.merchantId,
    featureEnabled: featureFlags?.handleAsapLogicOnServer?.on,
    allowList: featureFlags?.handleAsapLogicOnServer?.allow,
    denyList: featureFlags?.handleAsapLogicOnServer?.deny,
  });

  return useMutation(isHandleAsapLogicOnServer ? postValidateOrder : getValidateOrder, {
    ...options,
    onSuccess: (data, variables, context) => {
      const updatedOrder = isHandleAsapLogicOnServer ? data : data?.order;
      if (updatedOrder) {
        updateOrderInCache(updatedOrder);

        if (!isHandleAsapLogicOnServer && orderHasValidationIssues(data)) {
          unavailabilityDispatch({
            type: 'SET',
            payload: data?.unavailability,
          });
          refetchMenu();
        }

        const isSignificantASAPChange = dayjs(
          (updatedOrder as IOrder)?.orderDateTime
        ).isSameOrAfter(dayjs(oldOrder?.orderDateTime).add(15, 'minute'));

        if (isSignificantASAPChange) {
          notifySignificantAsapChange(updatedOrder as IOrder);
        }
      }

      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: (error) => {
      const updatedOrder = error.additionalData?.order;
      if (updatedOrder) {
        const isSignificantASAPChange = dayjs(
          (updatedOrder as IOrder)?.orderDateTime
        ).isSameOrAfter(dayjs(oldOrder?.orderDateTime).add(15, 'minute'));

        if (isSignificantASAPChange) {
          notifySignificantAsapChange(updatedOrder as IOrder);
        }
      }
      errorOrderScenarios(error);
    },
  });
};
