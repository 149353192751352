import { EAnalyticsEventNames, EModalTypes } from '@olo-web/types/enums';
import { useSendEvent, useOrderIdFeatureFlagEnabled } from '@olo-web/utils/common/hooks';
import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useIsDineIn } from '@olo-web/utils/common/hooks';
import { useCustomerDispatch, useCustomerState, useModalState } from '@olo-web/client-state';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useSavedDineInContextState } from '@olo-web/client-state';
import { useAddPromoCodeToOrder } from '@domain/orders/mutations/useAddPromoCodeToOrder';
import { IPromoCodeDiscount } from '@olo-web/types/loyaltyReward.interface';

export const addOrderItem = async ({
  merchantId,
  orderId,
  item,
}: IAddUpdateOrderItemProps): Promise<IOrder> => {
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/items`;
    const { data } = await axios.post(url, item);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const addOrderItemForGuest = async ({
  merchantId,
  orderId,
  item,
  guestId,
}: IAddUpdateOrderItemToGuestProps): Promise<IOrder> => {
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/guests/${guestId}/items`;
    const { data } = await axios.post(url, item);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useAddOrderItem = (
  options?: UseMutationOptions<
    IOrder,
    AxiosError,
    IAddUpdateOrderItemProps | IAddUpdateOrderItemToGuestProps
  >
) => {
  const { sendEvent } = useSendEvent();
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const updateOrderInCache = useUpdateOrderInCache();
  const orderIdFFEnabled = useOrderIdFeatureFlagEnabled();
  const customerDispatch = useCustomerDispatch();
  const addPromoCodeToOrder = useAddPromoCodeToOrder();
  const customer = useCustomerState();
  const isDineIn = useIsDineIn();
  const { modalKey, previousModal } = useModalState();
  const savedDineInState = useSavedDineInContextState();

  type TOrderAddItemProps = {
    item: IOrderAddItem;
    fromSuggestionSection?: boolean;
    isRecommendedItem?: boolean;
  };
  return useMutation(
    (args: TOrderAddItemProps) => {
      const commonArgs = {
        merchantId: merchant?.merchantId,
        orderId: order?.id,
        ...args,
      };
      if (isDineIn) {
        return addOrderItemForGuest({
          guestId: savedDineInState?.guest?.id,
          ...(commonArgs as IAddUpdateOrderItemToGuestProps),
        });
      } else {
        return addOrderItem(commonArgs as IAddUpdateOrderItemProps);
      }
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        const { item, fromSuggestionSection, isRecommendedItem } = variables;

        let newOrderData = data;
        const itemToSend = data.items.find(
          (itm) => itm.menuItemLocationId === item.menuItemLocationId
        );
        const autoApplyPromoCode = Object.values(customer?.promos).find(
          (promo: IPromoCodeDiscount) => promo.promotionType === 1 && promo.shouldBeAutoApplied
        ) as IPromoCodeDiscount;
        if (
          autoApplyPromoCode &&
          data?.itemSubtotal &&
          !autoApplyPromoCode.isActive &&
          autoApplyPromoCode?.minOrderValue &&
          parseInt(data.itemSubtotal) >= parseInt(autoApplyPromoCode.minOrderValue)
        ) {
          customerDispatch({
            type: 'SET_IS_LOADING_DISCOUNT',
            payload: true,
          });
          const discountedOrder = await addPromoCodeToOrder.mutateAsync({
            promoCode: autoApplyPromoCode.promoCode,
            merchantId: merchant.merchantId,
            orderId: order.id,
          });
          newOrderData = discountedOrder.data;
          customerDispatch({
            type: 'SET_IS_LOADING_DISCOUNT',
            payload: false,
          });
        }
        if (isDineIn) {
          updateOrderInCache(data, [
            'order',
            merchant?.merchantId,
            orderIdFFEnabled ? data?.id : data?.tableNumber,
          ]);
        } else {
          updateOrderInCache(newOrderData);
        }

        const isFromUpsellSheet =
          modalKey === EModalTypes.UPSELL ||
          previousModal[previousModal.length - 1]?.modalKey === EModalTypes.UPSELL;

        const googleAnalytics = {
          ecommerce: {
            menuItems: [{ ...itemToSend }],
          },
          fromSuggestionSection,
          isRecommendedItem,
          ...(isFromUpsellSheet && { categoryName: 'upsellSheet' }),
        };

        sendEvent(EAnalyticsEventNames.ADD_TO_CART, {
          googleAnalytics,
        });
        if (options?.onSuccess)
          options.onSuccess(
            data,
            variables as IAddUpdateOrderItemProps | IAddUpdateOrderItemToGuestProps,
            context
          );
      },
    }
  );
};
