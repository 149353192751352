import { roundToTwo } from './../../../common/functions/roundToTwo';
import { EAuthEntryPoints } from './../../../../types/enums';
import { useCallback } from 'react';
import { ICommonEventData } from '@olo-web/types/commonEventData.interface';
import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { EGALocations } from '@olo-web/types/enums/googleAnalyticsLocations.enum';
import { ERecommendationStatus } from '@olo-web/types/enums/recommendationStatus.enum';
import { ERecommendationType } from '@olo-web/types/enums/recommendationType.enum';

declare const window: any;

export type GAEventItem = {
  item_name: string;
  item_id: string;
  price?: string | number;
  priceAddOns?: string | number;
  priceTotal?: string | number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_variant?: string;
  quantity?: number | string;
  item_coupon?: string;
  item_list_name?: string;
  item_list_id?: string;
  index?: number | null;
};

type TLocationGAEvent = EGALocations;
type TActionGAEvent = 'view' | 'click';

export type GAEventParams = {
  categoryName?: string;
  channel?: string;
  discountNames?: string | null;
  discountTotal?: number | null;
  ecommerce?: {
    affiliation?: string;
    coupon?: string;
    currency?: string;
    menuItems?: (IConfiguredMenuItem | IOrderItem)[]; // this is the item input value from the call within the app
    items?: GAEventItem[]; // this is the item output value desired by GA
    shipping?: number;
    tax?: number;
    transaction_id?: string;
    value?: number | string;
    paymentTypeAvailable?: string[];
    paymentTypeDefaulted?: string;
  };
  errorMessage?: string | null;
  featuredItem?: boolean;
  loyaltyPointsEarned?: number | null;
  paymentType?: string | string[];
  numberOfRequiredGroups?: number;
  hasImage?: boolean;
  promoCode?: string;
  promoId?: string;
  rewardId?: string | number;
  itemId?: string;
  entryPoint?: EAuthEntryPoints | '';
  tagOverrides?: ICommonEventData;
  maxOrderAmount?: string;
  switchOrderTypeTo?: string;
  tipAmount?: number;
  eventTarget?: EventTarget;
  menuSearchTerm?: string;
  menuSearchResultCount?: number;
  location?: TLocationGAEvent;
  action?: TActionGAEvent;
  object?: EAnalyticsEventNames;
  eventMetadata?: Record<string, unknown> | null;
  initiator?: IGuest | IInitiator;
  guestsInfo?: IGuest | IGuest[];
  itemsSent?: IOrderItem[];
  guestsWhoSentItems?: IGuest[];
  dineInType?: string;
  eventInfo?: {
    location: string;
    action: string;
    object: string;
  };
  checkoutType?: string;
  guestBalancesPaid?: number;
  usedPreAuthCardForPayment?: boolean;
  isPreAuthOrder?: boolean;
  fromSuggestionSection?: boolean;
  isRecommendedItem?: boolean;
  recommendationStatus?: ERecommendationStatus;
  recommendationType?: ERecommendationType;
};

type SendEventReturn = {
  sendEvent: (eventName: string, params: GAEventParams) => void;
};

export const useSendGAEvent = (tags: ICommonEventData): SendEventReturn => {
  const sendEvent = useCallback(
    (eventName: string, params?: GAEventParams): void => {
      window.dataLayer = window.dataLayer || [];

      const { ecommerce, tagOverrides = {}, ...rest } = params || {};

      if (ecommerce?.value) {
        ecommerce.currency = 'USD';
        ecommerce.value = Number(ecommerce.value);
      }

      let price = 0;
      let priceAddOns = 0;
      let priceTotal = 0;

      if (ecommerce?.menuItems?.length) {
        const transformedItems = ecommerce.menuItems.map((item): GAEventItem => {
          price += +item.price;
          priceAddOns +=
            'displayAmount' in item
              ? roundToTwo(+item.itemSummaryDisplayAmount - +item.displayAmount)
              : +(item?.price ?? 0);
          priceTotal += +item.itemSummaryDisplayAmount;
          return {
            item_name: item?.name,
            item_id: item.menuItemLocationId,
            item_brand: tags?.merchantName,
            item_category: params?.categoryName,
            quantity: Number(item.quantity),
            index: 'position' in item ? Number(item.position) : null,
            price: Number(item.price),
          };
        });
        ecommerce.items = transformedItems;
      }

      const data: any = {
        event: eventName,
        price,
        priceAddOns,
        priceTotal,
        ...tags,
        ...tagOverrides,
        ...rest,
      };

      if (ecommerce) data.ecommerce = ecommerce;

      window.dataLayer.push({ ecommerce: null }); // this is suggested in GA documentation
      window.dataLayer.push(data);
    },
    [tags]
  );

  return { sendEvent };
};
