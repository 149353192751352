import { useMemo } from 'react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { EOrderTypes } from '@olo-web/types/enums';

export const isDeliveryOrder = (order, merchant) =>
  order?.orderTypeId === merchant?.orderTypeIds[EOrderTypes.DELIVERY] ||
  order?.orderTypeId === merchant?.orderTypeIds[EOrderTypes.DOORDASH_DRIVE];

export const useIsDelivery = () => {
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();

  return useMemo(() => isDeliveryOrder(order, merchant), [merchant, order]);
};
