import React, {
  createContext,
  useDebugValue,
  useContext,
  useReducer,
  useEffect,
  FC,
  useMemo,
} from 'react';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import { isObject } from '../functions';
import { useStorage } from '../hooks';
interface ICreateStoreOptions {
  name: string;
  persist?: 'session' | 'local';
  blacklist?: string[];
}

type TProviderProps = {
  cacheKey?: string;
  children: any;
};

export function createStore<T, R>(
  reducer: (state: T, action: R) => T,
  initialState: T,
  params: ICreateStoreOptions
): [FC<TProviderProps>, () => (action: R) => void, () => T] {
  const storeContext = createContext(null);
  const dispatchContext = createContext(null);

  const StoreProvider = ({ cacheKey = null, children }) => {
    const key = useMemo(() => {
      if (!params.persist) return null;
      if (cacheKey) {
        return `${params?.name}-${cacheKey}`;
      }
      return params?.name;
    }, [cacheKey]);

    const [storedValue, setStoredValue] = useStorage<T>(params.persist, key, initialState);

    const initialReducerState = useMemo(() => {
      if (!storedValue) return initialState;
      if (isObject(storedValue) && isObject(initialState)) {
        return merge(initialState, storedValue);
      }
      return storedValue;
    }, [storedValue]);

    const [state, dispatch] = useReducer(reducer, initialReducerState);

    const persistState = useMemo(
      () => (isObject(state) ? omit(state as any, params?.blacklist || []) : state),
      [state]
    );

    useEffect(() => {
      if (!params.persist) return;
      setStoredValue(persistState);
    }, [persistState, setStoredValue]);

    storeContext.displayName = `${params?.name}State`;
    dispatchContext.displayName = `${params?.name}Dispatch`;

    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={state}>{children}</storeContext.Provider>
      </dispatchContext.Provider>
    );
  };

  const useStore = () => {
    useDebugValue(params?.name);
    return useContext(storeContext);
  };

  const useDispatch = () => {
    useDebugValue(params?.name);
    return useContext(dispatchContext);
  };

  return [StoreProvider, useDispatch, useStore];
}
