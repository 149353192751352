import { RefObject } from 'react';
import { IS_SERVER } from '@olo-web/utils/common/constants';

type T = {
  element: HTMLElement;
  offset: number;
  rootElement?: RefObject<HTMLDivElement>;
};

const scrollElementToTopOfOtherElement = (props: T) => {
  const { element, offset = 0, rootElement } = props;
  const childTop = element.offsetTop;
  const top = childTop + offset;

  // This setTimeout is necessary because there is some other scrolling behavior that is interfering with this scroll and breaking the feature for big menus
  // So we need to make this scroll happen after that one.
  setTimeout(() => {
    rootElement.current.scrollTo({ top, behavior: 'smooth' });
  }, 0);
};

export const scrollElementToTop = (props: T): void => {
  if (!IS_SERVER) {
    const { element, offset = 0, rootElement } = props;
    if (rootElement) return scrollElementToTopOfOtherElement(props);
    const top = element.getBoundingClientRect().top + window.pageYOffset + offset;
    window.scrollTo({ top, behavior: 'smooth' });
  }
};
