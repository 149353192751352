import { theme } from '@chakra-ui/react';

const colors = {
  ...theme.colors,

  white: '#FFFFFF',
  black: '#0F162C',
  charcoal: 'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #FFFFFF',

  primary: {
    50: '#E8F0FF',
    100: '#B5CFFE',
    200: '#5D96FF',
    300: '#1769FF',
    350: '#1769FF',
    400: '#1254CC',
    500: '#0E3F99',
    600: '#092A66',
  },

  base: {
    50: '#F1F2F5',
    100: '#D6D9E1',
    200: '#BBC0CE',
    300: '#77819C',
    400: '#646E8A',
    500: '#47516E',
    600: '#353F5E',
  },

  informative: {
    50: '#e2def7',
    100: '#C6BEEF',
    200: '#9B8DE2',
    300: '#705CD6',
    400: '#4F37C9',
    500: '#402BAB',
    600: '#321F94',
  },

  success: {
    50: '#CCEEDC',
    100: '#99DDB9',
    200: '#4DC484',
    300: '#00AB4F',
    400: '#00893F',
    500: '#00672F',
    600: '#015428',
  },

  warning: {
    50: '#fcf1d9',
    100: '#FFE5B4',
    200: '#FFD27B',
    300: '#FFC043',
    400: '#FFB234',
    500: '#FF9C1E',
    600: '#C7732C',
  },

  danger: {
    50: '#f8dddd',
    100: '#FCBCBB',
    200: '#F07877',
    300: '#F73E3C',
    400: '#B92624',
    500: '#A41313',
    600: '#8C0000',
  },

  loyalty: {
    50: 'rgba(204, 153, 53, 0.10)',
    100: '#d8c8a9',
    200: '#c4ad7e',
    300: '#CC9A36',
    400: '#B5882E',
    500: '#9D7628',
    600: '#8d6a24',
  },

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.08)',
    200: 'rgba(255, 255, 255, 0.16)',
    300: 'rgba(255, 255, 255, 0.36)',
    400: 'rgba(255, 255, 255, 0.64)',
    500: 'rgba(255, 255, 255, 0.80)',
    600: 'rgba(255, 255, 255, 0.92)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.08)',
    200: 'rgba(0, 0, 0, 0.16)',
    300: 'rgba(0, 0, 0, 0.36)',
    350: 'rgba(0, 0, 0, 0.54)', // added because it's the lightest gray recommended by WCAG
    400: 'rgba(0, 0, 0, 0.64)',
    500: 'rgba(0, 0, 0, 0.80)',
    600: 'rgba(0, 0, 0, 0.92)',
  },

  secondary: {
    500: '#949494',
  },
};

export default colors;
