import { theme } from '@chakra-ui/react';
const d = theme.components.Input;

const Input = {
  ...d,
  sizes: {
    ...d.sizes,
    md: {
      ...d.sizes.md,
      addon: {
        // @ts-ignore comment
        ...d.sizes.md.addon,
        h: '44px',
        minW: '44px',
      },
      field: {
        ...d.sizes.md.field,
        h: '44px',
      },
    },
  },
  defaultProps: {
    colorScheme: 'primary',
    size: 'md',
    variant: 'outline',
    focusBorderColor: 'primary.350',
  },
};

export default Input;
