export enum EAlertTypes {
  SHOW_UNAVAILABLE_ORDER = 'showUnavailableOrder',
  PRODUCT_UNAVAILABILITY = 'productUnavailability',
  MAX_ORDER_AMOUNT = 'maxOrderAmount',
  LOCATION_CHANGE_EMPTIED_BAG = 'locationChangeEmptiedBag',
  PACING_CAPACITY_ALERT = 'pacingCapacityAlert',
  REWARDS_CAPACITY_ALERT = 'rewardsCapacityAlert',
  REWARD_TO_AN_EMPTY_BAG_ALERT = 'rewardToAnEmptyBagAlert',
  NO_AVAILABLE_TIMES_ALERT = 'noAvailableTimesAlert',
  VALIDATE_ORDER_TYPE_ALERT = 'validateOrderTypeAlert',
  SBA_PAYMENT_OVER_BALANCE_ALERT = 'sbaPaymentOverBalanceAlert',
  SERVER_TAB_STARTED = 'serverTabStarted',
  DELETE_ACCOUNT = 'deleteAccount',
}
