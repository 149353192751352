import { EValidationFields } from './../types/enums/paymentValidationFIelds.enum';
import { EPaymentMethods } from '@olo-web/types/enums/paymentMethods.enum';
import { createStore } from '@olo-web/utils/common/store';
import { IOrderDiscount } from '@olo-web/domain/orders/types';
import { IUtmParameters } from '@olo-web/types/extraParameters.interface';

interface ICheckoutState {
  selectedPaymentMethod: EPaymentMethods | null;
  giftCardNumber: string | null;
  selectedCard: IPaymentCardDetails | null;
  isContactBeingEdited: boolean;
  showCreditCardForm: boolean;
  orderNote: string;
  saveCard: boolean;
  ccFieldsAvailable: boolean;
  isEditingContactInfo: boolean;
  isContactlessDelivery: boolean;
  invalidCollectJsInputFieldNames: EValidationFields[];
  isLoading: boolean;
  gclid: string;
  utmParams: IUtmParameters;
  addedPayment: ICollectJSCallbackResponse | null;
  selectedOffer: IOrderDiscount | null;
  zipCode: string | null;
  guestBalance?: number;
  paymentForGuestIds?: string[];
  checkedGuests?: IGuest[];
  startATabIsLoading?: boolean;
}

type TClearCheckoutAction = Omit<IStandardAction<'CLEAR'>, 'payload'>;
type TClearGiftCardAction = Omit<IStandardAction<'CLEAR_GIFTCARD'>, 'payload'>;
type TSetGiftCardAction = IStandardAction<'SET_GIFTCARD', string>;
type TSetFieldsAvailableAction = IStandardAction<'SET_FIELDS_AVAILABLE', boolean>;
type TSelectPaymentMethod = IStandardAction<'SELECT_PAYMENT_METHOD', EPaymentMethods>;
type TSetSelectedCard = IStandardAction<'SET_SELECTED_CARD', IPaymentCardDetails | null>;
type TSetIsEditingContactInfo = IStandardAction<'SET_IS_EDITING_CONTACT_INFO', boolean>;
type TToggleIsEditingContactInfo = Omit<
  IStandardAction<'TOGGLE_IS_EDITING_CONTACT_INFO'>,
  'payload'
>;
type TStartSubmission = TEmptyAction<'START_SUBMISSION'>;
type TCompleteSubmission = TEmptyAction<'COMPLETE_SUBMISSION'>;
type TAddCollectJsInvalidFieldName = IStandardAction<
  'ADD_COLLECTJS_INVALID_FIELD_NAME',
  EValidationFields
>;
type TRemoveCollectJsInvalidFieldName = IStandardAction<
  'REMOVE_COLLECTJS_INVALID_FIELD_NAME',
  EValidationFields
>;
type TSetGclid = IStandardAction<'SET_GCLID', string>;
type TSetUtmParams = IStandardAction<'SET_UTM_PARAMS', IUtmParameters>;
type TSetPayment = IStandardAction<'ADD_PAYMENT', ICollectJSCallbackResponse>;
type TClearPayment = TEmptyAction<'REMOVE_PAYMENT'>;
type TClearCreditCard = TEmptyAction<'CLEAR_SELECTED_CARD'>;
type TSetSaveCard = IStandardAction<'SET_SAVE_CARD_AT_CHECKOUT', boolean>;
type TSetIsContactlessDelivery = IStandardAction<'SET_IS_CONTACTLESS_DELIVERY', boolean>;
type TSetOrderNote = IStandardAction<'SET_ORDER_NOTE', string>;
type TSetSelectedOffer = IStandardAction<'SET_SELECTED_OFFER', IOrderDiscount>;
type TClearSelectedOffer = TEmptyAction<'CLEAR_SELECTED_OFFER'>;
type TSetZipCode = IStandardAction<'SET_ZIP_CODE', string>;
type TSetGuestBalance = IStandardAction<'SET_GUEST_BALANCE', number>;
type TSetPaymentForGuestIds = IStandardAction<'SET_PAYMENT_FOR_GUEST_IDS', string[]>;
type TSetGuests = IStandardAction<'SET_CHECKED_GUESTS', IGuest[]>;
type TSetStartATabIsLoading = IStandardAction<'SET_START_A_TAB_LOADING', boolean>;

export type TCheckoutAction =
  | TClearCreditCard
  | TClearCheckoutAction
  | TSetFieldsAvailableAction
  | TSelectPaymentMethod
  | TClearGiftCardAction
  | TSetGiftCardAction
  | TSetSelectedCard
  | TSetIsEditingContactInfo
  | TToggleIsEditingContactInfo
  | TStartSubmission
  | TCompleteSubmission
  | TAddCollectJsInvalidFieldName
  | TRemoveCollectJsInvalidFieldName
  | TSetGclid
  | TSetUtmParams
  | TSetPayment
  | TClearPayment
  | TSetSaveCard
  | TSetIsContactlessDelivery
  | TSetOrderNote
  | TSetSelectedOffer
  | TClearSelectedOffer
  | TSetZipCode
  | TSetGuestBalance
  | TSetPaymentForGuestIds
  | TSetGuests
  | TSetStartATabIsLoading;

const initialState: ICheckoutState = {
  selectedPaymentMethod: null,
  giftCardNumber: null,
  selectedCard: null,
  isContactBeingEdited: false,
  showCreditCardForm: false,
  orderNote: '',
  saveCard: false,
  ccFieldsAvailable: false,
  isEditingContactInfo: false,
  isContactlessDelivery: true,
  invalidCollectJsInputFieldNames: [],
  isLoading: false,
  gclid: '', // gclid is sent as a query param after a Google Ad click, and used for tracking upon order completion
  utmParams: null, // utmParams serve as a tracking point for marketing tools
  addedPayment: null,
  selectedOffer: null,
  zipCode: null,
  checkedGuests: [],
  startATabIsLoading: false,
};

const reducer = (state: ICheckoutState, action: TCheckoutAction): ICheckoutState => {
  switch (action.type) {
    case 'SET_SELECTED_CARD':
      return {
        ...state,
        selectedCard: action.payload,
        showCreditCardForm: !action.payload,
      };
    case 'CLEAR_SELECTED_CARD':
      return {
        ...state,
        selectedCard: initialState.selectedCard,
        showCreditCardForm: initialState.showCreditCardForm,
      };
    case 'SET_GIFTCARD':
      return { ...state, giftCardNumber: action.payload };
    case 'CLEAR_GIFTCARD':
      return { ...state, giftCardNumber: null };
    case 'SELECT_PAYMENT_METHOD':
      return { ...state, selectedPaymentMethod: action.payload };
    case 'SET_FIELDS_AVAILABLE':
      return { ...state, ccFieldsAvailable: action.payload };
    case 'SET_IS_EDITING_CONTACT_INFO':
      return {
        ...state,
        isEditingContactInfo: action.payload,
      };
    case 'TOGGLE_IS_EDITING_CONTACT_INFO': {
      return {
        ...state,
        isEditingContactInfo: !state.isEditingContactInfo,
      };
    }
    case 'ADD_COLLECTJS_INVALID_FIELD_NAME': {
      if (state.invalidCollectJsInputFieldNames.includes(action.payload)) return state;
      return {
        ...state,
        invalidCollectJsInputFieldNames: [...state.invalidCollectJsInputFieldNames, action.payload],
      };
    }
    case 'REMOVE_COLLECTJS_INVALID_FIELD_NAME': {
      const idx = state.invalidCollectJsInputFieldNames.indexOf(action.payload);
      if (idx < 0) return state;
      return {
        ...state,
        invalidCollectJsInputFieldNames: [
          ...state.invalidCollectJsInputFieldNames.slice(0, idx),
          ...state.invalidCollectJsInputFieldNames.slice(idx + 1),
        ],
      };
    }
    case 'CLEAR':
      return initialState;
    case 'START_SUBMISSION':
      return { ...state, isLoading: true };
    case 'COMPLETE_SUBMISSION':
      return { ...state, isLoading: false };
    case 'SET_UTM_PARAMS':
      return { ...state, utmParams: action.payload };
    case 'SET_GCLID':
      return { ...state, gclid: action.payload };
    case 'ADD_PAYMENT':
      return { ...state, addedPayment: action.payload };
    case 'REMOVE_PAYMENT': {
      return { ...state, addedPayment: initialState.addedPayment, zipCode: initialState.zipCode };
    }
    case 'SET_SAVE_CARD_AT_CHECKOUT':
      return { ...state, saveCard: action.payload };
    case 'SET_IS_CONTACTLESS_DELIVERY':
      return { ...state, isContactlessDelivery: action.payload };
    case 'SET_ORDER_NOTE':
      return { ...state, orderNote: action.payload };
    case 'SET_SELECTED_OFFER':
      return { ...state, selectedOffer: action.payload };
    case 'CLEAR_SELECTED_OFFER':
      return { ...state, selectedOffer: initialState.selectedOffer };
    case 'SET_ZIP_CODE':
      return { ...state, zipCode: action.payload };
    case 'SET_GUEST_BALANCE':
      return { ...state, guestBalance: action.payload };
    case 'SET_PAYMENT_FOR_GUEST_IDS':
      return { ...state, paymentForGuestIds: action.payload };
    case 'SET_CHECKED_GUESTS':
      return { ...state, checkedGuests: action.payload };
    case 'SET_START_A_TAB_LOADING':
      return { ...state, startATabIsLoading: action.payload };
    default:
      return state;
  }
};

export const [CheckoutContextProvider, useCheckoutDispatch, useCheckoutState] = createStore<
  ICheckoutState,
  TCheckoutAction
>(reducer, initialState, {
  name: 'checkout',
  persist: 'session',
  blacklist: [
    'selectedPaymentMethod',
    'giftCardNumber',
    'selectedCard',
    'isContactBeingEdited',
    'showCreditCardForm',
    'saveCard',
    'ccFieldsAvailable',
    'contactInfo',
    'isEditingContactInfo',
    'isContactlessDelivery',
    'invalidCollectJsInputFieldNames',
    'isLoading',
    'addedPayment',
    'selectedOffer',
  ],
});
