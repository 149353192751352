import { useState, useCallback } from 'react';

// TODO: Cover with typings properly
export function useCallbackRef() {
  const [ref, setRef] = useState(null);
  const fn = useCallback((node) => {
    setRef(node);
  }, []);

  return [ref, fn];
}
