import { ELocation, EAddressType } from '@olo-web/types/enums';
import { GoogleMapsPlaceResult } from '../google';
// import { checkDeliveryAddress } from 'api/order.data';

interface IDistanceMerchant {
  distance: number;
  merchant: IMerchant;
}

export const convertLocationTypeToOption = (location: ELocation): IOption => {
  const options: IOption[] = [
    {
      label: 'Select Address',
      value: ELocation.NONE,
    },
    {
      label: 'Home',
      value: ELocation.HOME,
    },
    {
      label: 'Work',
      value: ELocation.WORK,
    },
    {
      label: 'Other',
      value: ELocation.OTHER,
    },
  ];

  return options.filter((o) => o.value === location)[0];
};

export const convertOptionToLocation = (option: IOption): ELocation => {
  switch (option.value) {
    case ELocation.HOME: {
      return ELocation.HOME;
    }
    case ELocation.WORK: {
      return ELocation.WORK;
    }
    case ELocation.OTHER: {
      return ELocation.OTHER;
    }
    default: {
      return ELocation.NONE;
    }
  }
};

export const convertMerchantToDelivery = (merchant: IMerchant): IDeliveryAddress => {
  return {
    address1: merchant.street1,
    address2: merchant.street2,
    city: merchant.city,
    state: merchant.state,
    zip: merchant.zip,
  };
};

// Calculate the distance using the `Spherical Law of Cosines`
//
// https://www.movable-type.co.uk/scripts/latlong.html
export const calculateDistance = (a: number[], b: number[]): number => {
  function degToRad(d: number): number {
    return d * (Math.PI / 180);
  }

  function radToDeg(r: number): number {
    return r * (180 / Math.PI);
  }

  const [lngA, latA] = a;
  const [lngB, latB] = b;

  let dist =
    Math.sin(degToRad(latA)) * Math.sin(degToRad(latB)) +
    Math.cos(degToRad(latA)) * Math.cos(degToRad(latB)) * Math.cos(degToRad(lngB - lngA));

  dist = Math.acos(dist);
  dist = radToDeg(dist);

  // to miles
  // dist = dist * 60 * 1.1515;
  //
  // to meters
  // dist = dist * 6371000

  return dist;
};

const compareDistances = (a: IDistanceMerchant, b: IDistanceMerchant): number => {
  if (a.distance < b.distance) {
    return -1;
  }

  if (a.distance > b.distance) {
    return 1;
  }

  return 0;
};

// https://stackoverflow.com/questions/9589130/find-closest-longitude-and-latitude-in-array
export const getClosestPickupLocation = (
  place: GoogleMapsPlaceResult,
  merchantList: IMerchant[]
): IMerchant[] => {
  let availableLocations: IMerchant[] = [];
  let closestLocations: IDistanceMerchant[] = [];

  if (place && place.address_components) {
    const cityAddressComponent = place.address_components.find(
      (ac) => ac.types.includes(EAddressType.CITY) || ac.types.includes(EAddressType.SUB_LOCALITY)
    );

    if (cityAddressComponent) {
      availableLocations = merchantList.filter((m) => {
        return (
          m.orderTypeIds.pickup.length > 0 &&
          m.city.toLowerCase() === cityAddressComponent.long_name.toLowerCase()
        );
      });

      closestLocations = availableLocations.map((m) => {
        if (place.geometry) {
          return {
            distance: calculateDistance(
              [place.geometry.location.lat(), place.geometry.location.lng()],
              [m.addressGeometry.location.lat, m.addressGeometry.location.lng]
            ),
            merchant: m,
          };
        }

        return {
          distance: 999999,
          merchant: m,
        };
      });
    }
  }

  // sort closest locations
  return closestLocations.sort(compareDistances).map((l) => l.merchant);
};

// export async function getAvailableDeliveryLocationsFromPlace(
//     place: GoogleMapsPlaceResult,
//     merchantId: string,
//     merchantList: IMerchant[],
// ): Promise<IMerchant[]> {
//     let availableLocations: IMerchant[] = [];
//     if (place && place.geometry) {
//         try {
//             const res = await checkDeliveryAddress(
//                 merchantId,
//                 [place.geometry.location.lat(), place.geometry.location.lng()],
//                 true,
//             );

//             const { allValidLocations } = res.data;

//             if (allValidLocations && allValidLocations.length > 0) {
//                 availableLocations = merchantList.filter(m => {
//                     return allValidLocations.find(
//                         l =>
//                             l.merchantId === m.merchantId &&
//                             m.orderTypeIds &&
//                             m.orderTypeIds.delivery,
//                     );
//                 });
//             }
//         } catch (e) {
//             console.error('[locationUtils] Failed to check delivery address');
//         }
//     }

//     return availableLocations;
// }

// export async function getAvailableDeliveryLocations(
//     lat: number,
//     lng: number,
//     merchantId: string,
//     merchantList: IMerchant[],
// ): Promise<IMerchant[]> {
//     let availableLocations: IMerchant[] = [];
//     try {
//         const res = await checkDeliveryAddress(merchantId, [lat, lng], true);

//         const { allValidLocations } = res.data;

//         if (allValidLocations && allValidLocations.length > 0) {
//             availableLocations = merchantList.filter(m => {
//                 return allValidLocations.find(
//                     l =>
//                         l.merchantId === m.merchantId &&
//                         m.orderTypeIds &&
//                         m.orderTypeIds.delivery,
//                 );
//             });
//         }
//     } catch (e) {
//         console.error('[locationUtils] Failed to check delivery address');
//     }

//     return availableLocations;
// }

export const getAvailablePickupLocations = (
  place: GoogleMapsPlaceResult,
  merchantList: IMerchant[]
): IMerchant[] => {
  let availableLocations: IMerchant[] = [];

  if (place && place.address_components) {
    const cityAddressComponent = place.address_components.find(
      (ac) => ac.types.includes(EAddressType.CITY) || ac.types.includes(EAddressType.SUB_LOCALITY)
    );

    if (cityAddressComponent) {
      availableLocations = merchantList.filter((m) => {
        return (
          m.orderTypeIds.pickup.length > 0 &&
          m.city.toLowerCase() === cityAddressComponent.long_name.toLowerCase()
        );
      });
    }
  }

  return availableLocations;
};

export function inputValueFromMerchant(merchant: IDeliveryAddress): string {
  return `${merchant.address1}, ${merchant.city}, ${merchant.state} ${merchant.zip}`;
}
