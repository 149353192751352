import { useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { useCallbackRef } from './useCallbackRef';

// TODO: Cover with typings properly
export function useMeasure(ref) {
  const [element, attachRef] = useCallbackRef();
  const [bounds, setBounds] = useState({} as DOMRectReadOnly);

  useEffect(() => {
    const observer = new ResizeObserver(([entry]: [ResizeObserverEntry]) => {
      setBounds(entry.contentRect);
    });

    if (element && element.current) {
      observer.observe(element.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [element]);

  useEffect(() => {
    attachRef(ref);
  }, [attachRef, ref]);

  return bounds;
}
