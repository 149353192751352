import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useQuery, UseQueryResult } from 'react-query';
import { configureMerchantData } from '../functions';
import { useFeatureFlags } from '@olo-web/domain/featureFlags';

export const getMerchant = async (
  merchantId: string,
  disallowDoorDashDriveOrders?: boolean
): Promise<IUseMerchantData> => {
  const { data } = await axios.get(`/api/merchants/${merchantId}`);
  return configureMerchantData(data, disallowDoorDashDriveOrders);
};

export function useMerchant(
  merchantId?: string,
  initialData?: IUseMerchantData
): UseQueryResult<IUseMerchantData, AxiosError> {
  const router = useRouter();
  const { merchantId: merchantIdFromUrl } = router.query;
  const merchId = merchantId || merchantIdFromUrl;
  const { data: flags } = useFeatureFlags();

  return useQuery(
    ['merchant', merchId],
    () => getMerchant(merchId as string, flags?.disallowDoorDashDriveOrders),
    {
      staleTime: 1000000,
      enabled: !!merchId,
      initialData,
    }
  );
}
