import Script from 'next/script';
import React, { memo } from 'react';
import { usePaymentTokenizationKey } from '@olo-web/domain';
import { useGlobalUIDispatch } from '@olo-web/client-state';
import { useConfigureCollectJS } from './useConfigureCollectJS';
declare const window: any;

export const CollectJS = memo(() => {
  const { data } = usePaymentTokenizationKey();
  const globalUiDispatch = useGlobalUIDispatch();
  useConfigureCollectJS();

  return (
    !!data?.nmiTokenizationKey && (
      <Script
        strategy="afterInteractive"
        onLoad={() => {
          globalUiDispatch({ type: 'SET_COLLECTJS', payload: window.CollectJS });
        }}
        onError={() => console.error('Error Loading Collect.js')}
        src="https://secure.safewebservices.com/token/Collect.js"
        data-tokenization-key={data.nmiTokenizationKey}
      />
    )
  );
});
