import axios from 'axios';
import { useEffect } from 'react';
import { useIsScanAndPayPage } from './useIsScanAndPayPage';
import { useIsDineIn } from './useIsDineIn';
import { EJoinSource } from '@olo-web/types/enums/joinSource.enum';

export const useJoinSourceHeader = () => {
  const isScanAndPay = useIsScanAndPayPage();
  const isDineIn = useIsDineIn();

  useEffect(() => {
    let joinSource = EJoinSource.OLO_WEB;

    if (isScanAndPay) joinSource = EJoinSource.SCAN_AND_PAY;
    else if (isDineIn) joinSource = EJoinSource.ORDER_AND_PAY;

    axios.defaults.headers.common['so-join-source'] = joinSource;
  }, [isScanAndPay, isDineIn]);
};
