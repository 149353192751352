import { useEffect, useState } from 'react';
import { useUpdateOrderStatus } from '@olo-web/domain/orders/mutations/useUpdateOrderStatus';
import { EOrderStatus } from '@olo-web/types/enums/orderStatus.enum';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useSavedDineInContextState } from '@olo-web/client-state';
import { useIsDineIn } from '@olo-web/utils/common/hooks';

export const useResetQROrderBuilding = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { data: order } = useOrder();
  const isDineInRoute = useIsDineIn();
  const savedDineInState = useSavedDineInContextState();
  const { mutate: updateOrderStatus } = useUpdateOrderStatus();

  useEffect(() => {
    if (isDineInRoute) {
      if (order && isFirstLoad) {
        if (
          order?.groupOrderInfo?.statusGuestId === savedDineInState?.guest?.id &&
          order?.groupOrderInfo?.status === EOrderStatus.IN_CHECKOUT
        ) {
          updateOrderStatus({
            status: EOrderStatus.BUILDING,
          });
        }
        setIsFirstLoad(false);
      }
    }
  }, [isDineInRoute, order, updateOrderStatus, savedDineInState?.guest?.id, isFirstLoad]);
};
