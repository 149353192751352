import { useEffect } from 'react';
import { useIsClient } from './useIsClient';
import { breakpointsObject } from '@olo-web/theme/breakpoints';
import throttle from 'lodash/throttle';
import { useScreenSizeDispatch } from '@olo-web/client-state';

// convert breakpointsObject values to number for useScreenSize
const bps: any = Object.keys(breakpointsObject).reduce((acc, key) => {
  acc[key] = parseInt(breakpointsObject[key].split('px')[0]);
  return acc;
}, {});

export const useScreenSizeListener = (): void => {
  const screenSizeDispatch = useScreenSizeDispatch();
  const isClient = useIsClient();

  const updateSize = throttle((): void => {
    const w = document.documentElement.clientWidth;
    screenSizeDispatch({
      type: 'SET',
      payload: {
        isXxsDown: !!(w && w < parseInt(bps.xxs)),
        isXxs: !!(w && w >= parseInt(bps.xxs)),
        isXsDown: !!(w && w < parseInt(bps.xs)),
        isXs: !!(w && w >= parseInt(bps.xs)),
        isSmDown: !!(w && w < parseInt(bps.sm)),
        isSm: !!(w && w >= parseInt(bps.sm)),
        isMdDown: !!(w && w < parseInt(bps.md)),
        isMd: !!(w && w >= parseInt(bps.md)),
        isLgDown: !!(w && w < parseInt(bps.lg)),
        isLg: !!(w && w >= parseInt(bps.lg)),
        isXlDown: !!(w && w < parseInt(bps.xl)),
        isXl: !!(w && w >= parseInt(bps.xl)),
        isXxlDown: !!(w && w < parseInt(bps.xxl)),
        isXxl: !!(w && w >= parseInt(bps.xxl)),
      },
    });
  }, 250);

  useEffect(() => {
    if (isClient) {
      updateSize();
      window.addEventListener('resize', updateSize);
    }
    return (): void => window.removeEventListener('resize', updateSize);
    // intentionally ignoring the dependency warning below (Should only be isClient)
    // eslint-disable-next-line
  }, [isClient]);
};
