import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import queryStringHelper from 'query-string';
import { useMerchantPath } from '@domain/merchants/hooks/useMerchantPath';
import { getQueryString } from '../functions';

type TQueryParams = Record<string, string>;

export const useGoToSplitByAmountPage = () => {
  const router = useRouter();
  const merchantPath = useMerchantPath();
  const queryString = getQueryString();

  const query = useMemo(() => queryStringHelper.parse(queryString), [queryString]);
  const pathname = `${merchantPath}/split-by-amount`;

  return useCallback(
    (queryParamsArg: TQueryParams = {}) => {
      router.push(
        {
          pathname,
          query: { ...query, ...queryParamsArg },
        },
        undefined,
        { shallow: true }
      );
    },
    [pathname, query, router]
  );
};
