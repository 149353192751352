import { useQueryClient } from 'react-query';
import { useCallback } from 'react';
import { useOrderQueryKey } from './useOrderQueryKey';
import { useSavedDineInContextState } from '@olo-web/client-state';
import { useOrderIdFeatureFlagEnabled } from '@olo-web/utils/common/hooks/useOrderIdFeatureFlagEnabled';

export const useUpdateOrderInCache = () => {
  const queryClient = useQueryClient();
  const orderIdFFEnabled = useOrderIdFeatureFlagEnabled();
  const savedDineInState = useSavedDineInContextState();
  const identifier = orderIdFFEnabled ? savedDineInState?.orderId : savedDineInState?.table?.number;
  const orderQueryKey: string[] = useOrderQueryKey(identifier ? { identifier } : undefined);

  return useCallback(
    (order, passedQueryKey?: string[]) => {
      if (passedQueryKey) {
        if (passedQueryKey[2] === null || passedQueryKey[2] === undefined) return;
      } else {
        if (orderQueryKey[2] === null || orderQueryKey[2] === undefined) return;
      }

      queryClient.setQueryData(passedQueryKey || orderQueryKey, order);
    },
    [orderQueryKey, queryClient]
  );
};
