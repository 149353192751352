/**
 * You must use this hook for any conditional rendering based on whether it's in the client or server.
 * https://nextjs.org/docs/messages/react-hydration-error
 */

import { useEffect, useState } from 'react';

export const useIsClient = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient;
};
