import { useRouter } from 'next/router';

export const useIsMenuPage = () => {
  const router = useRouter();

  return (
    router.route === '/[locationSlug]/[merchantSlug]/[merchantId]' ||
    router.route === '/[locationSlug]/[merchantSlug]/[merchantId]/[orderType]'
  );
};
