import { IMerchantTheme } from '@olo-web/types/merchantTheme.interface';
import { IBannerProps } from '@olo-web/types/bannerProps.interface';

export const useBannerColorScheme = (
  status: IBannerProps['status'],
  colorScheme: keyof IMerchantTheme['colors'] | any
): keyof IMerchantTheme['colors'] | string => {
  if (colorScheme) return colorScheme;
  switch (status) {
    case 'error':
      return 'red';
    case 'success':
      return 'green';
    case 'warning':
      return 'warning';
    default:
      return 'gray';
  }
};
