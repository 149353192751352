import { useCheckoutState, useCustomerState } from '@olo-web/client-state';
import { useCheckout } from '@olo-web/domain/orders/mutations/useCheckout';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { usePaymentMethodValues } from '@olo-web/domain/payments/hooks';
import { useIsScanAndPayPage, useIsDineIn } from '@olo-web/utils/common/hooks';
import { useCallback } from 'react';
import { useCustomerContactInfo } from './useCustomerContactInfo';
import { useDeliverServiceData } from './useDoorDashDeliveryData';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';

export const useCompleteOrderWithSelectedCard = () => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const { mutateAsync: checkout } = useCheckout();
  const { orderNote, selectedCard, gclid, paymentForGuestIds, guestBalance } = useCheckoutState();
  const { tipAmount: tip } = usePaymentMethodValues();
  const tipAmount = tip?.toFixed(2) ?? '0.00';
  const isSplitByAmount = useIsSplitByAmount();
  const customer = useCustomerContactInfo();
  const { customerId, sessionId, sessionSecret } = useCustomerState();
  const deliveryServiceData = useDeliverServiceData();
  const isScanAndPayPage = useIsScanAndPayPage();
  const isDineIn = useIsDineIn();

  return useCallback(() => {
    return checkout({
      merchantId: merchant?.merchantId,
      orderId: order?.id,
      checkId: order?.checkId,
      customerSessionDetails: {
        customerId,
        sessionId,
        sessionSecret,
      },
      orderNotes: orderNote,
      customer,
      paymentForGuestIds: isSplitByAmount ? paymentForGuestIds : undefined,
      paymentInfo: {
        tipAmount,
        amount: isSplitByAmount ? guestBalance : undefined,
        vtsPaymentInfo: {
          paymentMethodId: selectedCard?.id,
          address1: selectedCard?.address?.address1,
          address2: selectedCard?.address?.address2,
          city: selectedCard?.address?.city,
          firstName: selectedCard?.address?.firstName,
          lastName: selectedCard?.address?.lastName,
          postalCode: selectedCard?.address?.postalCode,
          state: selectedCard?.address?.provinceCode,
          paymentChannel: isScanAndPayPage ? 'qr-scan-pay' : isDineIn ? 'qr-order-pay' : 'olo-web',
          savePaymentMethodAsDefault: false,
          saveToPaymentMethod: false,
        },
      },
      conversionId: gclid,
      conversionType: gclid ? 'google' : null,
      deliveryAddress: {
        address1: deliveryServiceData?.deliveryInfo?.dropoffAddress?.street,
        address2: deliveryServiceData?.deliveryInfo?.dropoffAddress?.unit,
        city: deliveryServiceData?.deliveryInfo?.dropoffAddress?.city,
        state: deliveryServiceData?.deliveryInfo?.dropoffAddress?.state,
        zip: deliveryServiceData?.deliveryInfo?.dropoffAddress?.zipCode,
      },
    });
  }, [
    checkout,
    merchant?.merchantId,
    order?.id,
    order?.checkId,
    customerId,
    sessionId,
    sessionSecret,
    orderNote,
    customer,
    paymentForGuestIds,
    tipAmount,
    guestBalance,
    selectedCard?.id,
    selectedCard?.address?.address1,
    selectedCard?.address?.address2,
    selectedCard?.address?.city,
    selectedCard?.address?.firstName,
    selectedCard?.address?.lastName,
    selectedCard?.address?.postalCode,
    selectedCard?.address?.provinceCode,
    isScanAndPayPage,
    isDineIn,
    gclid,
    deliveryServiceData?.deliveryInfo?.dropoffAddress?.street,
    deliveryServiceData?.deliveryInfo?.dropoffAddress?.unit,
    deliveryServiceData?.deliveryInfo?.dropoffAddress?.city,
    deliveryServiceData?.deliveryInfo?.dropoffAddress?.state,
    deliveryServiceData?.deliveryInfo?.dropoffAddress?.zipCode,
    isSplitByAmount,
  ]);
};
