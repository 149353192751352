import { useSavedDineInContextState } from '@olo-web/client-state';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMemo } from 'react';

export const useLastGuestPayment = () => {
  const { data: order } = useOrder();
  const savedDineInState = useSavedDineInContextState();

  return useMemo(() => {
    const guestPayments = order?.payments?.filter(
      (payment) => payment.guestId === savedDineInState?.guest?.id
    );

    return guestPayments?.[guestPayments?.length - 1];
  }, [order?.payments, savedDineInState?.guest?.id]);
};
