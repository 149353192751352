export enum ECustomThemeViews {
  COLOR = 'colors',
  FONTS = 'fonts',
  LOGO = 'logoUrl',
  BACKGROUND_IMAGE = 'backgroundUrl',
  FAVICON = 'faviconUrl',
  ADMIN = 'admin',
  MANAGE_ACCESS = 'manage access',
  MANAGE_LOCATIONS = 'manage locations',
  PIXELS_AND_KEYS = 'pixels and keys',
  SETTINGS = 'settings',
}
