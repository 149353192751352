import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

export const getPollAccountActiviation = async (pollingId: string): Promise<any> => {
  try {
    const { data } = await axios.get(`/api/customers/check-activation/${pollingId}`);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const usePollAccountActivation = (
  pollingId: string
): UseQueryResult<{ authToken: string | null }, AxiosError> => {
  return useQuery('poll-account-activation', () => getPollAccountActiviation(pollingId), {
    staleTime: 1000000,
    enabled: !!pollingId,
  });
};
