import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

interface IGooglePaySmall extends IconProps {
  variant: 'black' | 'white';
}

export const GooglePaySmall = (props: IGooglePaySmall) => {
  const { variant = 'black', ...rest } = props;
  return (
    <Icon viewBox="0 0 57 23" fill="none" {...rest}>
      <path
        d="M26.1698 11.0994V17.5856H24.1113V1.56836H29.5683C30.9513 1.56836 32.1307 2.02937 33.0956 2.95137C34.0819 3.87338 34.5751 4.99909 34.5751 6.3285C34.5751 7.69007 34.0819 8.81578 33.0956 9.72706C32.1414 10.6384 30.9621 11.0886 29.5683 11.0886H26.1698V11.0994ZM26.1698 3.54103V9.12669H29.6112C30.426 9.12669 31.1122 8.84794 31.6482 8.30117C32.195 7.7544 32.4737 7.08969 32.4737 6.33922C32.4737 5.59947 32.195 4.94549 31.6482 4.39871C31.1122 3.8305 30.4367 3.55175 29.6112 3.55175H26.1698V3.54103Z"
        fill={variant}
      />
      <path
        d="M39.9574 6.26465C41.4798 6.26465 42.6805 6.67205 43.5596 7.48685C44.4388 8.30164 44.8783 9.41663 44.8783 10.8318V17.5861H42.9164V16.0637H42.8306C41.9836 17.318 40.8472 17.9398 39.432 17.9398C38.2206 17.9398 37.2128 17.5861 36.398 16.8677C35.5832 16.1494 35.1758 15.2596 35.1758 14.1875C35.1758 13.0511 35.6046 12.1505 36.4623 11.4858C37.32 10.8104 38.4671 10.478 39.893 10.478C41.1152 10.478 42.123 10.7032 42.9056 11.1534V10.6817C42.9056 9.9634 42.6269 9.36302 42.0587 8.85914C41.4905 8.35525 40.8258 8.10866 40.0646 8.10866C38.9174 8.10866 38.0061 8.59111 37.3414 9.56672L35.5296 8.43029C36.5266 6.98296 38.0061 6.26465 39.9574 6.26465ZM37.2985 14.2197C37.2985 14.7557 37.5237 15.206 37.9847 15.5598C38.435 15.9136 38.971 16.0958 39.5821 16.0958C40.4505 16.0958 41.2224 15.7742 41.8979 15.1309C42.5733 14.4877 42.9164 13.7372 42.9164 12.8688C42.2731 12.3649 41.3833 12.1076 40.2361 12.1076C39.3999 12.1076 38.703 12.3113 38.1455 12.708C37.5773 13.1261 37.2985 13.63 37.2985 14.2197Z"
        fill={variant}
      />
      <path
        d="M56.0721 6.61816L49.2107 22.3995H47.0879L49.6395 16.8782L45.1152 6.61816H47.3559L50.6151 14.4874H50.658L53.8314 6.61816H56.0721Z"
        fill={variant}
      />
      <path
        d="M17.9815 9.79219C17.9815 9.12105 17.9214 8.47886 17.8099 7.86133H9.18164V11.3993L14.1508 11.4003C13.9493 12.5775 13.3007 13.581 12.3068 14.25V16.5454H15.2648C16.9919 14.9469 17.9815 12.5839 17.9815 9.79219Z"
        fill="#4285F4"
      />
      <path
        d="M12.3087 14.2496C11.4853 14.805 10.425 15.1298 9.18458 15.1298C6.78843 15.1298 4.75572 13.5152 4.02777 11.3389H0.976562V13.7061C2.48823 16.7058 5.59518 18.7643 9.18458 18.7643C11.6654 18.7643 13.7496 17.9484 15.2666 16.5439L12.3087 14.2496Z"
        fill="#34A853"
      />
      <path
        d="M3.74057 9.58336C3.74057 8.97227 3.84241 8.38154 4.02789 7.82619V5.45898H0.976684C0.351648 6.69941 0 8.09957 0 9.58336C0 11.0672 0.35272 12.4673 0.976684 13.7077L4.02789 11.3405C3.84241 10.7852 3.74057 10.1945 3.74057 9.58336Z"
        fill="#FABB05"
      />
      <path
        d="M9.18458 4.03482C10.5386 4.03482 11.7512 4.50118 12.7086 5.41247L15.3299 2.79333C13.7378 1.31061 11.6622 0.400391 9.18458 0.400391C5.59625 0.400391 2.48823 2.45883 0.976562 5.45857L4.02777 7.82578C4.75572 5.64941 6.78843 4.03482 9.18458 4.03482Z"
        fill="#E94235"
      />
    </Icon>
  );
};
