import { useQuery, UseQueryResult } from 'react-query';

export const getBrowserLocation = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    navigator?.geolocation?.getCurrentPosition(
      (geolocation) =>
        resolve({ lat: geolocation?.coords?.latitude, lng: geolocation?.coords?.longitude }),
      (e) => reject(e)
    );
  }).catch((e) => {
    throw new Error(e.message);
  });
};

export const useBrowserLocation = (options?): UseQueryResult<any, GeolocationPositionError> =>
  useQuery(['location'], () => getBrowserLocation(), {
    retry: false,
    staleTime: Infinity,
    enabled: false,
    ...options,
  });
