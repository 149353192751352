import { createStore } from '@olo-web/utils/common/store';

import * as gmaps from '@olo-web/utils/google/maps/functions';
interface IDeliveryAddressState {
  addressForm: IDeliveryAddress | null | any;
  address: IDeliveryAddress | null;
}

type TClearTempAddressAction = Omit<IStandardAction<'CLEAR_TEMP'>, 'payload'>;
type TClearAddressAction = Omit<IStandardAction<'CLEAR_ADDRESS'>, 'payload'>;
type TUpdateTempPlace = IStandardAction<'UPDATE_TEMP_PLACE', gmaps.GoogleMapsPlaceResult | null>;
type TUpdateAddressForm = IStandardAction<'UPDATE_ADDRESS_FORM', IDeliveryAddress | null>;
type TUpdateUnitNumber = IStandardAction<'UPDATE_UNIT_NUMBER', string | null>;
type TUpdateAddress = TEmptyAction<'UPDATE_ADDRESS'>;
type TDeliveryAddressAction =
  | TClearTempAddressAction
  | TClearAddressAction
  | TUpdateTempPlace
  | TUpdateAddressForm
  | TUpdateUnitNumber
  | TUpdateAddress;

const initialState: IDeliveryAddressState = {
  addressForm: null,
  address: null,
};

const reducer = (
  state: IDeliveryAddressState,
  action: TDeliveryAddressAction
): IDeliveryAddressState => {
  switch (action.type) {
    case 'CLEAR_ADDRESS':
      return { ...state, address: null };
    case 'UPDATE_ADDRESS_FORM': {
      return { ...state, addressForm: action.payload };
    }
    case 'UPDATE_UNIT_NUMBER': {
      return { ...state, addressForm: { ...state.addressForm, address2: action.payload } };
    }
    case 'UPDATE_ADDRESS': {
      return { ...state, address: { ...state.addressForm } };
    }
    default:
      return state;
  }
};

export const [DeliveryAddressProvider, useDeliveryAddressDispatch, useDeliveryAddressState] =
  createStore<IDeliveryAddressState, TDeliveryAddressAction>(reducer, initialState, {
    name: 'deliveryAddress',
    persist: 'local',
  });
