import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { useLevelupDispatch } from '@olo-web/client-state';

export const getLevelupCustomer = async (levelupUserToken: string): Promise<IUserInfoLevelup> => {
  try {
    const { data } = await axios.get(`/api/levelup/users/${levelupUserToken}`);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useLevelupCustomer(
  levelupUserToken: string,
  initialData?: IUserInfoLevelup,
  onSuccess?: () => void
): UseQueryResult<IUserInfoLevelup, AxiosError> {
  const levelupDispatch = useLevelupDispatch();
  return useQuery(['levelup-user', levelupUserToken], () => getLevelupCustomer(levelupUserToken), {
    staleTime: 1000000,
    retry: false,
    enabled: !!levelupUserToken,
    initialData,
    onSuccess,
    onError: (error) => {
      levelupDispatch({
        type: 'LEVELUP_ERROR',
        payload: error?.toString(),
      });
    },
  });
}
