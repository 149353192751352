import { useEffect, useState } from 'react';
import { auth } from '../firebase';

export const useThemeAuthUser = (): firebase.default.User | null => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  return user;
};
