export const addFormDataToItem = (currentItem, formData): ISubMenuItemDisplay => {
  // TODO revert
  const item = { ...currentItem };
  item.subItems = item.subItems ? item.subItems.map((s) => formData[`sig-${s.id}`]) : [];
  item.modifiers = item.modifiers
    ? item.modifiers.map(
        (m) => formData[`mg${item.subItemGroupId ? `-${item.subItemGroupId}` : ''}-${m.id}`]
      )
    : [];
  return item;
};
