import { useCheckoutState, useContactInfoState } from '@olo-web/client-state';
import { useCustomer } from '@olo-web/domain/customer';
import { usePaymentMethodValues } from '@olo-web/domain/payments/hooks';
import { useIsLevelup, useIsScanAndPayPage, useIsDineIn } from '@olo-web/utils/common/hooks';
import { useIsOnPremisePaymentOnly } from './useIsOnPremisePaymentOnly';

export const useIsOrderButtonHidden = (): boolean => {
  const contactInfo = useContactInfoState();
  const { isEditingContactInfo, addedPayment, selectedCard } = useCheckoutState();
  const { data: customer } = useCustomer();
  const { isCreditCard, giftCardCoversEntireOrderAmount, isApplePay, isGooglePay } =
    usePaymentMethodValues();
  const isScanAndPayPage = useIsScanAndPayPage();
  const isLevelup = useIsLevelup();
  const isDineIn = useIsDineIn();
  const isPayOnPremiseOnly = useIsOnPremisePaymentOnly();

  if (isEditingContactInfo) return true;

  if (giftCardCoversEntireOrderAmount) return false;

  if (isPayOnPremiseOnly) return false;

  if (isCreditCard && !(addedPayment || selectedCard?.id)) return true;

  return !(
    customer ||
    contactInfo ||
    isScanAndPayPage ||
    isLevelup ||
    isGooglePay ||
    isApplePay ||
    isDineIn
  );
};
