import { createStore } from '@olo-web/utils/common/store/createStore';
interface ISavedOrderState {
  [key: string]: string | null;
}

type TSavePayload = {
  merchantId: string;
  orderId: string;
};

type TResetOrderAction = TEmptyAction<'RESET'>;
type TDiscardIdOrderAction = IStandardAction<'DISCARD_ID', TSavePayload>;
type TSaveOrderAction = IStandardAction<'SAVE', TSavePayload>;

type TSavedOrderAction = TResetOrderAction | TDiscardIdOrderAction | TSaveOrderAction;

const initialState = {};

const reducer = (state: ISavedOrderState, action: TSavedOrderAction): ISavedOrderState => {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case 'DISCARD_ID':
      if (action.payload.orderId === state[action.payload.merchantId]) {
        return {
          ...state,
          [action.payload.merchantId]: null,
        };
      }
      return state;
    case 'SAVE':
      return {
        ...state,
        [action.payload.merchantId]: action.payload.orderId,
      };
    default:
      return state;
  }
};

export const [SavedOrderProvider, useSavedOrderDispatch, useSavedOrderState] = createStore<
  ISavedOrderState,
  TSavedOrderAction
>(reducer, initialState, {
  name: 'savedOrder',
  persist: 'local',
});
