import { EOrderErrorsCode } from '@olo-web/types/enums/orderErrorsCode.enum';
import {
  EAlertTypes,
  EAnalyticsEventNames,
  EGALocations,
  EGAActions,
  EModalTypes,
} from '@olo-web/types/enums';
import { useAlertDispatch, useScreenSizeState, useModalDispatch } from '@olo-web/client-state';
import { useOrderUnavailabilityDispatch } from '@olo-web/client-state';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useNotifySignificantASAPChange } from './useNotifySignificantASAPChange';
import { isFeatureEnabledForMerchant, serializeToCamelCase } from '@olo-web/utils/common/functions';
import { useIsMenuPage, useSendEvent } from '@olo-web/utils/common/hooks';
import { useFeatureFlags, useMenu, useMerchant } from '@domain';
import { useCallback } from 'react';
import { useToast, useIsDelivery, getEstimatedTimeText } from '@olo-web/utils/common/hooks';
import { useOrder } from '@domain/orders/queries/useOrder';

interface IError {
  message: string;
  code?: string | number;
  alternateDateTimes?: Array<string>;
  additionalData?: {
    order?: IOrder;
  };
}

export const useErrorOrderScenarios = () => {
  const { data: featureFlags } = useFeatureFlags();
  const { data: merchant } = useMerchant();
  const { notify } = useToast();
  const updateOrderInCache = useUpdateOrderInCache();
  const { refetch: refetchMenu } = useMenu();
  const unavailabilityDispatch = useOrderUnavailabilityDispatch();
  const { refetch: refetchOrder } = useOrder();
  const isMenuPage = useIsMenuPage();
  const isDelivery = useIsDelivery();
  const modalDispatch = useModalDispatch();
  const { isMd } = useScreenSizeState();
  const notifySignificantAsapChange = useNotifySignificantASAPChange();

  const isHandleAsapLogicOnServer = isFeatureEnabledForMerchant({
    merchantId: merchant?.merchantId,
    featureEnabled: featureFlags?.handleAsapLogicOnServer?.on,
    allowList: featureFlags?.handleAsapLogicOnServer?.allow,
    denyList: featureFlags?.handleAsapLogicOnServer?.deny,
  });

  const alertDispatch = useAlertDispatch();
  const { sendEvent } = useSendEvent({ canRepeat: true });

  const sendAnalyticsEvent = useCallback(
    (eventName: EAnalyticsEventNames) => {
      sendEvent(EAnalyticsEventNames.DEFAULT, {
        googleAnalytics: {
          eventInfo: {
            location: EGALocations.ONLINE_ORDER,
            action: EGAActions.ERROR,
            object: eventName,
          },
          eventMetadata: {
            locationOfError: isMenuPage ? 'continue-to-checkout' : 'purchase',
          },
        },
      });
    },
    [isMenuPage, sendEvent]
  );

  return useCallback(
    (error: IError, defaultError?: () => void) => {
      if (!isHandleAsapLogicOnServer) {
        defaultError?.();
        return;
      }

      const requestDetails = error.additionalData?.order?.requestDetails;
      const order = error.additionalData?.order;

      switch (error.code) {
        case EOrderErrorsCode.ONLINE_ORDERING_PAUSED: {
          alertDispatch({
            type: 'OPEN_ALERT',
            payload: {
              alertKey: EAlertTypes.SHOW_UNAVAILABLE_ORDER,
              isCloseOnOutsideClick: false,
            },
          });

          sendAnalyticsEvent(EAnalyticsEventNames.ORDERING_UNAVAILABLE);
          break;
        }
        case EOrderErrorsCode.INVALID_ORDER_TIME: {
          alertDispatch({
            type: 'OPEN_ALERT',
            payload: {
              alertKey: EAlertTypes.SHOW_UNAVAILABLE_ORDER,
              isCloseOnOutsideClick: false,
            },
          });
          sendAnalyticsEvent(EAnalyticsEventNames.INVALID_ORDER_TIME);
          break;
        }
        case EOrderErrorsCode.PACING_CAPACITY_ERROR: {
          alertDispatch({
            type: 'OPEN_ALERT',
            payload: {
              alertKey: error?.alternateDateTimes?.length
                ? EAlertTypes.PACING_CAPACITY_ALERT
                : EAlertTypes.NO_AVAILABLE_TIMES_ALERT,
              alertContext: error?.alternateDateTimes,
            },
          });
          sendAnalyticsEvent(EAnalyticsEventNames.PACING_CAPACITY_ERROR);
          break;
        }
        case EOrderErrorsCode.INVALID_TABLE_NUMBER:
          sendAnalyticsEvent(EAnalyticsEventNames.INVALID_TABLE_NUMBER);
          modalDispatch({
            type: 'OPEN_MODAL',
            payload: { modalKey: EModalTypes.SOMETHING_WENT_WRONG },
          });
          break;
        case EOrderErrorsCode.TABLE_NUMBER_REQUIRED:
          sendAnalyticsEvent(EAnalyticsEventNames.TABLE_NUMBER_REQUIRED);
          modalDispatch({
            type: 'OPEN_MODAL',
            payload: { modalKey: EModalTypes.SOMETHING_WENT_WRONG },
          });
          break;
        case EOrderErrorsCode.ORDER_UPDATED: {
          // always update order in cache if it's an ORDER_UPDATED error
          if (error.additionalData?.order) {
            updateOrderInCache(error.additionalData?.order);
          } else {
            refetchOrder();
          }

          if (requestDetails?.details?.length > 1) {
            const containsNonMultError = requestDetails?.details?.some(
              (element) =>
                element.code === EOrderErrorsCode.INACTIVE_DISCOUNT ||
                element.code === EOrderErrorsCode.UNAVAILABLE_ITEMS
            );
            const isNotMultipleError = !(
              containsNonMultError && requestDetails?.details?.length === 2
            );
            if (isNotMultipleError) {
              // for inactive discount and unavailable item errors we get two request details even though it's just the one error. So we shouldn't call the multiple validation error handler for it
              notify({
                id: 'multiple-validation-errors',
                title: `Heads up!`,
                description: `Your order total has changed, please review.`,
                isClosable: true,
                duration: null,
                status: 'info',
                variant: 'left-accent',
                position: isMd ? 'top-right' : 'top',
              });
              sendAnalyticsEvent(EAnalyticsEventNames.MULTIPLE_VALIDATION_ERRORS);
              refetchMenu();
              return;
            }
          }

          const detail = requestDetails?.details?.[0];

          switch (detail?.code) {
            case EOrderErrorsCode.UNAVAILABLE_ITEMS: {
              alertDispatch({
                type: 'OPEN_ALERT',
                payload: {
                  alertKey: EAlertTypes.PRODUCT_UNAVAILABILITY,
                  isCloseOnOutsideClick: false,
                },
              });

              sendAnalyticsEvent(EAnalyticsEventNames.UNAVAILABLE_ITEMS);

              unavailabilityDispatch({
                type: 'SET',
                payload: serializeToCamelCase(detail?.payload),
              });

              refetchMenu();
              break;
            }

            case EOrderErrorsCode.INACTIVE_DISCOUNT: {
              notify({
                id: 'inactive-discount-error',
                description: `Sorry, the discount is either expired or invalid and has been removed from the order. `,
                isClosable: true,
                duration: null,
                status: 'info',
                variant: 'left-accent',
                position: isMd ? 'top-right' : 'top',
              });

              sendAnalyticsEvent(EAnalyticsEventNames.INACTIVE_DISCOUNT);
              break;
            }

            case EOrderErrorsCode.CONVERTED_TO_ASAP: {
              notify({
                id: 'order-time-changed',
                title: `We've updated your ${isDelivery ? 'delivery' : 'pickup'} time`,
                description: `Your new estimated time is ${getEstimatedTimeText(
                  order?.orderDateTime,
                  merchant
                )}`,
                isClosable: true,
                duration: null,
                status: 'info',
                variant: 'left-accent',
                position: isMd ? 'top-right' : 'top',
              });

              sendAnalyticsEvent(EAnalyticsEventNames.CONVERTED_TO_ASAP);
              break;
            }

            case EOrderErrorsCode.SIGNIFICANT_ASAP_CHANGE: {
              notifySignificantAsapChange(order);
              break;
            }
          }
          break;
        }

        default: {
          defaultError?.();
        }
      }
    },
    [
      isHandleAsapLogicOnServer,
      sendAnalyticsEvent,
      modalDispatch,
      alertDispatch,
      updateOrderInCache,
      refetchOrder,
      notify,
      isMd,
      refetchMenu,
      unavailabilityDispatch,
      isDelivery,
      merchant,
      notifySignificantAsapChange,
    ]
  );
};
