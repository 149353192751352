import { createBreakpoints } from '@chakra-ui/theme-tools';

export const breakpointsObject = {
  base: '0px',
  xxs: '480px',
  xs: '667px',
  sm: '768px',
  md: '1188px',
  lg: '1536px',
  xl: '1920px',
  xxl: '2360px',
};

export default createBreakpoints(breakpointsObject);
