import React from 'react';
import { useRouter } from 'next/router';
import { BASE_URL } from '@olo-web/utils/common/constants';
import { Link } from '../../Link';

// TODO: Need help with typing -@stnmonroe :(

export const LinkWrapper = React.forwardRef(
  ({ path, children, style, newTab, className, as }: any, ref: any): JSX.Element => {
    const router = useRouter();
    const { type }: any = router.query;
    const embedded = type && ['iframe', 'embed'].includes(type);

    let styleObj = { textDecoration: 'none' };
    if (style) styleObj = { ...styleObj, ...style };

    const isHttpsOrHttp = (path || '').slice(0, 10).includes('http');

    if (!embedded && !isHttpsOrHttp && !newTab) {
      return (
        <Link href={path} as={as} style={styleObj} ref={ref} className={className}>
          {children}
        </Link>
      );
    } else {
      const href = isHttpsOrHttp ? path : `${BASE_URL}${path}`;
      return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          style={styleObj}
          ref={ref}
          className={className}
        >
          {children}
        </a>
      );
    }
  }
);
