import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useMerchantGroup, useMerchant } from '@domain/merchants/queries';
import { slug } from '@olo-web/utils/common/functions';

type T = {
  merchantId?: string;
  merchant: IUseMerchantData | IMerchant;
  groupId?: number;
};

export function createMerchantPath({ merchantId, merchant, groupId }: T): string {
  if (!merchant) {
    return `/-/-/${merchantId}`;
  }

  const locationSlug = slug(`${merchant?.city || ''} ${merchant?.state}`);
  const merchantSlug = slug(
    `${merchant?.orgName || '-'}${groupId ? `${merchant?.orgName ? `-${groupId}` : groupId}` : '-'}`
  );

  return `/${merchantSlug || '-'}/${locationSlug || '-'}/${merchant?.merchantId || merchantId}`;
}

export function useMerchantPath() {
  const { data: group } = useMerchantGroup();
  const { data: merchant } = useMerchant();
  const router = useRouter();
  const { merchantId } = router.query;

  return useMemo(
    () => createMerchantPath({ merchantId: merchantId as string, merchant, groupId: group?.id }),
    [merchant, group?.id, merchantId]
  );
}
