import React from 'react';
import Script from 'next/script';
import { FullStory } from '../../../utils/fullStory';
import { googleTagManagerScript } from '@olo-web/utils/google/tagManager';
import { facebookPixelImageNoScript, facebookPixelScript } from '@olo-web/utils/facebook';
import { useMerchant, useMerchantGroup, useMerchantTheme } from '@domain/merchants/queries';
import { gtagScript } from '@olo-web/utils/google/analytics';
import { EMerchantPixelsAndKeys } from '@olo-web/types/enums/merchantPixelsAndKeys.enum';

const gtmKey = process.env.NEXT_PUBLIC_GTM_KEY || '';
const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || '';

export const Scripts = () => {
  const { data: theme } = useMerchantTheme();
  const { data: merchant } = useMerchant();
  const { data: group } = useMerchantGroup();
  const data = {
    merchantId: merchant?.merchantId,
    loyaltyEnabled: merchant?.spotOnLoyaltyEnabled,
    merchantName: group?.name,
    merchantLocation: merchant?.name,
    legacyRefactor: 'refactor',
    version: appVersion,
    orderTypesAvailable: merchant?.orderTypesAvailableAnalyticsString,
  };

  return (
    <>
      <Script
        async
        id="googleTagManager"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: googleTagManagerScript(gtmKey, data),
        }}
      />
      <FullStory />
      {theme?.[EMerchantPixelsAndKeys.GOOGLE_TAG_MANAGER] && (
        <Script
          async
          id={`googleTagManager=${theme[EMerchantPixelsAndKeys.GOOGLE_TAG_MANAGER]}`}
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: googleTagManagerScript(theme[EMerchantPixelsAndKeys.GOOGLE_TAG_MANAGER], data),
          }}
        />
      )}
      {theme?.[EMerchantPixelsAndKeys.GOOGLE_ADWORDS_KEY] && (
        <>
          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${
              theme[EMerchantPixelsAndKeys.GOOGLE_ADWORDS_KEY]
            }`}
          />
          <Script
            async
            id="gtagScript"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: gtagScript(theme[EMerchantPixelsAndKeys.GOOGLE_ADWORDS_KEY]),
            }}
          />
        </>
      )}
      {theme?.[EMerchantPixelsAndKeys.FACEBOOK_PIXEL] && (
        <>
          <Script
            async
            id="facebookPixelScript"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: facebookPixelScript(theme[EMerchantPixelsAndKeys.FACEBOOK_PIXEL]),
            }}
          />
          <noscript>
            {facebookPixelImageNoScript(theme[EMerchantPixelsAndKeys.FACEBOOK_PIXEL])}
          </noscript>
        </>
      )}
    </>
  );
};
