import React from 'react';
import { LinkWrapper, ConditionalWrapper } from '../index';

// TODO: Need help with typing -@stnmonroe

export const ConditionalLinkWrapper = React.forwardRef(
  ({ children, onClick, ...rest }: any, ref: any): JSX.Element => (
    <ConditionalWrapper
      condition={!onClick}
      wrapper={(kids) => (
        <LinkWrapper {...rest} ref={ref}>
          {kids}
        </LinkWrapper>
      )}
    >
      {React.Children.toArray(children).map((c: any) => React.cloneElement(c, rest))}
    </ConditionalWrapper>
  )
);
