import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useRouter } from 'next/router';
import { EOrderStatus } from '@olo-web/types/enums/orderStatus.enum';
import { useSavedDineInContextState } from '@olo-web/client-state';

interface IUpdateOrderStatusProps {
  merchantId: string;
  orderId: string;
  status: EOrderStatus;
  guestId: string;
}

export const updateOrderStatus = async ({
  status,
  merchantId,
  orderId,
  guestId,
}: IUpdateOrderStatusProps): Promise<IOrder> => {
  if (!orderId) {
    throw new Error('orderId is required to update order status');
  }

  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/setstatus`;
    const body = { status, guestId };
    const { data } = await axios.post(url, body);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useUpdateOrderStatus = (
  options?: UseMutationOptions<IOrder, AxiosError, { status: EOrderStatus }>
) => {
  const router = useRouter();
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();
  const savedDineInState = useSavedDineInContextState();
  const updateOrderInCache = useUpdateOrderInCache();
  const { merchantId: merchantIdFromUrl } = router.query;
  return useMutation(
    ({ status }) => {
      return updateOrderStatus({
        guestId: savedDineInState?.guest?.id,
        merchantId: (merchantIdFromUrl as string) || merchant?.merchantId,
        orderId: order?.id,
        status,
      });
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        updateOrderInCache(data);
        if (options?.onSuccess) options.onSuccess(data, variables, context);
      },
    }
  );
};
