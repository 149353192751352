import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult, useQueryClient } from 'react-query';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useCustomer, useLoyalty } from '@olo-web/domain/customer';

export const getPreviousOrdersFromMerchant = async (
  merchantId: string,
  customerId: string
): Promise<IPreviousOrder[]> => {
  try {
    const url = `/api/merchants/${merchantId}/customers/${customerId}/historical-orders`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useGetPreviousOrdersFromMerchant = (
  options?
): UseQueryResult<IPreviousOrder[], AxiosError> => {
  const { data: customer } = useCustomer();
  const { data: merchant } = useMerchant();
  const { data: loyalty } = useLoyalty();
  const customerId = customer?.customerId || loyalty?.customerId;

  return useQuery(
    ['previous-orders', merchant?.merchantId, customerId],
    () => getPreviousOrdersFromMerchant(merchant?.merchantId, customerId),
    {
      staleTime: 1000000,
      enabled: !!customerId,
      ...options,
    }
  );
};

export const useInvalidatePreviousOrdersQuery = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries('previous-orders');
};
