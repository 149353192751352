import { createStore } from '@olo-web/utils/common/store';

type TSetContactInfoAction = IStandardAction<'SUBMIT', ICustomer>;
type TClearContactInfoAction = TEmptyAction<'CLEAR'>;

type TContactInfoAction = TSetContactInfoAction | TClearContactInfoAction;

const reducer = (state: ICustomer | null, action: TContactInfoAction): ICustomer | null => {
  switch (action.type) {
    case 'SUBMIT':
      return action.payload;
    case 'CLEAR':
      return null;
    default:
      return state;
  }
};

export const [ContactInfoProvider, useContactInfoDispatch, useContactInfoState] = createStore<
  ICustomer | null,
  TContactInfoAction
>(reducer, null, { name: 'contactInfo', persist: 'local' });
