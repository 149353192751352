import { createStore } from '@olo-web/utils/common/store';
import { IOrderDiscount } from '@olo-web/domain/orders/types';
import { EPaymentMethods } from '@olo-web/types/enums/paymentMethods.enum';

interface ICustomerState {
  customerId?: string;
  sessionId?: string;
  sessionSecret?: string;
  loginTimestamp?: string | number;
  promos?: any;
  isLoadingDiscount?: boolean;
  receiptEmail?: string;
  hasJoinedAnOrder?: boolean;
  previousPaymentType?: EPaymentMethods | null;
  hasDismissedDisruptionBanner?: boolean;
  statusPageShouldShowBanner?: boolean;
}

type TClearCustomerAction = Omit<IStandardAction<'CLEAR'>, 'payload'>;
type TUpdateCustomerAction = IStandardAction<'UPDATE', ICustomerState>;
type TSetIsLoadingDiscount = IStandardAction<'SET_IS_LOADING_DISCOUNT', boolean>;
type TAddPromosCustomerAction = IStandardAction<'ADD_PROMO', IOrderDiscount>;
type TRemovePromosCustomerAction = IStandardAction<'REMOVE_PROMO', string>;
type TSetHasDismissedDisruptionBanner = IStandardAction<'SET_DISRUPTION', ICustomerState>;
export type TCustomerAction =
  | TClearCustomerAction
  | TUpdateCustomerAction
  | TAddPromosCustomerAction
  | TRemovePromosCustomerAction
  | TSetHasDismissedDisruptionBanner
  | TSetIsLoadingDiscount;

const initialState: ICustomerState = {
  customerId: '',
  sessionId: '',
  sessionSecret: '',
  loginTimestamp: '',
  promos: {},
  isLoadingDiscount: false,
  receiptEmail: '',
  previousPaymentType: null,
  hasDismissedDisruptionBanner: false,
  statusPageShouldShowBanner: false,
};

const reducer = (state: ICustomerState, action: TCustomerAction): ICustomerState => {
  switch (action.type) {
    case 'CLEAR':
      return initialState;
    case 'SET_IS_LOADING_DISCOUNT':
      return { ...state, isLoadingDiscount: action.payload };
    case 'UPDATE':
      return { ...state, ...action.payload };
    case 'ADD_PROMO':
      return {
        ...state,
        promos: {
          ...state.promos,
          [action.payload.promoCode]: {
            ...action.payload,
            // we want to preserve auto apply state if user removed items from bag and existing applied promo
            // went inactive, for a case where user adds items again and promo should be auto applied
            shouldBeAutoApplied:
              state.promos?.[action.payload.promoCode]?.shouldBeAutoApplied ||
              action.payload.shouldBeAutoApplied,
          },
        },
      };
    case 'REMOVE_PROMO':
      return {
        ...state,
        promos: Object.entries(state.promos).reduce(
          (accPromos, [promoKey, promoVal]: any) => ({
            ...accPromos,
            ...(promoVal?.promotionId !== action.payload && { [promoKey]: promoVal }),
          }),
          {}
        ),
      };
    case 'SET_DISRUPTION':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const [CustomerContextProvider, useCustomerDispatch, useCustomerState] = createStore<
  ICustomerState,
  TCustomerAction
>(reducer, initialState, {
  name: 'customer',
  persist: 'session',
  blacklist: ['customerId', 'sessionId', 'sessionSecret', 'loginTimestamp', 'receiptEmail'],
});
