import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

interface IApplePaySmall extends IconProps {
  variant: 'black' | 'white';
}

export const ApplePaySmall = (props: IApplePaySmall) => {
  const { variant = 'black', ...rest } = props;
  return (
    <Icon viewBox="0 0 50 21" fill="none" {...rest}>
      <path
        d="M9.35487 2.70807C9.9377 1.97907 10.3332 1.00025 10.2289 0C9.37571 0.042424 8.33457 0.562875 7.73179 1.29244C7.19057 1.91721 6.71154 2.93702 6.83642 3.89533C7.79416 3.97841 8.75102 3.41661 9.35487 2.70807Z"
        fill={variant}
      />
      <path
        d="M10.2193 4.0827C8.82841 3.99985 7.64582 4.87209 6.98161 4.87209C6.31703 4.87209 5.2999 4.12445 4.1998 4.1446C2.76794 4.16563 1.43934 4.97521 0.712728 6.26283C-0.78178 8.8387 0.318328 12.6596 1.77166 14.7575C2.47743 15.7954 3.328 16.9382 4.44876 16.8972C5.50769 16.8556 5.92275 16.2115 7.20993 16.2115C8.49616 16.2115 8.87003 16.8972 9.99098 16.8764C11.1534 16.8556 11.8802 15.838 12.5859 14.7991C13.3956 13.6159 13.727 12.4735 13.7479 12.4108C13.727 12.39 11.5063 11.5381 11.4857 8.98364C11.4648 6.84476 13.2292 5.82738 13.3123 5.76429C12.3159 4.29061 10.759 4.12445 10.2193 4.0827Z"
        fill={variant}
      />
      <path
        d="M22.3314 1.1875C25.3544 1.1875 27.4595 3.27131 27.4595 6.30519C27.4595 9.34989 25.3111 11.4445 22.2556 11.4445H18.9085V16.7673H16.4902V1.1875H22.3314V1.1875ZM18.9085 9.41465H21.6833C23.7888 9.41465 24.9871 8.28112 24.9871 6.31601C24.9871 4.35112 23.7888 3.2282 21.6941 3.2282H18.9085V9.41465Z"
        fill={variant}
      />
      <path
        d="M28.0898 13.5393C28.0898 11.5526 29.6122 10.3326 32.3116 10.1814L35.4209 9.99795V9.12348C35.4209 7.8602 34.5679 7.10444 33.143 7.10444C31.7931 7.10444 30.9509 7.75212 30.7459 8.76715H28.5434C28.673 6.71562 30.4219 5.2041 33.2292 5.2041C35.9823 5.2041 37.7421 6.66168 37.7421 8.9398V16.7675H35.5071V14.8997H35.4534C34.7949 16.1629 33.3587 16.9618 31.8689 16.9618C29.6447 16.9618 28.0898 15.5798 28.0898 13.5393ZM35.4209 12.5137V11.6176L32.6244 11.7902C31.2316 11.8875 30.4436 12.5029 30.4436 13.4746C30.4436 14.4677 31.2641 15.1156 32.5165 15.1156C34.1468 15.1156 35.4209 13.9927 35.4209 12.5137Z"
        fill={variant}
      />
      <path
        d="M39.8495 20.9459V19.0564C40.0219 19.0995 40.4105 19.0995 40.605 19.0995C41.6846 19.0995 42.2678 18.6461 42.6239 17.4801C42.6239 17.4585 42.8292 16.7891 42.8292 16.7783L38.7266 5.40918H41.2527L44.125 14.6514H44.1679L47.0401 5.40918H49.5017L45.2475 17.3612C44.2762 20.1146 43.1532 20.9998 40.7995 20.9998C40.605 20.9998 40.0219 20.9782 39.8495 20.9459Z"
        fill={variant}
      />
    </Icon>
  );
};
