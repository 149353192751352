export const facebookPixelImageNoScript = (facebookPixelId: string) => {
  return `
    <img
        height="1"
        width="1"
        style="display:none"
        src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1"
    />
  `;
};
