// https://stackoverflow.com/a/1634841
export const removeQueryParamFromPath = (path: string, param: string): string => {
  //prefer to use l.search if you have a location/link object
  const pathParams = path.split('?');
  if (pathParams.length >= 2) {
    const prefix = encodeURIComponent(param) + '=';
    const pars = pathParams[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return pathParams[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return path;
};

export const removeQueryParamsFromPath = (path: string, params: string[]): string => {
  let newPath = path;
  params.forEach((param) => {
    newPath = removeQueryParamFromPath(newPath, param);
  });
  return newPath;
};
