import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import dayjs from 'dayjs';

export const roundDownDateTime = (dateTime) => {
  return dayjs(dateTime).minute(0).second(0).millisecond(0).toISOString();
};

export const useRoundedDownOrderDateTime = () => {
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();
  const orderDateTime =
    order?.orderDateTime ||
    merchant?.defaultOrderType?.asapOrderDateTime ||
    new Date().toISOString();

  return roundDownDateTime(orderDateTime);
};
