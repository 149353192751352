export const createValidationInstructions = (min: number, max: number): string => {
  if (min === 0 && max === 0) return null;

  if (min === 0 && max > 0) {
    return `Select up to ${max}`;
  }

  if (min !== 0 && max === min) {
    return `Select ${max}`;
  }

  if (min !== 0 && !max) {
    return `Select ${min} or more`;
  }

  return `Select between ${min} and ${max}`;
};
