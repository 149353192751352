import { useOrder } from '@olo-web/domain/orders/queries/useOrder';

import { useSavedDineInContextState } from '@olo-web/client-state';

export const useDoesPreAuthBelongToGuest = () => {
  const { data: order } = useOrder();
  const savedDineInState = useSavedDineInContextState();

  if (order?.paymentAuth) {
    return order.paymentAuth?.guestId === savedDineInState?.guest.id;
  }

  return false;
};
