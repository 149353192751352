import { useCheckoutState } from '@olo-web/client-state';
import { useGiftCardBalance } from '@olo-web/domain';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useTipAmount } from '@olo-web/domain/orders/hooks/useTipAmount';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';
import { useIsStartATabModal } from '@olo-web/utils/common/hooks/useIsStartATabModal';

export const useCalculateMixedPayment = () => {
  const { data: order } = useOrder();
  const tip = +useTipAmount();
  const { data: giftCard } = useGiftCardBalance();
  const { guestBalance } = useCheckoutState();
  const isSplitByAmount = useIsSplitByAmount();
  const orderBalanceDue = +order?.balanceDueAmount || 0;
  const giftCardBalance = +giftCard?.remainingBalanceAmount || 0;
  const balanceDue = isSplitByAmount ? guestBalance : orderBalanceDue;
  const { data: merchant } = useMerchant();
  const isStartATab = useIsStartATabModal();
  const balanceMinusGiftcard = balanceDue - giftCardBalance;

  const preauthAmount = Math.max(
    Number(order?.balanceDueAmount),
    Number(merchant?.defaulttabamount || 0)
  );

  let tipAmount = tip;
  let giftCardTipAmount = 0;
  let amount = balanceMinusGiftcard;
  let giftCardAmount = giftCardBalance;

  if (balanceMinusGiftcard < 0) {
    giftCardAmount = balanceDue;
    amount = 0;

    const remainingGiftCardBalance = giftCardBalance - balanceDue;

    if (remainingGiftCardBalance < tip) {
      giftCardTipAmount = remainingGiftCardBalance;
      tipAmount = tip - giftCardTipAmount;
    } else {
      giftCardTipAmount = tip;
      tipAmount = 0;
    }
  }
  //When the user is on the SaT modal they should be using the greater of the two amounts between the order balance and the default tab amount
  if (isStartATab) {
    amount = preauthAmount;
  }

  return {
    tipAmount: tipAmount.toFixed(2),
    giftCardTipAmount: giftCardTipAmount.toFixed(2),
    amount: amount.toFixed(2),
    giftCardAmount: giftCardAmount.toFixed(2),
  };
};
