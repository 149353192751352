import { getWebInstrumentations } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

const env = process.env.NODE_ENV || '';

// Define your internal domains
const internalDomains = [
  'spoton.sh',
  'spoton.com',
  // Add any other internal domains here
];

// Create a regex pattern to match internal URLs
const internalUrlPattern = new RegExp(`^https?://(.*\\.)?(${internalDomains.join('|')})`, 'i');

class CustomTracingInstrumentation extends TracingInstrumentation {
  instrumentFetch: (fetch: any) => any;

  constructor(options) {
    super(options);
    const originalInstrumentFetch = this.instrumentFetch;
    this.instrumentFetch = (fetch) => {
      const instrumentedFetch = originalInstrumentFetch.call(this, fetch);
      return (input, init) => {
        const url = typeof input === 'string' ? input : input.url;
        if (url.includes('apple_pay_create_session')) {
          // For Apple Pay requests, remove the traceparent header
          if (init && init.headers) {
            if (init.headers instanceof Headers) {
              init.headers.delete('traceparent');
            } else if (Array.isArray(init.headers)) {
              init.headers = init.headers.filter(([key]) => key.toLowerCase() !== 'traceparent');
            } else if (typeof init.headers === 'object') {
              delete init.headers.traceparent;
              delete init.headers.Traceparent;
            }
          }
        }
        return instrumentedFetch(input, init);
      };
    };
  }
}

const instrumentationOptions = {
  propagateTraceHeaderCorsUrls: [internalUrlPattern],
};

const faroConfig = {
  url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/c39d454022d1d6e8d0492c50312bdc7a',
  app: {
    name: 'olo-web-refactor',
    version: '1.0.0',
    environment: env,
  },
  instrumentations: [
    ...getWebInstrumentations(),
    new CustomTracingInstrumentation(instrumentationOptions),
  ],
};

export default faroConfig;
