import { knownErrorMessages } from './../constants/knownErrorMessages';

export const isKnownError = (error: string) => {
  for (const err in knownErrorMessages) {
    if (error?.includes(knownErrorMessages[err])) {
      return true;
    }
  }
  return false;
};
