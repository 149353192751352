import { EOrderTypes } from '@olo-web/types/enums';
import { isObject } from '@olo-web/utils/common/functions';

export const getOrderTypesAvailableAnalyticsString = (merchant: IMerchant) => {
  if (!isObject(merchant?.orderTypeIds)) return '';
  const deliveryType =
    (merchant?.orderTypeIds?.[EOrderTypes.DOORDASH_DRIVE] && 'DoorDash') ||
    (merchant?.orderTypeIds?.[EOrderTypes.DELIVERY] && 'Delivery') ||
    null;
  const pickupType = (merchant?.orderTypeIds?.[EOrderTypes.PICKUP] && 'Pickup') || null;

  return [deliveryType, pickupType].filter(Boolean).join(', ');
};
