import { useCheckoutState, useCustomerState } from '@olo-web/client-state';
import { useCheckout } from '@olo-web/domain/orders/mutations/useCheckout';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { usePaymentMethodValues } from '@olo-web/domain/payments/hooks';
import { useCustomerContactInfo } from './useCustomerContactInfo';
import { useDeliverServiceData } from './useDoorDashDeliveryData';
import { useIsScanAndPayPage, useIsLevelup, useIsDineIn } from '@olo-web/utils/common/hooks';
import { useShouldSaveCard } from './useShouldSaveCard';
import { useCustomer } from '@olo-web/domain/customer/queries';
import { useLevelupCustomer } from '@olo-web/domain/customer/queries';
import { useLevelup } from '@olo-web/domain/orders/hooks/useLevelup';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';

export const useCompleteOrderWithPaymentToken = () => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const isScanAndPayPage = useIsScanAndPayPage();
  const { mutateAsync: checkout } = useCheckout();
  const { orderNote, gclid, zipCode, paymentForGuestIds, guestBalance } = useCheckoutState();
  const { tipAmount: tip } = usePaymentMethodValues();
  const tipAmount = tip?.toFixed(2) ?? '0.00';
  const customer = useCustomerContactInfo();
  const { customerId, sessionId, sessionSecret } = useCustomerState();
  const isSplitByAmount = useIsSplitByAmount();
  const deliveryServiceData = useDeliverServiceData();
  const shouldSaveCard = useShouldSaveCard();
  const { data: customerData } = useCustomer();
  const { userToken } = useLevelup();
  const { data: levelupCustomer } = useLevelupCustomer(userToken as string);
  const isLevelup = useIsLevelup();
  const isDineIn = useIsDineIn();

  return (paymentToken, mobileCustomer?: ICustomer) => {
    let finalCustomer: Partial<ICustomer>;

    if (isLevelup && levelupCustomer) {
      finalCustomer = {
        ...levelupCustomer,
      };
    } else {
      finalCustomer = {
        ...customer,
        ...mobileCustomer,
      };
    }

    return checkout({
      merchantId: merchant?.merchantId,
      orderId: order?.id,
      checkId: order?.checkId,
      customerSessionDetails: {
        customerId,
        sessionId,
        sessionSecret,
      },
      orderNotes: orderNote,
      customer: finalCustomer,
      paymentForGuestIds: isSplitByAmount ? paymentForGuestIds : undefined,
      paymentInfo: {
        tipAmount,
        amount: isSplitByAmount ? guestBalance : undefined,
        // VTS requires firstName and lastName to save a card to the account
        // We are intentionally providing the logged in users information here for this
        // Otherwise we never send the name as it is not the cardholder name
        vtsPaymentInfo: shouldSaveCard
          ? {
              firstName: customerData?.firstName,
              lastName: customerData?.lastName,
              postalCode: zipCode,
              paymentChannel: isScanAndPayPage
                ? 'qr-scan-pay'
                : isDineIn
                ? 'qr-order-pay'
                : 'olo-web',
              savePaymentMethodAsDefault: false,
              saveToPaymentMethod: !!paymentToken && shouldSaveCard,
              paymentToken,
            }
          : {
              postalCode: zipCode,
              paymentChannel: isScanAndPayPage
                ? 'qr-scan-pay'
                : isDineIn
                ? 'qr-order-pay'
                : 'olo-web',
              savePaymentMethodAsDefault: false,
              saveToPaymentMethod: !!paymentToken && shouldSaveCard,
              paymentToken,
            },
      },
      conversionId: gclid,
      conversionType: gclid ? 'google' : null,
      deliveryAddress: {
        address1: deliveryServiceData?.deliveryInfo?.dropoffAddress?.street,
        address2: deliveryServiceData?.deliveryInfo?.dropoffAddress?.unit,
        city: deliveryServiceData?.deliveryInfo?.dropoffAddress?.city,
        state: deliveryServiceData?.deliveryInfo?.dropoffAddress?.state,
        zip: deliveryServiceData?.deliveryInfo?.dropoffAddress?.zipCode,
      },
    });
  };
};
