import React from 'react';
import { Text } from '@chakra-ui/react';
import { EOrderStatus } from '@olo-web/types/enums/orderStatus.enum';
import { useToast } from '@olo-web/utils/common/hooks';
import { useModalDispatch } from '@olo-web/client-state';
import { EModalTypes } from '@olo-web/types/enums';
import { useSavedDineInContextState } from '@olo-web/client-state';
import { useGoToConfirmation } from '@olo-web/utils/common/hooks/useGoToConfirmation';
import { useIsOrderConfirmationPage } from '@olo-web/utils/common/hooks/useIsOrderConfirmationPage';

export const useNotifyOrderStatusChange = () => {
  const { notify } = useToast();
  const modalDispatch = useModalDispatch();
  const savedDineInState = useSavedDineInContextState();
  const goToConfirmation = useGoToConfirmation();
  const isOnConfirmationPage = useIsOrderConfirmationPage();

  return (e: INatsEvent, data, unsubscribeToNats, queryParams) => {
    const initiatorName = data?.initiator?.name;
    if (e.priorStatus === EOrderStatus.PENDING && e.currentStatus === EOrderStatus.BUILDING) {
      notify({
        status: 'success',
        variant: 'left-accent',
        description: <Text>Your pre-ordered items have been sent to the kitchen 🎉</Text>,
        isClosable: true,
      });
      return;
    }
    if (
      e.currentStatus !== e.priorStatus &&
      data?.initiator?.guestId !== savedDineInState?.guest?.id
    ) {
      if (e.currentStatus === EOrderStatus.BUILDING) {
        notify({
          status: 'info',
          variant: 'left-accent',
          description: (
            <Text>
              <strong>Heads up!</strong> {initiatorName} has exited checkout. Guest can resume
              adding items to the order.
            </Text>
          ),
          isClosable: true,
          dataTestId: 'dinein-guest-exited-checkout-toast',
        });
      }
      if (e.currentStatus === EOrderStatus.IN_CHECKOUT) {
        notify({
          status: 'info',
          description: (
            <Text>
              <strong>Heads up!</strong> {initiatorName} has started checking out. Items cannot be
              added to the order at this time.
            </Text>
          ),
          variant: 'left-accent',
          dataTestId: 'dinein-checkout-initiation-toast',
          isClosable: true,
        });
      }
      if (e.currentStatus === EOrderStatus.SPLITTING_CHECK) {
        notify({
          status: 'info',
          description: (
            <Text>
              <strong>Heads up!</strong> {initiatorName} has started splitting the check. Items
              cannot be added to the order at this time.
            </Text>
          ),
          variant: 'left-accent',
          isClosable: true,
          dataTestId: 'dinein-checkout-splitting-initiation-toast',
        });
      }
      if (e.currentStatus === EOrderStatus.COMPLETED) {
        unsubscribeToNats();
        if (!isOnConfirmationPage) {
          modalDispatch({
            type: 'OPEN_MODAL',
            payload: {
              modalKey: EModalTypes.CLOSED_ORDER,
              modalContext: {
                guest: initiatorName,
                onClose: () => goToConfirmation(queryParams),
                queryParams: queryParams,
              },
            },
          });
        }
      }
      if (e.currentStatus === EOrderStatus.GROUP_PAYING) {
        modalDispatch({
          type: 'OPEN_MODAL',
          payload: {
            modalKey: EModalTypes.GUEST_COMPLETES_SPLIT,
            modalContext: {
              guest: data?.initiator?.name,
            },
          },
        });
      }
    }
  };
};
