import { useMemo } from 'react';
import { useMerchantTheme } from '@domain/merchants/queries/useMerchantTheme';
import defaultTheme from '@olo-web/theme';
import { useCustomThemeDraftState } from '@olo-web/client-state';
import { ChakraTheme } from '@chakra-ui/react';
import { useFeatureFlags } from '@olo-web/domain/featureFlags';
import { useIsUpdateThemeQueryParamTrue } from './useIsUpdateThemeQueryParamTrue';

export const useCombinedTheme = (): ChakraTheme => {
  const { data: customTheme } = useMerchantTheme();
  const { colors: draftColors, fonts: draftFonts } = useCustomThemeDraftState();
  const isUpdateTheme = useIsUpdateThemeQueryParamTrue();
  const { data: flags } = useFeatureFlags();

  const merchantTheme = useMemo(() => {
    if (!isUpdateTheme) return customTheme;
    const c = customTheme ?? {};

    const primaryColors = {
      ...(c?.colors?.primary ?? {}),
      ...(draftColors?.primary ?? {}),
    };

    return {
      ...c,
      fonts: !flags?.hideMerchantThemeFonts
        ? {
            ...(c.fonts ?? {}),
            ...(draftFonts ?? {}),
          }
        : {},
      colors: {
        primary: {
          ...primaryColors,
          350: primaryColors?.[350] || primaryColors?.[300],
        },
        base: {
          ...(c?.colors?.base ?? {}),
          ...(draftColors?.base ?? {}),
        },
      },
    };
  }, [isUpdateTheme, customTheme, draftColors, draftFonts, flags?.hideMerchantThemeFonts]);

  const theme = useMemo(() => {
    return {
      ...defaultTheme,
      colors: {
        ...defaultTheme.colors,
        primary: {
          ...defaultTheme.colors.primary,
          ...(merchantTheme?.colors?.primary ?? {}),
        },
        base: {
          ...defaultTheme.colors.base,
          ...(merchantTheme?.colors?.base ?? {}),
        },
      },
      fonts: {
        ...defaultTheme.fonts,
        ...(merchantTheme?.fonts ?? {}),
      },
      shadows: {
        ...defaultTheme.shadows,
        outline: `0 0 0 1px ${
          merchantTheme?.colors?.primary?.[300] ?? defaultTheme?.colors?.primary?.[300]
        }`,
      },
    };
  }, [merchantTheme]);

  return (flags?.hideMerchantTheming ? defaultTheme : theme) as ChakraTheme;
};
