import { useEffect } from 'react';
import { useOrder } from '@domain/orders/queries/useOrder';
import { useSavedDineInContextState } from '@olo-web/client-state';
import { useRouter } from 'next/router';
import { useApplyOrRemoveOffer } from '@domain/customer/hooks/useApplyOrRemoveOffer';

const useApplyOfferInRoute = () => {
  const { data: order } = useOrder();
  const savedDineInState = useSavedDineInContextState();
  const {
    query: { dealCode, dealType, dealSource },
  } = useRouter();

  const { applyOffer } = useApplyOrRemoveOffer();

  useEffect(() => {
    if (order?.id && dealCode) {
      applyOffer({
        promoCode: dealCode as string,
        dealType: dealType as string,
        dealSource: dealSource as string,
      });
    }
  }, [order?.id, savedDineInState, applyOffer, dealCode, dealType, dealSource]);
};

export default useApplyOfferInRoute;
