import { useState } from 'react';
import { IS_SERVER } from '@olo-web/utils/common/constants';

export function useStorage<T>(
  type: 'session' | 'local' | undefined,
  key: string | null,
  initialValue: T
): [T, (T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (IS_SERVER || key === null || type === undefined) {
        return initialValue;
      }
      // Get from local storage by key
      const item = type === 'session' ? sessionStorage.getItem(key) : localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item && item !== 'undefined' ? JSON.parse(item || null) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // this is a no op as we cannot set session or local storage server side
      if (IS_SERVER || key === null || type === undefined) return;

      // Save to session storage
      if (type === 'session') sessionStorage.setItem(key, JSON.stringify(valueToStore));
      // Save to local storage
      else localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
}
