import { useState } from 'react';
import { useUpdateOrder } from '@olo-web/domain/orders/mutations/useUpdateOrder';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant, getMerchantOrderTypeDetails } from '@domain/merchants/queries';
import { useQueryClient } from 'react-query';
import { useHandleAsapLogicOnServer, useIsASAP, useSendEvent } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames } from '@olo-web/types/enums';
import dayjs from 'dayjs';
import { useOrderTipsDispatch } from '@olo-web/client-state';

type T = {
  onSuccess?: () => void;
  onError?: (error: any) => void;
};

export const useUpdateOrderFulfillment = (props?: T) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { onSuccess, onError } = props || {};

  const queryClient = useQueryClient();
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const { mutateAsync: updateOrder } = useUpdateOrder({ onSuccess, onError });
  const { sendEvent } = useSendEvent();
  const orderTipsDispatch = useOrderTipsDispatch();
  const handleAsapLogicOnServer = useHandleAsapLogicOnServer();

  const isASAP = useIsASAP();
  const updateOrderFulfillment = async (orderType: string) => {
    setIsLoading(true);
    const orderTypeId = merchant?.orderTypeIds[orderType];
    const merchantId = merchant?.merchantId;
    const orderId = order?.id;
    try {
      const orderTypeDetails = await getMerchantOrderTypeDetails(merchant?.merchantId, orderTypeId);
      queryClient.setQueryData(
        ['merchant-order-type-details', merchant?.merchantId, orderTypeId],
        orderTypeDetails
      );

      const orderDateTime = dayjs(order?.orderDateTime);
      const asapDateTime = dayjs(orderTypeDetails?.asapOrderDateTime);

      const data = await updateOrder({
        merchantId,
        orderId,
        orderInfo: {
          orderTypeId,
          orderDateTime:
            // If the order is asap or if the order date time is stale use the asap time
            // otherwise use the existing order date time
            isASAP || orderDateTime.isBefore(asapDateTime)
              ? handleAsapLogicOnServer
                ? undefined
                : orderTypeDetails?.asapOrderDateTime
              : order?.orderDateTime,
        },
      });

      if (data?.id) {
        sendEvent(EAnalyticsEventNames.SWITCH_ORDER_TYPE, {
          googleAnalytics: {
            switchOrderTypeTo: orderType,
          },
        });
      }
      orderTipsDispatch({ type: 'CLEAR', payload: merchant?.merchantId });
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };
  return { updateOrderFulfillment, isLoading, error };
};
