export const filterSentOrUnsentItems = (
  items?: IOrderItem[],
  isUnsentItemsOnly?: boolean,
  isSentItemsOnly?: boolean
) => {
  if (!items) return [];

  return isUnsentItemsOnly
    ? items.filter((item) => item?.sentDateTime === null)
    : isSentItemsOnly
    ? items.filter((item) => item?.sentDateTime !== null)
    : items;
};
