import { useReducer, useEffect } from 'react';

enum ELocationServiceActions {
  Success = 'SUCCESS',
  Error = 'ERROR',
  Init = 'INIT',
}

type TLocationSuccessAction = IStandardAction<
  ELocationServiceActions.Success,
  GeolocationCoordinates
>;

type TLocationErrorAction = IStandardAction<ELocationServiceActions.Error, string>;

type TLocationInitAction = TEmptyAction<ELocationServiceActions.Init>;

type TLocationServiceAction = TLocationInitAction | TLocationSuccessAction | TLocationErrorAction;

interface ILocationServiceState {
  position: GeolocationCoordinates | null;
  loading: boolean;
  error: string | null;
}

export const useLocationServices = () => {
  const initialState = {
    position: null,
    error: null,
    loading: false,
  };

  const reducer = (state: ILocationServiceState, action: TLocationServiceAction) => {
    switch (action.type) {
      case ELocationServiceActions.Success:
        return {
          position: action.payload,
          error: null,
          loading: false,
        };
      case ELocationServiceActions.Error:
        return {
          position: null,
          error: action.payload,
          loading: false,
        };
      case ELocationServiceActions.Init:
        return { position: null, error: null, loading: true };
      default:
        return state;
    }
  };

  const [{ position, loading, error }, dispatch] = useReducer(reducer, initialState);

  const onChange = ({ coords }) =>
    dispatch({
      type: ELocationServiceActions.Success,
      payload: coords,
    });
  const onError = ({ message }) =>
    dispatch({ type: ELocationServiceActions.Error, payload: message });

  useEffect(() => {
    dispatch({ type: ELocationServiceActions.Init });
    const geo = navigator.geolocation;
    if (!geo) {
      dispatch({
        type: ELocationServiceActions.Error,
        payload: 'Geolocation not supported',
      });
      return;
    }

    const watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);

  return { position, error, loading };
};
