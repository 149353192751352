import React, { useEffect, useMemo, useState } from 'react';
import { CreditCard } from '@olo-web/assets/icons/payments/CreditCard.ui';
import { CreditCardOnTab } from '@olo-web/assets/icons/payments/CreditCardOnTab.ui';
import { Cash } from '@olo-web/assets/icons/payments/Cash.ui';
import { GooglePayBorder } from '@olo-web/assets/icons/payments/GooglePayBorder.ui';
import { GooglePaySmall } from '@olo-web/assets/icons/payments/GooglePaySmall.ui';
import { ApplePayBorder } from '@olo-web/assets/icons/payments/ApplePayBorder.ui';
import { ApplePaySmall } from '@olo-web/assets/icons/payments/ApplePaySmall.ui';
import {
  useIsScanAndPayPage,
  useIsLevelup,
  useIsSplitByAmount,
  useIsStartATabModal,
  useIsDineIn,
  useHasStartedTab,
} from '@olo-web/utils/common/hooks';
import { EPaymentMethods } from '@olo-web/types/enums/paymentMethods.enum';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useFeatureFlags } from '@olo-web/domain';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import {
  useCheckoutDispatch,
  useCheckoutState,
  useCustomerDispatch,
  useCustomerState,
  useSavedDineInContextState,
} from '@olo-web/client-state';
import { useIsOnPremisePaymentOnly } from '@olo-web/components/templates/Checkout/common/hooks/useIsOnPremisePaymentOnly';
import { useDoesPreAuthBelongToGuest } from '@olo-web/components/templates/Checkout/common/hooks/useDoesPreAuthBelongToGuest';
import { isFeatureEnabledForMerchant } from '@olo-web/utils/common/functions/isFeatureEnabledForMerchant';
import { Capacitor } from '@capacitor/core';

type TPaymentMethodDisplay = {
  title: string;
  icon: JSX.Element;
  value: EPaymentMethods;
  note?: string;
  radioLabel: string | JSX.Element;
};

declare const window: any;

const defaultPaymentMethods: TPaymentMethodDisplay[] = [
  {
    title: 'Card on Tab',
    icon: <CreditCardOnTab color="black" w="50px" h="24px" />,
    value: EPaymentMethods.CARD_ON_TAB,
    radioLabel: 'Card on Tab',
  },
  {
    title: 'Credit card',
    icon: <CreditCard color="black" w="50px" h="24px" />,
    value: EPaymentMethods.CREDIT_CARD,
    radioLabel: 'Credit card',
  },
  {
    title: 'Apple Pay',
    icon: <ApplePayBorder variant="black" w="44px" mx="3px" h="32px" />,
    value: EPaymentMethods.APPLE_PAY,
    radioLabel: <ApplePaySmall variant="black" w="50px" h="18px" />,
  },
  {
    title: 'Google Pay',
    icon: <GooglePayBorder variant="black" w="48px" mx="1px" h="30px" />,
    value: EPaymentMethods.GOOGLE_PAY,
    radioLabel: <GooglePaySmall variant="black" w="50px" h="18px" />,
  },
  {
    title: 'Cash',
    icon: <Cash color="black" w="50px" h="25px" />,
    note: 'Cash payment is required when you pick up your order.',
    value: EPaymentMethods.CASH,
    radioLabel: 'Cash',
  },
];

const removePaymentMethod = (array: TPaymentMethodDisplay[], key: EPaymentMethods) => {
  return array.filter((p) => p.value !== key);
};

type TUsePaymentMethods = {
  paymentMethods: TPaymentMethodDisplay[];
  selectedPaymentMethod: EPaymentMethods;
  selectedPaymentMethodDisplay: TPaymentMethodDisplay | null;
  isCreditCardOnly: boolean;
  isPayLaterEnabled: boolean;
};

export const usePaymentMethods = (): TUsePaymentMethods => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const checkoutDispatch = useCheckoutDispatch();
  const { data: flags } = useFeatureFlags();
  const isScanAndPayPage = useIsScanAndPayPage();
  const isLevelup = useIsLevelup();
  const [canUseApplePay, setCanUseApplePay] = useState(null);
  const { previousPaymentType } = useCustomerState();
  const isOnPremisePaymentOnly = useIsOnPremisePaymentOnly();
  const { data: featureFlags } = useFeatureFlags();
  const isDineIn = useIsDineIn();
  const customerDispatch = useCustomerDispatch();
  const isStartATabModal = useIsStartATabModal();
  const isStartATab = useHasStartedTab();
  const savedDineInState = useSavedDineInContextState();

  useEffect(() => {
    if (flags)
      setCanUseApplePay(
        (!flags?.hideApplePay && window?.ApplePaySession?.canMakePayments()) || false
      );
  }, [flags]);

  const { selectedPaymentMethod } = useCheckoutState();

  const isPayLaterEnabled = useMemo(() => {
    if (isScanAndPayPage || isLevelup) return false;

    const merchantOrderTypes = merchant?.orderTypes;
    const orderTypeId = order?.orderTypeId;

    if (!merchantOrderTypes || !orderTypeId) return false;

    const orderType =
      merchantOrderTypes[
        Object.keys(merchantOrderTypes).find((key) => orderTypeId === merchantOrderTypes[key]?.id)
      ];

    if (!orderType) return false;

    return orderType.payLaterCCEnabled || orderType.payLaterCashEnabled;
  }, [isScanAndPayPage, isLevelup, merchant?.orderTypes, order?.orderTypeId]);

  const isSplitByAmount = useIsSplitByAmount();

  const doesPreAuthbelongToGuest = useDoesPreAuthBelongToGuest();

  const qrStartATabFFEnabled = isFeatureEnabledForMerchant({
    merchantId: merchant?.merchantId,
    featureEnabled: featureFlags?.qrStartATab?.on,
    allowList: featureFlags?.qrStartATab?.allow,
    denyList: featureFlags?.qrStartATab?.deny,
  });

  const allowApplePaySaT = isFeatureEnabledForMerchant({
    merchantId: merchant?.merchantId,
    featureEnabled: flags?.startATabApplePay.on,
    allowList: flags?.startATabApplePay.allow,
    denyList: flags?.startATabApplePay?.deny,
  });

  const allowGooglePaySaT = isFeatureEnabledForMerchant({
    merchantId: merchant?.merchantId,
    featureEnabled: flags?.startATabGooglePay.on,
    allowList: flags?.startATabGooglePay.allow,
    denyList: flags?.startATabGooglePay?.deny,
  });

  const isDineInPreAuthRequired = merchant?.dineinpreauthrequired;

  const paymentMethods = useMemo(() => {
    let pMs = defaultPaymentMethods;

    if (isStartATabModal) {
      if (!allowApplePaySaT) {
        pMs = removePaymentMethod(pMs, EPaymentMethods.APPLE_PAY);
      }
      if (!allowGooglePaySaT) {
        pMs = removePaymentMethod(pMs, EPaymentMethods.GOOGLE_PAY);
      }
    }

    if (
      !qrStartATabFFEnabled ||
      !isDineInPreAuthRequired ||
      isSplitByAmount ||
      isStartATabModal ||
      !isDineIn ||
      order?.paymentAuth?.guestId !== savedDineInState?.guest?.id ||
      !order?.paymentAuth?.canCaptureViaAPI
    ) {
      pMs = removePaymentMethod(pMs, EPaymentMethods.CARD_ON_TAB);
    }
    if (!isPayLaterEnabled) {
      pMs = removePaymentMethod(pMs, EPaymentMethods.CASH);
      pMs = removePaymentMethod(pMs, EPaymentMethods.CARD_ON_TAB);
    }
    if (flags?.hideGooglePay || Capacitor.getPlatform() === 'ios') {
      pMs = removePaymentMethod(pMs, EPaymentMethods.GOOGLE_PAY);
    }
    if (!canUseApplePay) {
      pMs = removePaymentMethod(pMs, EPaymentMethods.APPLE_PAY);
    }
    return pMs;
  }, [
    isStartATabModal,
    qrStartATabFFEnabled,
    isDineInPreAuthRequired,
    isSplitByAmount,
    isDineIn,
    isPayLaterEnabled,
    flags?.hideGooglePay,
    canUseApplePay,
    allowApplePaySaT,
    allowGooglePaySaT,
    flags?.qrStartATab.on,
    doesPreAuthbelongToGuest,
    isSplitByAmount,
    order?.paymentAuth?.guestId,
    savedDineInState?.guest?.id,
  ]);

  const isCreditCardOnly =
    paymentMethods.length === 1 && selectedPaymentMethod === EPaymentMethods.CREDIT_CARD;

  const selectedPaymentMethodDisplay = useMemo(() => {
    if (!selectedPaymentMethod || !paymentMethods?.length) return null;
    if (isStartATab) {
      return paymentMethods.find((pM) => pM.value === EPaymentMethods.CARD_ON_TAB);
    }
    return paymentMethods.find((pM) => pM.value === selectedPaymentMethod);
  }, [selectedPaymentMethod, paymentMethods, isStartATab]);

  useEffect(() => {
    const dispatchSavedPayment = (value: EPaymentMethods) => {
      checkoutDispatch({
        type: 'SELECT_PAYMENT_METHOD',
        payload: value,
      });
    };
    const dispatchPreviousPayment = (value: EPaymentMethods) => {
      customerDispatch({
        type: 'UPDATE',
        payload: { previousPaymentType: value },
      });
    };
    if (
      !isSplitByAmount &&
      isStartATab &&
      doesPreAuthbelongToGuest &&
      previousPaymentType === null
    ) {
      dispatchPreviousPayment(selectedPaymentMethod);
      dispatchSavedPayment(EPaymentMethods.CARD_ON_TAB);
    } else if (!selectedPaymentMethod && canUseApplePay !== null) {
      if (previousPaymentType && !isOnPremisePaymentOnly) {
        dispatchSavedPayment(previousPaymentType);
      } else if (canUseApplePay) {
        dispatchSavedPayment(EPaymentMethods.APPLE_PAY);
      } else {
        dispatchSavedPayment(EPaymentMethods.CREDIT_CARD);
      }
    }
  }, [
    paymentMethods,
    selectedPaymentMethod,
    checkoutDispatch,
    previousPaymentType,
    canUseApplePay,
    isOnPremisePaymentOnly,
    doesPreAuthbelongToGuest,
    isStartATab,
    isSplitByAmount,
    customerDispatch,
  ]);

  return {
    paymentMethods,
    selectedPaymentMethod,
    selectedPaymentMethodDisplay,
    isCreditCardOnly,
    isPayLaterEnabled,
  };
};
