import { useMemo } from 'react';
import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import { useMerchantPath } from '@domain/merchants/hooks/useMerchantPath';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import {
  useIsCheckoutPage,
  useIsDineIn,
  useIsOrderConfirmationPage,
  useIsScanAndPayPage,
} from '@olo-web/utils/common/hooks';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames } from '@olo-web/types/enums';

export type TForgotPasswordProps = {
  email: string;
  origin: string;
  merchantPath: string;
  orderId: string;
};

export const forgotPassword = async ({
  email,
  origin,
  merchantPath,
  orderId,
}: TForgotPasswordProps): Promise<any> => {
  const apiUrl = `/api/customers/forgot-password`;
  const url = `/${merchantPath}/reset-password?email=${email}&orderId=${orderId}&origin=${origin}`;
  const body = { email, url };
  const response = await axios.post(apiUrl, body);
  return response.data;
};

export function useForgotPassword(options?: UseMutationOptions<any, AxiosError, string>) {
  const { sendEvent } = useSendEvent();
  const { data: order } = useOrder();
  const isCheckoutPage = useIsCheckoutPage();
  const isConfirmationPage = useIsOrderConfirmationPage();
  const isDineIn = useIsDineIn();
  const merchantPath = useMerchantPath();
  const isScanAndPay = useIsScanAndPayPage();

  const origin = useMemo(() => {
    if (isScanAndPay) return `/pay/${order?.checkId}`;
    if (isCheckoutPage) return '/checkout';
    if (isConfirmationPage) return `/thank-you/${order?.id}`;
    if (isDineIn) return '/dinein';
    return '';
  }, [isCheckoutPage, isConfirmationPage, isDineIn, order?.id, isScanAndPay, order?.checkId]);

  return useMutation(
    (email) => forgotPassword({ email, origin, merchantPath, orderId: order?.id }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        sendEvent(EAnalyticsEventNames.FORGOT_PASSWORD);
        if (options?.onSuccess) options.onSuccess(data, variables, context);
      },
    }
  );
}
