export enum ENVIRONMENTS {
  QA = 'QA',
  PROD = 'PROD',
}

export const getEnvironment = () => {
  const env = {
    staging: ENVIRONMENTS.QA,
    unlimited: ENVIRONMENTS.QA,
    prod: ENVIRONMENTS.PROD,
  };

  const environment = process.env.NEXT_PUBLIC_APP_ENVIRONMENT || '';

  return env[environment];
};
