import { useCustomer, useLoyalty } from '@olo-web/domain/customer';
import { useContactInfoState } from '@olo-web/client-state';

export const useCustomerContactInfo = (): ICustomer => {
  const { data: customerData } = useCustomer();
  const { data: loyalty } = useLoyalty();
  const contactInfo = useContactInfoState();

  const {
    firstName = '',
    lastName = '',
    email = '',
    phoneNo = customerData?.phone || '',
  } = contactInfo || customerData || {};

  return {
    spotOnCustomerID: customerData?.customerId || loyalty?.customerId || '',
    firstName,
    lastName,
    email,
    phoneNo,
  };
};
