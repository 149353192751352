import { useRouter } from 'next/router';
import { UseQueryResult, useQuery } from 'react-query';
import { useGlobalUIState } from '@olo-web/client-state';
import axios, { AxiosError } from 'axios';
import { IFeatureFlags } from '@olo-web/types/featureFlags.interface';

export const getFeatureFlags = async (
  userKey: string,
  merchantId: string
): Promise<IFeatureFlags> => {
  try {
    const { data } = await axios.get(`/api/flags`, {
      params: { userKey, merchantId },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw new Error(error?.response?.data?.detail);
  }
};

export const useFeatureFlags = (
  initialData?: IFeatureFlags
): UseQueryResult<IFeatureFlags, AxiosError> => {
  const globalUIState = useGlobalUIState();
  const router = useRouter();
  const { merchantId } = router.query;

  return useQuery(
    ['feature-flags', globalUIState?.ldUserKey],
    () => getFeatureFlags(globalUIState?.ldUserKey, merchantId as string),
    {
      staleTime: Infinity,
      enabled: !!globalUIState?.ldUserKey,
      retry: 0,
      initialData,
    }
  );
};
