import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useMerchant, useMerchantGroup } from '@domain/merchants/queries';

const envGiftCardUrl = process.env.NEXT_PUBLIC_GIFTCARD_URL || '';

export const useGiftCardUrl = () => {
  const { data: group } = useMerchantGroup();
  const { data: merchant } = useMerchant();
  const [giftCardUrl, setGiftCardUrl] = useState(null);

  useEffect(() => {
    if (group?.id && merchant?.merchantId && group?.eGiftCardMerchantId) {
      setGiftCardUrl(
        `${envGiftCardUrl}/${group?.id}/${merchant?.merchantId}?${queryString.stringify(
          {
            source: 'olo-web',
            returnUrl: encodeURIComponent(window.location.href),
          },
          { encode: false }
        )}`
      );
    }
  }, [group?.id, merchant?.merchantId, group?.eGiftCardMerchantId]);

  return giftCardUrl;
};
