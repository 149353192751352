import { theme } from '@chakra-ui/react';
const defaultStyle = theme.components.Modal;

const Modal = {
  ...defaultStyle,
  baseStyle: {
    overlay: {
      bg: 'blackAlpha.400',
      zIndex: 'modal',
    },
  },
  variants: {
    ...defaultStyle.baseStyle,
    borderless: () => ({
      ...defaultStyle.baseStyle,
      padding: 0,
    }),
  },
};

export default Modal;
