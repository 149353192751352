import { useQuery, UseQueryResult } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';

export const getMerchantOrderTypeTimes = async (
  merchantId: string,
  orderTypeId: string,
  date: string
) => {
  try {
    const url = `/api/merchants/${merchantId}/ordertypes/${orderTypeId}/schedule`;
    const params = { date };
    const { data } = await axios.get<IMerchantOrderTypeTimes>(url, {
      params,
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

type TUseMerchantOrderTypeTimesProps = {
  merchantId?: string;
  orderTypeId?: string;
  orderDate: string | null;
  rest?: any;
};

export function useMerchantOrderTypeTimes({
  merchantId,
  orderTypeId,
  orderDate,
  rest,
}: TUseMerchantOrderTypeTimesProps): UseQueryResult<IMerchantOrderTypeTimes, AxiosError> {
  const { data: order } = useOrder();
  const router = useRouter();
  const { merchantId: merchantIdFormUrl } = router.query;
  const merchId = merchantId || merchantIdFormUrl;
  const orderType = orderTypeId || order?.orderTypeId;
  const date = orderDate;

  const isEnabled = !!(merchId && orderType && date);

  return useQuery(
    ['merchant-order-type-times', merchId, orderType, date],
    () => getMerchantOrderTypeTimes(merchId as string, orderType!, date!),
    {
      staleTime: 1000000,
      enabled: isEnabled,
      ...rest,
    }
  );
}
