import { EOrderTypes } from '@olo-web/types/enums';

export const getDefaultOrderType = (data: IMerchant): IMerchantOrderTypeDetails => {
  const deliveryOrderType = data?.orderTypes?.doordashdrive || data?.orderTypes?.delivery || null;
  const pickupOrderType = data?.orderTypes?.pickup || null;

  if (deliveryOrderType) deliveryOrderType.orderTypeSlug = EOrderTypes.DELIVERY;
  if (pickupOrderType) pickupOrderType.orderTypeSlug = EOrderTypes.PICKUP;

  if (!deliveryOrderType) return pickupOrderType;
  if (!pickupOrderType) return deliveryOrderType;
  return data?.deliveryByDefaultEnabled ? deliveryOrderType : pickupOrderType;
};
