import { theme } from '@chakra-ui/react';
const defaultStyle = theme.components.CloseButton;

const CloseButton = {
  ...defaultStyle,
  baseStyle: {
    ...defaultStyle.baseStyle,
  },
  variants: {
    ...defaultStyle.baseStyle,
    filled: () => ({
      ...defaultStyle.baseStyle,
      zIndex: '99999999',
      borderRadius: '50%',
      background: 'white',
      _hover: {
        bg: 'whiteAlpha.500',
      },
      border: '1px solid rgba(71, 81, 110, 0.05)',
      boxShadow: '0px 1px 2px rgb(0 0 0 / 15%)',
    }),
  },
};

export default CloseButton;
