import { useCheckoutState, useCustomerState } from '@olo-web/client-state';
import { useGiftCardBalance } from '@olo-web/domain';
import { useCheckout } from '@olo-web/domain/orders/mutations/useCheckout';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useCallback } from 'react';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';
import { useCustomerContactInfo } from './useCustomerContactInfo';
import { useDeliverServiceData } from './useDoorDashDeliveryData';
import { useCalculateMixedPayment } from './useCalculateMixedPayment';
import { useIsScanAndPayPage } from '@olo-web/utils/common/hooks';
import { useShouldSaveCard } from './useShouldSaveCard';
import { useCustomer } from '@olo-web/domain/customer/queries';
import { useIsDineIn } from '@olo-web/utils/common/hooks';

export const useCompleteOrderWithMixedPayments = () => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const { mutateAsync: checkout } = useCheckout();
  const { orderNote, gclid, zipCode, paymentForGuestIds } = useCheckoutState();
  const customer = useCustomerContactInfo();
  const isSplitByAmount = useIsSplitByAmount();
  const { data: giftCard } = useGiftCardBalance();
  const { customerId, sessionId, sessionSecret } = useCustomerState();
  const deliveryServiceData = useDeliverServiceData();
  const { tipAmount, giftCardTipAmount, amount, giftCardAmount } = useCalculateMixedPayment();
  const isScanAndPayPage = useIsScanAndPayPage();
  const shouldSaveCard = useShouldSaveCard();
  const { data: customerData } = useCustomer();
  const isDineIn = useIsDineIn();

  return useCallback(
    (paymentToken, mobileCustomer?: ICustomer) => {
      const finalCustomer = { ...customer, ...mobileCustomer };
      return checkout({
        merchantId: merchant?.merchantId,
        orderId: order?.id,
        checkId: order?.checkId,
        customerSessionDetails: {
          customerId,
          sessionId,
          sessionSecret,
        },
        orderNotes: orderNote,
        customer: finalCustomer,
        paymentForGuestIds: isSplitByAmount ? paymentForGuestIds : undefined,
        paymentInfo: [
          {
            tipAmount,
            amount,
            // VTS requires firstName and lastName to save a card to the account
            // We are intentionally providing the logged in users information here for this
            // Otherwise we never send the name as it is not the cardholder name
            vtsPaymentInfo: shouldSaveCard
              ? {
                  firstName: customerData?.firstName,
                  lastName: customerData?.lastName,
                  postalCode: zipCode,
                  paymentChannel: isScanAndPayPage
                    ? 'qr-scan-pay'
                    : isDineIn
                    ? 'qr-order-pay'
                    : 'olo-web',
                  savePaymentMethodAsDefault: false,
                  saveToPaymentMethod: !!paymentToken && shouldSaveCard,
                  paymentToken,
                }
              : {
                  postalCode: zipCode,
                  paymentChannel: isScanAndPayPage
                    ? 'qr-scan-pay'
                    : isDineIn
                    ? 'qr-order-pay'
                    : 'olo-web',
                  savePaymentMethodAsDefault: false,
                  saveToPaymentMethod: !!paymentToken && shouldSaveCard,
                  paymentToken,
                },
          },
          {
            amount: giftCardAmount,
            tipAmount: giftCardTipAmount,
            giftCardNum: giftCard?.cardNumber,
          },
        ],
        conversionId: gclid,
        conversionType: gclid ? 'google' : null,
        deliveryAddress: {
          address1: deliveryServiceData?.deliveryInfo?.dropoffAddress?.street,
          address2: deliveryServiceData?.deliveryInfo?.dropoffAddress?.unit,
          city: deliveryServiceData?.deliveryInfo?.dropoffAddress?.city,
          state: deliveryServiceData?.deliveryInfo?.dropoffAddress?.state,
          zip: deliveryServiceData?.deliveryInfo?.dropoffAddress?.zipCode,
        },
      });
    },
    [
      customer,
      checkout,
      merchant?.merchantId,
      order?.id,
      order?.checkId,
      customerId,
      sessionId,
      sessionSecret,
      orderNote,
      paymentForGuestIds,
      tipAmount,
      amount,
      shouldSaveCard,
      customerData?.firstName,
      customerData?.lastName,
      zipCode,
      isScanAndPayPage,
      isDineIn,
      giftCardAmount,
      giftCardTipAmount,
      giftCard?.cardNumber,
      gclid,
      deliveryServiceData?.deliveryInfo?.dropoffAddress?.street,
      deliveryServiceData?.deliveryInfo?.dropoffAddress?.unit,
      deliveryServiceData?.deliveryInfo?.dropoffAddress?.city,
      deliveryServiceData?.deliveryInfo?.dropoffAddress?.state,
      deliveryServiceData?.deliveryInfo?.dropoffAddress?.zipCode,
      isSplitByAmount,
    ]
  );
};
