import { DiscountType } from '@olo-web/domain/orders/types';
import { ILoyaltyItem, IPromoCodeDiscount } from '@olo-web/types/loyaltyReward.interface';

export const getDiscountType = (offer: IPromoCodeDiscount | ILoyaltyItem): DiscountType => {
  switch (offer?.promotionType) {
    case 1:
      return DiscountType.PROMO_CODE;
    case 2:
      return DiscountType.REWARD;
    case 3:
    case 4:
    case 5:
      return DiscountType.DEAL;
  }
};
