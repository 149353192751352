import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useCurrentMerchantSavedOrderId } from '@olo-web/utils/common/hooks/useCurrentMerchantSavedOrderId';
import { useSendEvent } from '@olo-web/utils/common/hooks/useSendEvent';

export const useListenForContinuedOrderEvent = () => {
  const savedOrderId = useCurrentMerchantSavedOrderId();
  const { sendEvent } = useSendEvent();

  const onSuccess = (data: IOrder) => {
    if (data?.id === savedOrderId && !!data?.items?.length) {
      sendEvent(EAnalyticsEventNames.ORDER_CONTINUED);
    }
  };

  useOrder(onSuccess);
};
