import { useCheckoutDispatch } from '@olo-web/client-state';
import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';

type RemovePromoCodeToOrderProps = {
  merchantId: string;
  orderId: string;
  discountId: string;
};

export const removePromoCodeToOrder = async ({
  merchantId,
  orderId,
  discountId,
}: RemovePromoCodeToOrderProps): Promise<IOrder> => {
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/discounts/${discountId}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useRemovePromoCodeFromOrder = (
  options?: UseMutationOptions<IOrder, AxiosError, RemovePromoCodeToOrderProps>
) => {
  const updateOrderInCache = useUpdateOrderInCache();
  const { sendEvent } = useSendEvent();
  const checkoutDispatch = useCheckoutDispatch();

  return useMutation(removePromoCodeToOrder, {
    ...options,
    onSuccess: (data, variables, context) => {
      updateOrderInCache(data);
      sendEvent(EAnalyticsEventNames.PROMO_CODE_REMOVE, {
        googleAnalytics: {
          promoId: variables.discountId,
        },
      });
      checkoutDispatch({ type: 'CLEAR_SELECTED_OFFER' });
      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
  });
};
