import { theme } from '@chakra-ui/react';
const defaultStyle = theme.components.Button;

const Popover = {
  ...defaultStyle,
  variants: {
    bagbar: {
      popper: {
        maxWidth: '360px',
        width: 'unset',
        maxHeight: 'unset',
        height: 'unset',
      },
    },
  },
};

export default Popover;
