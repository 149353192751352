import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Mastercard = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 34 23" fill="none" {...props}>
      <path
        d="M32.1111 0.00195312H1.88889C0.845684 0.00195312 0 0.822764 0 1.83529V20.1686C0 21.1811 0.845684 22.002 1.88889 22.002H32.1111C33.1543 22.002 34 21.1811 34 20.1686V1.83529C34 0.822764 33.1543 0.00195312 32.1111 0.00195312Z"
        fill="#08031D"
      />
      <path
        d="M27.8027 16.2051H27.7551V15.9219H27.8257L27.9173 16.1208L27.9952 15.9219H28.064V16.2033H28.0163V15.9906L27.9412 16.174H27.8852L27.8027 15.9906V16.2042V16.2051ZM27.5965 16.2042H27.5552V15.9705H27.459V15.9219H27.7001V15.9705H27.5965V16.2033V16.2042Z"
        fill="#F69E1E"
      />
      <path d="M20.1302 5.11523H13.1104V17.0851H20.1302V5.11523Z" fill="#F26122" />
      <path
        d="M13.8663 11.0999C13.8551 9.96121 14.0958 8.83418 14.5712 7.79949C15.0466 6.7648 15.745 5.84805 16.6163 5.11493C15.1913 3.9335 13.3601 3.35754 11.5153 3.51057C9.67058 3.66359 7.95927 4.53343 6.7484 5.93352C5.50018 7.36504 4.8125 9.2001 4.8125 11.0994C4.8125 12.9987 5.50018 14.8337 6.7484 16.2653C7.95927 17.6654 9.67058 18.5352 11.5153 18.6882C13.3601 18.8412 15.1913 18.2653 16.6163 17.0839C15.7451 16.3508 15.0468 15.4342 14.5714 14.3997C14.096 13.3652 13.8552 12.2383 13.8663 11.0999Z"
        fill="#EA1D25"
      />
      <path
        d="M28.4142 11.0993C28.4259 12.5073 28.0488 13.8912 27.3243 15.0986C26.5999 16.306 25.5563 17.29 24.3085 17.9423C23.0826 18.5657 21.7011 18.8167 20.3344 18.6644C18.9676 18.5121 17.6752 17.9631 16.6167 17.0852C18.1446 15.7959 19.1223 13.9715 19.3497 11.9853C19.5772 9.99908 19.0373 8.00097 17.8404 6.3996C17.4861 5.92347 17.0752 5.49222 16.6167 5.11535C17.6751 4.23723 18.9674 3.68808 20.3342 3.53562C21.701 3.38316 23.0825 3.63405 24.3085 4.25735C25.5562 4.90953 26.5997 5.8934 27.3241 7.1006C28.0485 8.30781 28.4257 9.69151 28.4142 11.0993Z"
        fill="#F69E1E"
      />
    </Icon>
  );
};
