import React from 'react';
import { Text } from '@chakra-ui/react';
import { useToast } from '@olo-web/utils/common/hooks';

export const useNotifyGuestPaymentsAdded = () => {
  const { notify } = useToast();
  return (e: INatsEvent, data) => {
    let message = '';
    const guestsTotalIndex = e?.paymentForGuestIds?.length - 1;

    e?.paymentForGuestIds?.forEach((guestId, index) => {
      const guest = data.order.guests.find((guest) => guest.id === guestId);
      const name = guest.name;

      message += name;
      if (guestsTotalIndex > 0 && index !== guestsTotalIndex) {
        message += ', ';
      }

      if (index + 1 === guestsTotalIndex) {
        message += 'and ';
      }

      if (index === guestsTotalIndex) {
        message += "'s bill has been paid.";
      }
    });

    if (message) {
      notify({
        status: 'info',
        variant: 'left-accent',
        description: <Text>{message}</Text>,
        isClosable: true,
        bannerProps: {
          descriptionTestId: 'dinein-guest-bill-has-been-paid',
        },
      });
    }
  };
};
