import Script from 'next/script';
import React, { memo } from 'react';
import { usePaymentTokenizationKey } from '@olo-web/domain';
import { useGlobalUIDispatch } from '@olo-web/client-state';
declare const Gateway: any;

export const GatewayJS = memo(() => {
  const { data } = usePaymentTokenizationKey();
  const globalUiDispatch = useGlobalUIDispatch();

  return (
    !!data?.checkoutKey && (
      <Script
        strategy="afterInteractive"
        src="https://secure.nmi.com/js/v1/Gateway.js"
        onLoad={async () => {
          try {
            // Initialize Gateway.js
            const gateway = Gateway.create(data.checkoutKey);

            // Initialize the Kount service
            const kount = gateway?.getKount();

            // Run Kount
            const sessionId = await kount.createSession();

            globalUiDispatch({ type: 'SET_KOUNT_SESSIONID', payload: sessionId });
          } catch (e) {
            console.warn(e);
          }
        }}
      />
    )
  );
});
