type TConditionalWrapper = {
  condition?: boolean;
  wrapper: (children: any) => JSX.Element;
  falseWrapper?: (children: any) => JSX.Element;
  children?: any;
};

export const ConditionalWrapper = ({
  condition,
  wrapper,
  falseWrapper,
  children,
}: TConditionalWrapper) =>
  condition ? wrapper(children) : falseWrapper ? falseWrapper(children) : children;
