import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useMerchantGroup } from '@domain/merchants/queries/useMerchantGroup';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useValidateDeliveryAddress } from '@olo-web/domain/orders/queries/useValidateDeliveryAddress';
import * as gmaps from './functions';
import { useDeliveryAddressDispatch } from '@olo-web/client-state';

export function useGoogleMapsAddressLookup({
  inputRef,
  onSuccess,
}: {
  inputRef: MutableRefObject<HTMLInputElement>;
  onSuccess?: () => void;
}): any {
  const deliveryAddressDispatch = useDeliveryAddressDispatch();
  const { data: merchantGroupDetails } = useMerchantGroup();
  const { data: merchantDetails } = useMerchant();
  const merchants = merchantGroupDetails?.merchants;
  const [maps, setMaps] = useState<gmaps.GoogleMaps>();
  const [autocomplete, setAutocomplete] = useState<gmaps.GoogleMapsAutocomplete>();
  const { data: validateDeliveryAddress, isLoading } = useValidateDeliveryAddress();

  useEffect(() => {
    gmaps
      .googleMaps()
      .then((map: gmaps.GoogleMaps) => {
        // create a maps instance
        setMaps(map);
      })
      .catch((e: Error) => {
        console.error('[DeliveryAddress] Something went wrong loading google maps', e);
      });
  }, []);

  useEffect(() => {
    if (maps && inputRef.current) {
      const autocomplete = gmaps.createAutocomplete(maps, inputRef.current);

      setAutocomplete(autocomplete);
    }
  }, [maps, inputRef]);

  const setSelectedLocation = useCallback(async (): Promise<void> => {
    if (autocomplete) {
      const place: gmaps.GoogleMapsPlaceResult = autocomplete.getPlace();
      if (merchantDetails?.deliveryOrderType?.id) {
        if (place && place.address_components && place.geometry && merchants) {
          const gmapsLocation = place?.geometry?.location;

          deliveryAddressDispatch({
            type: 'UPDATE_ADDRESS_FORM',
            payload: {
              ...gmaps.convertGeoAddressToOLOAddress(place.address_components),
              lat: gmapsLocation?.lat(),
              lng: gmapsLocation?.lng(),
            },
          });

          if (validateDeliveryAddress?.withinBounds) onSuccess();
        }
      }
    }
  }, [
    autocomplete,
    merchantDetails?.deliveryOrderType?.id,
    merchants,
    deliveryAddressDispatch,
    validateDeliveryAddress?.withinBounds,
    onSuccess,
  ]);

  useEffect(() => {
    if (maps && autocomplete) {
      maps.event.addListener(autocomplete, 'place_changed', setSelectedLocation);
    }

    return function cleanUp(): void {
      if (maps && autocomplete) {
        maps.event.clearListeners(autocomplete, 'place_changed');
      }
    };
  }, [autocomplete, merchants, merchantDetails, maps, setSelectedLocation]);

  return { maps, autocomplete, isLoading };
}
