import { DrawerHeader, Icon, IconButton, ModalHeaderProps, Flex } from '@chakra-ui/react';
import { useModalDispatch, useModalState } from '@olo-web/client-state';
import React, { FC } from 'react';
import { ArrowLeft } from 'react-feather';

interface I extends ModalHeaderProps {
  children: any;
  hideArrow?: boolean;
  dataTestId?: string;
}

export const CommonDrawerHeader: FC<I> = (props) => {
  const { children, dataTestId, ...rest } = props;
  const { previousModal } = useModalState();
  const modalDispatch = useModalDispatch();
  const handleClick = () => {
    modalDispatch({
      type: 'OPEN_PREVIOUS_MODAL',
    });
  };
  return (
    <DrawerHeader
      as="h2"
      display="flex"
      alignItems="center"
      data-testid="modal-header"
      px={4}
      {...rest}
    >
      {previousModal!.length! > 0 && !props?.hideArrow && (
        <IconButton
          variant="ghost"
          mr={2}
          ml={-2}
          aria-label="Go back to previous modal"
          icon={<Icon as={ArrowLeft} h={6} w={6} />}
          onClick={handleClick}
        />
      )}
      <Flex as="h2" align="center" mr={8} data-testid={dataTestId}>
        {children}
      </Flex>
    </DrawerHeader>
  );
};
