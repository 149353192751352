///////////////////////////////////////////////////////////////////////////////
//
// Google Maps Interface
//
///////////////////////////////////////////////////////////////////////////////

// This is a very lightweight client, that essentially just loads the google
// maps API.
//
// It is then up to the programmer to follow the docs at
// `developers.google.com/maps/documentation/javascript/reference` to properly
// implement the required functionality.
//
// NOTE: since we interact directly with the goole API we HAVE to use callbacks
// here.
//
// TODO: Create a react hook to take care of initialization of whatever we need.
//
// USAGE:
//
// // create a state for each of the google maps services we want to use
// const [maps, setMaps] = useState<gmaps.GoogleMaps>();
// const [placeService, setPlaceService] = useState<gmaps.GoogleMapsPlaceService>();
// const [autocomplete, setAutocomplete] = useState<gmaps.GoogleMapsAutocomplete>();
//
// // Next we can load the services we want to use in effects
//
// // Load google maps into state
// useEffect(() => {
//     gmaps
//         .googleMaps()
//         .then((map: gmaps.GoogleMaps) => {
//              // create a maps instance
//              setMaps(map);
//          })
//      });
// }, []);
//
// // Create an autocomplete instance after a google maps instance is loaded up
// useEffect(() => {
//     if (maps && searchInput.current) {
//         const autocomplete = gmaps.createAutocomplete(
//             maps,
//             searchInput.current,
//         );
//
//         setAutocomplete(autocomplete);
//     }
// }, [maps]);
//
// // After google maps is loaded up, create a service instance
// useEffect(() => {
//      if (maps && googleMapsResultNode.current) {
//          // create a place service instance
//          const placeService = gmaps.createPlacesService(
//              maps,
//              googleMapsResultNode.current,
//          );
//          setPlaceService(placeService);
//      }
// }, [maps]);
//
// // Finally we can perform actions
//
// // For example we can auto complete things
//
// useEffect(() => {
//      if (maps && autocomplete) {
//          maps.event.addListener(
//              autocomplete,
//              'place_changed',
//              setSelectedLocation,
//          );
//      }
//
//      return function cleanUp(): void {
//          if (maps && autocomplete) {
//              maps.event.clearListeners(autocomplete, 'place_changed');
//          }
//      };
// }, [autocomplete]);

import googleMapsClient from 'load-google-maps-api';
import numbro from 'numbro';
import { EAddressType } from '@olo-web/types/enums';

const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '';

export type GoogleMaps = typeof google.maps;
export type GoogleMapsPlaceService = google.maps.places.PlacesService;
export type GoogleMapsPlaceServiceStatus = google.maps.places.PlacesServiceStatus;
export type GoogleMapsPlaceResult = google.maps.places.PlaceResult;
export type GoogleMapsAutocomplete = google.maps.places.Autocomplete;
export type GoogleMapsAutocompleteOpts = google.maps.places.AutocompleteOptions;
export type GoogleMapsMap = google.maps.Map;
export type GoogleMapsMapOptions = google.maps.MapOptions;
export type GoogleMapsMarker = google.maps.Marker;
export type GoogleMapsMarkerOptions = google.maps.MarkerOptions;
export type GoogleMapsPoint = google.maps.Point;

const METERS_TO_MILES_CONSTANT = 0.000621371192;

// check if a geo-address component contains a type
function containsAddressPart(
  type: EAddressType,
  component: google.maps.GeocoderAddressComponent
): boolean {
  return component.types.includes(type);
}

export function getAddressFromLatLng(location: google.maps.LatLng) {
  const geocoder = new google.maps.Geocoder();
  return (
    geocoder
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .geocode({ location }, () => {})
      .then((results) => {
        return convertGeoAddressToOLOAddress(results?.results[0]?.address_components);
      })
      .catch(console.warn)
  );
}

// convert a google geo-addres to OLO compatible format
export function convertGeoAddressToOLOAddress(
  geoAddress: google.maps.GeocoderAddressComponent[]
): IDeliveryAddress {
  const initAddress: IDeliveryAddress = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  };

  // filter out the parts of the geo address data that we want
  const accumulatedAddresses = geoAddress.reduce((acc, current) => {
    return {
      ...acc,
      zip: containsAddressPart(EAddressType.ZIPCODE, current) ? current.long_name : acc.zip,
      state: containsAddressPart(EAddressType.STATE, current) ? current.short_name : acc.state,
      city:
        containsAddressPart(EAddressType.CITY, current) ||
        containsAddressPart(EAddressType.SUB_LOCALITY, current) ||
        containsAddressPart(EAddressType.NEIGHBORHOOD, current)
          ? current.long_name
          : acc.city,
      address1: containsAddressPart(EAddressType.STREET, current)
        ? current.long_name
        : acc.address1,
      address2: containsAddressPart(EAddressType.ROUTE, current)
        ? current.short_name
        : acc.address2,
    };
  }, initAddress);

  // combine the street name and address
  const result = {
    ...accumulatedAddresses,
    address1: `${accumulatedAddresses.address1} ${accumulatedAddresses.address2}`,
    address2: ``,
  };

  return result;
}

// Get a google maps instance
export function googleMaps(libraries: string[] = ['places', 'geometry']): Promise<GoogleMaps> {
  return googleMapsClient({
    key: googleMapsApiKey,
    libraries: libraries,
  }).then((googleMaps: GoogleMaps): GoogleMaps => {
    return googleMaps;
  });
}

// create a places service that renders to a DOM node
export function createPlacesService(
  googleMaps: GoogleMaps,
  node: HTMLDivElement | GoogleMapsMap
): GoogleMapsPlaceService {
  return new googleMaps.places.PlacesService(node);
}

// find a place given an OLO address
export function findPlace(
  placeService: GoogleMapsPlaceService,
  address: IDeliveryAddress,
  callback: (res: GoogleMapsPlaceResult[], status: GoogleMapsPlaceServiceStatus) => void
): void {
  placeService.findPlaceFromQuery(
    {
      query: `${address.address1}, ${address.city}, ${address.state} ${address.zip}`,
      fields: ['place_id', 'geometry.location'],
    },
    callback
  );
}

// get place details
export function getPlaceDetails(
  placeId: string,
  placeService: GoogleMapsPlaceService,
  callback: (res: GoogleMapsPlaceResult, status: GoogleMapsPlaceServiceStatus) => void
): void {
  placeService.getDetails(
    {
      placeId: placeId,
      fields: ['name', 'geometry.location', 'place_id', 'formatted_address', 'address_components'],
    },
    callback
  );
}

export function getLatLng(lat: any, lng: any): any {
  return new google.maps.LatLng(lat, lng);
}

export function getDistanceBetweenTwoPlaces(
  origin: google.maps.LatLng,
  destination: google.maps.LatLng
): number {
  return Number.parseFloat(
    numbro(
      google.maps.geometry.spherical.computeDistanceBetween(origin, destination) *
        METERS_TO_MILES_CONSTANT
    ).format({
      mantissa: 2,
    })
  );
}

// Create and bind an autocomplete instance
//
// This attaches to an input and returns an autocopmlete instance
//
// NOTE: restricted to USA
export function createAutocomplete(
  googleMaps: GoogleMaps,
  inputField: HTMLInputElement,
  disableLocationSpecificity = false
): GoogleMapsAutocomplete {
  const autocomplete = new googleMaps.places.Autocomplete(inputField, {
    fields: ['name', 'geometry.location', 'place_id', 'formatted_address', 'address_components'],
    componentRestrictions: {
      country: 'US',
    },
  });
  if (!disableLocationSpecificity) {
    autocomplete.setTypes(['address']);
  }

  return autocomplete;
}

// create a map
export function createMap(
  googleMaps: GoogleMaps,
  mapsDiv: HTMLDivElement,
  options: GoogleMapsMapOptions = {
    center: { lat: -41.881, lng: -87.623 }, // default to Chicago
    zoom: 12,
  }
): GoogleMapsMap {
  return new googleMaps.Map(mapsDiv, options);
}

// create a marker
export function createMarker(
  googleMaps: GoogleMaps,
  options: GoogleMapsMarkerOptions
): GoogleMapsMarker {
  return new googleMaps.Marker(options);
}

export function createPoint(googleMaps: GoogleMaps, x: number, y: number): GoogleMapsPoint {
  return new googleMaps.Point(x, y);
}

export const createFriendlyGoogleMapsErrorMessage = (
  status: google.maps.places.PlacesServiceStatus
): null | string => {
  switch (status) {
    case google.maps.places.PlacesServiceStatus.OK:
      return null;
    case google.maps.places.PlacesServiceStatus.INVALID_REQUEST:
      return 'This address is invalid';
    case google.maps.places.PlacesServiceStatus.NOT_FOUND:
      return 'We could not locate this address';
    case google.maps.places.PlacesServiceStatus.REQUEST_DENIED:
    case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
    case google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR:
    case google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT:
      return 'We could not verify this address';
    default:
      return `Status: ${status}`;
  }
};
