import slugify from 'slugify';

export const slug = (text: string): string => {
  const options = {
    replacement: '-',
    remove: /[*+~.()'"!:@,#/]/g,
    lower: true,
  };

  const trimmedText = (text || '').trim().toLowerCase();

  return slugify(trimmedText, options);
};
