import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useOrderTypeId } from '@olo-web/utils/common/hooks/useOrderTypeId';
import { useCreateEmptyOrder } from './useCreateEmptyOrder';
import { useMerchant, useMerchantOrderTypeDetails } from '@domain/merchants/queries';
import { useModalDispatch } from '@olo-web/client-state';
import { EModalTypes } from '@olo-web/types/enums';
import { useHandleAsapLogicOnServer } from '@olo-web/utils/common/hooks';

export const updateOrder = async ({
  merchantId,
  orderId,
  orderInfo,
}: IUpdateOrRepeatOrderProps): Promise<IOrder> => {
  if (!merchantId || !orderId) {
    return;
  }
  const url = `/api/merchants/${merchantId}/orders/${orderId}`;
  const { data } = await axios.patch(url, orderInfo);
  return data;
};

export const useUpdateOrder = (
  options?: UseMutationOptions<IOrder, AxiosError, IUpdateOrRepeatOrderProps>
) => {
  const updateOrderInCache = useUpdateOrderInCache();
  const orderTypeId = useOrderTypeId();
  const { data: orderTypeDetails } = useMerchantOrderTypeDetails({ orderTypeId });
  const { data: merchant } = useMerchant();
  const { mutate: createEmptyOrder } = useCreateEmptyOrder();
  const modalDispatch = useModalDispatch();
  const handleAsapLogicOnServer = useHandleAsapLogicOnServer();

  return useMutation(updateOrder, {
    ...options,
    onSuccess: (data, variables, context) => {
      updateOrderInCache(data);
      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
    onError: (error) => {
      if (error?.response?.status === 500) {
        createEmptyOrder({
          merchantId: merchant?.merchantId,
          orderInfo: {
            orderTypeId,
            orderDateTime: handleAsapLogicOnServer
              ? undefined
              : orderTypeDetails?.asapOrderDateTime,
          },
        });
        modalDispatch({
          type: 'OPEN_MODAL',
          payload: { modalKey: EModalTypes.ORDER_IS_RESET },
        });
      }
    },
  });
};
