import { useToast } from '@olo-web/utils/common/hooks';
import { useCallback } from 'react';
import { useMerchant } from '@domain';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';
import { useGoToConfirmation } from './useGoToConfirmation';

export const useNotifyPaymentIsCompleted = () => {
  const { notify, toast } = useToast();
  const toastId = 'server-payment';
  const { data: merchant } = useMerchant();
  const isSplitByAmountFromRoute = useIsSplitByAmount();
  const goToConfirmation = useGoToConfirmation();

  return useCallback(
    (isSplitByAmount: boolean) => {
      let toastDescription =
        isSplitByAmount || isSplitByAmountFromRoute
          ? 'Everyone has finished paying'
          : 'The bill has been paid';

      toastDescription = `${toastDescription}${
        !merchant?.dineinPayLaterEnabled ? ' and the items have been sent to the kitchen' : ''
      }`;

      if (!toast.isActive(toastId)) {
        notify({
          id: toastId,
          description: `${toastDescription}  🎉`,
          isClosable: true,
          dataTestId: `${
            isSplitByAmount || isSplitByAmountFromRoute
              ? 'everyone-has-finished-paying-toast'
              : 'the-bill-has-been-paid-toast'
          }`,
          status: 'success',
          bannerProps: {
            descriptionTestId:
              isSplitByAmount || isSplitByAmountFromRoute
                ? 'dinein-sba-paid-bill-toast'
                : 'dinein-single-paid-bill-toast',
          },
        });
      }

      goToConfirmation();
    },
    [goToConfirmation, isSplitByAmountFromRoute, merchant?.dineinPayLaterEnabled, notify]
  );
};
