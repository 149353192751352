import { EOrderTypes } from '@olo-web/types/enums';
import { useRouter } from 'next/router';
import { useSavedDineInContextState } from '@olo-web/client-state';

export const useIsDineInRoute = () => {
  const {
    query: { orderType },
  } = useRouter();

  return Boolean(orderType === EOrderTypes.DINE_IN);
};

export const useIsDineIn = () => {
  const {
    query: { orderType },
  } = useRouter();
  const savedDineInState = useSavedDineInContextState();

  return orderType === EOrderTypes.DINE_IN || savedDineInState !== null;
};
