import { useRouter } from 'next/router';

export const useIsScanAndPayPage = () => {
  const router = useRouter();

  return (
    router.route === '/[locationSlug]/[merchantSlug]/[merchantId]/pay/[checkId]' ||
    !!router?.query?.checkId
  );
};
