import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const UnknownPaymentMethod = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 41 26" fill="none" {...props}>
      <rect
        x="0.75"
        y="0.75"
        width="32.5"
        height="20.5"
        rx="3.25"
        fill="#F7F7FA"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M6 17H5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9 17L15 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1 6L33 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </Icon>
  );
};
