import { useCheckoutDispatch } from '@olo-web/client-state';
import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useSendEvent, useIsScanAndPayPage } from '@olo-web/utils/common/hooks';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';

export const removeRewardOrDealFromOrder = async ({
  merchantId,
  orderId,
  rewardId,
}: IAddOrRemoveOrderRewardProps): Promise<AxiosResponse> => {
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/rewards`;
    const data = { rewardId };
    return await axios.delete(url, { data });
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useRemoveRewardOrDealFromOrder = (
  options?: UseMutationOptions<AxiosResponse, AxiosError, IAddOrRemoveOrderRewardProps>
) => {
  const queryClient = useQueryClient();
  const { sendEvent } = useSendEvent();
  const checkoutDispatch = useCheckoutDispatch();
  const isScanAndPayPage = useIsScanAndPayPage();
  const router = useRouter();
  const { checkId } = router.query;

  return useMutation(removeRewardOrDealFromOrder, {
    ...options,
    onSuccess: (data, variables, context) => {
      const { merchantId, orderId } = variables;
      queryClient.setQueryData(
        ['order', merchantId, isScanAndPayPage ? checkId : orderId],
        data.data
      );
      sendEvent(EAnalyticsEventNames.REWARD_REMOVE, {
        googleAnalytics: {
          rewardId: variables.rewardId,
        },
      });
      checkoutDispatch({ type: 'CLEAR_SELECTED_OFFER' });
      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
  });
};
