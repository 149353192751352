import Alert from './Alert';
import Button from './Button';
import CloseButton from './CloseButton';
import Modal from './Modal';
import Radio from './Radio';
import Input from './Input';
import Select from './Select';
import Drawer from './Drawer';
import Checkbox from './Checkbox';
import Popover from './Popover';
import Tabs from './Tabs';
import Form from './Form';

const components = {
  Alert,
  Button,
  CloseButton,
  Modal,
  Radio,
  Input,
  Select,
  Drawer,
  Checkbox,
  Popover,
  Tabs,
  Form,
};

export default components;
