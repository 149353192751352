import { useScreenSizeState, useContactInfoDispatch } from '@olo-web/client-state';
import { EValidationFields } from '@olo-web/types/enums/paymentValidationFIelds.enum';
import { useIsOrderButtonHidden } from '../../hooks/useIsOrderButtonHidden';
import { usePaymentMethodValues } from '@olo-web/domain/payments/hooks';
import { useCallback, useEffect } from 'react';
import { useCheckoutState, useCheckoutDispatch, useGlobalUIState } from '@olo-web/client-state';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useValidateOnCheckout } from '../../hooks/useValidateOnCheckout';
import {
  useError,
  useIsSsr,
  useSendEvent,
  useIsLevelup,
  useIsStartATabModal,
} from '@olo-web/utils/common/hooks';
import { useTheme } from '@chakra-ui/react';
import { usePlaceOrderWithPaymentToken } from '../../hooks/usePlaceOrder';
import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useGiftCardBalance } from '@olo-web/domain/giftcard/queries/useGiftCardBalance';
import { useCalculateMixedPayment } from '@templates/Checkout/common/hooks/useCalculateMixedPayment';
import { useSignup } from '@domain/customer';
import { getGuestLoyalty } from '@domain/customer/queries/useGuestLoyalty';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useStartATabAuth } from '@domain/orders/hooks/useStartATabAuth';
declare const window: any;

export const useConfigureCollectJS = () => {
  const { data: order } = useOrder();
  const { selectedPaymentMethod, selectedCard, addedPayment, isLoading } = useCheckoutState();
  const { isCreditCard, isMobilePayment } = usePaymentMethodValues();
  const { isXsDown } = useScreenSizeState();
  const checkoutDispatch = useCheckoutDispatch();
  const { colors } = useTheme();
  const { primary, danger, blackAlpha } = colors;
  const isSsr = useIsSsr();
  const { collectJs } = useGlobalUIState();
  const orderButtonIsHidden = useIsOrderButtonHidden();
  const placeOrderWithPaymentToken = usePlaceOrderWithPaymentToken();
  const callError = useError();
  const { sendEvent } = useSendEvent();
  const { data: giftCard } = useGiftCardBalance();
  const paymentVals = useCalculateMixedPayment();
  const { mutateAsync: signup } = useSignup();
  const { data: merchant } = useMerchant();
  const contactInfoDispatch = useContactInfoDispatch();
  const isLevelup = useIsLevelup();
  const validateOnCheckout = useValidateOnCheckout();
  const isStartATab = useIsStartATabModal();
  const { mutate: startATabAuth } = useStartATabAuth();
  const getMobileLoyalty = async (data: ICustomer) => {
    // For the levelup integration we don't actually wanna use the info
    // that comes from apple or google pay.
    if (isLevelup) return;

    try {
      contactInfoDispatch({
        type: 'SUBMIT',
        payload: data,
      });
      const response = await signup({ ...data, phone: data?.phoneNo });
      return { ...data, spotOnCustomerID: response?.customer_id };
    } catch {
      //eslint-disable-line no-empty
    }

    const response = await getGuestLoyalty(data?.email, merchant?.merchantId);
    return { ...data, spotOnCustomerID: response?.customer_id };
  };

  const collectCallback = async (response: ICollectJSCallbackResponse): Promise<void> => {
    if (isXsDown && !isMobilePayment) {
      document.getElementById('place-order-container')?.scrollIntoView();
    }
    checkoutDispatch({
      type: 'ADD_PAYMENT',
      payload: response,
    });
    const isMobile = response?.tokenType === 'googlePay' || response?.tokenType === 'applePay';

    if (isMobile || isStartATab) {
      try {
        await validateOnCheckout();
      } catch (e) {
        if (isStartATab) {
          checkoutDispatch({ type: 'REMOVE_PAYMENT' });
        }
        return;
      }

      if (isStartATab) {
        startATabAuth(response?.token);
        return;
      }
    }

    if (isMobile) {
      let userPhone = null;
      if (response?.wallet?.billingInfo?.phone) userPhone = response?.wallet?.billingInfo?.phone;
      else if (response?.wallet?.shippingInfo?.phone)
        userPhone = response?.wallet?.shippingInfo?.phone;
      if (userPhone.length === 11 && userPhone[0] !== '+') userPhone = '+' + userPhone;
      const userEmail = response?.wallet?.email;
      const firstName = response?.wallet?.billingInfo?.firstName;
      const lastName = response?.wallet?.billingInfo?.lastName;
      const mobileCustomer = {
        email: userEmail,
        phoneNo: userPhone,
        firstName: firstName,
        lastName: lastName,
      };
      if (!mobileCustomer.email || !mobileCustomer.phoneNo) {
        placeOrderWithPaymentToken(response?.token);
        return;
      }
      try {
        const customer = await getMobileLoyalty(mobileCustomer);
        await placeOrderWithPaymentToken(response?.token, customer);
      } catch {
        await placeOrderWithPaymentToken(response?.token, mobileCustomer);
      }
    }
  };

  const validationCallback = useCallback(
    (fieldName: EValidationFields, valid: boolean): void => {
      const type = valid
        ? 'REMOVE_COLLECTJS_INVALID_FIELD_NAME'
        : 'ADD_COLLECTJS_INVALID_FIELD_NAME';
      checkoutDispatch({ type, payload: fieldName });

      if (!valid) {
        checkoutDispatch({ type: 'COMPLETE_SUBMISSION' });
      }
    },
    [checkoutDispatch]
  );

  const timeoutCallback = useCallback((): void => {
    checkoutDispatch({ type: 'COMPLETE_SUBMISSION' });
    sendEvent(EAnalyticsEventNames.ADD_PAYMENT_INFO_FAIL);
    callError({
      error: new Error('CollectJS timeout error'),
      title: 'Error placing your order',
      description: 'There was a problem processing your payment, please try again',
      toastError: true,
    });
  }, [checkoutDispatch, callError, sendEvent]);

  const fieldsAvailableCallback = useCallback(
    (): void => checkoutDispatch({ type: 'SET_FIELDS_AVAILABLE', payload: true }),
    [checkoutDispatch]
  );
  const paymentLabel = `${merchant?.orgName ?? 'total'} via SpotOn`;
  const applePayTotalType = isStartATab ? 'pending' : 'final';
  const configureCollectJS = (price = '0.00') => {
    window.CollectJS.configure({
      variant: 'inline',
      styleSniffer: 'false',
      currency: 'USD',
      country: 'US',
      price: price,
      fields: {
        ccnumber: {
          selector: '#cjs-ccnumber',
          title: 'Card Number',
          placeholder: '0000 0000 0000 0000',
        },
        ccexp: {
          selector: '#cjs-ccexp',
          title: 'Card Expiration',
          placeholder: '00 / 00',
        },
        cvv: {
          display: 'show',
          selector: '#cjs-cvv',
          title: 'CVV Code',
          placeholder: '***',
        },
        googlePay: {
          selector: '#cjs-googlepay',
          billingAddressRequired: true,
          billingAddressParameters: {
            phoneNumberRequired: true,
            format: 'MIN',
          },
          emailRequired: true,
        },
        applePay: {
          selector: '#cjs-applepay',
          style: {
            height: '44px',
          },
          totalLabel: paymentLabel,
          requiredBillingContactFields: ['postalAddress'],
          contactFields: ['phone', 'email'],
          totalType: applePayTotalType,
        },
      },
      googleFont: 'Poppins:400',
      customCss: {
        'border-color': blackAlpha[600],
        color: blackAlpha[800],
        'border-style': 'solid',
        'border-width': '1.5px',
        'font-size': '16px',
        'font-weight': '400',
        height: '44px',
        'line-height': '1.5',
        padding: '0 16px',
        'font-family': 'Poppins',
        'outline-style': 'none',
        'border-radius': '0.375rem',
      },
      validCss: {
        'border-color': primary[600],
        color: blackAlpha[800],
        'border-style': 'solid',
        'border-width': '1.5px',
        'font-size': '16px',
        height: '44px',
        'line-height': '1.5',
        'outline-style': 'none',
        padding: '0 16px',
      },
      invalidCss: {
        'border-color': danger[300],
        color: blackAlpha[800],
        'border-style': 'solid',
        'border-width': '1.5px',
        'font-size': '16px',
        height: '44px',
        'line-height': '1.5',
        'outline-style': 'none',
        padding: '0 16px',
      },
      focusCss: {
        'border-color': primary[200],
        color: blackAlpha[800],
        'border-style': 'solid',
        'border-width': '2px',
        'font-size': '16px',
        height: '44px',
        'line-height': '1.5',
        padding: '0 16px',
        'outline-style': 'none',
        'box-shadow': 'none',
      },
      placeholderCss: {
        'font-family': 'Poppins, sans-serif',
        'font-size': '16px',
        color: blackAlpha[350],
      },
      callback: collectCallback,
      validationCallback: validationCallback,
      fieldsAvailableCallback: fieldsAvailableCallback,
      timeoutCallback: timeoutCallback,
    });
  };

  useEffect(() => {
    if (!isSsr) {
      if (!collectJs) {
        return;
      }

      const element = document.getElementById('cjs-ccnumber');
      const childrenExist = element?.childNodes?.length > 0;

      if (childrenExist || (isCreditCard && selectedCard)) return;

      checkoutDispatch({
        type: 'SET_FIELDS_AVAILABLE',
        payload: false,
      });
      const authorizationAmount = isStartATab
        ? (Math.round(+paymentVals.amount * 100) / 100).toFixed(2)
        : (Math.round((+paymentVals.amount + +paymentVals.tipAmount) * 100) / 100).toFixed(2);

      configureCollectJS(authorizationAmount.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order?.displayTotalAmount,
    collectJs,
    selectedPaymentMethod,
    isSsr,
    selectedCard,
    orderButtonIsHidden,
    addedPayment,
    isLoading,
    giftCard,
    paymentVals?.amount,
    paymentVals?.tipAmount,
  ]);

  return configureCollectJS;
};
