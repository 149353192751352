import numbro from 'numbro';

export function splitBill({
  guests,
  total,
  changedGuestIndex,
  changedAmount,
}: {
  guests: SplitBillGuest[];
  total: number;
  changedGuestIndex: number;
  changedAmount: number;
}): SplitBillGuest[] {
  let unlockedAmount = total;
  let unlockedLength = 0;

  // Prevents making amounts with more than two decimals
  changedAmount = Number(changedAmount.toFixed(2));

  const guestsCopy = guests.map((g) => ({ ...g }));

  guestsCopy.forEach((splitPayment, i) => {
    if (splitPayment.isLocked) {
      unlockedAmount = numbro(unlockedAmount).subtract(splitPayment.amount).value();
    } else if (i !== changedGuestIndex) {
      unlockedLength++;
    }
  });

  if (unlockedAmount < changedAmount || unlockedLength === 0) {
    changedAmount = unlockedAmount;
  }

  unlockedAmount = numbro(unlockedAmount).subtract(changedAmount).value();

  guestsCopy.forEach((guest, index) => {
    if (index === changedGuestIndex) {
      guest.amount = changedAmount;
    } else if (!guest.isLocked) {
      const a = Number(numbro(unlockedAmount).divide(unlockedLength).value().toFixed(2));
      unlockedAmount = numbro(unlockedAmount).subtract(a).value();
      unlockedLength--;
      guest.amount = a;
    }
  });

  return guestsCopy;
}
