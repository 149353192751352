import { IFeatureFlags } from '@olo-web/types/featureFlags.interface';
import { isFeatureEnabledForMerchant } from './isFeatureEnabledForMerchant';

export const filterFeatureFlags = (featureFlags: IFeatureFlags, merchantId: string) => {
  const processedFeatureFlags = {};

  for (const key in featureFlags) {
    const value = featureFlags[key];

    if (
      typeof value === 'object' &&
      value !== null &&
      'allow' in value &&
      'deny' in value &&
      'on' in value
    ) {
      processedFeatureFlags[key] = isFeatureEnabledForMerchant({
        allowList: value.allow,
        denyList: value.deny,
        featureEnabled: value.on,
        merchantId,
      });
    } else {
      processedFeatureFlags[key] = value;
    }
  }

  return processedFeatureFlags;
};
