const textStyles = {
  // Typically use <Heading /> for styles below
  drama1: {
    fontSize: 48,
    lineHeight: '72px',
    fontWeight: '700',
  },
  drama2: {
    fontSize: 36,
    lineHeight: '54px',
    fontWeight: '700',
  },
  h1: {
    fontSize: 30,
    lineHeight: '45px',
    fontWeight: '600',
  },
  h2: {
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: '600',
  },
  h3: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: '600',
  },
  h4: {
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: '600',
  },
  // Typically use <Text /> for styles below
  subtitle1: {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: '500',
  },
  subtitle2: {
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: '500',
  },
  p: {
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: '400',
  },
  pBold: {
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: '600',
    letterSpacing: '1%',
  },
  label: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: '400',
  },
  link: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: '600',
    letterSpacing: '1%',
    textDecoration: 'underline',
  },
};

export default textStyles;
