import { useRouter } from 'next/router';
import { serializeToCamelCase } from '@olo-web/utils';
import { useCustomerState } from '@olo-web/client-state';
import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { IUseJoinCustomerWithMerchantProps } from '../types';
import { configHeaders } from '../functions';
import { IUtmParameters } from '@olo-web/types/extraParameters.interface';

export const joinCustomerWithMerchant = async ({
  customerId,
  merchantId,
  sessionId,
  sessionSecret,
  loginTimestamp,
  implicitJoin,
  utmParams,
}: IUseJoinCustomerWithMerchantProps): Promise<ICustomerJoinMerchant> => {
  try {
    const url = `/api/customers/${customerId}/join-merchant/${merchantId}`;
    const body = {
      implicit_join: implicitJoin,
      include_only_redeemable_promotions: true,
      extra: utmParams ? { utm_parameters: utmParams } : null,
    };
    const headers = configHeaders({
      customerId,
      sessionId,
      sessionSecret,
      loginTimestamp,
    });
    const response = await axios.post(url, body, { headers });
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

type T = {
  customerId?: string;
  merchantId?: string;
  implicitJoin?: boolean;
  utmParams?: IUtmParameters;
};

export const useJoinCustomerWithMerchant = (
  props?: T
): UseQueryResult<ICustomerJoinMerchant, AxiosError> => {
  const { customerId, merchantId, implicitJoin = true, utmParams } = props || {};
  const { customerId: cIdFromState, sessionId, sessionSecret, loginTimestamp } = useCustomerState();
  const router = useRouter();
  const { merchantId: mIdFromRouter } = router.query;

  const cId = customerId || cIdFromState;
  const mId = merchantId || (mIdFromRouter as string);

  const isEnabled = !!(mId && cId && sessionId && sessionSecret);

  return useQuery(
    ['join', cId, mId],
    () =>
      joinCustomerWithMerchant({
        customerId: cId,
        merchantId: mId,
        sessionId,
        sessionSecret,
        loginTimestamp,
        implicitJoin,
        utmParams,
      }),
    {
      staleTime: 1000000,
      enabled: isEnabled,
      select: serializeToCamelCase,
      retry: 2,
    }
  );
};
