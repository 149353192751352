import React, { FC } from 'react';
import NextLink from 'next/link';
import { Link as ChakraLink, useStyleConfig } from '@chakra-ui/react';
import { ILinkProps } from './types';

// TODO: ask about icon library, (ie, ExternalLinkIcon)
// TODO: ask for help with typing

const NextComposed = React.forwardRef(function NextComposed(props: any, ref: any) {
  const { href, ...other } = props;

  return <NextLink href={href} ref={ref} {...other} />;
});

export const Link: FC<ILinkProps | any> = (props) => {
  const {
    href,
    className,
    innerRef,
    naked,
    style,
    children,
    size,
    variant,
    colorScheme,
    ...other
  } = props;

  const defaultStyle = { textDecoration: 'none', ...style };
  const styles = useStyleConfig('Link', { size, variant, colorScheme });

  if (/https|https|tel|mailto/.test(href))
    return (
      <ChakraLink
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...other}
        style={defaultStyle}
        isExternal
        sx={styles}
      >
        {children}
      </ChakraLink>
    );

  if (naked)
    return (
      <NextComposed className={className} ref={innerRef} href={href} {...other}>
        {children}
      </NextComposed>
    );

  return (
    <NextLink href={href}>
      <ChakraLink className={className} ref={innerRef} {...other} style={defaultStyle} sx={styles}>
        {children}
      </ChakraLink>
    </NextLink>
  );
};
