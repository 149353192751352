import { createFriendlyErrorMessage } from '@olo-web/utils/common/functions';
import { useToast } from '@olo-web/utils/common/hooks';
import { UseToastOptions } from '@chakra-ui/toast';
import { trackError } from '@olo-web/utils/metrics/datadog';

export interface IUseErrorProps extends UseToastOptions {
  error: any;
  logError?: boolean;
  toastError?: boolean;
  toastId?: string;
  isCloseable?: boolean;
}

type T = (props: IUseErrorProps) => void;
declare const window: any;

export const useError = (): T => {
  const { toast, notify } = useToast();

  return ({
    error,
    logError = true,
    toastError = false,
    description,
    title = 'Something went wrong',
    isClosable = true,
    status = 'error',
    variant = 'left-accent',
    toastId,
    ...rest
  }: IUseErrorProps) => {
    if (!error) return;
    if (logError) console.error(error);
    if (window?.FS) window.FS.log('error', error?.message);
    trackError(error);
    const errorText = createFriendlyErrorMessage(error) as string;
    if (toastError && !toast?.isActive(toastId)) {
      notify({
        title: title,
        description: description || errorText,
        status,
        isClosable,
        variant,
        id: toastId ?? null,
        ...rest,
      });
    }
  };
};
