import { createStore } from '@olo-web/utils/common/store/createStore';
import { Msg, Sub, NatsConnection } from 'nats.ws';
interface INatsState {
  natsConnection: NatsConnection | null;
  topic: string | null;
  subscription: Sub<Msg> | null;
  messages: Msg[];
  natsConnectionStarted: boolean;
}

const initialState: INatsState = {
  natsConnection: null,
  topic: null,
  subscription: null,
  messages: [],
  natsConnectionStarted: false,
};
type TTopicPayload = {
  topic: string;
};

type TStoreSubPayload = {
  natsConnection: NatsConnection;
  subscription: Sub<Msg>;
};

type TSaveMsgPayload = {
  natsConnection: NatsConnection;
  subscription: Sub<Msg>;
};

type TRemoveSubAction = TEmptyAction<'REMOVE_SUBSCRIPTION'>;
type TSetTopicAction = IStandardAction<'SET_TOPIC', TTopicPayload>;
type TStoreSubAction = IStandardAction<'STORE_SUBSCRIPTION', TStoreSubPayload>;
type TSaveMessageAction = IStandardAction<'SAVE_MESSAGE', TSaveMsgPayload>;
type TSetStartedAction = IStandardAction<'SET_STARTED', boolean>;

type TNatsContextAction =
  | TSetStartedAction
  | TSaveMessageAction
  | TStoreSubAction
  | TSetTopicAction
  | TRemoveSubAction;

const reducer = (state: INatsState, action: TNatsContextAction) => {
  switch (action.type) {
    case 'STORE_SUBSCRIPTION':
      return {
        ...state,
        subscription: action.payload.subscription,
        natsConnection: action.payload.natsConnection,
      };
    case 'SET_TOPIC':
      return {
        ...state,
        topic: action.payload.topic,
      };
    case 'REMOVE_SUBSCRIPTION':
      return { ...state, messages: [], topic: null };
    case 'SAVE_MESSAGE':
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case 'SET_STARTED':
      return { ...state, natsConnectionStarted: action.payload };
    default:
      return state;
  }
};

export const [NatsProvider, useNatsDispatch, useNatsState] = createStore(reducer, initialState, {
  name: 'nats',
});
