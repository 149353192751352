import { datadogRum } from '@datadog/browser-rum';
import dayjs from 'dayjs';
import { isKnownError } from '@olo-web/utils/common/functions';
import { getCurrentSessionUrl } from 'react-fullstory';

const buildSha = process.env.NEXT_PUBLIC_BUILD_COMMIT_SHA || '';
const datadogEnv = process.env.NEXT_PUBLIC_DATADOG_ENV || '';
const datadogToken = process.env.NEXT_PUBLIC_DATADOG_TOKEN || '';
const datadogAppId = process.env.NEXT_PUBLIC_DATADOG_APPID || '';
if (!(process.env.NODE_ENV === 'test')) {
  datadogRum.init({
    applicationId: datadogAppId,
    clientToken: datadogToken,
    site: 'datadoghq.com',
    service: 'olo-web-refactor',
    env: datadogEnv,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: buildSha,
    sampleRate: 5,
    trackInteractions: true,
    allowedTracingOrigins: [/https:\/\/.*order-api.spoton\.com/, /https:\/\/.*\.spoton\.sh/],
  });
}
export interface Context {
  source: string;
  payload: any;
}

const getFullStorySessionUrl = async () => {
  try {
    const sessionUrl = getCurrentSessionUrl(true);
    return sessionUrl || '';
  } catch (err) {
    console.error(err);
    return '';
  }
};

export const trackError = async (err: Error, errorContext?: Context): Promise<void> => {
  if (isKnownError(err.message)) {
    return;
  }

  const fullStorySessionUrl = await getFullStorySessionUrl();

  datadogRum.addError(err, {
    ...errorContext,
    ts: dayjs().toISOString(),
    fullStorySessionUrl,
  });
};

export const trackEvent = async (actionName: string, actionContext: Context): Promise<void> => {
  const fullStorySessionUrl = await getFullStorySessionUrl();

  datadogRum.addAction(actionName, {
    ...actionContext,
    ts: dayjs().toISOString(),
    fullStorySessionUrl,
  });
};

type TDDIdentify = {
  email?: string;
  id?: string;
  name?: string;
};

export const identifyUser = ({ email, id, name }: TDDIdentify) => {
  datadogRum.setUser({ email, id, name });
};

export const removeUser = () => datadogRum.removeUser();
