import { EOrderTypes } from '@olo-web/types/enums';

export const getDefaultOrderTypeId = (data: IMerchant): string => {
  const deliveryOrderType = data?.orderTypeIds?.[EOrderTypes.DOORDASH_DRIVE]
    ? EOrderTypes.DOORDASH_DRIVE
    : data?.orderTypeIds?.[EOrderTypes.DELIVERY]
    ? EOrderTypes.DELIVERY
    : null;

  const deliveryOrderTypeId = data?.orderTypeIds?.[deliveryOrderType] || null;
  const pickupOrderTypeId = data?.orderTypeIds?.[EOrderTypes.PICKUP] || null;

  const deliveryUnavailable = !data?.orderTypes?.[deliveryOrderType]?.asapOrderDateTime;
  const pickupUnavailable = !data?.orderTypes?.pickup?.asapOrderDateTime;

  if (!deliveryOrderTypeId || deliveryUnavailable) return pickupOrderTypeId;
  if (!pickupOrderTypeId || pickupUnavailable) return deliveryOrderTypeId;
  return data?.deliveryByDefaultEnabled ? deliveryOrderTypeId : pickupOrderTypeId;
};
