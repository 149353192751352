import { useMerchantGroup, useMerchant } from '@domain/merchants/queries';
import { useMutation, MutationOptions } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useSavedDineInContextState } from '@olo-web/client-state';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useToast } from '@olo-web/utils/common/hooks';

export const sendItems = async (
  orderId: string,
  merchantId: string,
  guestId: string
): Promise<IOrder> => {
  try {
    const { data } = await axios.post(`/api/merchants/${merchantId}/orders/${orderId}/send`, {
      guestId,
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useSendItems = (options?: MutationOptions<any, AxiosError>) => {
  const { data: group } = useMerchantGroup();
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const savedDineInState = useSavedDineInContextState();
  const updateOrderInCache = useUpdateOrderInCache();
  const { notify } = useToast();
  const merchId = merchant?.merchantId || group?.eGiftCardMerchantId;

  const mutation = useMutation(() => sendItems(order.id, merchId, savedDineInState?.guest?.id), {
    ...options,
    onSuccess: (data) => {
      const order = data;
      updateOrderInCache(order);
    },
    onError: (error) => {
      if (merchant?.dineinPayLaterEnabled)
        notify({
          status: 'error',
          description:
            error?.message === 'The kitchen is currently closed'
              ? "We're unable to send your items because the kitchen is currently closed."
              : "We're unable to send your items",
          variant: 'left-accent',
          position: 'top',
          isClosable: true,
        });
    },
  });
  return mutation;
};
