import { usePaymentMethodValues } from '@olo-web/domain/payments/hooks';
import { useIsLevelup } from '@olo-web/utils/common/hooks';
import { useIsLoggedIn } from '@olo-web/utils/common/hooks/useIsLoggedIn';
import { useCheckoutState } from '@olo-web/client-state';

export const useShouldSaveCard = (): boolean => {
  const { isCreditCard } = usePaymentMethodValues();
  const isLevelup = useIsLevelup();
  const isLoggedIn = useIsLoggedIn();
  const { saveCard } = useCheckoutState();

  return saveCard && isLoggedIn && isCreditCard && !isLevelup;
};
