import { IOrderTips } from '@olo-web/client-state';
import { ECustomTipsMode, ESpecialTipsValue } from '@olo-web/domain/orders/types';

export function calculateCustomTipAmount(total: number, tipsData: IOrderTips): number {
  if (!tipsData) return 0;
  if (tipsData.customTipsMode === ECustomTipsMode.AMOUNT) {
    return tipsData.customTipsAmount ? +tipsData.customTipsAmount : 0;
  }
  if (tipsData.customTipsMode === ECustomTipsMode.PERCENTAGES) {
    return tipsData.customTipsPercentage
      ? parseFloat((total * (+tipsData.customTipsPercentage / 100)).toFixed(2))
      : 0;
  }
  return 0;
}

export function calculateTipAmount(total: number, tipsData: IOrderTips): number {
  if (!tipsData) return 0;
  if (tipsData.selectedTips === ESpecialTipsValue.NONE) {
    return 0;
  } else if (tipsData.selectedTips === ESpecialTipsValue.CUSTOM) {
    return calculateCustomTipAmount(total, tipsData);
  } else {
    return parseFloat((total * (+tipsData.selectedTips / 100)).toFixed(2));
  }
}
