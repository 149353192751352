import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { IOrderTips, useOrderTipsState } from '@olo-web/client-state';
import { ECustomTipsMode } from '@olo-web/domain/orders/types';

const initialState: IOrderTips = {
  selectedTips: null,
  customTipsMode: ECustomTipsMode.AMOUNT,
  customTipsAmount: '',
  customTipsPercentage: '',
};

export const useCurrentOrderTips = (): IOrderTips => {
  const orderTips = useOrderTipsState();
  const router = useRouter();
  const { merchantId } = router.query;

  return useMemo(() => {
    return orderTips[merchantId as string] || initialState;
  }, [orderTips, merchantId]);
};
