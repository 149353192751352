import { useCustomThemeDraftState } from '@olo-web/client-state';
import { useMerchantTheme, useMerchantGroup } from '@domain/merchants/queries';
const spotOnFaviconUrl =
  'https://firebasestorage.googleapis.com/v0/b/android-spoton-mobile-192322.appspot.com/o/favicon-32x32.png?alt=media&token=24ab335c-dcba-406f-bc60-7f0115cc381b';

export const useMerchantImages = () => {
  const { data: theme } = useMerchantTheme();
  const { data: group } = useMerchantGroup();
  const { logoUrl, backgroundUrl, faviconUrl } = useCustomThemeDraftState();

  return {
    logoUrl: logoUrl || theme?.logoUrl || group?.logoUrl || group?.mobileBackgroundUrl,
    logoAlt: group?.name,
    backgroundUrl: backgroundUrl || theme?.backgroundUrl || group?.backgroundUrl,
    backgroundAlt: group?.name,
    faviconUrl:
      faviconUrl || theme?.faviconUrl || theme?.logoUrl || group?.logoUrl || spotOnFaviconUrl,
  };
};
