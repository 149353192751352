import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import queryStringHelper from 'query-string';
import { useMerchantPath } from '@domain/merchants/hooks/useMerchantPath';
import { getQueryString } from '../functions';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';

type TQueryParams = Record<string, string>;

export const useGoToCheckoutPage = () => {
  const router = useRouter();
  const merchantPath = useMerchantPath();
  const queryString = getQueryString();
  const isDinein = useIsDineIn();

  const query = useMemo(() => {
    const innerQuery = queryStringHelper.parse(queryString);
    if (isDinein) {
      innerQuery.orderType = 'dinein';
    }

    return innerQuery;
  }, [queryString, isDinein]);
  const pathname = `${merchantPath}/checkout`;

  return useCallback(
    (queryParamsArg: TQueryParams = {}) => {
      router.push(
        {
          pathname,
          query: { ...query, ...queryParamsArg },
        },
        undefined,
        { shallow: true }
      );
    },
    [pathname, query, router]
  );
};
