import { useCallback, useEffect } from 'react';
import { connect, JSONCodec } from 'nats.ws';
import { NATS_URL } from '@olo-web/utils/common/constants';
import { useNatsDispatch, useNatsState } from '@olo-web/client-state/natsContext';
import { getEnvironment } from '@olo-web/utils/common/functions';
import { useNatsEventsHandlers } from '@olo-web/utils/common/hooks/useNatsEventsHandlers';
import { ENatsContextActions } from '@olo-web/types/enums/natsContextActions.enum';
const env = getEnvironment();
const timeStamps = {};
const init = async () => {
  const natsConnection = await connect({
    servers: NATS_URL,
    pingInterval: 2500,
    maxPingOut: 2,
    reconnect: false,
    timeout: 10000,
    waitOnFirstConnect: false,
    user: 'noauth',
    pass: 'noauth',
  });

  return natsConnection;
};

async function listenForData(sub: any, decode: (a: Uint8Array) => unknown, callback: any) {
  for await (const { data: rawData } of sub) {
    const data = decode(rawData) as any;
    const eventName = data?.event?.action;
    const lastUpdate = timeStamps[eventName] && new Date(timeStamps[eventName]).getTime();
    const nextUpdate = data?.timestamp && new Date(data?.timestamp).getTime();
    if (!lastUpdate || lastUpdate < nextUpdate) {
      timeStamps[eventName] = nextUpdate;
      callback(data);
    }
  }
}

export const useUnsubscribeToNats = () => {
  const natsDispatch = useNatsDispatch();
  const unsubscribeToNats = async () => {
    natsDispatch({ type: ENatsContextActions.REMOVE_SUBSCRIPTION });
  };
  return { unsubscribeToNats };
};

export const useNatsConnectionListener = () => {
  const {
    natsConnection: connectionFromState,
    topic: topicFromState,
    natsConnectionStarted,
  } = useNatsState();
  const natsDispatch = useNatsDispatch();
  useNatsEventsHandlers();
  useEffect(() => {
    const openConnection = async () => {
      try {
        //Here we are initiating the connection then immediately subscribing to the provided topic
        // and listening for messages.
        const connectionToNats = await init();
        const sub = await connectionToNats?.subscribe(topicFromState);
        const { decode } = JSONCodec();
        natsDispatch({
          type: ENatsContextActions.STORE_SUBSCRIPTION,
          payload: { subscription: sub, natsConnection: connectionToNats },
        });
        await listenForData(sub, decode, (data) => {
          if (!data) return;
          natsDispatch({ type: ENatsContextActions.SAVE_MESSAGE, payload: data });
        });
        natsDispatch({ type: ENatsContextActions.SET_STARTED, payload: false });
      } catch (err) {
        await connectionFromState?.close();
        natsDispatch({ type: ENatsContextActions.SET_STARTED, payload: false });
        console.error(err);
      }
    };

    //If we have a topic and we haven't started the connection process, start it.
    if (topicFromState !== null && !natsConnectionStarted) {
      natsDispatch({ type: ENatsContextActions.SET_STARTED, payload: true });
      openConnection();
      //If the connection process previously started but the topic is null, we should close the connection
    } else if (topicFromState === null && natsConnectionStarted) {
      natsDispatch({ type: ENatsContextActions.SET_STARTED, payload: false });
      connectionFromState?.close();
    }
  }, [topicFromState, natsConnectionStarted, connectionFromState, natsDispatch]);
};

export const useSubscribeToNats = () => {
  const { topic: topicFromState, subscription } = useNatsState();
  const natsDispatch = useNatsDispatch();
  const { unsubscribeToNats } = useUnsubscribeToNats();
  //This function just sets the top to the natsContext which triggers the above useEffect
  const subscribe = useCallback(
    async (topic) => {
      if (topic === topicFromState) return;
      if (!!subscription && topic !== topicFromState) {
        unsubscribeToNats();
      }
      natsDispatch({ type: ENatsContextActions.SET_TOPIC, payload: { topic } });
    },
    [natsDispatch, subscription, topicFromState, unsubscribeToNats]
  );

  return {
    subscribe,
  };
};

export const END_POINTS = {
  tableNotification: (merchantId: string, tableId: string): string =>
    `${env}.pos.olo.merchant.${merchantId}.table.${tableId}.>`,
  orderNotification: (merchantId: string, orderId?: string): string =>
    `${env}.pos.olo.merchant.${merchantId}.order.${orderId}`,
};
