import { extendTheme } from '@chakra-ui/react';
// import config from './config';
import styles from './styles';
import fonts from './fonts';
import components from './components';
import breakpoints from './breakpoints';
import colors from './colors';
import textStyles from './textStyles';
import fontSizes from './fontSizes';
// import layerStyles from './layerStyles';

const overrides = {
  // config,
  styles,
  fonts,
  components,
  breakpoints,
  colors,
  textStyles,
  fontSizes,
  // layerStyles,
};

const theme = extendTheme(overrides);

export default theme;
