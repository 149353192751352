import { useCheckoutState, useCustomerState } from '@olo-web/client-state';
import { useCheckout } from '@olo-web/domain/orders/mutations/useCheckout';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useCallback } from 'react';
import { useCustomerContactInfo } from './useCustomerContactInfo';
import { useDeliverServiceData } from './useDoorDashDeliveryData';

export const useCompleteOrderWithCash = () => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const { mutateAsync: checkout } = useCheckout();
  const customer = useCustomerContactInfo();
  const { orderNote, gclid } = useCheckoutState();
  const { customerId, sessionId, sessionSecret } = useCustomerState();
  const deliveryServiceData = useDeliverServiceData();

  return useCallback(() => {
    return checkout({
      merchantId: merchant?.merchantId,
      orderId: order?.id,
      orderNotes: orderNote,
      customer,
      paymentInfo: {},
      conversionId: gclid,
      conversionType: gclid ? 'google' : null,
      deliveryAddress: {
        address1: deliveryServiceData?.deliveryInfo?.dropoffAddress?.street,
        address2: deliveryServiceData?.deliveryInfo?.dropoffAddress?.unit,
        city: deliveryServiceData?.deliveryInfo?.dropoffAddress?.city,
        state: deliveryServiceData?.deliveryInfo?.dropoffAddress?.state,
        zip: deliveryServiceData?.deliveryInfo?.dropoffAddress?.zipCode,
      },
      customerSessionDetails: {
        customerId,
        sessionId,
        sessionSecret,
      },
    });
  }, [
    checkout,
    deliveryServiceData,
    customer,
    merchant?.merchantId,
    order?.id,
    orderNote,
    gclid,
    customerId,
    sessionSecret,
    sessionId,
  ]);
};
