import { useMemo } from 'react';
import { useCombinedTheme } from './useCombinedTheme';
import defaultTheme from '@olo-web/theme';

export const useFontsToLoad = () => {
  const theme = useCombinedTheme();

  return useMemo(() => {
    const fonts = [];
    Object.keys(defaultTheme.fonts).forEach((key) => {
      if (defaultTheme.fonts[key] !== theme.fonts[key]) {
        const font =
          typeof theme.fonts[key] === 'string' ? (theme.fonts[key] as string).split(',')[0] : '';
        if (!fonts.includes(font)) fonts.push(font);
      }
    });
    return fonts;
  }, [theme]);
};
