export enum EDateTimeFormat {
  DATE_MONTH_YEAR = 'ddd, MMMM D, YYYY',
  TWELVE_HOUR_TIME = 'h:mm a',
  TWENTY_FOUR_HOUR_TIME = 'HH:mm',
  DAY_OF_WEEK = 'dddd',
  YEAR_MONTH_DATE = 'YYYY-MM-DD',
  DATE_AND_TIME = 'lll',
  LONG_FORM = 'LLLL', // Thursday, October 15, 2020 2:13 PM
  LONG_FORM_WITHOUT_YEAR = 'dddd, MMMM D, h:mm A', // Thursday, October 15, 2:13 PM
  SHORTENED_FORM = 'llll', // Thu, Oct 15, 2020 2:13 PM
  LONG_DAY_MONTH = 'dddd, MMMM D',
  FULL_FORMAT = 'ddd, MMMM D, YYYY h:mm a',
  LONG_TIME_DISPLAY = 'LT', // 4:20 AM
  MONTH_AND_DAY = 'MMM D',
}
