import { useSavedDineInContextDispatch, useSavedOrderDispatch } from '@olo-web/client-state';
import { useRouter } from 'next/router';
import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import queryString from 'query-string';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';
import { useOrderQueryKey } from '../hooks/useOrderQueryKey';
import {
  useLevelupState,
  useLevelupDispatch,
  useSavedDineInContextState,
} from '@olo-web/client-state';

import { useCurrentMerchantSavedOrderId } from '@olo-web/utils/common/hooks/useCurrentMerchantSavedOrderId';
import { useIsOrderConfirmationPage } from '@olo-web/utils/common/hooks/useIsOrderConfirmationPage';
import { useIsOrderReceiptPage } from '@olo-web/utils/common/hooks/useIsOrderReceiptPage';
import { useIsScanAndPayPage } from '@olo-web/utils/common/hooks/useIsScanAndPayPage';
import { useIsLevelup } from '@olo-web/utils/common/hooks/useIsLevelup';
import { useTableNumFromQuery } from '@olo-web/utils/common/hooks/useTableNumFromQuery';
import { useOrderIdFeatureFlagEnabled } from '@olo-web/utils/common/hooks/useOrderIdFeatureFlagEnabled';

export const getOrder = async (merchantId: string, orderId: string): Promise<IOrder> => {
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}`;
    const { data } = await axios.get(url);

    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const getOrderByCheckId = async (merchantId: string, checkId: string): Promise<IOrder> => {
  const url = `/api/merchants/${merchantId}/orders`;
  const { data } = await axios.get(url, { params: { checkId } });
  return data;
};

export const getHistoricalOrder = async (orderId: string, checkId?: string): Promise<IOrder> => {
  const url = `/api/historical-orders/${orderId}`;
  const { data } = await axios.get(url, { params: { checkId } });
  return data.order;
};

export const getOrderAtTable = async (
  merchantId: string,
  tableIdOrNumber: string
): Promise<IOrder> => {
  try {
    const url = `/api/merchants/${merchantId}/tables/${tableIdOrNumber}`;
    const { data } = await axios.get(`${url}`);
    return data?.orders?.[0];
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const getLevelupOrder = async (
  levelupUserToken: string,
  levelupCheckCode: string
): Promise<IOrder> => {
  try {
    const url = `/api/levelup/orders?${queryString.stringify({
      userToken: levelupUserToken,
      checkCode: levelupCheckCode,
    })}`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useOrderId = (): string => {
  const {
    query: { orderId: routerOrderId },
  } = useRouter();
  const savedOrderId = useCurrentMerchantSavedOrderId();
  return routerOrderId ? (routerOrderId as string) : savedOrderId;
};

export const useCheckNumber = () => {
  const router = useRouter();
  const { checkNumber } = router.query;
  const { checkNumber: checkNumberFromState } = useLevelupState();

  return checkNumber || checkNumberFromState;
};

export const useOrder = (
  onSuccess?: (data: IOrder) => void,
  initialData?: IOrder,
  tableNumberArg?: string
): UseQueryResult<IOrder, AxiosError> => {
  const router = useRouter();
  const { merchantId, checkId, userToken } = router.query;
  const checkNumber = useCheckNumber();
  const orderId = useOrderId();
  const tableNum = useTableNumFromQuery();

  const isOrderConfirmationPage = useIsOrderConfirmationPage();
  const isOrderReceiptPage = useIsOrderReceiptPage();
  const isScanAndPayPage = useIsScanAndPayPage();
  const isDineIn = useIsDineIn();
  const savedDineInState = useSavedDineInContextState();
  const isLevelup = useIsLevelup();
  const levelupDispatch = useLevelupDispatch();
  const savedOrderDispatch = useSavedOrderDispatch();
  const isHistorical = isOrderConfirmationPage || isOrderReceiptPage;
  const orderIdFFEnabled = useOrderIdFeatureFlagEnabled();
  const shouldUseOrderId = orderIdFFEnabled;
  const { guestId: guestIdFromQuery } = router.query;
  const savedDineInContextDispatch = useSavedDineInContextDispatch();
  const savedDineinDispatch = useSavedDineInContextDispatch();

  // This identifier is used for the dine-in query key
  let identifier;

  if (shouldUseOrderId) {
    identifier = savedDineInState?.orderId || orderId || null;
  } else {
    identifier = tableNumberArg || savedDineInState?.table?.number || (tableNum as string);
  }

  const orderQueryKey: string[] = useOrderQueryKey(identifier ? { identifier } : undefined);

  const checkEnabled = (): boolean => {
    if (!orderQueryKey) {
      return false;
    } else if (isLevelup && !isHistorical) {
      return !!((userToken && checkNumber) || checkId);
    } else if (isDineIn && !orderId && !identifier) {
      return false;
    } else if (isDineIn) {
      return !!identifier;
    } else {
      return !!((orderId || checkId) && merchantId);
    }
  };

  const isEnabled = checkEnabled();
  return useQuery(
    orderQueryKey,
    () => {
      if (isHistorical) {
        return getHistoricalOrder(orderId, checkId as string);
      }

      if (isScanAndPayPage || checkId) {
        return getOrderByCheckId(merchantId as string, checkId as string);
      }

      if (isDineIn && !shouldUseOrderId) {
        return getOrderAtTable(merchantId as string, identifier);
      }

      if (isLevelup && !checkId) {
        return getLevelupOrder(userToken as string, checkNumber as string);
      }
      return getOrder(merchantId as string, orderId || identifier);
    },
    {
      staleTime: 1000000,
      enabled: isEnabled,
      retry: 0,
      initialData,
      onSuccess: (data) => {
        if (onSuccess) onSuccess(data);
        const guestFromQuery = data?.guests?.find((guest) => guest?.id === guestIdFromQuery);
        if (guestFromQuery && orderIdFFEnabled) {
          savedDineInContextDispatch({
            type: 'SAVE',
            payload: {
              guest: guestFromQuery,
              orderId: data?.id,
            },
          });
        }
      },
      onError: (error) => {
        if (error?.message === 'Order Not Found' || error?.response?.status === 404) {
          savedOrderDispatch({
            type: 'DISCARD_ID',
            payload: { merchantId: merchantId as string, orderId },
          });
          savedDineinDispatch({
            type: 'RESET',
          });
        }

        if (isLevelup) {
          levelupDispatch({
            type: 'LEVELUP_ERROR',
            payload: error?.message,
          });
        }
      },
    }
  ) as UseQueryResult<IOrder, AxiosError>;
};
