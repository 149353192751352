import axios, { AxiosError } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { IUseAddUpdateAddressesProps } from '..';
import { serializeToSnakeCase } from '../../../utils';

export const addUpdateAddresses = async ({
  customerId,
  addresses,
}: IUseAddUpdateAddressesProps): Promise<any> => {
  try {
    const url = `/api/customers/${customerId}/add-update-address`;
    const body = serializeToSnakeCase(addresses);

    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useAddUpdateAddresses(
  options?: MutationOptions<any, AxiosError, IUseAddUpdateAddressesProps>
) {
  return useMutation(addUpdateAddresses, options);
}
