import { theme } from '@chakra-ui/react';
const defaultStyle = theme.components.Drawer;

const Drawer = {
  ...defaultStyle,
  parts: ['drawer', 'footer'],
  baseStyle: {
    overlay: {
      bg: 'blackAlpha.400',
      zIndex: 'modal',
    },
    footer: {
      padding: '16px 16px calc(8px + env(safe-area-inset-bottom, 8px)) 16px',
    },
  },
};

export default Drawer;
