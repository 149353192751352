import { getDefaultOrderTypeId } from './getDefaultOrderTypeId';
import { EOrderTypes } from '@olo-web/types/enums';
import {
  createLocationString,
  isOrderingAvailable,
  getOrderTypesAvailableAnalyticsString,
  getDefaultOrderType,
} from '.';
import omit from 'lodash/omit';

const removeDoorDashDriveOrderType = (merchant: IMerchant): IMerchant => {
  const orderTypeIds = omit(merchant?.orderTypeIds || {}, [
    EOrderTypes.DOORDASH_DRIVE,
  ]) as TOrderTypeIds;
  const orderTypes = omit(merchant?.orderTypes || {}, [EOrderTypes.DOORDASH_DRIVE]);
  return {
    ...merchant,
    orderTypeIds,
    orderTypes,
  };
};

export const configureMerchantData = (
  data: IMerchant,
  disallowDoorDashDriveOrders?: boolean
): IUseMerchantData => {
  let merchant = { ...data };
  delete merchant.schedule;

  if (disallowDoorDashDriveOrders) {
    merchant = removeDoorDashDriveOrderType(merchant);
  }

  return {
    ...merchant,
    locationString: createLocationString(merchant),
    pickupOrderType: merchant?.orderTypes?.[EOrderTypes.PICKUP] || null,
    hasDeliveryService: !!merchant?.orderTypes?.[EOrderTypes.DOORDASH_DRIVE],
    deliveryOrderType:
      merchant?.orderTypes?.[EOrderTypes.DOORDASH_DRIVE] ||
      merchant?.orderTypes?.[EOrderTypes.DELIVERY] ||
      null,
    defaultOrderTypeId: getDefaultOrderTypeId(merchant),
    defaultOrderType: getDefaultOrderType(merchant),
    isOrderingAvailable: isOrderingAvailable(merchant),
    parsedOrderTypeIds: {
      delivery:
        merchant?.orderTypeIds?.[EOrderTypes.DOORDASH_DRIVE] ??
        merchant?.orderTypeIds?.[EOrderTypes.DELIVERY] ??
        null,
      pickup: merchant?.orderTypeIds?.[EOrderTypes.PICKUP] ?? null,
      dinein: merchant?.orderTypeIds?.[EOrderTypes.DINE_IN] ?? null,
    },
    orderTypesAvailableAnalyticsString: getOrderTypesAvailableAnalyticsString(merchant),
  };
};

export const configureMerchantGroupData = (
  data: IUseMerchantGroup,
  disallowDoorDashDriveOrders?: boolean
) => ({
  ...data,
  merchants: data?.merchants?.map((m) => configureMerchantData(m, disallowDoorDashDriveOrders)),
});
