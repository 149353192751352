export const isSubItemGroupValid = (sig: ISubItemGroupDisplay): boolean => {
  let selected = 0,
    validAndSelected = 0;

  sig.items.forEach((s: ISubMenuItemDisplay) => {
    if (s.isSelected) {
      const inc = s.qty || 1;
      selected = selected + inc;
      if (s.isValid) validAndSelected = validAndSelected + inc;
    }
  });

  if (selected < sig.min || selected > validAndSelected) return false;

  return true;
};
