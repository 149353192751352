import { IOrderDiscount } from '@domain/orders/types';
import {
  ILoyaltyItem,
  IPromoCode,
  IPromoCodeDiscount,
} from '@olo-web/types/loyaltyReward.interface';

export const isOfferARewardOrDeal = (
  offer: IOrderDiscount | ILoyaltyItem | IPromoCodeDiscount | IPromoCode | string
): boolean => {
  if (typeof offer === 'string') return false;

  if ('promotionType' in offer) {
    return offer.promotionType <= 5 && offer.promotionType >= 2;
  }

  return Object.prototype.hasOwnProperty.call(offer, 'spotOnRewardId');
};
