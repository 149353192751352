import { createStore } from '@olo-web/utils/common/store';

interface ILevelupState {
  isLevelup: boolean;
  checkNumber: string | null;
  checkId: string | null;
  error: any;
}

type TUpdateCheckNumber = IStandardAction<'LEVELUP_UPDATE_CHECK_NUMBER', string>;
type TUpdateIsLevelup = IStandardAction<'LEVELUP_UPDATE_IS_LEVELUP', boolean>;
type TUpdateCheckId = IStandardAction<'LEVELUP_UPDATE_CHECK_ID', string>;
type TUpdateError = IStandardAction<'LEVELUP_ERROR', any>;

type TLevelupAction = TUpdateIsLevelup | TUpdateCheckNumber | TUpdateCheckId | TUpdateError;

const initialState: ILevelupState = {
  isLevelup: false,
  checkNumber: null,
  checkId: null,
  error: null,
};

const reducer = (state: ILevelupState, action: TLevelupAction): ILevelupState => {
  switch (action.type) {
    case 'LEVELUP_UPDATE_IS_LEVELUP':
      return { ...state, isLevelup: action.payload };
    case 'LEVELUP_UPDATE_CHECK_NUMBER':
      return { ...state, checkNumber: action.payload };
    case 'LEVELUP_UPDATE_CHECK_ID':
      return { ...state, checkId: action.payload };
    case 'LEVELUP_ERROR':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export const [LevelupContextProvider, useLevelupDispatch, useLevelupState] = createStore<
  ILevelupState,
  TLevelupAction
>(reducer, initialState, {
  name: 'levelup',
  persist: 'session',
});
