import { ECustomThemeViews } from '@olo-web/types/enums';
import { EFirestoreCollections } from './../../../types/enums/firestoreCollections.enum';
import { FirebaseError } from 'firebase-admin';
import { firestore } from '../../../utils/google/firebase';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { IMerchantTheme } from '@olo-web/types/merchantTheme.interface';

type T = {
  theme: IMerchantTheme;
  docId: string;
  // userId: string; // a reminder to add an updatedBy
};

export const updateMerchantTheme = async ({ theme, docId }: T): Promise<void> => {
  try {
    // if (!docId) throw new Error('No merchant theme document id provided');
    // TODO: Add updatedAt, updatedBy to body... Then make sure to render

    return await firestore()
      .collection(EFirestoreCollections.MERCHANT_THEME)
      .doc(docId)
      .set(theme, { merge: true });
  } catch (error) {
    throw new Error(error?.message || error);
  }
};

export const useUpdateMerchantTheme = (options?: UseMutationOptions<void, FirebaseError, T>) => {
  const queryClient = useQueryClient();
  return useMutation(updateMerchantTheme, {
    ...options,
    onSuccess: (_, variables, context) => {
      const { theme } = variables;
      queryClient.refetchQueries(EFirestoreCollections.MERCHANT_THEME);
      if (ECustomThemeViews.BACKGROUND_IMAGE in theme || ECustomThemeViews.LOGO in theme) {
        queryClient.refetchQueries(['previous-images']);
      }
      if (options?.onSuccess) options.onSuccess(_, variables, context);
    },
  });
};
