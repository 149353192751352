import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import { useSendEvent } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames } from '@olo-web/types/enums';

type TUpdatePasswordProps = {
  token: string;
  password: string;
};

export const updatePassword = async ({ token, password }: TUpdatePasswordProps): Promise<any> => {
  const url = `/api/customers/update-password`;
  const body = { token, password };
  const response = await axios.post(url, body);
  return response.data;
};

export function useUpdatePassword(
  options?: UseMutationOptions<any, AxiosError, TUpdatePasswordProps>
) {
  const { sendEvent } = useSendEvent();

  return useMutation(updatePassword, {
    ...options,
    onSuccess: (data, variables, context) => {
      sendEvent(EAnalyticsEventNames.RESET_PASSWORD);
      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
  });
}
