import { useCallback } from 'react';
import { useModalDispatch, useModalState } from '@olo-web/client-state';
import { useJoinCustomerWithMerchant } from '@olo-web/domain/customer';
import { EModalTypes } from '@olo-web/types/enums';

export const useAuth = () => {
  const modalDispatch = useModalDispatch();
  const { previousModal } = useModalState();
  // TODO: figure out why this is necessary to avoid a "ReferenceError: Cannot access 'getOrder' before initialisation"
  useJoinCustomerWithMerchant();

  const onSuccess = useCallback(() => {
    // Return to the first modal that was opened before sign up if that modal is not login.
    if (previousModal?.length > 0 && previousModal[0].modalKey !== EModalTypes.LOGIN) {
      modalDispatch({
        type: 'OPEN_PREVIOUS_MODAL_AT_INDEX',
        payload: 0,
      });
    } else {
      modalDispatch({ type: 'CLOSE_MODAL' });
    }
  }, [modalDispatch, previousModal]);

  const openModal = (modalKey: string) =>
    modalDispatch({ type: 'OPEN_MODAL', payload: { modalKey } });

  const goToLoginModal = () => openModal(EModalTypes.LOGIN);
  const goToSignUpModal = () => openModal(EModalTypes.SIGNUP);
  const goToForgotPasswordModal = () => openModal(EModalTypes.FORGET_PASSWORD);

  return { onSuccess, goToLoginModal, goToSignUpModal, goToForgotPasswordModal };
};
