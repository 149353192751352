import {
  useIsOrderConfirmationPage,
  useIsOrderReceiptPage,
  useIsScanAndPayPage,
  useIsLevelup,
} from '@olo-web/utils/common/hooks';
import { useMemo } from 'react';
import { useOrderId, useCheckNumber } from '../queries/useOrder';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';
import { useRouter } from 'next/router';

export const useOrderQueryKey = ({ identifier }: { identifier?: string } = {}) => {
  const router = useRouter();
  const { merchantId, checkId } = router.query;
  const orderId = useOrderId();
  const checkNumber = useCheckNumber();
  const isOrderConfirmationPage = useIsOrderConfirmationPage();
  const isOrderReceiptPage = useIsOrderReceiptPage();
  const isLevelup = useIsLevelup();
  const isScanAndPayPage = useIsScanAndPayPage();
  const isDineIn = useIsDineIn();
  const isHistorical = isOrderConfirmationPage || isOrderReceiptPage;

  let orderIdentifier;

  if (isHistorical) {
    orderIdentifier = orderId;
  } else if (isLevelup && !checkId) {
    orderIdentifier = checkNumber;
  } else if (isDineIn && !isScanAndPayPage) {
    orderIdentifier = identifier;
  } else if (isScanAndPayPage) {
    orderIdentifier = checkId;
  } else {
    orderIdentifier = orderId;
  }

  return useMemo(() => {
    const orderQueryKey: string[] = ['order', merchantId as string, orderIdentifier];

    if (isHistorical && checkId) orderQueryKey.push(checkId as string);
    return orderQueryKey;
  }, [merchantId, orderIdentifier, isHistorical, checkId]);
};
