import { useMemo } from 'react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '../queries/useOrder';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';

export const useIsOverMaxOrderAmount = () => {
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();
  const isDinein = useIsDineIn();

  return useMemo(() => {
    if (isDinein) return false;

    const maxAmount = Number(merchant?.maxOrderAmount || 0);
    const orderAmount = Number(order?.postDiscountSubtotal || 0);

    if (!(orderAmount && maxAmount)) return false;

    return orderAmount > maxAmount;
  }, [order?.postDiscountSubtotal, merchant?.maxOrderAmount, isDinein]);
};
