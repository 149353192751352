import { createStore } from '@olo-web/utils/common/store';
import { TipsValue, ECustomTipsMode } from '@olo-web/domain/orders/types';

export interface IOrderTips {
  selectedTips: TipsValue;
  customTipsMode: ECustomTipsMode;
  customTipsPercentage: string;
  customTipsAmount: string;
}

export interface IMerchantOrderTips {
  [key: string]: IOrderTips;
}

interface ICommonPayload {
  merchantId: string;
}

interface IStringPayload extends ICommonPayload {
  value: string;
}

interface ITipsPayload extends ICommonPayload {
  value: TipsValue;
}

interface ICustomTipsPayload extends ICommonPayload {
  value: ECustomTipsMode;
}

type TTipsValueChange = IStandardAction<'TIPS_VALUE_CHANGE', ITipsPayload>;
type TTipsModeChange = IStandardAction<'TIPS_MODE_CHANGE', ICustomTipsPayload>;
type TTipsAmountChange = IStandardAction<'TIPS_AMOUNT_CHANGE', IStringPayload>;
type TTipsPercentageChange = IStandardAction<'TIPS_PERCENTAGE_CHANGE', IStringPayload>;
type TClearTips = IStandardAction<'CLEAR', string>;

type TOrderTipsAction =
  | TTipsValueChange
  | TTipsModeChange
  | TTipsAmountChange
  | TTipsPercentageChange
  | TClearTips;

const initialState = {};

const reducer = (state: IMerchantOrderTips, action: TOrderTipsAction) => {
  switch (action.type) {
    case 'TIPS_MODE_CHANGE':
      return {
        ...state,
        [action.payload.merchantId]: {
          ...state[action.payload.merchantId],
          customTipsMode: action.payload.value,
        },
      };
    case 'TIPS_VALUE_CHANGE':
      return {
        ...state,
        [action.payload.merchantId]: {
          ...state[action.payload.merchantId],
          selectedTips: action.payload.value,
        },
      };
    case 'TIPS_AMOUNT_CHANGE':
      return {
        ...state,
        [action.payload.merchantId]: {
          ...state[action.payload.merchantId],
          customTipsAmount: action.payload.value,
        },
      };
    case 'TIPS_PERCENTAGE_CHANGE':
      return {
        ...state,
        [action.payload.merchantId]: {
          ...state[action.payload.merchantId],
          customTipsPercentage: action.payload.value,
        },
      };
    case 'CLEAR': {
      const s = { ...state };
      delete s[action.payload];
      return s;
    }
    default:
      return state;
  }
};

export const [OrderTipsProvider, useOrderTipsDispatch, useOrderTipsState] = createStore<
  IMerchantOrderTips,
  TOrderTipsAction
>(reducer, initialState, {
  name: 'orderTips',
});
