import { useRouter } from 'next/router';

export const useIsCheckoutPage = () => {
  const router = useRouter();

  return (
    router.route === '/[locationSlug]/[merchantSlug]/[merchantId]/checkout' ||
    router.route === '/[locationSlug]/[merchantSlug]/[merchantId]/pay/[checkId]'
  );
};
