import { datadogRum } from '@datadog/browser-rum';
import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useScheduleTypeState } from '@olo-web/client-state';
import { useOrderType } from '@olo-web/utils/common/hooks/useOrderType';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { IS_PROD } from './../constants/index';
import { useCallback, useState } from 'react';
import { useSendGAEvent, GAEventParams } from './../../google/analytics/hooks/useSendGAEvent';
import { trackEvent, Context } from '../../metrics/datadog';
import { useMerchant, useMerchantGroup } from '@domain/merchants/queries';
import { useCustomer, useLoyalty } from '@olo-web/domain/customer';
import { ICommonEventData } from '@olo-web/types/commonEventData.interface';
import { event as fsEvent } from 'react-fullstory';
import { useFeatureFlags } from '@olo-web/domain/featureFlags';
import { filterFeatureFlags } from '../functions/filterFeatureFlags';

type TUseSendEvent = {
  googleAnalytics?: boolean;
  dataDog?: boolean;
  fullStory?: boolean;
  canRepeat?: boolean;
};

type TEventParams = {
  googleAnalytics?: GAEventParams;
  dataDog?: Context;
};

type TSendEventReturn = {
  sendEvent: (eventName: EAnalyticsEventNames, params?: TEventParams) => void;
};

const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || '';

export const useCommonEventData = (): ICommonEventData => {
  const { data: merchant } = useMerchant();
  const { data: group } = useMerchantGroup();
  const { data: customer } = useCustomer();
  const { data: loyalty } = useLoyalty();
  const { data: order } = useOrder();
  const { data: featureFlags } = useFeatureFlags();
  const orderType = useOrderType();
  const scheduleOrder = useScheduleTypeState();

  const filteredFlags = filterFeatureFlags(featureFlags, merchant?.merchantId);

  return {
    customerId: customer?.customerId || customer?.spotOnCustomerID || loyalty?.customerId,
    firstName: customer?.firstName,
    lastName: customer?.lastName,
    email: customer?.email,
    loyaltyEnabled: merchant?.spotOnLoyaltyEnabled,
    loyaltySpots: Number(loyalty?.availableSpots ?? 0),
    merchantId: merchant?.merchantId,
    merchantName: group?.name,
    merchantLocation: merchant?.name,
    orderId: order?.id,
    orderType,
    scheduleOrder,
    legacyRefactor: 'refactor',
    version: appVersion,
    orderTypesAvailable: merchant?.orderTypesAvailableAnalyticsString,
    orderDateTime: order?.orderDateTime,
    featureFlags: filteredFlags,
  };
};

export const useSendEvent = (props?: TUseSendEvent): TSendEventReturn => {
  const [shouldFire, setShouldFire] = useState(true);
  const {
    googleAnalytics = true,
    dataDog = true,
    fullStory = true,
    canRepeat = false,
  } = props || {};
  const tags = useCommonEventData();
  const { sendEvent: sendGAEvent } = useSendGAEvent(tags);

  const sendEvent = useCallback(
    (eventName: EAnalyticsEventNames, params?: TEventParams) => {
      if (!shouldFire) return;

      if (googleAnalytics) {
        sendGAEvent(eventName, params?.googleAnalytics);
      }

      const allTags = {
        ...(params?.googleAnalytics || {}),
        ...tags,
      };

      if (dataDog) {
        trackEvent(eventName, {
          source: 'browser',
          payload: allTags,
        });
      }

      if (IS_PROD && fullStory) {
        fsEvent(eventName, {
          ...allTags,
          ddSessionId: datadogRum.getInternalContext()?.session_id,
        } as any);
      }

      setShouldFire(canRepeat);
    },
    [tags, dataDog, fullStory, sendGAEvent, googleAnalytics, canRepeat, shouldFire]
  );

  return { sendEvent };
};
