import { serializeToCamelCase } from '@olo-web/utils';
import { useCustomerState } from '@olo-web/client-state';
import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { configHeaders } from '@olo-web/domain/customer';

export type TUseCustomer = ICustomer & ICustomerInfo & { phone: string };

export const getCustomerProfile = async ({
  customerId,
  sessionId,
  sessionSecret,
  loginTimestamp,
}): Promise<ICustomer> => {
  try {
    const headers = configHeaders({
      customerId,
      sessionId,
      sessionSecret,
      loginTimestamp,
    });

    const { data } = await axios.get(`/api/customers/${customerId}/profile`, { headers });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useCustomer = (): UseQueryResult<TUseCustomer, AxiosError> => {
  const { customerId, sessionId, sessionSecret, loginTimestamp } = useCustomerState();

  return useQuery(
    ['customer', customerId],
    () => getCustomerProfile({ customerId, sessionId, sessionSecret, loginTimestamp }),
    {
      staleTime: 1000000,
      enabled: !!customerId,
      select: serializeToCamelCase,
      retry: 2,
    }
  );
};
