import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useMerchantPath } from '@domain/merchants/hooks/useMerchantPath';
import { useModalDispatch, useSavedDineInContextDispatch } from '@olo-web/client-state';
import { EOrderStatus } from '@olo-web/types/enums/orderStatus.enum';
import { useUpdateOrderStatus } from '@olo-web/domain/orders/mutations/useUpdateOrderStatus';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';

interface IOptions {
  resetOrderStatus?: boolean;
}

export const useGoToMenu = ({ resetOrderStatus }: IOptions = {}) => {
  const modalDispatch = useModalDispatch();
  const isDinein = useIsDineIn();
  const savedDineInDispatch = useSavedDineInContextDispatch();
  const merchantPath = useMerchantPath();

  const pathToRoute = isDinein ? merchantPath + '/dinein' : merchantPath;
  const router = useRouter();
  const onSuccess = useCallback(() => {
    router.push(pathToRoute, pathToRoute, { shallow: true });
  }, [pathToRoute, router]);
  const { mutate: updateOrderStatus } = useUpdateOrderStatus({ onSuccess });

  return useCallback(
    ({ shouldClearOrder } = { shouldClearOrder: false }) => {
      modalDispatch({ type: 'CLOSE_MODAL' });
      if (resetOrderStatus) {
        updateOrderStatus({ status: EOrderStatus.BUILDING });
      } else {
        onSuccess();
      }

      if (shouldClearOrder) {
        savedDineInDispatch({
          type: 'RESET',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalDispatch, resetOrderStatus, updateOrderStatus, onSuccess]
  );
};
