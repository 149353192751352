import { useMemo } from 'react';
import dayjs from 'dayjs';
import { EDateTimeFormat } from '@olo-web/types/enums';

export const useConfirmedTimeText = (orderTime?: string, merchantTimeZone?: string) =>
  useMemo((): string => {
    if (!orderTime) return '';
    const now = dayjs();
    const orderTimeDate = dayjs.tz(dayjs(orderTime), merchantTimeZone);

    const isOrderTimeToday = orderTimeDate.isSame(now, 'day');
    const isOrderTimeTomorrow = orderTimeDate.isSame(now.add(1, 'day'), 'day');
    const isOrderCurrentYear = orderTimeDate.isSame(now, 'year');

    // If orderTime is today: 'Today at <XX:XX> <AM / PM>'
    if (isOrderTimeToday) {
      return `${orderTimeDate.format('[Today at] h:mm A')}`;
    }

    // If orderTime is tomorrow: 'Tomorrow at <XX:XX> <AM / PM>'
    if (isOrderTimeTomorrow) {
      return `${orderTimeDate.format('[Tomorrow at] h:mm A')}`;
    }

    // If orderTime is last year or next years: Thursday, October 15, 2020 2:13 PM
    if (!isOrderCurrentYear) {
      return `${orderTimeDate.format(EDateTimeFormat.LONG_FORM)}`;
    }

    // If orderTime is > tomorrow: '<Day (Short)>, <Month (Short)> <Date> at <XX:XX> <AM / PM>'
    return `${orderTimeDate.format('ddd, MMM D[th] [at] h:mm A')}`;
  }, [orderTime]);
