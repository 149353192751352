import { IS_SERVER } from '@olo-web/utils/common/constants';
import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseApiKey = process.env.NEXT_PUBLIC_FIREBASE_API_KEY || '';
const firebaseAuthDomain = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || '';
const firebaseDatabaseUrl = process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL || '';
const firebaseProjectId = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || '';
const firebaseStorageBucket = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET || '';
const firebaseMessagingSenderId = process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || '';
const firebaseAppId = process.env.NEXT_PUBLIC_FIREBASE_APP_ID || '';
const firebaseMeasurementId = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID || '';

const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  databaseURL: firebaseDatabaseUrl,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessagingSenderId,
  appId: firebaseAppId,
  measurementId: firebaseMeasurementId,
};

const hasNecessaryCredentials = Object.keys(firebaseConfig).every((k) => !!firebaseConfig[k]);

if (!Firebase.apps.length && hasNecessaryCredentials) {
  Firebase.initializeApp(firebaseConfig);
}

const block = IS_SERVER || !hasNecessaryCredentials;

export const firebase = !block && Firebase;
export const firestore = block ? null : firebase?.firestore;
export const FieldValue = block ? null : firebase?.firestore?.FieldValue;
export const auth = block ? null : firebase?.auth;
export const storage = block ? null : firebase.storage;
