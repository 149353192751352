import { mode } from '@chakra-ui/theme-tools';

const styles = {
  global: (props: any): any => ({
    'html, body': {
      color: mode('blackAlpha.500', 'whiteAlpha.500')(props),
      bg: 'white',
    },
    'div, p, table': {
      '&::-webkit-scrollbar': {
        borderRadius: 2,
        width: 1,
        height: 0,
      },
    },
    '.js-focus-visible :focus:not([data-focus-visible-added])': {
      outline: 'none',
      boxShadow: 'none',
    },
    '.main-logo': {
      display: 'block',
      height: '100%',
      width: 'auto',
      maxWidth: '160px',
      maxHeight: '80px',
      '@media (min-width: 1188px)': {
        maxWidth: '200px',
      },
      '@media (min-width: 1536px)': {
        maxHeight: '96px',
      },
    },
    '.nav-bar-logo': {
      display: 'block',
      height: '100%',
      width: 'auto',
      maxWidth: '80px',
      maxHeight: '40px',
    },
  }),
};

export default styles;
