import { TCustomThemeViews } from '@olo-web/types/merchantTheme.interface';
import { EFirestoreCollections } from './../../../types/enums/firestoreCollections.enum';
import { initialState, useCustomThemeDraftDispatch } from '@olo-web/client-state';
import { FirebaseError } from 'firebase-admin';
import { firestore } from '../../../utils/google/firebase/firebase';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

type T = {
  docId: string;
  field: TCustomThemeViews | undefined;
};

export const revertMerchantTheme = async ({ docId, field }: T): Promise<void> => {
  try {
    if (!docId) throw new Error('No merchant theme document id provided');
    return await firestore()
      .collection(EFirestoreCollections.MERCHANT_THEME)
      .doc(docId)
      .update({ [field]: initialState[field] });
  } catch (error) {
    throw new Error(error?.message || error);
  }
};

export const useRevertMerchantTheme = (options?: UseMutationOptions<void, FirebaseError, T>) => {
  const queryClient = useQueryClient();
  const themeDispatch = useCustomThemeDraftDispatch();

  return useMutation(revertMerchantTheme, {
    ...options,
    onSuccess: (_, variables, context) => {
      queryClient.refetchQueries(EFirestoreCollections.MERCHANT_THEME);
      themeDispatch({ type: 'CLEAR_THEME_DRAFT' });
      if (options?.onSuccess) options.onSuccess(_, variables, context);
    },
  });
};
