import { useQuery, UseQueryResult } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';

import { getDefaultTipsValue } from '@olo-web/domain/orders/functions/getDefaultTipsValue';
import dayjs from 'dayjs';
import { useIsThirdPartyDelivery } from '@olo-web/utils/common/hooks/useIsThirdPartyDelivery';
import { useOrderTipsDispatch, useCheckoutDispatch } from '@olo-web/client-state';
import { useCurrentOrderTips } from '@olo-web/utils/common/hooks';
import { useMerchant } from '@olo-web/domain';
import { EPaymentMethods } from '@olo-web/types/enums/paymentMethods.enum';

export const getMerchantOrderTypeDetails = async (
  merchantId: string,
  orderTypeId: string,
  locationTimezone?: string
) => {
  try {
    const url = `/api/merchants/${merchantId}/ordertypes/${orderTypeId}`;
    const { data } = await axios.get<IMerchantOrderTypeDetails>(url);
    locationTimezone && dayjs.tz.setDefault(locationTimezone);
    data.scheduleDates = data.scheduleDates?.map(dayjs);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

type TUseMerchantOrderTypeDetailsProps = {
  merchantId?: string;
  orderTypeId?: string;
  rest?: any;
};

export function useMerchantOrderTypeDetails(
  props?: TUseMerchantOrderTypeDetailsProps
): UseQueryResult<IMerchantOrderTypeDetails, AxiosError> {
  const { merchantId, orderTypeId, ...rest } = props || {};
  const { data: order } = useOrder();
  const router = useRouter();
  const { merchantId: merchantIdFormUrl } = router.query;
  const merchId = merchantId || merchantIdFormUrl;
  const orderType = orderTypeId || order?.orderTypeId;
  const orderTipsDispatch = useOrderTipsDispatch();
  const tips = useCurrentOrderTips();
  const isThirdPartyDelivery = useIsThirdPartyDelivery();
  const { data: merchant } = useMerchant();
  const checkoutDispatch = useCheckoutDispatch();
  return useQuery(
    ['merchant-order-type-details', merchId, orderType],
    () => getMerchantOrderTypeDetails(merchId as string, orderType, merchant?.locationTimezone),
    {
      staleTime: 240000,
      refetchInterval: 240000,
      refetchOnMount: 'always',
      retry: false,
      enabled: !!(merchId && orderType),
      onSuccess: (data) => {
        if (
          tips?.customTipsAmount ||
          tips?.customTipsPercentage ||
          data.suggestedTipPercentages.indexOf(Number(tips.selectedTips)) !== -1
        )
          return;
        orderTipsDispatch({
          type: 'TIPS_VALUE_CHANGE',
          payload: {
            merchantId: merchId as string,
            value: String(getDefaultTipsValue(data, isThirdPartyDelivery)),
          },
        });
        if ((data?.payLaterCCEnabled || data?.payLaterCashEnabled) && !data?.onlineCCPaymentEnabled)
          checkoutDispatch({
            type: 'SELECT_PAYMENT_METHOD',
            payload: EPaymentMethods.CASH,
          });
      },
      ...rest,
    }
  );
}
