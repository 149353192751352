import { DiscountAdjustmentKinds } from '@olo-web/utils/common/functions/getDealDiscountDescription';

export enum ESpecialTipsValue {
  NONE = 'none',
  CUSTOM = 'custom',
}

export type TipsValue = ESpecialTipsValue | string;

export enum ECustomTipsMode {
  AMOUNT = 'amount',
  PERCENTAGES = 'percentages',
}

export enum DiscountType {
  REWARD = 'reward',
  PROMO_CODE = 'promoCode',
  DEAL = 'deal',
}

export interface IOrderDiscount {
  promoCode?: string; // SAVE20
  promotionType: number; //reward | deal | promo code (1, 2, 3)
  promotionName: string; // 20% off
  promotionId: string;
  // After adding a promo code, the returned promotion id is a temporary one, in the context of the order (session). So we keep the original id here
  marketingPromotionId?: string;
  discountValue: string;
  spotsToRedeem?: number | string;
  minPurchaseAmount?: number | string;
  isActive?: boolean; // since, promo
  shouldBeAutoApplied?: boolean;
  adjustmentKind: DiscountAdjustmentKinds;
  adjustmentValue: string;
  minOrderValue?: string;
  maxDiscountValue?: string;
}
