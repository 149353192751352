import React, { useCallback } from 'react';
import { chakra, Text, useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react';
import { Banner } from '@olo-web/components/atoms/Banner';
import { IBannerProps } from '@olo-web/types/bannerProps.interface';

interface IUseToast extends UseToastOptions {
  bannerProps?: Omit<IBannerProps, 'title' & 'description' & 'status'>;
  isTitlePartOfDescription?: boolean;
  dataTestId?: string;
}

export const useToast = () => {
  const toast = useChakraToast();

  const notify = useCallback(
    (opts: IUseToast) => {
      const { status = 'info', isTitlePartOfDescription = false, ...rest } = opts;
      const bannerTextBody = isTitlePartOfDescription
        ? {
            description: (
              <Text fontSize="md">
                <chakra.span fontWeight="semibold" as="h2">
                  {opts?.title}
                </chakra.span>{' '}
                {opts?.description}
              </Text>
            ),
          }
        : {
            title: opts?.title,
            description: opts?.description,
          };

      const bannerProps: IBannerProps = {
        status,
        boxShadow: 'xl',
        ...(rest?.bannerProps || {}),
        ...bannerTextBody,
      };

      toast({
        duration: 5000,
        position: 'top',
        variant: 'left-accent',
        status,
        ...rest,
        render: (props) => (
          <Banner
            key={props?.id}
            onClose={rest?.isClosable && props?.onClose}
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            data-testid={rest?.dataTestId}
            {...bannerProps}
          />
        ),
      });
    },
    [toast]
  );

  return { notify, toast };
};
