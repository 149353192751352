import { useCustomerDispatch } from '@olo-web/client-state';
import { usePaymentMethodValues } from '@olo-web/domain/payments/hooks';
import { EPaymentMethods } from '@olo-web/types/enums/paymentMethods.enum';

export const useUpdateLocalPaymentType = () => {
  const { isApplePay, isGooglePay, isCreditCard } = usePaymentMethodValues();

  const customerDispatch = useCustomerDispatch();

  return () => {
    if (isApplePay)
      customerDispatch({
        type: 'UPDATE',
        payload: { previousPaymentType: EPaymentMethods.APPLE_PAY },
      });
    else if (isGooglePay)
      customerDispatch({
        type: 'UPDATE',
        payload: { previousPaymentType: EPaymentMethods.GOOGLE_PAY },
      });
    else if (isCreditCard)
      customerDispatch({
        type: 'UPDATE',
        payload: { previousPaymentType: EPaymentMethods.CREDIT_CARD },
      });
  };
};
