import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useQuery, UseQueryResult } from 'react-query';
import queryString from 'query-string';
import { useError } from '@olo-web/utils/common/hooks';
import { useOrderTypeId } from '@olo-web/utils/common/hooks';
import { useModalState, useModalDispatch } from '@olo-web/client-state';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useRoundedDownOrderDateTime } from '@olo-web/domain/orders/hooks/useRoundedDownOrderDateTime';
import { createFormGroupsFromMenuItem } from '../functions';

// Retrieves the gory details for a single menu item. Includes the item's name,
// description, available modifiers, subitems, and much more. See the Example
// Result for more info.

export const configureItemData = (data: IMenuItem): IConfiguredMenuItem => ({
  ...data,
  formGroups: createFormGroupsFromMenuItem(data),
});

export const getItem = async (
  merchantId: string,
  menuItemLocationId: string,
  orderTypeId: string,
  orderDateTime: string
): Promise<any> => {
  try {
    const queryParams = {
      orderTypeId,
      orderDateTime,
    };

    const { data } = await axios.get(
      `/api/merchants/${merchantId}/menu-items/${menuItemLocationId}?${queryString.stringify(
        queryParams,
        { encode: false }
      )}`
    );

    return configureItemData(data);
  } catch (error) {
    throw {
      message: error?.response?.data?.message,
      code: error?.response?.data?.code,
    };
  }
};

export function useItem(
  menuItemLocationId?: string,
  merchantId?: string
): UseQueryResult<IConfiguredMenuItem, AxiosError> {
  const { data: order } = useOrder();
  const { modalContext } = useModalState();
  const modalDispatch = useModalDispatch();
  const router = useRouter();
  const callError = useError();
  const orderTypeId = useOrderTypeId();
  const { merchantId: merchantIdFormUrl, itemId: itemIdFromUrl } = router.query;
  const merchId = merchantId || merchantIdFormUrl;
  const itemId = menuItemLocationId || itemIdFromUrl || modalContext?.item?.menuItemLocationId;
  const isEnabled = Boolean(merchId && itemId && orderTypeId);
  const orderDateTime = order?.orderDateTime || new Date().toISOString();
  const roundedDownOrderDateTime = useRoundedDownOrderDateTime();

  return useQuery(
    ['item', itemId, orderTypeId, roundedDownOrderDateTime],
    () => getItem(merchId as string, itemId, orderTypeId, orderDateTime),
    {
      staleTime: 1000000,
      enabled: isEnabled,
      retry: 1,
      onError: (error) => {
        modalDispatch({ type: 'CLOSE_MODAL' });

        callError({
          error,
          title: 'Error retrieving item details',
          toastError: true,
          position: 'top',
          toastId: 'itemError',
        });
      },
    }
  );
}
