import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useCustomerState } from '@olo-web/client-state';
import { useCustomer } from '@olo-web/domain/customer';
import { IUseUpdateProfileProps } from '..';
import { configHeaders } from '../functions';
import { serializeToSnakeCase } from '../../../utils/common/functions';

export const updateProfile = async ({
  customerId,
  updatedProfile,
  sessionId,
  sessionSecret,
  loginTimestamp,
}: IUseUpdateProfileProps): Promise<any> => {
  try {
    const url = `/api/customers/${customerId}/update-profile`;
    const body = serializeToSnakeCase(updatedProfile);
    body.name = `${body.first_name} ${body.last_name}`;
    body.customer_id = customerId;
    // delete body.first_name;
    // delete body.last_name;

    const headers = configHeaders({
      customerId,
      sessionId,
      sessionSecret,
      loginTimestamp,
    });

    const { data } = await axios.post(url, body, { headers });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useUpdateProfile(
  options?: UseMutationOptions<any, AxiosError, IUpdateProfileInfo>
): any {
  const { customerId, sessionId, sessionSecret, loginTimestamp } = useCustomerState();
  const { refetch: refetchUser } = useCustomer();
  const queryClient = useQueryClient();
  const queryKey = ['customer', customerId];
  return useMutation(
    (user) =>
      updateProfile({
        customerId,
        updatedProfile: user,
        sessionId,
        sessionSecret,
        loginTimestamp: +loginTimestamp,
      }),
    {
      ...options,
      onMutate: async (updatedUser: IUpdateProfileInfo) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(queryKey);
        // Snapshot the previous value
        const prevUser = queryClient.getQueryData(queryKey);
        // Optimistically update to the new value
        queryClient.setQueryData(queryKey, (oldUser: ICustomer) => {
          return {
            ...oldUser,
            // this needs to be snakecased cause the API handles data
            // camelcased and we map it locally to snake case
            ...serializeToSnakeCase(updatedUser),
          };
        });
        // Return a context object with the snapshotted value
        return {
          prevUser,
        };
      },
      onError: (_err, _updatedUser, context) => {
        // If the mutation fails, use the context returned from onMutate to roll back
        queryClient.setQueryData(queryKey, context.prevUser);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries('todos');
      },
      onSuccess: (data, variables, context) => {
        refetchUser();
        options?.onSuccess(data, variables, context);
      },
    }
  );
}
