import { useCallback } from 'react';
import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { usePaymentMethods } from '@templates/Checkout/common/hooks/usePaymentMethods';
import { useCustomerState } from '@olo-web/client-state';

export const useSendBeginCheckoutEvent = () => {
  const { sendEvent } = useSendEvent({ canRepeat: true });
  const { data: order } = useOrder();
  const { paymentMethods } = usePaymentMethods();
  const { previousPaymentType } = useCustomerState();
  const stringPaymentMethods = paymentMethods.map((payment) => payment.title);

  return useCallback(() => {
    sendEvent(EAnalyticsEventNames.BEGIN_CHECKOUT, {
      googleAnalytics: {
        ecommerce: {
          menuItems: order?.items,
          value: order?.postDiscountSubtotal,
          paymentTypeAvailable: stringPaymentMethods,
          paymentTypeDefaulted: previousPaymentType,
        },
      },
    });
  }, [order, previousPaymentType, sendEvent, stringPaymentMethods]);
};
