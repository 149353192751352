import { EOrderTypes } from '@olo-web/types/enums';
import { useMemo } from 'react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import findKey from 'lodash/findKey';
import { useIsDineIn } from '@olo-web/utils/common/hooks';

export const useOrderType = (): EOrderTypes => {
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();
  const isDineIn = useIsDineIn();

  const orderType = useMemo(
    () =>
      findKey(merchant?.orderTypeIds, (orderTypeId) => order?.orderTypeId === orderTypeId) ||
      findKey(
        merchant?.orderTypeIds,
        (orderTypeId) => merchant?.defaultOrderTypeId === orderTypeId
      ),
    [merchant?.orderTypeIds, order?.orderTypeId, merchant?.defaultOrderTypeId]
  );
  if (isDineIn) return EOrderTypes.DINE_IN;
  return orderType as EOrderTypes;
};
