import { useMerchant } from '@olo-web/domain';
import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

export const getPaymentTokenizationKey = async (
  merchantId: string
): Promise<ITokenizationKeyResponse> => {
  try {
    const url = `/api/payments/olo-payments/${merchantId}`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const usePaymentTokenizationKey = (
  merchantId?: string
): UseQueryResult<ITokenizationKeyResponse, AxiosError> => {
  const { data: merchant } = useMerchant();
  const mId = merchantId || merchant?.merchantId;

  return useQuery(['payment-tokenization-key', mId], () => getPaymentTokenizationKey(mId), {
    staleTime: 1000000,
    enabled: !!mId,
    retry: 1,
  });
};
