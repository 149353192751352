import { useEffect, useMemo } from 'react';
import { useCheckoutDispatch, useCheckoutState } from '@olo-web/client-state';
import { useOrder } from '../queries/useOrder';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';

export const useCheckedGuests = () => {
  const checkoutDispatch = useCheckoutDispatch();
  const checkoutState = useCheckoutState();
  const { data: order } = useOrder();
  const isSplitByAmount = useIsSplitByAmount();

  const checkedGuests = useMemo(() => {
    const checkedGuests = checkoutState?.checkedGuests;

    return order?.guests?.filter((guest) =>
      checkedGuests?.some(
        (checkedGuest) =>
          !(checkedGuest?.groupOrderBalancePaidFlag || guest?.groupOrderBalancePaidFlag) &&
          checkedGuest?.id === guest.id
      )
    );
  }, [checkoutState?.checkedGuests, order?.guests]);

  const total = useMemo(() => {
    let total = 0;
    checkedGuests?.forEach((guest) => {
      total += +guest.groupOrderBalanceAmount;
    });

    return total;
  }, [checkedGuests]);

  const updateGuestTotals = (orderGuest: IGuest) => {
    const checkedGuestsIndex = checkedGuests.findIndex((gt) => gt.id === orderGuest.id);
    const newCheckedGuests = [...checkedGuests];

    if (checkedGuestsIndex > -1) {
      newCheckedGuests.splice(checkedGuestsIndex, 1);
    } else {
      newCheckedGuests.push(orderGuest);
    }

    checkoutDispatch({
      type: 'SET_CHECKED_GUESTS',
      payload: newCheckedGuests,
    });
  };

  useEffect(() => {
    if (
      checkedGuests?.length !== 0 &&
      checkoutState?.checkedGuests?.length !== checkedGuests?.length
    ) {
      checkoutDispatch({
        type: 'SET_CHECKED_GUESTS',
        payload: checkedGuests,
      });
    }
  }, [checkedGuests, checkoutState?.checkedGuests?.length, checkoutDispatch]);

  useEffect(() => {
    if (!checkedGuests || !isSplitByAmount) return;

    checkoutDispatch({
      type: 'SET_GUEST_BALANCE',
      payload: total,
    });
    checkoutDispatch({
      type: 'SET_PAYMENT_FOR_GUEST_IDS',
      payload: checkedGuests.map((checkedGuest) => checkedGuest.id),
    });
  }, [checkoutDispatch, checkedGuests, total, isSplitByAmount]);

  return { guests: checkedGuests, total, updateGuestTotals };
};
