import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useMerchantPath } from '@domain/merchants/hooks/useMerchantPath';
import { useIsDineIn } from '@olo-web/utils/common/hooks';

type T = { includeQueryParam?: boolean };
const defaultOptions = { includeQueryParam: true };

export const useCurrentMerchantPath = ({ includeQueryParam }: T = defaultOptions) => {
  const merchantPath = useMerchantPath();
  const router = useRouter();
  const isDineIn = useIsDineIn();
  const query = router?.asPath.split('?')[1];
  const params = `${isDineIn ? '/dinein' : ''}${query && includeQueryParam ? '?' + query : ''}`;

  return useMemo(() => {
    return `${merchantPath}${params} `;
  }, [merchantPath, params]);
};
