import { useEffect } from 'react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useMerchantPath } from '@domain/merchants/hooks/useMerchantPath';
import { useRouter } from 'next/router';
import { useIsScanAndPayPage } from '../hooks/useIsScanAndPayPage';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';

export const useUpdateUrlIfEmptySlug = () => {
  const router = useRouter();
  const merchantPath = useMerchantPath();
  const { locationSlug, merchantSlug, merchantId } = router.query;
  const { data: merchant } = useMerchant();
  const { orderType } = router.query;
  const isScanNPay = useIsScanAndPayPage();
  const { data: order } = useOrder();

  useEffect(() => {
    if (!!merchant?.merchantId && (locationSlug === '-' || merchantSlug === '-')) {
      const destination = `${merchantPath}${
        orderType ? `/${orderType}` : isScanNPay ? `/pay/${order?.checkId}` : ''
      }${window.location.search}`;
      router.push(destination, destination, { shallow: true });
    }
  }, [
    locationSlug,
    merchantSlug,
    merchantId,
    merchant,
    router,
    merchantPath,
    orderType,
    isScanNPay,
    order?.checkId,
  ]);
};
