import { useFeatureFlags } from '@olo-web/domain/featureFlags';
import { useMerchant } from '@olo-web/domain';
import { isFeatureEnabledForMerchant } from '@olo-web/utils';

export const useOrderIdFeatureFlagEnabled = () => {
  const { data: flags } = useFeatureFlags();
  const { data: merchant } = useMerchant();

  const orderIdFeatureFlagEnabled = isFeatureEnabledForMerchant({
    featureEnabled: flags?.useOrderIdForQR?.on,
    allowList: flags?.useOrderIdForQR?.allow,
    denyList: flags?.useOrderIdForQR?.deny,
    merchantId: merchant?.merchantId,
  });
  return orderIdFeatureFlagEnabled;
};
