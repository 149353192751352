import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Cash = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 34 22" fill="none" {...props}>
      <rect
        x="0.75"
        y="0.75"
        width="32.5"
        height="20.5"
        rx="3.25"
        fill="#CCEEDC"
        stroke="#4DC484"
        strokeWidth="1.5"
      />
      <path
        d="M17 17.75C20.7279 17.75 23.75 14.7279 23.75 11C23.75 7.27208 20.7279 4.25 17 4.25C13.2721 4.25 10.25 7.27208 10.25 11C10.25 14.7279 13.2721 17.75 17 17.75Z"
        stroke="#4DC484"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1 8.75085C18.9641 8.51517 18.7667 8.32088 18.5289 8.18876C18.2911 8.05665 18.0219 7.9917 17.75 8.00085H16.25C15.8522 8.00085 15.4706 8.15889 15.1893 8.44019C14.908 8.72149 14.75 9.10302 14.75 9.50085C14.75 9.89867 14.908 10.2802 15.1893 10.5615C15.4706 10.8428 15.8522 11.0008 16.25 11.0008H17.75C18.1478 11.0008 18.5294 11.1589 18.8107 11.4402C19.092 11.7215 19.25 12.103 19.25 12.5008C19.25 12.8987 19.092 13.2802 18.8107 13.5615C18.5294 13.8428 18.1478 14.0008 17.75 14.0008H16.25C15.9781 14.01 15.7089 13.945 15.4711 13.8129C15.2333 13.6808 15.0359 13.4865 14.9 13.2508"
        stroke="#4DC484"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 14V15.5M17 6.5V8V6.5Z"
        stroke="#4DC484"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
