import { ECustomThemeViews } from '@olo-web/types/enums';
import {
  IMerchantSettings,
  IMerchantTheme,
  IMerchantThemeColorsVariants,
  IMerchantThemeFonts,
} from '@olo-web/types/merchantTheme.interface';
import { createStore } from '@olo-web/utils/common/store';

type TUpdateColorsPayload = {
  variant: 'base' | 'primary';
  value: IMerchantThemeColorsVariants;
};

type TUpdateFontsAction = IStandardAction<'UPDATE_FONTS', IMerchantThemeFonts>;
type TUpdateColorAction = IStandardAction<'UPDATE_COLOR', ICustomThemeColorPayload>;
type TUpdateColorsAction = IStandardAction<'UPDATE_COLORS', TUpdateColorsPayload>;
type TSetView = IStandardAction<'SET_VIEW', ECustomThemeViews>;
type TUpdateMerchantSettings = IStandardAction<'UPDATE_MERCHANT_SETTINGS', IMerchantSettings>;
type TUpdateLogoUrl = IStandardAction<'UPDATE_LOGO_URL', string>;
type TUpdateBackgroundUrl = IStandardAction<'UPDATE_BACKGROUND_URL', string>;
type TUpdateFaviconUrl = IStandardAction<'UPDATE_FAVICON_URL', string>;
type TClearColorAction = IStandardAction<'CLEAR_COLOR', 'primary' | 'base'>;
type TClearFontsAction = Omit<IStandardAction<'CLEAR_FONTS'>, 'payload'>;
type TClearAction = Omit<IStandardAction<'CLEAR_THEME_DRAFT'>, 'payload'>;
type TMerchantThemeAction =
  | TUpdateFontsAction
  | TUpdateColorAction
  | TUpdateColorsAction
  | TUpdateLogoUrl
  | TUpdateBackgroundUrl
  | TUpdateFaviconUrl
  | TClearFontsAction
  | TClearColorAction
  | TSetView
  | TUpdateMerchantSettings
  | TClearAction;

export const initialState: IMerchantTheme = {
  colors: {},
  fonts: {},
  logoUrl: null,
  backgroundUrl: null,
  faviconUrl: null,
  view: ECustomThemeViews.COLOR,
};

const reducer = (state: IMerchantTheme, action: TMerchantThemeAction): IMerchantTheme => {
  switch (action.type) {
    case 'UPDATE_FONTS':
      return {
        ...state,
        fonts: {
          ...state.fonts,
          ...action.payload,
        },
      };
    case 'UPDATE_COLOR':
      return {
        ...state,
        colors: {
          ...state.colors,
          [action.payload.variant]: {
            ...(state.colors?.[action.payload.variant] ?? {}),
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case 'UPDATE_COLORS': {
      return {
        ...state,
        colors: {
          ...state.colors,
          [action.payload.variant]: action.payload.value,
        },
      };
    }
    case 'CLEAR_COLOR':
      return {
        ...state,
        colors: {
          ...state.colors,
          [action.payload]: null,
        },
      };
    case 'CLEAR_FONTS': {
      return {
        ...state,
        fonts: {},
      };
    }
    case 'CLEAR_THEME_DRAFT':
      return initialState;
    case 'UPDATE_LOGO_URL':
      return {
        ...state,
        logoUrl: action.payload,
      };
    case 'UPDATE_BACKGROUND_URL':
      return {
        ...state,
        backgroundUrl: action.payload,
      };
    case 'UPDATE_FAVICON_URL':
      return {
        ...state,
        faviconUrl: action.payload,
      };
    case 'SET_VIEW':
      return {
        ...state,
        view: action.payload,
      };
    case 'UPDATE_MERCHANT_SETTINGS':
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export const [
  CustomThemeDraftContextProvider,
  useCustomThemeDraftDispatch,
  useCustomThemeDraftState,
] = createStore<IMerchantTheme, TMerchantThemeAction>(reducer, initialState, {
  name: 'custom-theme-draft',
});
