import { useEffect } from 'react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useAlertDispatch } from '@olo-web/client-state';
import { EAlertTypes, EAnalyticsEventNames } from '@olo-web/types/enums';
import { useSendEvent } from '@olo-web/utils/common/hooks/useSendEvent';
import { useIsDineIn } from '@olo-web/utils/common/hooks';

export const useUnavailabilityAlert = () => {
  const alertDispatch = useAlertDispatch();
  const { data: merchant, isLoading } = useMerchant();
  const { sendEvent } = useSendEvent();
  const isDineIn = useIsDineIn();

  useEffect(() => {
    if (!isLoading && !merchant?.isOrderingAvailable && !isDineIn) {
      sendEvent(EAnalyticsEventNames.ORDERING_UNAVAILABLE);
      alertDispatch({
        type: 'OPEN_ALERT',
        payload: {
          alertKey: EAlertTypes.SHOW_UNAVAILABLE_ORDER,
          isCloseOnOutsideClick: false,
        },
      });
    }
    // alertDispatch and sendEvent shouldn't be in these dependencies as this leads to an endless hook update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDineIn, isLoading, merchant?.isOrderingAvailable, merchant?.orderTypes.dinein]);
};
