import { useMemo } from 'react';
import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useQuery, UseQueryResult } from 'react-query';
import dayjs from 'dayjs';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { dynamicSort } from '@olo-web/utils/common/functions';
import { useOrderTypeId } from '@olo-web/utils/common/hooks';
import { useMenuState } from '@olo-web/client-state';
import Fuse from 'fuse.js';
import { useRoundedDownOrderDateTime } from '@olo-web/domain/orders/hooks/useRoundedDownOrderDateTime';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';

// Retrieves the available menu groups for the supplied location / order type /
// order time.
//
// Optionally add "includeMenuItems=true" as a query parameter if
// you wish for an array of menu item summary info to be added for each menu
// group.
//
// NOTE: Order type and time are required since the available menu groups can
// vary by order type and time.

export const getMenu = async (
  merchantId: string,
  orderTypeId: string,
  orderDateTime: string,
  excludeMenuItems?: boolean
): Promise<IMenuGroup[]> => {
  try {
    const queryParams = {
      orderTypeId,
      orderDateTime: orderDateTime ? dayjs(orderDateTime).toISOString() : null,
      includeMenuItems: !excludeMenuItems,
    };
    const { data } = await axios.get(`/api/merchants/${merchantId}/menu-groups`, {
      params: queryParams,
    });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

const configureMenuGroupData = (data: IMenuGroup[], featuredGroupId?: string) => {
  let groups = data.filter((data) => data?.menuItems?.length).sort(dynamicSort('position'));
  const featuredGroupIdx = featuredGroupId && groups.findIndex((g) => g.id === featuredGroupId);
  const featuredGroup = featuredGroupIdx >= 0 && groups[featuredGroupIdx];
  if (featuredGroup) {
    groups = [...groups.slice(0, featuredGroupIdx), ...groups.slice(featuredGroupIdx + 1)];
    featuredGroup.name = 'Featured';
    featuredGroup.description = null;
    featuredGroup.menuItems = featuredGroup.menuItems.filter((i) => i.imageUrl?.length);
    groups.unshift(featuredGroup);
  }
  return groups;
};

export function useMenu(
  excludeMenuItems?: boolean,
  merchantId?: string,
  initialData?: IMenuGroup[]
): UseQueryResult<IMenuGroup[], AxiosError> {
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();
  const router = useRouter();
  const orderTypeId = useOrderTypeId();
  const roundedDownOrderDateTime = useRoundedDownOrderDateTime();
  const { merchantId: merchantIdFormUrl } = router.query;
  const merchId = merchantId || merchantIdFormUrl;
  const isEnabled = Boolean(merchId && orderTypeId);
  const featuredGroupId = merchant?.featuredMenuGroupId;

  return useQuery<IMenuGroup[], AxiosError>(
    ['menu', merchId, orderTypeId, roundedDownOrderDateTime],
    () =>
      getMenu(
        merchId as string,
        orderTypeId,
        order?.orderDateTime || dayjs().toISOString(),
        excludeMenuItems
      ),
    {
      staleTime: 1000000,
      enabled: isEnabled,
      initialData,
      select: (data) => configureMenuGroupData(data, featuredGroupId),
    }
  );
}

interface UseMenuFilteredBySearchResult {
  groups: IMenuGroup[];
  isLoading: boolean;
  isIdle: boolean;
  error?: AxiosError;
  searchText?: string;
}

export function useMenuFilteredBySearch(): UseMenuFilteredBySearchResult {
  const { data: menu, isLoading, error, isIdle } = useMenu();
  const { searchText } = useMenuState();

  const fuse = useMemo(() => {
    return new Fuse(menu, {
      includeMatches: true,
      keys: ['menuItems.name', 'menuItems.description'],
      threshold: 0.4,
    });
  }, [menu]);

  const filteredMenuGroups = useMemo<IMenuGroup[]>(() => {
    if (!menu) return [];
    if (!searchText) return menu;
    const searchResults = fuse.search(searchText);
    if (!searchResults?.length) return [];

    return searchResults.reduce((a, c) => {
      const refs = [];
      // We use the refs array to store the refIndex to avoid duplicates
      // Duplicates occur when the search text is matched in the title and the description of the same menu item
      return [
        ...a,
        {
          ...c.item,
          menuItems: c.matches
            .filter(({ refIndex }) => {
              if (!refs.includes(refIndex)) {
                refs.push(refIndex);
                return true;
              }
              return false;
            })
            .map(({ refIndex }) => c.item.menuItems[refIndex]),
        },
      ];
    }, []);
  }, [searchText, menu, fuse]);

  return {
    groups: filteredMenuGroups,
    isLoading,
    isIdle,
    error,
    searchText: searchText,
  };
}
