import axios, { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useQuery, UseQueryResult } from 'react-query';
import { calcDistance } from '@olo-web/utils/common/functions/calcDistance';
import { useBrowserLocation } from '@olo-web/domain/location/queries/useBrowserLocation';
import { configureMerchantGroupData } from '@domain/merchants/functions/configureMerchantData';
import { useFeatureFlags } from '@olo-web/domain/featureFlags';

export const getMerchantGroup = async (
  merchantId: string,
  disallowDoorDashDriveOrders?: boolean
): Promise<IUseMerchantGroup> => {
  const { data } = await axios.get(`/api/groups`, {
    params: { merchantId },
  });
  return configureMerchantGroupData(data, disallowDoorDashDriveOrders);
};

export function useMerchantGroup(
  merchantId?: string,
  initialData?: IUseMerchantGroup
): UseQueryResult<IUseMerchantGroup, AxiosError> {
  const router = useRouter();
  const { merchantId: merchantIdFormUrl } = router.query;
  const merchId = merchantId || merchantIdFormUrl;
  const { data: flags } = useFeatureFlags();

  return useQuery(
    ['merchant-group', merchId],
    () => getMerchantGroup(merchId as string, flags?.disallowDoorDashDriveOrders),
    {
      staleTime: 1000000,
      enabled: !!merchId,
      initialData,
    }
  );
}

export const useSortedMerchantGroup = (): { data: IUseMerchantGroup; loading?: boolean } => {
  const { data: position, error, isLoading } = useBrowserLocation();
  const { data } = useMerchantGroup();

  return useMemo(() => {
    if (isLoading || error || !position) {
      return { data, isLoading };
    }

    const sortedMerchants = data?.merchants
      ?.map((merchant) => ({
        orgId: data.id,
        ...merchant,
        distance: calcDistance(
          merchant?.addressGeometry?.location?.lat,
          merchant?.addressGeometry?.location?.lng,
          position?.lat,
          position?.lng
        ),
      }))
      .sort((a, b) => a.distance - b.distance);

    return { data: { ...data, merchants: sortedMerchants } };
  }, [isLoading, error, position, data]);
};
