import { EAuthEntryPoints } from '../types/enums/authEntryPoints.enum';
import { createStore } from '@olo-web/utils/common/store';
import { ModalProps } from '@chakra-ui/react';

interface IModalState {
  modalKey?: string;
  modalContext?: any;
  modalProps?: Omit<ModalProps, 'children' | 'onClose' | 'isOpen'>;
  previousModal?: any[];
  subType?: string;
  analyticsAuthEntryPoint?: EAuthEntryPoints | '';
}

type TCloseModalAction = TEmptyAction<'CLOSE_MODAL'>;
type TOpenModalAction = IStandardAction<'OPEN_MODAL', IModalState>;
type TOpenPreviousModalAction = TEmptyAction<'OPEN_PREVIOUS_MODAL'>;
type TUpdateModalActions = IStandardAction<'UPDATE_SUB_TYPE', string>;
type TUpdateContextActions = IStandardAction<'UPDATE_CONTEXT', any>;
type TOepnPreviousModalAtIndexAction = IStandardAction<'OPEN_PREVIOUS_MODAL_AT_INDEX', number>;

type TModalAction =
  | TCloseModalAction
  | TOpenModalAction
  | TUpdateModalActions
  | TOpenPreviousModalAction
  | TUpdateContextActions
  | TOepnPreviousModalAtIndexAction;

const initialState: IModalState = {
  modalKey: '',
  modalContext: {},
  modalProps: {},
  previousModal: [],
  subType: '',
  analyticsAuthEntryPoint: '',
};

const reducer = (state: IModalState, action: TModalAction): IModalState => {
  const previousModal: IModalState['previousModal'] = state.previousModal
    ? [...state.previousModal]
    : [];
  switch (action.type) {
    case 'OPEN_MODAL':
      state.modalKey && state.modalKey !== action.payload.modalKey && previousModal.push(state);

      return { ...state, ...action.payload, previousModal };
    case 'OPEN_PREVIOUS_MODAL_AT_INDEX': {
      const newPreviousModal = previousModal?.slice(0, action.payload + 1);

      return {
        ...(newPreviousModal?.pop() || state),
        newPreviousModal,
      };
    }
    case 'OPEN_PREVIOUS_MODAL':
      return { ...(previousModal.pop() || state), previousModal };
    case 'CLOSE_MODAL':
      return initialState;
    case 'UPDATE_SUB_TYPE':
      return { ...state, subType: action.payload };
    case 'UPDATE_CONTEXT':
      return { ...state, modalContext: { ...state.modalContext, ...action.payload } };
    default:
      return state;
  }
};

export const [ModalContextProvider, useModalDispatch, useModalState] = createStore<
  IModalState,
  TModalAction
>(reducer, initialState, {
  name: 'modal',
});
