import { dynamicSort } from '@olo-web/utils/common/functions';
import {
  buildModifiersWithDisplayValues,
  getModifierDisplayType,
  isModifierGroupRequired,
} from '.';

export const createFormModifierGroups = (
  modifierGroups: IMenuItemModifierGroup[],
  defaultModifierGroups: IMenuItemDefaultModifierGroup[]
): IMenuItemModifierGroupDisplay[] => {
  return (modifierGroups || [])
    .map((mod) => {
      const defaultGroup = defaultModifierGroups?.find((d) => d.modInfoId === mod.id);

      const isValid = !isModifierGroupRequired(mod);

      return {
        ...mod,
        defaultGroup,
        displayType: getModifierDisplayType(mod),
        modifiers: buildModifiersWithDisplayValues(
          mod.modifiers,
          defaultGroup,
          mod.implicitDefaultModOptionText
        ),
        isDirty: false,
        isValid,
      };
    })
    .sort(dynamicSort('position'));
};
