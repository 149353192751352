import { useRouter } from 'next/router';
import { useLevelupDispatch, useLevelupState } from '@olo-web/client-state';
import { useMount } from 'react-use';

export const useIsLevelup = () => {
  const { isLevelup } = useLevelupState();
  const levelupDispatch = useLevelupDispatch();
  const router = useRouter();

  // In this approach, this is a once open always open kind of switch,
  // where once state is set to be that this is a levelup route,
  // it stays that way. There could be a potential update needed to
  // get out of it, but for this integration is rather unnecessary.

  const isLevelupRoute = router.route === '/levelup/[merchantId]';

  useMount(() => {
    if (isLevelupRoute) {
      levelupDispatch({
        type: 'LEVELUP_UPDATE_IS_LEVELUP',
        payload: isLevelupRoute,
      });
    }
  });

  return isLevelup || isLevelupRoute;
};
