interface ConfigHeaderArgs {
  customerId: string;
  sessionId: string;
  loginTimestamp: number | string;
  sessionSecret: string;
}

export const configHeaders = ({
  customerId,
  sessionId,
  loginTimestamp,
  sessionSecret,
}: ConfigHeaderArgs) => ({
  'so-customer-id': customerId,
  'so-session-id': sessionId,
  'so-timestamp': loginTimestamp,
  'so-hmac': sessionSecret,
});
