import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import snakeCase from 'lodash/snakeCase';

///////////////////////////////////////////////////////////
//
// Serialize backend response
//
// This is for use for converting/transforming backend responses that are in
// `snake_case` to `camelCase` and vice versa.
//
// NOTE: the type of object will have to be determined explicitly, this just
// transforms any type of object.
//
///////////////////////////////////////////////////////////

// Recursively modify keys in an object
//  param {Object|Array} item - an object or an array to modify
//  param {Function} func - a function that modifys the key
//  returns {Object|Array} an object with the modified keys
//
// WARNING: modifies the object
function modifyKeys(item: any, modifier: any): any {
  if (Array.isArray(item)) {
    return item.map((value) => modifyKeys(value, modifier));
  } else if (item !== null && item.constructor === Object) {
    return Object.keys(item).reduce((acc, key) => {
      return {
        ...acc,
        [modifier(key)]: modifyKeys(item[key], modifier),
      };
    }, {});
  }

  return item;
}

// Convert an objects keys to camelCase
//  param {Object|Array} unserializedObject - an object to convert
//  returns {Object|Array} returns a copy of the unserialized object with keys
//   in camel case
export function serializeToCamelCase(unserializedObject: unknown): any {
  const object = cloneDeep(unserializedObject);
  return modifyKeys(object, camelCase);
}

// Convert an objects keys to snake_case
//  param {Object|Array} serializedObject - an object to convert
//  returns {Object|Array} returns a copy of the deserialized object with keys
//   in snake case
export function serializeToSnakeCase(serializedObject: unknown): any {
  // Exceptions to the rule
  function convertToSnakeCase(item: string): string {
    const custom: { [key: string]: string } = {
      poNumber: 'PO_number',
    };

    if (custom[item] !== undefined) {
      return custom[item];
    }

    return snakeCase(item);
  }

  const object = cloneDeep(serializedObject);
  return modifyKeys(object, convertToSnakeCase);
}
