// Important! ⚠️
// This component is being written as of 10 July 2020
// We are using a Class component, something that is being
// not widely used to write new React components since a
// new paradigm has appeared since: Functional components
// with hooks. However methods componentDidCatch and
// getDerivedStateFromError don't have a hook equivalent
// yet, however the React team is porting them and they
// should be available sometime in the near future. When
// they appear, it would be time to update this component
// and get rid of this info.

import React, { Component, ReactNode, ErrorInfo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render(): JSX.Element | ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box w="100%" bg="white" minWidth="100%" minHeight="100%" mb={2} mt={3} p={2}>
          <Flex justifyContent="center">
            <Text textStyle="h4" textAlign="left">
              We&apos;re having a little trouble
            </Text>
          </Flex>
          <ErrorMessage />
        </Box>
      );
    }

    return this.props.children;
  }
}

function ErrorMessage() {
  const { data: merchant } = useMerchant();

  return (
    <Text align="center" mt={2} mb={4} textStyle="p">
      Sorry, but something went wrong with your order.
      <br />
      Please try again{merchant?.phone ? ` or give us a call at ${merchant?.phone}.` : '.'}
    </Text>
  );
}
