import { useMemo } from 'react';
import { useMerchantGroup } from '@domain/merchants/queries/useMerchantGroup';
import { isOrderingAvailable } from '@domain/merchants/functions/isOrderingAvailable';
import { ECookies } from '@olo-web/types/enums';
import { parseCookies } from 'nookies';

export const getAvailableLocations = (merchantGroup) =>
  merchantGroup?.merchants?.filter(isOrderingAvailable);

export const getPreferredLocation = (preferredLocation, merchantGroup) => {
  if (!preferredLocation) return null;
  const availableMerchants = getAvailableLocations(merchantGroup);
  return availableMerchants?.find((merchant) => merchant.merchantId === preferredLocation);
};

export const useDefaultLocation = () => {
  const { data: merchantGroup } = useMerchantGroup();
  const cookies = parseCookies();

  const availableMerchants = getAvailableLocations(merchantGroup);
  const savedMerchant = getPreferredLocation(cookies?.[ECookies.preferredLocation], merchantGroup);

  const defaultLocation = useMemo(() => {
    if (savedMerchant) {
      return { ...savedMerchant, orgId: merchantGroup?.id };
    }
    // If we have neither a cookie to reference nor the users geolocation we cannot establish a
    // default
    return null;
  }, [savedMerchant, merchantGroup?.id]);

  return {
    defaultLocation,
    multipleLocations: availableMerchants.length > 1,
  };
};
