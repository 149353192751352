import { useCallback, useEffect } from 'react';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import {
  EAlertTypes,
  EAnalyticsEventNames,
  ECheckoutTypes,
  ENatsActions,
  EOrderTypes,
} from '@olo-web/types/enums';
import {
  useIsOrderConfirmationPage,
  useNotifyOrderStatusChange,
} from '@olo-web/utils/common/hooks';
import {
  useAlertDispatch,
  useModalDispatch,
  useSavedDineInContextState,
} from '@olo-web/client-state';
import { EModalTypes } from '@olo-web/types/enums';
import { EOrderStatus } from '@olo-web/types/enums/orderStatus.enum';
import { useToast } from '@olo-web/utils/common/hooks';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import { EGALocations } from '@olo-web/types/enums/googleAnalyticsLocations.enum';
import { useNotifyGuestPaymentsAdded } from './useNotifyGuestPaymentsAdded';
import { useGoToConfirmation } from '@olo-web/utils/common/hooks/useGoToConfirmation';
import { useUnsubscribeToNats } from '../nats/useNats';
import { useNotifyPaymentIsCompleted } from './useNotifyPaymentIsCompleted';
import { useNatsState } from '@olo-web/client-state/natsContext';
import { useIsSplitBillPage } from './useIsSplitBillPage';
import { useGoToMenu } from './useGoToMenu';

let toastId;

export const useNatsEventsHandlers = () => {
  const updateOrderInCache = useUpdateOrderInCache();
  const notifyOrderStatusChange = useNotifyOrderStatusChange();
  const notifyGuestPaymentsAdded = useNotifyGuestPaymentsAdded();
  const notifyPaymentIsCompleted = useNotifyPaymentIsCompleted();
  const modalDispatch = useModalDispatch();
  const goToConfirmation = useGoToConfirmation();
  const { refetch } = useOrder();
  const { notify, toast } = useToast();
  const savedDineInState = useSavedDineInContextState();
  const { sendEvent } = useSendEvent();
  const natsState = useNatsState();
  const { unsubscribeToNats } = useUnsubscribeToNats();
  const isSplitBillPage = useIsSplitBillPage();
  const goToMenu = useGoToMenu({ resetOrderStatus: true });
  const isOnConfirmationPage = useIsOrderConfirmationPage();
  const alertDispatch = useAlertDispatch();

  const handleEvents = useCallback(
    (natsPayload) => {
      let isSplitByAmountButOnMenuPage = false;
      const lastServerPayment = natsPayload?.order?.payments
        ?.reverse()
        .find((elem) => !elem.guestId);
      const paymentAmount = lastServerPayment && Number(lastServerPayment?.amount)?.toFixed(2);

      if (natsPayload?.order) {
        updateOrderInCache(natsPayload?.order);
      } else {
        refetch();
      }

      const events = natsPayload?.events;

      events?.forEach((event) => {
        // check prior status if one guest reload confirmation page
        if (
          (event.currentStatus === EOrderStatus.SPLITTING_CHECK ||
            event.currentStatus === EOrderStatus.GROUP_PAYING ||
            event.priorStatus === EOrderStatus.GROUP_PAYING ||
            natsPayload?.order?.groupOrderInfo?.status === EOrderStatus.SPLITTING_CHECK ||
            natsPayload?.order?.groupOrderInfo?.status === EOrderStatus.GROUP_PAYING) &&
          !isSplitByAmountButOnMenuPage
        ) {
          isSplitByAmountButOnMenuPage = true;
        }

        const hasUnsentItems = natsPayload?.order?.items?.some(
          (item: { sentDateTime: any }) => item.sentDateTime === null
        );

        const queryParams: { checkoutType?: string; orderType?: string } = {};
        if (isSplitByAmountButOnMenuPage) {
          queryParams.checkoutType = ECheckoutTypes.SPLIT_BY_AMOUNT;
          queryParams.orderType = EOrderTypes.DINE_IN;
        }

        switch (event.action) {
          case ENatsActions.STATUS_CHANGE:
            notifyOrderStatusChange(event, natsPayload, unsubscribeToNats, queryParams);
            break;
          case ENatsActions.PAYMENTS_ADDED:
            if (savedDineInState?.guest?.id !== natsPayload?.initiator?.guestId) {
              notifyGuestPaymentsAdded(event, natsPayload);
            }

            if (natsPayload?.order?.balanceDueAmount === '0') {
              notifyPaymentIsCompleted(isSplitByAmountButOnMenuPage);
              unsubscribeToNats();
            }
            break;
          case ENatsActions.PAYMENT_AUTH_ADDED:
            updateOrderInCache(natsPayload?.order);
            if (natsPayload?.initiator === null)
              alertDispatch({
                type: 'OPEN_ALERT',
                payload: {
                  alertKey: EAlertTypes.SERVER_TAB_STARTED,
                },
              });

            break;
          case ENatsActions.ORDER_UPDATED:
            if (
              natsPayload?.order?.balanceDueAmount === '0' &&
              natsPayload?.order?.groupOrderInfo?.status !== EOrderStatus.COMPLETED &&
              //the initiator is who ever initiated the event, and will only be null when coming from the FOH
              natsPayload?.initiator === null &&
              !!natsPayload?.order?.closedDateTime &&
              !isOnConfirmationPage
            ) {
              notifyPaymentIsCompleted(isSplitByAmountButOnMenuPage);
              modalDispatch({
                type: 'OPEN_MODAL',
                payload: {
                  modalKey: EModalTypes.CLOSED_ORDER,
                  modalContext: {
                    onClose: () => goToConfirmation(queryParams),
                    drawerHeaderProps: { hideArrow: true },
                    queryParams: queryParams,
                  },
                },
              });
              unsubscribeToNats();
              // notify the user of the change via toast message when the server adds the cash payment to the order.
            } else if (
              natsPayload?.order?.balanceDueAmount !== '0' &&
              natsPayload?.order?.balanceDueAmount !== natsPayload?.order?.displayTotalAmount &&
              natsPayload?.initiator === null &&
              toastId !== lastServerPayment?.id
            ) {
              if (!toast.isActive(lastServerPayment?.id)) {
                toastId = lastServerPayment?.id;

                const message = `Payment of $${paymentAmount} has been made through the server.${
                  window.location.href.includes(`/split-by-amount`)
                    ? ' The remaining balance has been split among unlocked guests.'
                    : ''
                }`;

                notify({
                  id: toastId,
                  description: message,
                  isClosable: true,
                  status: 'info',
                });
              }
              sendEvent(EAnalyticsEventNames.ORDER_TOTAL_CHANGE, {
                googleAnalytics: {
                  location: EGALocations.DINE_IN,
                  object: EAnalyticsEventNames.ORDER_TOTAL_CHANGE,
                },
              });
              // If order updated while isSplitBillPage and order has unsentItems we need to navigate them back to menu
            } else if (isSplitBillPage && hasUnsentItems) {
              notify({
                id: 'returning-to-menu',
                description: `You have unsent items in your bag, please send them to the kitchen or remove them from your bag before checking out.`,
                isClosable: true,
                status: 'info',
              });
              goToMenu();
            }
            break;
        }
      });
    },
    [
      goToConfirmation,
      goToMenu,
      isOnConfirmationPage,
      isSplitBillPage,
      modalDispatch,
      notify,
      notifyGuestPaymentsAdded,
      notifyOrderStatusChange,
      notifyPaymentIsCompleted,
      refetch,
      savedDineInState?.guest?.id,
      sendEvent,
      toast,
      unsubscribeToNats,
      updateOrderInCache,
    ]
  );
  useEffect(() => {
    if (natsState?.messages.length > 0)
      handleEvents(natsState?.messages[natsState.messages.length - 1]);
    //Every time a message is recieved we want the handleEvents function to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [natsState?.messages]);
};
