import { isSubItemGroupValid } from './isSubItemGroupValid';
import { ValidateResult } from 'react-hook-form';
import { isModifierGroupValid } from './isModifierGroupValid';

export const isMenuItemValid = (item: ISubMenuItemDisplay): ValidateResult => {
  const modifiersAreValid = !item.modifiers.some(
    (mg: IMenuItemModifierGroupDisplay) => isModifierGroupValid(mg) !== true
  );
  const subItemsAreValid = !item.subItems.some(
    (sig: ISubItemGroupDisplay) => isSubItemGroupValid(sig) !== true
  );
  return modifiersAreValid && subItemsAreValid;
};
