import { useDeliveryAddressState } from '@olo-web/client-state';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useQuery, UseQueryResult } from 'react-query';

type CheckDeliveryAddressProps = {
  merchantId: string;
  lat: number;
  lng: number;
  includeAllValidLocations?: boolean;
};

export const checkDeliveryAddress = async ({
  merchantId,
  lat,
  lng,
  includeAllValidLocations,
}: CheckDeliveryAddressProps): Promise<IDeliveryLocationConfirmation> => {
  try {
    const url = `/api/merchants/${merchantId}/validate-delivery-coordinates?includeAllValidLocations=${includeAllValidLocations}`;
    const body = { coordinates: [lat, lng] };

    const response = await axios.post(url, body);
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useValidateDeliveryAddress = (
  options?
): UseQueryResult<IDeliveryLocationConfirmation, AxiosError> => {
  const { address, addressForm } = useDeliveryAddressState();
  const router = useRouter();
  const { merchantId } = router.query;
  const { data: merchant } = useMerchant();
  const addressToCheck = addressForm || address;

  const lat = addressToCheck?.lat;
  const lng = addressToCheck?.lng;

  return useQuery(
    ['validateDelivery', merchantId, lat, lng],
    () =>
      checkDeliveryAddress({
        merchantId: merchantId as string,
        lat,
        lng,
        includeAllValidLocations: true,
      }),
    {
      staleTime: 1000000,
      enabled: !!(!merchant?.doorDashDriveDelivery && merchantId && lat && lng),
      ...options,
    }
  );
};
