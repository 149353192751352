import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import { isOfferARewardOrDeal } from '../functions';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useAddPromoCodeToOrder } from '@olo-web/domain/orders/mutations/useAddPromoCodeToOrder';
import { useAddRewardOrDealToOrder } from '@olo-web/domain/orders/mutations/useAddRewardOrDealToOrder';
import { useRemoveRewardOrDealFromOrder } from '@olo-web/domain/orders/mutations/useRemoveRewardOrDealFromOrder';
import { useRemovePromoCodeFromOrder } from '@olo-web/domain/orders/mutations/useRemovePromoCodeFromOrder';
import { EAnalyticsEventNames, EGALocations, EToastId } from '@olo-web/types/enums';
import { useSendEvent, useToast } from '@olo-web/utils/common/hooks';
import { useCheckoutDispatch, useModalDispatch, useModalState } from '@olo-web/client-state';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useLoyalty } from '@olo-web/domain/customer';
import { useError } from '@olo-web/utils/common/hooks';
import { createFriendlyErrorMessage } from '@olo-web/utils';
import {
  ILoyaltyItem,
  IPromoCode,
  IPromoCodeDiscount,
} from '@olo-web/types/loyaltyReward.interface';
import { IOrderDiscount } from '@domain/orders/types';

interface IApplyOrRemoveOfferOptions {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

export const useApplyOrRemoveOffer = (options?: IApplyOrRemoveOfferOptions) => {
  const router = useRouter();
  const { pathname, query } = router;
  const { data: order } = useOrder();
  const { data: loyalty } = useLoyalty();
  const modalDispatch = useModalDispatch();
  const { data: merchant } = useMerchant();
  const checkoutDispatch = useCheckoutDispatch();
  const { notify } = useToast();
  const { sendEvent } = useSendEvent();
  const { modalContext } = useModalState();
  const onSuccessApplyOffer = () => {
    setTimeout(() => {
      modalDispatch({ type: 'CLOSE_MODAL' });
      // open BagDrawer after closing ApplyOfferDrawer
      // since ApplyOfferDrawer has problems with scrolling if BagDrawer is open
      modalContext?.handleOpenBagDrawer?.();
    }, 700);
  };
  const callError = useError();
  const {
    query: { dealCode, checkId },
  } = useRouter();

  const {
    mutateAsync: removeRewardOrDeal,
    isLoading: removingRewardOrDeal,
    isError: isRemoveRewardOrDealError,
    error: removeRewardOrDealError,
  } = useRemoveRewardOrDealFromOrder({
    onSuccess: () => {
      checkoutDispatch({
        type: 'CLEAR_SELECTED_OFFER',
      });
    },
  });
  const {
    mutateAsync: addRewardOrDeal,
    isLoading: addingRewardOrDeal,
    isError: isAddRewardOrDealError,
    error: addRewardOrDealError,
  } = useAddRewardOrDealToOrder({
    onSuccess: (data) => {
      options?.onSuccess?.(data);
      onSuccessApplyOffer();

      notify({
        id: 'success-reward',
        status: 'success',
        description: 'Reward was added',
        duration: 10000,
        bannerProps: {
          width: 0,
          height: 0,
          padding: 0,
          top: '-500px',
          overflow: 'hidden',
          'aria-live': 'assertive',
        },
      });
    },
    onError: (error) => {
      if (options?.onError) {
        options.onError(error);
        return;
      }

      let id;
      if (error.message?.includes('Order must have a discountable items')) {
        id = EToastId.undiscountableItem;
      }
      callError({ error, toastError: true, title: 'No reward added', toastId: id });
    },
  });
  const {
    mutateAsync: removePromo,
    isLoading: removingPromo,
    isError: isRemovePromoCodeError,
    error: removePromoCodeError,
  } = useRemovePromoCodeFromOrder();
  const {
    mutateAsync: addPromo,
    isLoading: addingPromo,
    isError: isAddPromoCodeError,
    error: addPromoCodeError,
    reset: resetAddPromoCode,
  } = useAddPromoCodeToOrder({
    onSuccess: (data, variables) => {
      if (options?.onSuccess) {
        options?.onSuccess(data);
      } else {
        if (!order?.items || order?.items?.length === 0) {
          notify({
            id: 'success-promocode',
            status: 'success',
            description:
              'The promotion has been added to your order and will be applied at checkout. Start adding items and save! 🎉',
            isClosable: true,
            bannerProps: {
              'aria-live': 'assertive',
            },
          });
        } else {
          notify({
            id: 'success-promocode',
            status: 'success',
            description: 'The promotion has been added to your order 🎉',
            isClosable: true,
            bannerProps: {
              'aria-live': 'assertive',
            },
          });
        }

        if (!dealCode) {
          modalDispatch({ type: 'CLOSE_MODAL' });
        }

        // open BagDrawer after closing ApplyOfferDrawer
        // since ApplyOfferDrawer has problems with scrolling if BagDrawer is open
        modalContext?.handleOpenBagDrawer?.();
      }

      sendEvent(EAnalyticsEventNames.DEFAULT, {
        googleAnalytics: {
          eventInfo: {
            location: EGALocations.ONLINE_ORDER,
            action: 'submit',
            object: EAnalyticsEventNames.PROMOTION_APPLIED,
          },
          eventMetadata: {
            promo_add_method: variables.promoCode === dealCode ? 'automatic' : 'manual',
            promo_code_id: variables.promoCode,
            discountTotal_real: data.discounts[0].displayAmount,
          },
        },
      });
    },
    onError: (error, variables) => {
      if (options?.onError) {
        options.onError(error);
      } else {
        if (error && dealCode) {
          notify({
            id: 'wrong-promocode',
            status: 'info',
            description: createFriendlyErrorMessage(error),
            isClosable: true,
            bannerProps: {
              'aria-live': 'assertive',
            },
          });
          delete router.query.dealCode;
          delete router.query.dealSource;
          delete router.query.dealType;
          router.replace({ pathname, query }, undefined, { shallow: true });
        }
      }

      sendEvent(EAnalyticsEventNames.DEFAULT, {
        googleAnalytics: {
          eventInfo: {
            location: EGALocations.ONLINE_ORDER,
            action: 'submit',
            object: EAnalyticsEventNames.PROMOTION_INVALID,
          },
          eventMetadata: {
            reason: createFriendlyErrorMessage(error),
            promo_code_id: variables.promoCode,
            promo_add_method: variables.promoCode === dealCode ? 'automatic' : 'manual',
          },
        },
      });
    },
  });
  const common = useMemo(
    () => ({
      orderId: order?.id,
      merchantId: merchant?.merchantId,
      spotOnCustomerId: loyalty?.customerId,
    }),
    [order?.id, merchant?.merchantId, loyalty?.customerId]
  );

  const applyOffer = useCallback(
    async (loyaltyItem: ILoyaltyItem | IPromoCodeDiscount | IPromoCode | string) => {
      const appliedOffer = order?.discounts?.[0];
      if (appliedOffer) {
        if (isOfferARewardOrDeal(appliedOffer)) {
          await removeRewardOrDeal({
            rewardId: appliedOffer?.spotOnRewardId,
            spotOnCustomerId: loyalty?.customerId,
            ...common,
          });
        } else {
          await removePromo({
            discountId: appliedOffer?.id,
            ...common,
          });
        }
      }

      if (isOfferARewardOrDeal(loyaltyItem)) {
        await addRewardOrDeal({
          rewardId: (loyaltyItem as ILoyaltyItem | IPromoCodeDiscount).promotionId,
          checkId: checkId as string,
          ...common,
        });
      } else {
        await addPromo({
          promoCode: (loyaltyItem as IPromoCode)?.promoCode || (loyaltyItem as string),
          dealType: (loyaltyItem as IPromoCode)?.promoCode,
          dealSource: (loyaltyItem as IPromoCode)?.dealSource,
          ...common,
        });
      }
    },
    [
      addPromo,
      addRewardOrDeal,
      loyalty?.customerId,
      order?.discounts,
      removePromo,
      removeRewardOrDeal,
      checkId,
      common,
    ]
  );

  const removeOffer = useCallback(
    async (loyaltyItem: ILoyaltyItem | IPromoCodeDiscount | IOrderDiscount) => {
      if (isOfferARewardOrDeal(loyaltyItem)) {
        await removeRewardOrDeal({
          rewardId:
            'promotionId' in loyaltyItem
              ? loyaltyItem?.promotionId
              : (loyaltyItem as any)?.spotOnRewardId,
          spotOnCustomerId: loyalty?.customerId,
          ...common,
        });
      } else {
        await removePromo({
          discountId:
            'tempPromotionId' in loyaltyItem
              ? loyaltyItem?.tempPromotionId
              : 'promotionId' in loyaltyItem
              ? loyaltyItem?.promotionId.toString()
              : (loyaltyItem as any)?.spotOnRewardId,
          ...common,
        });
      }
    },
    [common, loyalty?.customerId, removePromo, removeRewardOrDeal]
  );

  return {
    applyOffer,
    removeOffer,
    resetAddPromoCode,
    error:
      removeRewardOrDealError || addRewardOrDealError || removePromoCodeError || addPromoCodeError,
    isLoading: removingPromo || removingRewardOrDeal || addingPromo || addingRewardOrDeal,
    isError:
      isRemoveRewardOrDealError ||
      isAddRewardOrDealError ||
      isRemovePromoCodeError ||
      isAddPromoCodeError,
  };
};
