import { useEffect, useMemo, useState } from 'react';
import { useMerchantOrderTypeDetails, useMerchant } from '@domain/merchants/queries';
import dayjs from 'dayjs';
import {
  useIsDelivery,
  useEstimatedTimeText,
  useIsThirdPartyDelivery,
} from '@olo-web/utils/common/hooks';
import { useUpdateOrder } from '@olo-web/domain/orders/mutations/useUpdateOrder';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useAlertState, useCheckoutState, useModalState } from '@olo-web/client-state';
import { EAlertTypes } from '@olo-web/types/enums';
import { useEstimateDelivery } from '@olo-web/domain/deliveryService/queries/useEstimateDelivery';
import { useScreenSizeState } from '@olo-web/client-state';
import { useToast } from '@olo-web/utils/common/hooks';
import { useHandleAsapLogicOnServer } from '@olo-web/utils/common/hooks';

const THRESHOLD_ASAPTIME_CHANGE = 14; // minutes
const toastId = 'asap-order-time-changed'; // no more than one notify for the same reason

const calculateDifference = (prevOrderDate: string, orderDate: string): number | null => {
  const newDateTime = dayjs(orderDate);
  const prevDate = dayjs(prevOrderDate);
  if (newDateTime.isValid() && prevDate.isValid()) {
    return Math.abs(newDateTime.diff(prevDate, 'minute'));
  } else {
    return null;
  }
};

export const useOrderTimeChangeToast = () => {
  const [prevTimeDelta, setPrevTimeDelta] = useState<number | null>(null);
  const { notify } = useToast();
  const { modalContext } = useModalState();
  const { alertKey } = useAlertState();
  const { isMd } = useScreenSizeState();
  const { data: orderTypeDetails, isError: isOrderTypeDetailsError } =
    useMerchantOrderTypeDetails();
  const { data: order } = useOrder();
  const { refetch: refecthMerchant, data: merchant } = useMerchant();
  const { mutate: updateOrder, isLoading: isOrderUpdating } = useUpdateOrder();
  const { data: deliveryEstimate } = useEstimateDelivery(true);
  const { data: currentDeliveryEstimate } = useEstimateDelivery();
  const { isLoading } = useCheckoutState();
  const handleAsapLogicOnServer = useHandleAsapLogicOnServer();

  const isDelivery = useIsDelivery();
  const isThirdPartyDelivery = useIsThirdPartyDelivery();

  const orderText = useEstimatedTimeText(
    isThirdPartyDelivery ? deliveryEstimate?.deliveryTime : orderTypeDetails?.asapOrderDateTime
  );
  const orderTypeId = order?.orderTypeId ?? merchant?.defaultOrderTypeId;

  const orderTimeIsStale = useMemo(() => {
    const orderDateTime = dayjs(order?.orderDateTime);
    const asapDateTime = dayjs(orderTypeDetails?.asapOrderDateTime);

    return orderDateTime.isBefore(asapDateTime, 'minutes');
  }, [order?.orderDateTime, orderTypeDetails?.asapOrderDateTime]);

  useEffect(() => {
    if (handleAsapLogicOnServer) return;
    const currOrderTime = isThirdPartyDelivery
      ? currentDeliveryEstimate?.deliveryTime
      : order?.orderDateTime;

    // If the prevTimeDelta is null and we can calucalte a currOrderTime, we should set the delta so
    // that any future updates have a delta to compare to this should only happen once as we never
    // set this value to null
    if (prevTimeDelta === null && currOrderTime) {
      setPrevTimeDelta(calculateDifference(currOrderTime, dayjs().toISOString()));
    }
  }, [
    currentDeliveryEstimate?.deliveryTime,
    isThirdPartyDelivery,
    order?.orderDateTime,
    prevTimeDelta,
    handleAsapLogicOnServer,
  ]);

  useEffect(() => {
    if (handleAsapLogicOnServer) return;
    // If current order type's asapTime is null, or if it is in error we want to trigger a refetch
    // of the merchant in case olo is paused or the current order type is no longer valid
    // refetching the merchant will automatically trigger either a change to the order type or a an
    // alert for ordering unavailable if needed
    if (orderTypeDetails?.asapOrderDateTime === null || isOrderTypeDetailsError) {
      refecthMerchant();
    }

    if (
      // Don't try to update the order time if the order doesn't exist, is already submitted, or is
      // being updated
      !order?.id ||
      order?.orderNumber !== '' ||
      !orderTypeDetails?.asapOrderDateTime ||
      isOrderUpdating ||
      // If we are using delivery service we want to ensure that the delivery time is available
      // before we make any updates, so we can show the user the time in the notify
      (isThirdPartyDelivery &&
        (!deliveryEstimate?.deliveryTime || !currentDeliveryEstimate?.deliveryTime)) ||
      // If the pacing modal is open or the order time modal is open because of a pacing error, then
      // the order time will be handled there instead of here
      alertKey === EAlertTypes.PACING_CAPACITY_ALERT ||
      modalContext?.isPacingError
    )
      return;

    if (orderTimeIsStale && !isLoading) {
      const newOrderTime = isThirdPartyDelivery
        ? deliveryEstimate?.deliveryTime
        : orderTypeDetails?.asapOrderDateTime;

      const newOrderTimeDelta = calculateDifference(newOrderTime, dayjs().toISOString());

      const absDiff = Math.abs(prevTimeDelta - newOrderTimeDelta);

      updateOrder({
        merchantId: merchant?.merchantId,
        orderId: order?.id,
        orderInfo: {
          orderTypeId,
          orderDateTime: orderTypeDetails?.asapOrderDateTime,
        },
      });

      if (prevTimeDelta !== null && absDiff >= THRESHOLD_ASAPTIME_CHANGE) {
        notify({
          id: toastId,
          title: `We've updated your ${isDelivery ? 'delivery' : 'pickup'} time`,
          description: `Your new estimated time is ${orderText}`,
          isClosable: true,
          duration: null,
          status: 'info',
          variant: 'left-accent',
          position: isMd ? 'top-right' : 'top',
        });
      }

      setPrevTimeDelta(calculateDifference(newOrderTime, dayjs().toISOString()));
    }
  }, [
    handleAsapLogicOnServer,
    currentDeliveryEstimate?.deliveryTime,
    deliveryEstimate?.deliveryTime,
    isDelivery,
    isThirdPartyDelivery,
    isMd,
    isOrderTypeDetailsError,
    merchant?.merchantId,
    order,
    order?.id,
    orderText,
    orderTimeIsStale,
    orderTypeDetails,
    orderTypeDetails?.asapOrderDateTime,
    orderTypeId,
    refecthMerchant,
    notify,
    updateOrder,
    alertKey,
    modalContext?.isPacingError,
    prevTimeDelta,
    isOrderUpdating,
    isLoading,
  ]);
};
