import { EFirestoreCollections } from '@olo-web/types/enums/firestoreCollections.enum';
import { useFeatureFlags } from '@olo-web/domain/featureFlags';
import { firestore } from '@olo-web/utils/google/firebase';
import { FirebaseError } from 'firebase-admin';
import { useRouter } from 'next/router';
import { useQuery, UseQueryResult } from 'react-query';
import { IMerchantTheme } from '@olo-web/types/merchantTheme.interface';

export const getMerchantTheme = async (merchantId: string): Promise<IMerchantTheme> => {
  try {
    if (!firestore) return undefined;
    const res = await firestore()
      .collection(EFirestoreCollections.MERCHANT_THEME)
      .where('merchantIds', 'array-contains', merchantId)
      .get();
    if (res.empty) return null;
    const docs = res.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    if (docs.length > 1) console.error(`Merchant id ${merchantId} has multiple docs`);
    return docs[0] as IMerchantTheme;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useMerchantTheme(
  merchantId?: string,
  initialData?: IMerchantTheme
): UseQueryResult<IMerchantTheme, FirebaseError> {
  const router = useRouter();
  const { data: flags } = useFeatureFlags();
  const { merchantId: merchantIdFromUrl } = router.query;
  const merchId = merchantId || merchantIdFromUrl;
  const enabled = !!(!flags?.hideMerchantTheming && merchId);

  return useQuery(
    [EFirestoreCollections.MERCHANT_THEME, merchId],
    () => getMerchantTheme(merchId as string),
    {
      staleTime: 1000000,
      enabled,
      initialData,
    }
  );
}
