import { ModalHeader, Icon, IconButton, ModalHeaderProps, Flex } from '@chakra-ui/react';
import { useModalDispatch, useModalState } from '@olo-web/client-state';
import React, { FC } from 'react';
import { ArrowLeft } from 'react-feather';

interface I extends ModalHeaderProps {
  children: any;
}

export const CommonModalHeader: FC<I> = (props) => {
  const { children, ...rest } = props;
  const { previousModal = [] } = useModalState();
  const modalDispatch = useModalDispatch();

  const handleClick = () => {
    modalDispatch({
      type: 'OPEN_PREVIOUS_MODAL',
    });
  };

  return (
    <ModalHeader display="flex" alignItems="center" data-testid="modal-header" {...rest}>
      {previousModal.length > 0 && (
        <IconButton
          variant="ghost"
          mr={2}
          ml={-2}
          aria-label="Go back to previous modal"
          icon={<Icon as={ArrowLeft} h={6} w={6} />}
          onClick={handleClick}
        />
      )}
      <Flex align="center" h="44px">
        {children}
      </Flex>
    </ModalHeader>
  );
};
