import { useCallback, useMemo } from 'react';
import {
  useModalDispatch,
  useDeliveryAddressState,
  useDeliveryAddressDispatch,
} from '@olo-web/client-state';
import { useIsDelivery } from '@olo-web/utils/common/hooks';
import { EModalTypes } from '@olo-web/types/enums';

export const useDeliveryModal = () => {
  const modalDispatch = useModalDispatch();
  const { address } = useDeliveryAddressState();
  const deliveryAddressDispatch = useDeliveryAddressDispatch();
  const isDelivery = useIsDelivery();
  const closeDeliveryModal = useCallback(() => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  }, [modalDispatch]);
  const openDeliveryModal = useCallback(
    (options = {} as any) => {
      const { showNeedAddressWarning } = options;

      modalDispatch({
        type: 'OPEN_MODAL',
        payload: {
          modalKey: EModalTypes.CUSTOMER_DELIVERY_ADDRESS,
          modalContext: {
            showNeedAddressWarning,
            onClose: () =>
              deliveryAddressDispatch({
                type: 'UPDATE_ADDRESS_FORM',
                payload: address || null,
              }),
          },
        },
      });
    },
    [address, deliveryAddressDispatch, modalDispatch]
  );

  const shouldShowDeliveryModal = useMemo(
    () => !address?.address1 && isDelivery,
    [address?.address1, isDelivery]
  );

  const conditionalyOpenDeliveryModal = useCallback(() => {
    if (isDelivery) {
      openDeliveryModal();
    }
  }, [openDeliveryModal, isDelivery]);

  return {
    openDeliveryModal,
    closeDeliveryModal,
    conditionalyOpenDeliveryModal,
    shouldShowDeliveryModal,
  };
};
