import { createStore } from '@olo-web/utils/common/store';

type TClearOrderUnavailabilityAction = Omit<IStandardAction<'CLEAR'>, 'payload'>;
type TClearOrderUnavailabilityItemsAction = Omit<IStandardAction<'CLEAR_ITEMS'>, 'payload'>;
type TClearOrderUnavailabilityModsAction = Omit<IStandardAction<'CLEAR_MODS'>, 'payload'>;
type TSetOrderUnavailabilityAction = IStandardAction<'SET', IOrderUnavailability>;
type TRemoveItemsOrderUnavailablilityAction = IStandardAction<'REMOVE_ITEMS', string[]>;

type TOrderUnavailabilityAction =
  | TClearOrderUnavailabilityAction
  | TClearOrderUnavailabilityItemsAction
  | TSetOrderUnavailabilityAction
  | TRemoveItemsOrderUnavailablilityAction
  | TClearOrderUnavailabilityModsAction;

const initialState = {
  unavailableItems: [],
  unavailableModifiers: [],
};

const reducer = (
  state: IOrderUnavailability,
  action: TOrderUnavailabilityAction
): IOrderUnavailability => {
  switch (action.type) {
    case 'CLEAR':
      return initialState;
    case 'SET':
      return action.payload;
    case 'CLEAR_ITEMS':
      return {
        ...state,
        unavailableItems: [],
      };
    case 'CLEAR_MODS':
      return {
        ...state,
        unavailableModifiers: [],
      };
    case 'REMOVE_ITEMS':
      return {
        ...state,
        unavailableItems: state.unavailableItems?.filter(
          (u) => !action.payload.includes(u?.orderItems[0]?.name ?? '')
        ),
        unavailableModifiers: state.unavailableModifiers?.filter(
          (u) => !action.payload.includes(u?.itemName)
        ),
      };
    default:
      return state;
  }
};

export const [
  OrderUnavailabilityProvider,
  useOrderUnavailabilityDispatch,
  useOrderUnavailabilityState,
] = createStore<IOrderUnavailability, TOrderUnavailabilityAction>(reducer, initialState, {
  name: 'orderUnavailability',
});
