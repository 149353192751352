import { createStore } from '@olo-web/utils/common/store';

type TSetScreenSizeAction = IStandardAction<'SET', IScreenSize>;
type TClearScreenSizeAction = TEmptyAction<'CLEAR'>;

type TScreenSizeAction = TSetScreenSizeAction | TClearScreenSizeAction;

export const initialScreenSizeState: IScreenSize = {
  isXxsDown: true,
  isXxs: false,
  isXsDown: true,
  isXs: false,
  isSmDown: true,
  isSm: false,
  isMdDown: true,
  isMd: false,
  isLgDown: true,
  isLg: false,
  isXlDown: true,
  isXl: false,
  isXxlDown: true,
  isXxl: false,
};

const reducer = (state: IScreenSize, action: TScreenSizeAction): IScreenSize => {
  switch (action.type) {
    case 'SET':
      return action.payload;
    case 'CLEAR':
      return initialScreenSizeState;
    default:
      return state;
  }
};

export const [ScreenSizeProvider, useScreenSizeDispatch, useScreenSizeState] = createStore<
  IScreenSize,
  TScreenSizeAction
>(reducer, initialScreenSizeState, { name: 'screenSize' });
