import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import queryStringHelper from 'query-string';
import { useInvalidatePreviousOrdersQuery } from '@olo-web/domain/orders/queries/useGetPreviousOrdersFromMerchant';
import { useMerchantPath } from '@domain/merchants/hooks/useMerchantPath';
import { getQueryString } from '../functions';
import { EOrderTypes } from '@olo-web/types/enums';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';

interface IOptions {
  invalidatePreviousOrders?: boolean;
}

type TQueryParams = Record<string, string>;

export const useGoToConfirmation = (options: IOptions = {}) => {
  const [hasGoneToConfirmation, setHasGoneToConfirmation] = useState(false);
  const invalidatePreviousOrdersQuery = useInvalidatePreviousOrdersQuery();
  const merchantPath = useMerchantPath();
  const existingQueryString = getQueryString();
  const router = useRouter();
  const isDinein = useIsDineIn();
  const { data: order } = useOrder();

  const query = useMemo(() => {
    const innerQuery = queryStringHelper.parse(existingQueryString);

    if (innerQuery.cameFrom) {
      innerQuery.checkoutType = innerQuery.cameFrom;
      delete innerQuery.cameFrom;
    }

    if (isDinein && !(innerQuery.orderType === EOrderTypes.DINE_IN)) {
      innerQuery.orderType = EOrderTypes.DINE_IN;
    }

    return innerQuery;
  }, [existingQueryString, isDinein]);

  return useCallback(
    (queryParamsArg: TQueryParams = {}) => {
      if (options.invalidatePreviousOrders) {
        invalidatePreviousOrdersQuery();
      }

      if (!hasGoneToConfirmation) {
        router.push(
          {
            pathname: `${merchantPath}/thank-you/${order?.id || query.orderId}`,
            query: { ...query, ...queryParamsArg },
          },
          undefined,
          {
            shallow: true,
          }
        );
        setHasGoneToConfirmation(true);
      }
    },
    [
      options.invalidatePreviousOrders,
      hasGoneToConfirmation,
      invalidatePreviousOrdersQuery,
      router,
      merchantPath,
      order?.id,
      query,
    ]
  );
};
