import { useMemo } from 'react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { isFeatureEnabledForMerchant } from '@olo-web/utils/common/functions/isFeatureEnabledForMerchant';
import { useFeatureFlags } from '@domain';

export const useHandleAsapLogicOnServer = () => {
  const { data: merchant } = useMerchant();
  const { data: featureFlags } = useFeatureFlags();

  return useMemo(
    () =>
      isFeatureEnabledForMerchant({
        merchantId: merchant?.merchantId,
        featureEnabled: featureFlags?.handleAsapLogicOnServer?.on,
        allowList: featureFlags?.handleAsapLogicOnServer?.allow,
        denyList: featureFlags?.handleAsapLogicOnServer?.deny,
      }),
    [merchant, featureFlags]
  );
};
