import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Divider,
  Button,
  Icon,
  Text,
  Box,
  VStack,
} from '@chakra-ui/react';
import { useError } from '@olo-web/utils/common/hooks';
import { createFriendlyErrorMessage } from '@olo-web/utils/common/functions';
import { ChevronRight } from 'react-feather';
import React, { useMemo, useEffect } from 'react';
import { ConditionalWrapper } from '../Wrappers';
import { Link } from '../Link';
import { IBannerProps } from '@olo-web/types/bannerProps.interface';
import { useBannerColorScheme } from './hooks/useBannerColorScheme';

export const Banner = (props: IBannerProps) => {
  const {
    error,
    title,
    description,
    onClose,
    actionText,
    actionIcon,
    onAction,
    useErrorProps = {},
    status = 'info',
    actionHref,
    descriptionTestId,
    ...rest
  } = props;
  const callError = useError();
  const desc = useMemo(() => {
    if (!description && error) return createFriendlyErrorMessage(error);
    if (!description) return null;
    // Why are we using '--brkpt--' instead of '\n'? For some reason, no matter what is tried
    //...the includes() function will not recognize \n as string
    if (Array.isArray(description)) {
      return description.map((d, i) => (
        <Text mt={2} key={`${d}-${i + 1}`}>
          {d}
        </Text>
      ));
    }
    return description;
  }, [description, error]);

  // TODO - Try to refactor to use onError in hook
  useEffect(() => {
    if (error) {
      callError({
        error: status === 'error' && error,
        title,
        description,
        ...useErrorProps,
      });
    }
  }, [callError, description, error, status, title, useErrorProps]);

  const canClose = Boolean(onClose);

  const colorScheme = useBannerColorScheme(status, props?.colorScheme);

  return (
    <Alert
      status={status}
      alignItems="flex-start"
      justifyContent="flex-start"
      borderRadius="4px"
      colorScheme={colorScheme as any}
      bg={`${colorScheme}.100`}
      {...rest}
    >
      <AlertIcon h={5} w={5} />
      <VStack justifyContent="flex-start" w="100%" alignItems="flex-start">
        {title && (
          <AlertTitle fontSize="sm" mr={canClose ? 8 : 0} as="h2">
            {title}
          </AlertTitle>
        )}
        {desc && (
          <AlertDescription
            fontSize="sm"
            mr={canClose && !title ? 8 : 0}
            data-testid={descriptionTestId}
          >
            {desc}
          </AlertDescription>
        )}
        {Boolean((onAction || actionHref) && actionText) && (
          <Box w="100%">
            <Divider borderBottomColor="blackAlpha.300" />
            <ConditionalWrapper
              condition={!!(!onAction && actionHref)}
              wrapper={(children) => <Link href={actionHref}>{children}</Link>}
            >
              <Button
                size="md"
                justifyContent="space-between"
                variant="tertiary"
                px={0}
                color="blackAlpha.800"
                _hover={{
                  color: 'blackAlpha.900',
                }}
                aria-label={actionText}
                rightIcon={<Icon as={actionIcon || ChevronRight} h={5} w={5} />}
                onClick={(e) => (actionHref ? null : onAction?.(e))}
                mb={-3}
                w="100%"
              >
                {actionText}
              </Button>
            </ConditionalWrapper>
          </Box>
        )}
      </VStack>
      {canClose && <CloseButton position="absolute" right={2} top={1.5} onClick={onClose} />}
    </Alert>
  );
};
