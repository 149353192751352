import { useRouter } from 'next/router';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useIsOrderTypeInvalid } from './useIsOrderTypeInvalid';

export const useOrderTypeId = () => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const {
    query: { orderType },
  } = useRouter();
  const isOrderTypeInvalid = useIsOrderTypeInvalid();

  // If the order type on the persisted order is not valid we should not use it anymore (this can
  // happen if the settings in BOH are changed)
  if (isOrderTypeInvalid)
    return merchant?.parsedOrderTypeIds?.[orderType as string] ?? merchant?.defaultOrderTypeId;

  return (
    order?.orderTypeId ??
    merchant?.parsedOrderTypeIds?.[orderType as string] ??
    merchant?.defaultOrderTypeId
  );
};
