import axios, { AxiosError } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { IUseAddUpdateAddressProps } from '..';
import { serializeToSnakeCase } from '../../../utils';
import { useCustomerState } from '@olo-web/client-state';
import { configHeaders } from '../functions';

export const addUpdateAddress = async ({
  customerId,
  sessionId,
  sessionSecret,
  loginTimestamp,
  address,
}: IUseAddUpdateAddressProps): Promise<any> => {
  try {
    const url = `/api/customers/${customerId}/add-update-address`;
    const body = serializeToSnakeCase(address);
    const headers = configHeaders({
      customerId,
      sessionId,
      sessionSecret,
      loginTimestamp: +loginTimestamp,
    });
    const response = await axios.post(url, body, { headers });
    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useAddUpdateAddress(options?: MutationOptions<any, AxiosError, ICustomerAddress>) {
  const { customerId, sessionId, sessionSecret, loginTimestamp } = useCustomerState();

  return useMutation(
    (address) =>
      addUpdateAddress({
        address,
        customerId,
        sessionId,
        sessionSecret,
        loginTimestamp,
      }),
    options
  );
}
