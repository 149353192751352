import { createStore } from '@olo-web/utils/common/store';

type IOrderReviewContextState = {
  [key: string]: boolean;
} | null;

type TAddOrderReviewAction = IStandardAction<'ADD', IOrderReviewContextState>;
type TClearOrderReviewsAction = IStandardAction<'CLEAR', IOrderReviewContextState>;

type TOrderReviewContextAction = TAddOrderReviewAction | TClearOrderReviewsAction;

const initialState = {};

const reducer = (
  state: IOrderReviewContextState,
  action: TOrderReviewContextAction
): IOrderReviewContextState => {
  switch (action.type) {
    case 'CLEAR':
      return initialState;
    case 'ADD':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const [OrderReviewContextProvider, useOrderReviewDispatch, useOrderReviewState] =
  createStore<IOrderReviewContextState, TOrderReviewContextAction>(reducer, initialState, {
    name: 'orderReviewContext',
    persist: 'local',
  });
