import { useIsLoggedIn } from '@olo-web/utils/common/hooks/useIsLoggedIn';
import { AxiosError } from 'axios';
import { useGuestLoyalty } from '../queries/useGuestLoyalty';
import { useJoinCustomerWithMerchant } from '../queries/useJoinCustomerWithMerchant';
import { UseQueryResult } from 'react-query';
import { useCheckoutState } from '@olo-web/client-state';

interface IRewards extends IGuestLoyalty {
  hasLoyalty?: boolean;
  totalCheckIns?: number;
  totalSpend?: string;
}
export const useLoyalty = (): UseQueryResult<IRewards, AxiosError> => {
  const isLoggedIn = useIsLoggedIn();
  const { utmParams } = useCheckoutState();
  const join = useJoinCustomerWithMerchant({ utmParams });
  const guestLoyalty = useGuestLoyalty();

  return isLoggedIn ? join : guestLoyalty;
};
