import React, { ComponentType, useMemo, useRef } from 'react';
import dynamic from 'next/dynamic';
import { AlertDialog, AlertDialogOverlay } from '@chakra-ui/react';
import { useAlertState, useAlertDispatch } from '@olo-web/client-state';
import { EAlertTypes } from '@olo-web/types/enums';

const MaxOrderAmountAlert = dynamic(() => import('./MaxOrderAmountAlert'));
const OrderingUnavailable = dynamic(() => import('./OrderingUnavailable'));
const ProductUnavailabilityAlert = dynamic(() => import('./ProductUnavailabilityAlert'));
const ConfirmLocationChangeAlert = dynamic(() => import('./ConfirmLocationChangeAlert'));
const PacingCapacityAlert = dynamic(() => import('./PacingCapacityAlert'));
const RewardsCapacityAlert = dynamic(() => import('./RewardsCapacityAlert'));
const RewardToAnEmptyBagAlert = dynamic(() => import('./RewardToAnEmptyBagAlert'));
const NoAvailableTimesAlert = dynamic(() => import('./NoAvailableTimesAlert'));
const ValidateOrderTypeAlert = dynamic(() => import('./ValidateOrderTypeAlert'));
const SBAPaymentOverBalanceAlert = dynamic(() => import('./SBAPaymentOverBalanceAlert'));
const ServerTabStarted = dynamic(() => import('./ServerTabStarted'));
const DeleteAccountAlert = dynamic(() => import('./DeleteAccountAlert'));

type TAlertListenerProps = {
  Component: ComponentType<any> | null;
};

export function AlertListener(): JSX.Element {
  const alertState = useAlertState();
  const alertDispatch = useAlertDispatch();
  const cancelRef = useRef(null);

  const { Component } = useMemo((): TAlertListenerProps => {
    switch (alertState?.alertKey) {
      case EAlertTypes.NO_AVAILABLE_TIMES_ALERT:
        return {
          Component: NoAvailableTimesAlert,
        };
      case EAlertTypes.SBA_PAYMENT_OVER_BALANCE_ALERT:
        return {
          Component: SBAPaymentOverBalanceAlert,
        };
      case EAlertTypes.SHOW_UNAVAILABLE_ORDER:
        return {
          Component: OrderingUnavailable,
        };
      case EAlertTypes.PRODUCT_UNAVAILABILITY:
        return {
          Component: ProductUnavailabilityAlert,
        };
      case EAlertTypes.MAX_ORDER_AMOUNT:
        return {
          Component: MaxOrderAmountAlert,
        };
      case EAlertTypes.LOCATION_CHANGE_EMPTIED_BAG:
        return {
          Component: ConfirmLocationChangeAlert,
        };
      case EAlertTypes.PACING_CAPACITY_ALERT:
        return {
          Component: PacingCapacityAlert,
        };
      case EAlertTypes.REWARDS_CAPACITY_ALERT:
        return {
          Component: RewardsCapacityAlert,
        };
      case EAlertTypes.REWARD_TO_AN_EMPTY_BAG_ALERT:
        return {
          Component: RewardToAnEmptyBagAlert,
        };
      case EAlertTypes.VALIDATE_ORDER_TYPE_ALERT:
        return {
          Component: ValidateOrderTypeAlert,
        };
      case EAlertTypes.SERVER_TAB_STARTED: {
        return {
          Component: ServerTabStarted,
        };
      }
      case EAlertTypes.DELETE_ACCOUNT:
        return {
          Component: DeleteAccountAlert,
        };
      default:
        return {
          Component: null,
        };
    }
  }, [alertState?.alertKey]);

  return (
    <AlertDialog
      isOpen={!!alertState?.alertKey}
      onClose={() => alertDispatch({ type: 'CLOSE_ALERT' })}
      leastDestructiveRef={cancelRef}
      closeOnOverlayClick={alertState.isCloseOnOutsideClick}
    >
      <AlertDialogOverlay />
      {Component && <Component onClose={() => alertDispatch({ type: 'CLOSE_ALERT' })} />}
    </AlertDialog>
  );
}
