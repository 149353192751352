import { useMemo, Children, isValidElement, cloneElement } from 'react';

type UseChildrenWithPropsArgs = {
  children: JSX.Element[];
  props: any;
};

export const useChildrenWithProps = ({ children, props }): UseChildrenWithPropsArgs => {
  const childrenWithProps = useMemo(() => {
    if (!children?.length) return [];
    return Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, props);
      }
      return child;
    });
  }, [children, props]);

  return childrenWithProps;
};
