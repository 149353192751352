import { useMerchant } from '@domain/merchants/queries/useMerchant';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useMemo } from 'react';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

export const useCurrentMerchantDayJs = (): {
  dayjs: typeof dayjs;
  convertToMerchantTime: (date?: Date) => Dayjs;
} => {
  const { data: merchant } = useMerchant();
  return useMemo(() => {
    if (merchant) dayjs.tz.setDefault(merchant?.locationTimezone);

    return { dayjs, convertToMerchantTime: (date?: Date) => dayjs(date) };
  }, [merchant]);
};
