export const menuItemImageLoader = ({ src, width }) => {
  const isHighQuality = src.includes('menu_hq');
  if (!isHighQuality) return src;

  const uploadIndex = src.indexOf('upload/');
  if (uploadIndex === -1) return src;

  const urlTillUpload = src.substring(0, uploadIndex + 7);
  const filename = src.substring(uploadIndex + 7);

  return `${urlTillUpload}c_fill,f_auto,q_100,w_${width},h_${width}/${filename}`;
};
