import { useMemo } from 'react';
import { useDeliveryAddressState, useAlertState } from '@olo-web/client-state';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useMerchant, useMerchantOrderTypeDetails } from '@domain/merchants/queries';
import dayjs from 'dayjs';
import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { getRequestConfig } from '../functions';
import { IModifyDeliveryProps } from '../mutations/types';
import { EAlertTypes } from '@olo-web/types/enums';
import { useIsThirdPartyDelivery } from '@olo-web/utils/common/hooks/useIsThirdPartyDelivery';

export const estimateDelivery = async ({
  deliveryInfo,
  merchantId,
}: IModifyDeliveryProps): Promise<IDeliveryServiceEstimate> => {
  try {
    const url = `/api/delivery-service/deliveries/estimate`;
    const config = getRequestConfig(merchantId);

    const { data } = await axios.post(url, deliveryInfo, config);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useEstimateDelivery(
  asap?: boolean,
  options?: UseQueryOptions<unknown, AxiosError, IDeliveryServiceEstimate>
): UseQueryResult<IDeliveryServiceEstimate, AxiosError> {
  const { data: merchant } = useMerchant();
  const { data: orderTypeDetails } = useMerchantOrderTypeDetails();
  const { data: order } = useOrder();
  const { address, addressForm } = useDeliveryAddressState();
  const isThirdPartyDelivery = useIsThirdPartyDelivery();
  const alertState = useAlertState();

  const dropoffAddress = {
    street: addressForm?.address1 || address?.address1,
    unit: addressForm?.address2 || address?.address2,
    city: addressForm?.city || address?.city,
    state: addressForm?.state || address?.state,
    zipCode: addressForm?.zip || address?.zip,
  };

  const addressKey = `${dropoffAddress.street}, ${dropoffAddress.zipCode}`;
  const normalizedPickupTime = useMemo(() => {
    // convert these to a standard format to avoid duplicate requests
    if (alertState?.alertKey === EAlertTypes.PACING_CAPACITY_ALERT) {
      return dayjs(alertState?.alertContext[0]).second(0).millisecond(0).toISOString();
    }
    const pickupTime = asap ? orderTypeDetails?.asapOrderDateTime : order?.orderDateTime;
    return pickupTime ? dayjs(pickupTime).second(0).millisecond(0).toISOString() : null;
  }, [
    alertState?.alertKey,
    alertState?.alertContext,
    asap,
    orderTypeDetails?.asapOrderDateTime,
    order?.orderDateTime,
  ]);

  return useQuery(
    // why is this a useQuery when it's a POST? Possibly refactor to useMutation
    ['estimate', merchant?.merchantId, normalizedPickupTime, addressKey],
    () =>
      estimateDelivery({
        merchantId: merchant?.merchantId,
        deliveryInfo: {
          orderValue: 0,
          pickupAddress: {
            street: merchant?.street1,
            unit: merchant?.street2,
            city: merchant?.city,
            state: merchant?.state,
            zipCode: merchant?.zip,
          },
          dropoffAddress,
          pickupTime: normalizedPickupTime,
        },
      }),
    {
      staleTime: 240000,
      refetchInterval: 240000,
      retry: false,
      retryOnMount: false,
      enabled:
        isThirdPartyDelivery &&
        !!merchant?.doorDashDriveDelivery &&
        !!merchant?.merchantId &&
        !!normalizedPickupTime &&
        !!(addressForm || address),
      ...options,
    }
  );
}
