import { useEffect } from 'react';
import { useRemoveRewardOrDealFromOrder } from '@olo-web/domain/orders/mutations/useRemoveRewardOrDealFromOrder';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useLoyalty } from '@olo-web/domain/customer/hooks/useLoyalty';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';
import { useIsOrderReceiptPage } from '@olo-web/utils/common/hooks/useIsOrderReceiptPage';
import { useIsOrderConfirmationPage } from '@olo-web/utils/common/hooks/useIsOrderConfirmationPage';
import { useCheckoutState, useScreenSizeState } from '@olo-web/client-state';
import { useToast } from '@olo-web/utils/common/hooks/useToast';

export const useRemoveInvalidRewards = () => {
  const { data: order } = useOrder();
  const { mutate: removeRewardFromOrder } = useRemoveRewardOrDealFromOrder();
  const { data: loyalty, isLoading } = useLoyalty();
  const { isMd } = useScreenSizeState();
  const isOrderReceiptPage = useIsOrderReceiptPage();
  const isOrderConfirmationPage = useIsOrderConfirmationPage();
  const isDineIn = useIsDineIn();
  const { notify } = useToast();
  const { isLoading: isCheckoutLoading } = useCheckoutState();

  useEffect(() => {
    if (isOrderReceiptPage || isOrderConfirmationPage || isLoading || isCheckoutLoading) return;

    order?.discounts?.map((item) => {
      if (!Object.hasOwn(item, 'promotion_type')) return;

      if (
        item?.spotOnRewardId &&
        (item.promotion_type === 'deal' || item.promotion_type === 'reward') &&
        !(loyalty?.rewards[item?.spotOnRewardId] || loyalty?.deals[item?.spotOnRewardId]) &&
        !isDineIn
      ) {
        removeRewardFromOrder({
          rewardId: item?.spotOnRewardId,
          merchantId: order?.merchantID,
          orderId: order?.id,
        });

        notify({
          title: 'Reward removed',
          description: `The $${Math.abs(
            +item?.displayAmount
          )} reward discount has been removed from your bag because your contact information changed.`,
          isClosable: true,
          status: 'info',
          variant: 'left-accent',
          position: isMd ? 'top-right' : 'top',
        });
      }
    });
  }, [
    isMd,
    isOrderConfirmationPage,
    isOrderReceiptPage,
    loyalty?.rewards,
    loyalty?.deals,
    order,
    removeRewardFromOrder,
    notify,
    isCheckoutLoading,
    isDineIn,
    isLoading,
  ]);
};
