export const buildModifiersWithDisplayValues = (
  modifiers: IMenuItemModifier[],
  defaultGroup: IMenuItemDefaultModifierGroup,
  implicitDefaultName: string
): IMenuItemModifierDisplay[] => {
  return (modifiers || [])
    .filter((m) => m.isAvailable)
    .map((mod) => {
      const builtModifier: IMenuItemModifierDisplay = {
        ...mod,
        isSelected: false,
        options: [],
        implicitDefault: false,
      };

      const defaultMod = defaultGroup?.modifiers?.find((d) => d.groupItemId === mod.groupItemId);

      const displayOptions: ISubModifierOptionDisplay[] = mod.options.map((option) => {
        const defaultOption = defaultMod?.autoApply
          ? mod.options?.find((option) => option.id === defaultMod.optionId)
          : null;
        if (defaultOption) builtModifier.isSelected = mod.inStock;
        const isSelected = defaultOption?.id === option.id && mod.inStock;
        const isDisabled = !!defaultGroup?.disabledOptionIDs?.includes(option.id);

        return { ...option, isSelected, isDisabled };
      });

      if (defaultMod && !defaultMod.autoApply) {
        displayOptions.unshift({
          name: implicitDefaultName,
          id: 'implicitly-selected',
          isSelected: true,
          price: '0',
          isDisabled: false,
          groupItemId: mod.groupItemId,
          displayAmount: '0',
        });
        builtModifier.isSelected = builtModifier.inStock;
        builtModifier.implicitDefault = builtModifier.inStock;
      }

      builtModifier.options = displayOptions;
      return builtModifier;
    });
};
