import { useModalDispatch } from '@olo-web/client-state';
import { EModalTypes } from '@olo-web/types/enums';

function devTools() {
  globalThis.OLO = globalThis.OLO || {};
  return {
    start: function () {
      if (!globalThis.location) return;

      globalThis.OLO.DEV = new URLSearchParams(globalThis.location.search).get('dev') === 'true';

      if (!globalThis.OLO.DEV) return;

      // TODO: determine if this need to be fixed
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const dispatch = useModalDispatch();

      globalThis.OLO = {
        ...globalThis.OLO,
        modal: {
          dispatch,
          types: EModalTypes,
        },
      };
    },
  };
}

export default devTools();
