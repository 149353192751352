import { useLoyalty } from './useLoyalty';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';

export const useHasLoyaltyBeenAddedByOtherGuest = (): boolean => {
  const { data: loyalty } = useLoyalty();
  const { data: order } = useOrder();

  return (
    !!order?.loyaltyCustomer && order?.loyaltyCustomer?.spotOnCustomerId !== loyalty?.customerId
  );
};
