import React from 'react';
import { IconProps, Text, Flex } from '@chakra-ui/react';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { Visa } from '@olo-web/assets/icons/payments/Visa.ui';
import { Mastercard } from '@olo-web/assets/icons/payments/Mastercard.ui';
import { AmericanExpress } from '@olo-web/assets/icons/payments/AmericanExpress.ui';
import { Discover } from '@olo-web/assets/icons/payments/Discover.ui';
import { UnknownPaymentMethod } from '@olo-web/assets/icons/payments/UnknownPaymentMethod.ui';

const getCardTypeIcon = (cardType: string) => {
  switch (cardType) {
    case 'visa':
      return <Visa height={9} width={9} fill="none" />;
    case 'mastercard':
      return <Mastercard height={9} width={9} fill="none" />;
    case 'discover':
      return <Discover height={9} width={9} fill="none" />;
    case 'amex':
      return <AmericanExpress height={9} width={9} fill="none" />;
    default:
      return <UnknownPaymentMethod viewBox="0 0 34 22" height={9} width={9} fill="none" />;
  }
};

export const CreditCardOnTab = (props: IconProps) => {
  const { data: order } = useOrder();

  const preAuthCardType = order?.paymentAuth?.cardType;

  return (
    <Flex justifyContent="flex-end" align="center" pr={2}>
      {order?.paymentAuth?.cardNumber && (
        <Text pr={2} fontWeight="400" fontSize="sm">
          {order?.paymentAuth?.cardNumber}
        </Text>
      )}
      {getCardTypeIcon(preAuthCardType!)}
    </Flex>
  );
};
