export enum EModalTypes {
  LOGIN = 'login',
  SIGNUP = 'signup',
  ITEM_DETAILS = 'itemDetails',
  APPLY_OFFERS = 'applyOffers',
  MERCHANT_LOCATION_LIST = 'merchantLocationList',
  MERCHANT_LOCATION_MAP = 'merchantLocationMap',
  CUSTOMER_DELIVERY_ADDRESS = 'customerDeliveryAddress',
  CUSTOMER_DELIVERY_SAVED_ADDRESSES = 'customerDeliverySavedAddresses',
  MERCHANT_LOCATION = 'merchantLocation',
  ORDER_TIME = 'orderTime',
  FORGET_PASSWORD = 'forgotPassword',
  REQUEST_EMAIL_RECEIPT = 'requestEmailReceipt',
  INIT_DINEIN_ORDER = 'initDineInModal',
  LEAVE_TABLE = 'leaveTable',
  TABLE_READY = 'tableReady',
  TABLE_CHECKOUT = 'tableCheckout',
  ORDERED_ITEMS = 'orderedItems',
  CLOSED_ORDER = 'closedOrder',
  CONFIRM_FULFILLMENT_TIME = 'confirmFulfillmentTime',
  CONFIRM_FUTURE_FULFILLMENT_TIME = 'confirmFutureFulfillmentTime',
  UPSELL = 'upsell',
  ORDER_DETAILS = 'orderDetails',
  ADD_GUEST = 'addGuest',
  SBA_CONTINUE_TO_CHECKOUT = 'SBAContinueToCheckout',
  GUEST_COMPLETES_SPLIT = 'guestCompletesSplit',
  ORDER_IS_RESET = 'orderIsReset',
  INVITE_OTHERS = 'inviteOthers',
  UNKNOWN_ERROR = 'unknownError',
  CUSTOMER_FEEDBACK_PROMPT = 'customerFeedbackPrompt',
  CUSTOMER_FEEDBACK_THANK_YOU = 'customerFeedbackThankYou',
  START_A_TAB = 'startATab',
  SOMETHING_WENT_WRONG = 'somethingWentWrong',
  LOYALTY_QR_CODE = 'loyaltyQRCode',
  COOKIE_BANNER = 'cookieBanner',
}
