export const dynamicSort = (key: string): any => {
  let sortOrder = 1;
  if (key[0] === '-') {
    sortOrder = -1;
    key = key.substr(1);
  }
  return (a, b) => {
    const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    return result * sortOrder;
  };
};
