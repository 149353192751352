import { useLayoutEffect, useEffect, useState, useCallback } from 'react';

import { IS_SERVER } from '@olo-web/utils/common/constants';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

// NOTE: chakra-ui built in useMediaQuery does not work with SSR
// https://github.com/chakra-ui/chakra-ui/issues/2827
export const useMediaQuery = (mediaExpression: string | string[]) => {
  const expressions = Array.isArray(mediaExpression) ? mediaExpression : [mediaExpression];

  const [targetsReached, setTargetsReached] = useState<Record<string, boolean>>(() =>
    expressions.reduce(
      (acc, expression) => ({
        ...acc,
        [expression]: (() => {
          if (!IS_SERVER) {
            return window.matchMedia(expression).matches;
          }
        })(),
      }),
      {}
    )
  );

  const updateTarget = useCallback((expression: string, matches: boolean) => {
    setTargetsReached((prev) => ({
      ...prev,
      [expression]: matches,
    }));
  }, []);

  useIsomorphicLayoutEffect(() => {
    const queries = expressions.map((expression) => ({
      expression,
      media: window.matchMedia(expression),
      handler: (e: MediaQueryListEvent) => {
        updateTarget(expression, e.matches);
      },
    }));

    const initialState = queries.reduce<Record<string, boolean>>(
      (acc, { expression, media }) => ({
        ...acc,
        [expression]: media.matches,
      }),
      {}
    );

    queries.forEach(({ media, handler }) => {
      media.addEventListener('change', handler);
    });

    setTargetsReached(initialState);

    return () => {
      queries.forEach(({ media, handler }) => {
        media.removeEventListener('change', handler);
      });
    };
  }, [...expressions]);

  return expressions.map((expression) => targetsReached[expression]);
};
