import { useMemo } from 'react';
import { useIsDelivery } from './useIsDelivery';
import { useIsPickup } from './useIsPickup';

export const useOrderTypeDisplayName = () => {
  const isDelivery = useIsDelivery();
  const isPickup = useIsPickup();

  return useMemo(
    () => (isDelivery ? 'Delivery' : isPickup ? 'Pickup' : 'Dine In'),
    [isDelivery, isPickup]
  );
};
