import { useMemo } from 'react';
import { EOrderTypes } from '@olo-web/types/enums';
import { useMerchant } from '@olo-web/domain';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';

export const useIsThirdPartyDelivery = () => {
  const { data: order } = useOrder();
  const { data: merchant } = useMerchant();

  return useMemo(() => {
    if (!(order?.orderTypeId && merchant?.orderTypeIds)) return false;
    return order?.orderTypeId === merchant?.orderTypeIds[EOrderTypes.DOORDASH_DRIVE];
  }, [merchant?.orderTypeIds, order?.orderTypeId]);
};
