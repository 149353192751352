import { theme } from '@chakra-ui/react';
import { IComponentThemeProps } from '../types';
const d = theme.components.Radio;

const Radio = {
  ...d,
  baseStyle: ({ colorScheme, flexDir }: IComponentThemeProps): any => ({
    ...d.baseStyle,
    label: {
      ml: flexDir === 'row-reverse' ? 0 : 2,
      w: flexDir === 'row-reverse' ? '100%' : 'auto',
      _hover: {
        cursor: 'pointer',
      },
      _disabled: {
        opacity: 1,
      },
    },
    control: {
      borderColor: 'blackAlpha.350',
      _checked: {
        _before: {
          content: '""',
          display: 'inline-block',
          pos: 'relative',
          w: '70%',
          h: '70%',
          borderRadius: '50%',
          bg: `${colorScheme}.350`,
        },
        bg: 'transparent',
        borderColor: `${colorScheme}.350`,
        borderWidth: '0.15rem',
        _focus: {
          boxShadow: `0 0 3px gray`,
        },
        _hover: {
          borderColor: `${colorScheme}.400`,
          bg: 'transparent',
        },
      },
      _hover: {
        cursor: 'pointer',
      },
    },
  }),
  sizes: {
    ...d.sizes,
    md: {
      ...d.sizes.md,
      container: {
        h: '44px',
        _hover: {
          cursor: 'pointer',
        },
      },
    },
    lg: {
      ...d.sizes.lg,
      container: {
        h: '44px',
        _hover: {
          cursor: 'pointer',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'primary',
  },
};

export default Radio;
