import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Discover = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 34 22" fill="none" {...props}>
      <path
        d="M1.88889 0.25H32.1111C33.0233 0.25 33.75 0.965865 33.75 1.83333V20.1667C33.75 21.0341 33.0233 21.75 32.1111 21.75H1.88889C0.976668 21.75 0.25 21.0341 0.25 20.1667V1.83333C0.25 0.965865 0.976668 0.25 1.88889 0.25Z"
        fill="white"
        stroke="#08031D"
        strokeWidth="0.5"
      />
      <path
        d="M33.4821 10.5L33.5 19.3249C33.5 20.5 33.1733 21.4826 31.6878 21.4737H2C1.19997 21.4847 1 21 1 21C1 21 5.43163 20.5113 7.72841 20.0393C8.33552 19.9295 8.92799 19.7914 9.52047 19.6679C19.0724 17.5914 27.3388 14.5378 31.9918 11.541C32.512 11.1971 33.0487 10.844 33.4821 10.5Z"
        fill="#EE7623"
      />
      <path
        d="M4.136 11.6921C4.09108 11.6921 4.04433 11.6921 4.00125 11.6921H2.75V7.31505H4.21667C4.65074 7.29314 5.08018 7.4129 5.44027 7.65627C5.80037 7.89964 6.07163 8.25345 6.21317 8.66438C6.38961 9.09823 6.4236 9.57692 6.31024 10.0313C6.19689 10.4858 5.94204 10.8924 5.5825 11.1925C5.16933 11.5153 4.66031 11.6911 4.136 11.6921ZM3.61625 8.05755V11H3.83625C3.88208 11 3.92792 11.0065 3.97375 11.0065C4.35793 11.0065 4.72912 10.8675 5.01875 10.615C5.17166 10.4757 5.29354 10.3058 5.37645 10.1163C5.45937 9.92675 5.50146 9.72191 5.5 9.51505C5.50275 9.308 5.46123 9.10275 5.37823 8.91305C5.29524 8.72334 5.17268 8.55355 5.01875 8.41505C4.73047 8.17873 4.36943 8.04921 3.99667 8.04838C3.94167 8.04838 3.88942 8.04838 3.83625 8.05755H3.61625Z"
        fill="#08031D"
      />
      <path d="M7.63229 7.30859H6.77246V11.7223H7.63229V7.30859Z" fill="#08031D" />
      <path
        d="M9.74183 8.99838C9.22666 8.81505 9.05433 8.68213 9.05433 8.44196C9.07917 8.2943 9.15936 8.16166 9.27858 8.07105C9.3978 7.98045 9.54707 7.93869 9.69599 7.9543C9.82974 7.96007 9.9606 7.99507 10.0794 8.05683C10.1982 8.11859 10.302 8.20562 10.3835 8.3118L10.829 7.72696C10.4766 7.40357 10.0148 7.22571 9.53649 7.22921C9.36224 7.21804 9.18753 7.24228 9.02291 7.30049C8.85828 7.35869 8.70716 7.44965 8.57866 7.56787C8.45016 7.68609 8.34694 7.82913 8.27524 7.98834C8.20355 8.14755 8.16485 8.31964 8.16149 8.49421C8.16149 9.10563 8.43649 9.41088 9.24133 9.70421C9.44792 9.77033 9.64799 9.85533 9.83899 9.95813C9.91861 10.0041 9.98468 10.0703 10.0306 10.1499C10.0764 10.2296 10.1005 10.32 10.1002 10.4119C10.0996 10.4992 10.0808 10.5854 10.0452 10.665C10.0096 10.7447 9.95787 10.8162 9.89329 10.8749C9.82871 10.9336 9.75268 10.9783 9.66998 11.0062C9.58728 11.0342 9.4997 11.0446 9.41274 11.037C9.21647 11.0392 9.02377 10.9845 8.85787 10.8796C8.69197 10.7747 8.55996 10.624 8.47774 10.4458L7.92041 10.9875C8.07988 11.2574 8.31038 11.4784 8.58678 11.6263C8.86319 11.7742 9.17489 11.8434 9.48791 11.8263C9.68432 11.8397 9.88138 11.8121 10.0665 11.7451C10.2516 11.6781 10.4207 11.5732 10.5631 11.4372C10.7054 11.3012 10.8178 11.1369 10.8931 10.955C10.9684 10.7731 11.0049 10.5775 11.0004 10.3807C11.0004 9.67946 10.719 9.35588 9.74183 8.99838Z"
        fill="#08031D"
      />
      <path
        d="M11.2814 9.51329C11.2785 9.81893 11.337 10.122 11.4535 10.4046C11.5699 10.6872 11.742 10.9436 11.9594 11.1584C12.1768 11.3733 12.4351 11.5422 12.7191 11.6554C13.0031 11.7685 13.3068 11.8234 13.6124 11.8169C13.9872 11.8189 14.3571 11.7315 14.6914 11.562V10.5537C14.563 10.7038 14.4037 10.8245 14.2245 10.9075C14.0453 10.9906 13.8503 11.034 13.6528 11.035C13.4528 11.0399 13.2539 11.0035 13.0686 10.9278C12.8834 10.8522 12.7158 10.7391 12.5764 10.5956C12.4371 10.452 12.3289 10.2812 12.2587 10.0939C12.1885 9.90651 12.1579 9.70665 12.1687 9.50687C12.1626 9.31236 12.1952 9.11857 12.2648 8.93681C12.3343 8.75505 12.4393 8.58894 12.5737 8.44817C12.7081 8.3074 12.8691 8.19478 13.0475 8.11687C13.2258 8.03896 13.4178 7.99732 13.6124 7.99437C13.8169 7.99494 14.019 8.03915 14.205 8.12404C14.3911 8.20892 14.5569 8.33254 14.6914 8.48662V7.47829C14.3673 7.29869 14.0031 7.20408 13.6326 7.20329C13.3263 7.19966 13.0223 7.25663 12.7381 7.37092C12.4539 7.48521 12.195 7.65457 11.9765 7.86926C11.758 8.08394 11.5841 8.33972 11.4648 8.62186C11.3455 8.90401 11.2831 9.20696 11.2814 9.51329Z"
        fill="#08031D"
      />
      <path
        d="M21.5189 10.2713L20.3364 7.30859H19.4014L21.2714 11.8323H21.7297L23.6437 7.30859H22.7087L21.5189 10.2713Z"
        fill="#08031D"
      />
      <path
        d="M24.0283 11.7223H26.4758V10.9753H24.8882V9.78359H26.4117V9.03376H24.8882V8.05751H26.4758V7.30859H24.0283V11.7223Z"
        fill="#08031D"
      />
      <path
        d="M27.9121 11.6898H27.0459V7.31641H28.3109C29.3073 7.31641 29.8784 7.78757 29.8784 8.60891C29.9006 8.90659 29.8102 9.20174 29.6252 9.43599C29.4401 9.67023 29.1739 9.82651 28.8792 9.87391L30.2542 11.688H29.2046L28.0084 9.94266H27.9121V11.6889V11.6898ZM27.9121 8.00391V9.33766H28.1871C28.7371 9.33766 28.9993 9.12591 28.9993 8.69141C28.9993 8.23307 28.7243 8.00391 28.1871 8.00391H27.9121Z"
        fill="#08031D"
      />
      <path
        d="M19.6565 9.51579C19.6566 9.97963 19.5193 10.4331 19.2617 10.8189C19.0041 11.2046 18.6379 11.5053 18.2094 11.6829C17.7809 11.8605 17.3093 11.907 16.8544 11.8165C16.3995 11.7261 15.9816 11.5027 15.6536 11.1748C15.3256 10.8468 15.1023 10.4289 15.0118 9.97394C14.9214 9.519 14.9679 9.04746 15.1455 8.61896C15.3231 8.19046 15.6237 7.82425 16.0095 7.56667C16.3952 7.30908 16.8487 7.17169 17.3125 7.17188C17.6204 7.17188 17.9251 7.2325 18.2095 7.3503C18.4939 7.46809 18.7523 7.64074 18.9699 7.85839C19.1876 8.07605 19.3603 8.33444 19.478 8.61881C19.5958 8.90319 19.6565 9.20798 19.6565 9.51579Z"
        fill="#EE7623"
      />
      <path
        d="M30.2227 7.80955C30.1314 7.81137 30.0432 7.77685 29.9773 7.71359C29.9115 7.65033 29.8735 7.5635 29.8717 7.47221C29.8698 7.38092 29.9043 7.29265 29.9676 7.22681C30.0309 7.16097 30.1177 7.12295 30.209 7.12113H30.2227C30.311 7.11985 30.3964 7.15269 30.4611 7.2128C30.5103 7.26047 30.5441 7.32171 30.5583 7.38872C30.5726 7.45572 30.5665 7.52544 30.5409 7.58899C30.5154 7.65253 30.4714 7.70701 30.4148 7.74548C30.3581 7.78395 30.2912 7.80466 30.2227 7.80496V7.80955ZM30.209 7.20455C30.1747 7.20455 30.1407 7.2113 30.109 7.22443C30.0773 7.23756 30.0485 7.25681 30.0243 7.28106C29.9753 7.33006 29.9477 7.39651 29.9477 7.4658C29.945 7.50212 29.9497 7.53862 29.9615 7.57307C29.9733 7.60753 29.992 7.63923 30.0164 7.66624C30.0408 7.69325 30.0705 7.71501 30.1036 7.73019C30.1367 7.74537 30.1726 7.75366 30.209 7.75455H30.2145C30.2478 7.75473 30.2807 7.74732 30.3107 7.73286C30.3408 7.71841 30.3671 7.69729 30.3877 7.67113C30.4392 7.61494 30.4685 7.54198 30.4702 7.4658C30.47 7.39658 30.4424 7.33027 30.3934 7.28133C30.3445 7.23239 30.2782 7.20479 30.209 7.20455Z"
        fill="#08031D"
      />
      <path
        d="M30.1402 7.67219H30.0439V7.27344H30.1814C30.2804 7.27344 30.3327 7.31652 30.3327 7.39719C30.3314 7.42005 30.3226 7.44186 30.3077 7.45925C30.2928 7.47664 30.2726 7.48865 30.2502 7.49344L30.373 7.67127H30.2639L30.1402 7.50719V7.67219Z"
        fill="#08031D"
      />
    </Icon>
  );
};
