import { Poppins } from '@next/font/google';

const poppins = Poppins({
  weight: ['400', '500', '600', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  fallback: ['sans-serif'],
});

const fonts = {
  body: poppins.style.fontFamily,
  heading: poppins.style.fontFamily,
  mono: 'Menlo, monospace',
};
export default fonts;
