import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const CreditCard = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 41 26" fill="none" {...props}>
      <rect
        x="1.5"
        y="0.5"
        width="39"
        height="24.8824"
        rx="3.5"
        fill="white"
        stroke="currentColor"
      />
      <path d="M8.05859 20H6.88212" stroke="currentColor" strokeLinecap="round" />
      <path d="M11.5879 20L18.6467 20" stroke="currentColor" strokeLinecap="round" />
      <path d="M1.43164 7.0586L40.2552 7.0586" stroke="currentColor" strokeLinecap="round" />
    </Icon>
  );
};
