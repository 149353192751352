import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useQuery, UseQueryResult } from 'react-query';

export const getTables = async (merchantId: string): Promise<any> => {
  try {
    const url = `/api/merchants/${merchantId}/tables`;
    const { data } = await axios.get(`${url}`);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useTables(merchantId?: string): UseQueryResult<ITable[], AxiosError> {
  const router = useRouter();
  const { merchantId: merchantIdFormUrl } = router.query;
  const merchId = merchantId || merchantIdFormUrl;
  return useQuery(['tables', merchId], () => getTables(merchId as string), {
    staleTime: 1000000,
    enabled: !!merchId,
  });
}
