import { ECheckoutTypes } from '@olo-web/types/enums';
import { useRouter } from 'next/router';

export const useIsSplitByAmount = () => {
  const router = useRouter();
  const isSplitAmountPage =
    router.route === '/[locationSlug]/[merchantSlug]/[merchantId]/split-by-amount';

  return router.query.checkoutType === ECheckoutTypes.SPLIT_BY_AMOUNT || isSplitAmountPage;
};
