import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useQuery, UseQueryResult } from 'react-query';
import queryString from 'query-string';

export const getTableStatus = async (merchantId: string, tableNumber: string): Promise<any> => {
  try {
    const url = `/api/merchants/${merchantId}/table-status`;
    const query = queryString.stringify({
      tableNumber: tableNumber?.replace(' ', '+'),
    });
    const { data } = await axios.get(`${url}?${query}`);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export function useTableStatus(
  tableNumber: string,
  merchantId?: string
): UseQueryResult<ITableNumberStatus, AxiosError> {
  const router = useRouter();
  const { merchantId: merchantIdFormUrl } = router.query;
  const merchId = merchantId || merchantIdFormUrl;
  return useQuery(['table-status', merchId], () => getTableStatus(merchId as string, tableNumber), {
    staleTime: 1000000,
    enabled: !!merchId,
  });
}
