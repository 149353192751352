import { useRouter } from 'next/router';
import { useCheckoutDispatch } from '@olo-web/client-state';
import { useEffect } from 'react';
import { removeQueryParamsFromPath } from '@olo-web/utils';
import { IUtmParameters } from '@olo-web/types/extraParameters.interface';

export const useListenForUtmParams = () => {
  const router = useRouter();
  const { utm_campaign, utm_medium, utm_source } = router.query as unknown as IUtmParameters;
  const path = router.asPath;
  const checkoutDispatch = useCheckoutDispatch();

  useEffect(() => {
    if (utm_campaign && utm_medium && utm_source) {
      checkoutDispatch({
        type: 'SET_UTM_PARAMS',
        payload: { utm_campaign, utm_medium, utm_source },
      });
      const newPath = removeQueryParamsFromPath(path, ['utm_campaign', 'utm_source', 'utm_medium']);
      router.push(newPath, newPath, { shallow: true });
    }
  }, [utm_campaign, utm_medium, utm_source, checkoutDispatch, path, router]);
};
