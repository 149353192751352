import { createStore } from '@olo-web/utils/common/store';
import { RefObject } from 'react';

interface IMenuState {
  selectedGroupId: string;
  searchText: string;
  scrollBoxRef: RefObject<HTMLDivElement> | null;
  menuRibbonRef: RefObject<HTMLDivElement> | null;
}

type TUpdateSearchAction = IStandardAction<'UPDATE_SEARCH', string>;
type TUpdateSelectedGroup = IStandardAction<'UPDATE_SELECTED_GROUP', string>;
type TSetScrollBoxRef = IStandardAction<'SET_SCROLL_BOX_REF', RefObject<HTMLDivElement>>;
type TSetMenuRibbonRef = IStandardAction<'SET_MENU_RIBBON_REF', RefObject<HTMLDivElement>>;
type TClearMenuAction = Omit<IStandardAction<'CLEAR_MENU'>, 'payload'>;
type TMenuAction =
  | TUpdateSearchAction
  | TUpdateSelectedGroup
  | TClearMenuAction
  | TSetScrollBoxRef
  | TSetMenuRibbonRef;

const initialState: IMenuState = {
  selectedGroupId: '',
  searchText: '',
  scrollBoxRef: null,
  menuRibbonRef: null,
};

const reducer = (state: IMenuState, action: TMenuAction): IMenuState => {
  switch (action.type) {
    case 'UPDATE_SEARCH':
      return {
        ...state,
        selectedGroupId: '',
        searchText: action.payload,
      };
    case 'UPDATE_SELECTED_GROUP':
      return { ...state, selectedGroupId: action.payload };
    case 'SET_SCROLL_BOX_REF':
      return {
        ...state,
        scrollBoxRef: action.payload,
      };
    case 'SET_MENU_RIBBON_REF':
      return {
        ...state,
        menuRibbonRef: action.payload,
      };
    case 'CLEAR_MENU':
      return initialState;
    default:
      return state;
  }
};

export const [MenuContextProvider, useMenuDispatch, useMenuState] = createStore<
  IMenuState,
  TMenuAction
>(reducer, initialState, { name: 'menu' });
