// import { getTipsValues } from './getTipsValues';
import { DOORDASH_DRIVE_DEFAULT_TIP } from '@olo-web/domain/orders/constants';

export const getDefaultTipsValue = (orderDetails: IMerchantOrderTypeDetails, isDDD?: boolean) => {
  // const tipsValues = getTipsValues(orderDetails);
  if (isDDD) return DOORDASH_DRIVE_DEFAULT_TIP;
  const defaultTip = orderDetails?.defaultTipPercentage;
  if (defaultTip === 0) return 'none';
  if (!defaultTip || defaultTip < 0) return '';
  return defaultTip;
};
