import { useState } from 'react';
import { useFeatureFlags } from '@olo-web/domain';
import { useRouter } from 'next/router';
import { default as ReactFullStory } from 'react-fullstory';

const ORG_ID = '10SX78';

export const FullStory = () => {
  const router = useRouter();
  const { merchantId } = router.query;
  const { data: flags } = useFeatureFlags();
  const [randValue] = useState(Math.random());

  if (!merchantId || !flags) return null;
  const recordPercentage = flags?.fullStoryRecordPercentage ?? 0.5;
  const doNotRecord = !(recordPercentage >= 1) && randValue > recordPercentage;
  const recordMerchantAllTheTime =
    flags?.fullStoryRecordMerchantIdAllTheTime?.merchantIds?.includes(merchantId as string);

  if (doNotRecord && !recordMerchantAllTheTime) {
    return null;
  }
  return <ReactFullStory org={ORG_ID} />;
};
