import { removeQueryParamFromPath } from '@olo-web/utils';
import { useCheckoutDispatch } from '@olo-web/client-state';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

declare const window: any;

export const useListenForGclid = (): void => {
  const router = useRouter();
  const { gclid } = router.query;
  const path = router.asPath;
  const checkoutDispatch = useCheckoutDispatch();

  useEffect(() => {
    if (gclid) {
      checkoutDispatch({
        type: 'SET_GCLID',
        payload: gclid as string,
      });
      const newPath = removeQueryParamFromPath(path, 'gclid');
      if (window?.gtag) window.gtag('config', gclid);
      router.push(newPath, newPath, { shallow: true });
    }
  }, [gclid, checkoutDispatch, path, router]);
};
