import { useEffect, useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useCreateEmptyOrder } from '@olo-web/domain/orders/mutations/useCreateEmptyOrder';
import { useUpdateOrder } from '@olo-web/domain/orders/mutations/useUpdateOrder';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useMerchantOrderTypeDetails } from '@domain/merchants/queries/useMerchantOrderTypeDetails';
import {
  useSavedDineInContextState,
  useSavedOrderDispatch,
  useSavedDineInContextDispatch,
  useAlertDispatch,
} from '@olo-web/client-state';
import { useCurrentMerchantSavedOrderId } from '@olo-web/utils/common/hooks/useCurrentMerchantSavedOrderId';
import { useIsWelcomePage } from '@olo-web/utils/common/hooks/useIsWelcomePage';
import { useOrderTypeId } from '@olo-web/utils/common/hooks/useOrderTypeId';
import { useIsOrderTypeInvalid } from '@olo-web/utils/common/hooks/useIsOrderTypeInvalid';
import { useIsDineInRoute } from '@olo-web/utils/common/hooks/useIsDineIn';
import { EOrderTypes, EAlertTypes, EOrderStatus } from '@olo-web/types/enums';
import { useResetQROrderBuilding } from '@domain/customer/hooks/useResetQROrderBuilding';
import useApplyOfferInRoute from './useApplyOfferInRoute';
import { useHandleAsapLogicOnServer } from '@olo-web/utils/common/hooks';

export const useInitializeCustomerSession = (
  setHasInitialized: Dispatch<SetStateAction<boolean>>
): void => {
  const {
    data: order,
    isIdle: orderIsIdle,
    isLoading: orderLoading,
    isFetched: isOrderFetched,
  } = useOrder();
  const { data: merchant, isFetched: isMerchantFetched } = useMerchant();
  const orderTypeId = useOrderTypeId();
  const {
    query: { orderType: orderTypeFromUrl },
  } = useRouter();
  const { data: orderTypeDetails, isFetched: isOrderTypeDetailsFetched } =
    useMerchantOrderTypeDetails({ orderTypeId });
  const { mutate: createEmptyOrder, isLoading: creatingOrder } = useCreateEmptyOrder();
  const savedOrderId = useCurrentMerchantSavedOrderId();
  const { mutate: updateOrder } = useUpdateOrder();
  const isWelcomePage = useIsWelcomePage();
  const savedOrderDispatch = useSavedOrderDispatch();
  const isOrderTypeInvalid = useIsOrderTypeInvalid();
  const isDineInRoute = useIsDineInRoute();
  const updateOrderInCache = useUpdateOrderInCache();
  const savedDineInState = useSavedDineInContextState();
  const savedDineInContextDispatch = useSavedDineInContextDispatch();
  const alertDispatch = useAlertDispatch();
  const handleAsapLogicOnServer = useHandleAsapLogicOnServer();

  useResetQROrderBuilding();
  useApplyOfferInRoute();

  const orderTypeDoesntMatchURLType = useMemo(() => {
    return (
      order && order?.orderTypeId !== merchant?.parsedOrderTypeIds?.[orderTypeFromUrl as string]
    );
  }, [order, merchant?.parsedOrderTypeIds, orderTypeFromUrl]);

  const isOrderInvalid = useMemo(() => {
    // if order and merchant data are not ready to be evaluated, do not create an empty order
    if (
      !orderTypeDetails ||
      orderLoading ||
      creatingOrder ||
      !!(!order?.id && orderIsIdle && savedOrderId)
    )
      return false;
    // otherwise, create an empty if no order exists or the order was completed (hence orderNumber exists)
    return !order?.id || !!order?.orderNumber?.length || savedOrderId === null;
  }, [
    orderTypeDetails,
    orderLoading,
    creatingOrder,
    orderIsIdle,
    savedOrderId,
    order?.id,
    order?.orderNumber,
  ]);
  const orderTimeIsStale = useMemo(() => {
    const orderDateTime = dayjs(order?.orderDateTime);
    const asapDateTime = dayjs(orderTypeDetails?.asapOrderDateTime);

    return orderDateTime.isBefore(asapDateTime, 'minutes');
  }, [order?.orderDateTime, orderTypeDetails?.asapOrderDateTime]);

  const asapOrderDateTime = handleAsapLogicOnServer
    ? undefined
    : orderTypeDetails?.asapOrderDateTime;

  const createNewEmptyOrder = useCallback(() => {
    if (isWelcomePage) return;
    createEmptyOrder({
      merchantId: merchant?.merchantId,
      orderInfo: {
        orderTypeId,
        orderDateTime: asapOrderDateTime,
      },
    });
  }, [merchant?.merchantId, orderTypeId, createEmptyOrder, isWelcomePage, asapOrderDateTime]);

  const updateOrderType = useCallback(
    (orderTypeIdArg?: string) => {
      updateOrder({
        merchantId: merchant?.merchantId,
        orderId: order?.id,
        orderInfo: {
          orderTypeId: orderTypeIdArg || orderTypeId,
          orderDateTime: asapOrderDateTime,
        },
      });
      if (isOrderTypeInvalid) {
        alertDispatch({
          type: 'OPEN_ALERT',
          payload: {
            alertKey: EAlertTypes.VALIDATE_ORDER_TYPE_ALERT,
          },
        });
      }
    },
    [
      updateOrder,
      merchant?.merchantId,
      order?.id,
      orderTypeId,
      asapOrderDateTime,
      isOrderTypeInvalid,
      alertDispatch,
    ]
  );

  useEffect(() => {
    if (isDineInRoute) {
      if (order?.orderTypeId !== merchant?.orderTypeIds[EOrderTypes.DINE_IN]) {
        savedOrderDispatch({
          type: 'DISCARD_ID',
          payload: { merchantId: merchant?.merchantId as string, orderId: order?.id },
        });
      }
      // Just in case user has dine in context saved from a previous order that wasn't completed.
      if (
        savedDineInState &&
        order?.guests?.findIndex((guest) => guest.id === savedDineInState?.guest?.id) === -1 &&
        order?.groupOrderInfo?.status !== EOrderStatus.GROUP_PAYING
      ) {
        savedDineInContextDispatch({ type: 'RESET' });
      }

      return;
    } else if (savedDineInState !== null) {
      savedDineInContextDispatch({ type: 'RESET' });
    }

    if (isOrderInvalid) {
      createNewEmptyOrder();
    } else if (isOrderTypeInvalid || orderTimeIsStale || orderTypeDoesntMatchURLType) {
      updateOrderType(
        orderTypeDoesntMatchURLType
          ? merchant?.parsedOrderTypeIds?.[orderTypeFromUrl as string]
          : null
      );
    }

    const hasInitializedSession = isOrderFetched && isOrderTypeDetailsFetched && isMerchantFetched;
    if (
      hasInitializedSession ||
      isOrderInvalid ||
      isOrderTypeInvalid ||
      orderTypeDoesntMatchURLType
    ) {
      setHasInitialized(true);
    }
  }, [
    savedDineInContextDispatch,
    orderTimeIsStale,
    savedDineInState,
    isDineInRoute,
    createNewEmptyOrder,
    isOrderInvalid,
    isOrderTypeInvalid,
    orderTypeDoesntMatchURLType,
    updateOrderType,
    order?.orderTypeId,
    order?.id,
    merchant?.orderTypeIds,
    merchant.id,
    merchant?.merchantId,
    savedOrderDispatch,
    updateOrderInCache,
    order?.guests,
    isMerchantFetched,
    isOrderFetched,
    isOrderTypeDetailsFetched,
    setHasInitialized,
    order?.groupOrderInfo?.status,
    merchant?.parsedOrderTypeIds,
    orderTypeFromUrl,
  ]);
};
