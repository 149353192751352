import { useCheckoutState } from '@olo-web/client-state';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { UseQueryResult, useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';

export const getGiftCardBalance = async (
  giftCardNumber: string,
  merchantId: string
): Promise<IGiftCard> => {
  try {
    const { data } = await axios.get(`/api/merchants/${merchantId}/giftcards/${giftCardNumber}`);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const useGiftCardBalance = (
  giftCardNumber?: string,
  merchantId?: string
): UseQueryResult<IGiftCard, AxiosError> => {
  const { data: merchant } = useMerchant();
  const { giftCardNumber: giftCardNumberFromState } = useCheckoutState();
  const cardNumber = giftCardNumber || giftCardNumberFromState;
  const merchId = merchantId || merchant?.merchantId;

  const isEnabled = !!(merchId && cardNumber);

  return useQuery(
    ['gift-card', cardNumber, merchId],
    () => getGiftCardBalance(cardNumber, merchId),
    {
      staleTime: 100000,
      enabled: isEnabled,
      retry: 0,
    }
  );
};
