import { createStore } from '@olo-web/utils/common/store/createStore';
import { EOrderTime } from '@olo-web/types/enums';

type TUpdateScheduleType = IStandardAction<'SET_SCHEDULE_TYPE', EOrderTime>;

const reducer = (state: EOrderTime, action: TUpdateScheduleType): EOrderTime => {
  switch (action.type) {
    case 'SET_SCHEDULE_TYPE':
      return action.payload;
    default:
      return state;
  }
};

export const [ScheduleTypeProvider, useScheduleTypeDispatch, useScheduleTypeState] = createStore<
  EOrderTime,
  TUpdateScheduleType
>(reducer, EOrderTime.ASAP, { name: 'scheduleType' });
