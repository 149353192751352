import { useQuery, UseQueryResult } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useContactInfoState } from '@olo-web/client-state';
import { serializeToCamelCase } from '@olo-web/utils/common/functions';
import { useIsLevelup } from '@olo-web/utils/common/hooks/useIsLevelup';

export const getGuestLoyalty = async (
  email: string,
  merchantId: string
): Promise<IGuestLoyaltyResponse> => {
  try {
    const url = `/api/customers/get-guest-loyalty`;
    const body = { email, merchant_id: merchantId };
    const { data } = await axios.post(url, body);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useGuestLoyalty = (): UseQueryResult<IGuestLoyalty, AxiosError> => {
  const router = useRouter();
  const { merchantId } = router.query;
  const contactInfo = useContactInfoState();
  const email = contactInfo?.email;
  const isLevelup = useIsLevelup();
  return useQuery(
    ['loyalty', merchantId, email],
    () => getGuestLoyalty(email, merchantId as string),
    {
      enabled: !!email && !isLevelup,
      staleTime: 1000000,
      select: serializeToCamelCase,
      retry: 0,
      retryOnMount: false,
    }
  );
};
