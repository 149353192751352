import { createStore } from '@olo-web/utils/common/store';
type ISavedDineInContextState = {
  table?: ITable;
  guest?: IGuest;
  orderId?: string;
} | null;

type TResetDineInContextAction = TEmptyAction<'RESET'>;
type TSaveDineInContextAction = IStandardAction<'SAVE', ISavedDineInContextState>;
type TUpdateDineInContextAction = IStandardAction<'UPDATE', ISavedDineInContextState>;

type TSavedDineInContextAction =
  | TResetDineInContextAction
  | TSaveDineInContextAction
  | TUpdateDineInContextAction;

// We don't store table state by merchantId because there is an edge case bug where if a user doesn't finish a dine in order
// and comes back to the merchant to dine in, they'll join the table that was stored from their previous dine in and
// they may accidentally add items and get items sent to the kitchen for the wrong table.
const initialState = null;

const reducer = (
  state: ISavedDineInContextState,
  action: TSavedDineInContextAction
): ISavedDineInContextState => {
  switch (action.type) {
    case 'RESET':
      return initialState;
    case 'SAVE':
      return action.payload;
    case 'UPDATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const [
  SavedDineInContextProvider,
  useSavedDineInContextDispatch,
  useSavedDineInContextState,
] = createStore<ISavedDineInContextState, TSavedDineInContextAction>(reducer, initialState, {
  name: 'savedDineInContext',
  persist: 'session',
});
