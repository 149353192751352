import { EAnalyticsEventNames } from '@olo-web/types/enums';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import axios, { AxiosError } from 'axios';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { useMutation, UseMutationOptions } from 'react-query';

export const addRewardOrDealToOrder = async ({
  merchantId,
  orderId,
  spotOnCustomerId,
  rewardId,
  checkId,
}: IAddOrRemoveOrderRewardProps): Promise<IOrder> => {
  if (!rewardId) throw new Error('Please select a reward');
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/rewards`;
    const body = { spotOnCustomerId, rewardId, checkId };
    const { data } = await axios.post(url, body);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.response?.data?.error || error);
  }
};

export const useAddRewardOrDealToOrder = (
  options?: UseMutationOptions<IOrder, AxiosError, IAddOrRemoveOrderRewardProps>
) => {
  const updateOrderInCache = useUpdateOrderInCache();
  const { sendEvent } = useSendEvent();
  return useMutation(addRewardOrDealToOrder, {
    ...options,
    onSuccess: (data, variables, context) => {
      updateOrderInCache(data);
      sendEvent(EAnalyticsEventNames.REWARD_ADD, {
        googleAnalytics: {
          rewardId: variables.rewardId,
        },
      });
      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
  });
};
