import { dynamicSort } from '@olo-web/utils/common/functions';
import { getSubItemDisplayType, isSubItemGroupRequired, createFormGroupsFromMenuItem } from '.';

export const createFormSubItemGroups = (subItemGroups: ISubItemGroup[]): ISubItemGroupDisplay[] => {
  return (subItemGroups || [])
    .map((sig) => ({
      ...sig,
      isSelected: false,
      isValid: !isSubItemGroupRequired(sig),
      displayType: getSubItemDisplayType(sig),
      items: sig.items
        .filter((item) => item.isAvailable)
        .map((item) => ({
          ...item,
          formGroups: createFormGroupsFromMenuItem(item),
          isSelected: false,
          isValid: !isSubItemGroupRequired(item),
          qty: 0,
        })),
    }))
    .sort(dynamicSort('position'));
};
