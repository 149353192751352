export const getModifierDisplayType = (
  modifierGroup: IMenuItemModifierGroup
): 'radio' | 'checkbox-or-collapse' => {
  const optionsExists = modifierGroup.modifiers?.some((m) => !!m?.options?.length);
  if (
    (!optionsExists || modifierGroup.isChoiceList) &&
    modifierGroup.min === 1 &&
    modifierGroup.max === 1
  ) {
    return 'radio';
  }
  return 'checkbox-or-collapse';
};
