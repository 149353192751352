import { useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useHandleAsapLogicOnServer } from './useHandleAsapLogicOnServer';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getEstimatedTimeText = (
  orderTime: string,
  merchant: IMerchant,
  handleAsapLogicOnServer = false,
  order?: IOrder
) => {
  const useAsapFulfillmentLeadTime = order?.asapFulfillmentLeadTime && handleAsapLogicOnServer;

  if (!orderTime && !useAsapFulfillmentLeadTime) return '';

  const now = dayjs().utc().tz(merchant?.locationTimezone);
  const orderTimeDate = useAsapFulfillmentLeadTime
    ? now.add(order?.asapFulfillmentLeadTime, 'minute')
    : dayjs(orderTime).utc().tz(merchant?.locationTimezone);
  const minutesUntilOrderTime = orderTimeDate.diff(now, 'minute');
  const isOrderTimeToday = orderTimeDate.isSame(now, 'day');
  const isOrderTimeTomorrow = orderTimeDate.isSame(now.add(1, 'day'), 'day');
  // If orderTime is 0 - 90 min: 'in <Time Block Start> - <Time Block End> minutes'
  if (minutesUntilOrderTime <= 90) {
    return `in ${minutesUntilOrderTime} - ${minutesUntilOrderTime + 10} minutes`;
  }

  // If orderTime is > 90 min: ‘Today at <XX:XX> <AM / PM>’
  if (isOrderTimeToday && minutesUntilOrderTime > 90) {
    return `${orderTimeDate.format('[Today at] h:mm A')}`;
  }

  // If orderTime is tomorrow: 'Tomorrow at <XX:XX> <AM / PM>'
  if (isOrderTimeTomorrow) {
    return `${orderTimeDate.format('[Tomorrow at] h:mm A')}`;
  }

  // If orderTime is > tomorrow: '<Day (Short)>, <Month (Short)> <Date> at <XX:XX> <AM / PM>'
  return `${orderTimeDate.format('ddd, MMM D[th] [at] h:mm A')}`;
};

export const useEstimatedTimeText = (orderTime?: string) => {
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const handleAsapLogicOnServer = useHandleAsapLogicOnServer();

  return useMemo(
    () => getEstimatedTimeText(orderTime, merchant, handleAsapLogicOnServer, order),
    [orderTime, merchant, handleAsapLogicOnServer, order]
  );
};
