import { useRouter } from 'next/router';
import { getMerchantTheme } from './../queries/useMerchantTheme';
import { FirebaseError } from 'firebase-admin';
import { firestore } from '../../../utils/google/firebase/firebase';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { EFirestoreCollections } from '@olo-web/types/enums';

export const createMerchantTheme = async (merchantIds: string[]): Promise<string> => {
  try {
    const promises = merchantIds.map((id) => getMerchantTheme(id));
    const currentMerchantThemeDocs = await Promise.all(promises);

    const merchantIdsToUse = merchantIds.filter((_, idx) => !currentMerchantThemeDocs[idx]);

    const body = { fonts: {}, colors: {}, merchantIds: merchantIdsToUse };
    const resp = await firestore().collection(EFirestoreCollections.MERCHANT_THEME).add(body);
    return resp.id;
  } catch (error) {
    throw new Error(error?.message || error);
  }
};

export const useCreateMerchantTheme = (
  options?: UseMutationOptions<string, FirebaseError, string[]>
) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { merchantId } = router.query;
  return useMutation(createMerchantTheme, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData([EFirestoreCollections.MERCHANT_THEME, merchantId], {
        body: {},
        colors: {},
        id: data,
        merchantIds: variables,
      });
      if (options?.onSuccess) options.onSuccess(data, variables, context);
    },
  });
};
