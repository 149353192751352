import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useMerchantOrderTypeDetails } from '@domain/merchants/queries/useMerchantOrderTypeDetails';

export const useIsASAPUnavailable = () => {
  const { data: orderTypeDetails } = useMerchantOrderTypeDetails();
  const asapTime = orderTypeDetails?.asapOrderDateTime;

  const isAsapUnavailable = useMemo(() => !dayjs(asapTime).isSame(dayjs(), 'day'), [asapTime]);

  return isAsapUnavailable;
};
